import React from "react"

const LimitIntentsAlert = ({ uuidTransaccion, functionOnOut, disableFinishButton }) => {

    return (
        <div className="err_finale animate__animated animate__slideInUp">
            <div className="center_checks">
                <h5 className='ubuntu_bold_punto_trader'>Proceso no exitoso</h5>
                <p className='calibri_punto_trader text_size_punto_trader'>Has superado el número <b className="calibri_bold_punto_trader">máximo</b> de intentos para este proceso inténtalo nuevamente <b className="calibri_bold_punto_trader">más tarde</b>.</p>
                <p className='calibri_punto_trader text_size_punto_trader'>Si deseas mayor información no dudes en contactarnos con el <b className="calibri_bold_punto_trader">ID</b> de proceso <b className="calibri_bold_punto_trader">{(uuidTransaccion).split("-")[0]}</b> </p>
                <br />
                <div className="action_buttons noscroll_screen">
                    <button className='btn btn-raised btn-primary forcewidth100 button_punto_trader' onClick={() => functionOnOut()} disabled={disableFinishButton}>Entendido</button>
                </div>
            </div>
        </div>
    )
}

export default LimitIntentsAlert;