import React from 'react';
/* Librerías */
import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import history from './services/history';
/* Estilos */
import './App.css';
/* Componentes */
import Ayuda from './pages/ayuda';
import InformacionDomicilio from './pages/identificacion_edit';
import Finalizado from './pages/finalizado';
import PdfTokenInstructions from './pages/pdf_token_instructions';
import PdfTokenError from './pages/pdf_token_error';
import PdfTokenResult from './pages/pdf_token_result';
import PreparacionIne from './pages/preparacion_ine';
import ResultadosIdentificacion from './pages/resultados_identificacion';
import Terminos from './pages/terminos';
import Inicio from './pages/inicio';
import CancelarFlujo from './pages/cancelar_flujo';
import Clausula from './pages/clausula';
import ValidacionDocumento from './pages/validacion_documento';
import AutorizacionSolicitud from './pages/autorizacion_solicitud';
import AutorizacionDocumento from './pages/autorizacion_documento';
// import CameraVideoTokenIOS from './components/cameravideotoken_ios';
import PdfToken from './pages/pdf_token';
// import PDFVideoToken from './components/pdfvideotoken';
import Pivote from './pages/pivote';
import InicioSolicitud from './pages/inicio_solicitud';
import Causales from './pages/causales';
import Cancelado from './pages/cancelado';
import FormularioInformacionPersonal from './pages/forms/formulario_informacion_personal';
import FormularioInformacionPerfilamiento from './pages/forms/formulario_informacion_perfilamiento';
import PreparacionComprobanteDomicilio from './pages/preparacion_comprobante_domicilio';
import PreparacionComprobanteBancario from './pages/preparacion_comprobante_bancario';
import CameraPhotoComprobante from './components/cameraphotoComprobante_ios';
import FormularioCliente from './pages/forms/formulario_cliente';
import GraciasPorTuInteres from './pages/gracias_por_tu_interes';
import ComponenteVideoGrabacionIdentificacion from './components/componente_video_grabacion_identificacion';
import PreparacionVideoGrabacion from './pages/preparacion_video_grabacion';
import IndexComponenteCapturaIdentificacion from './components/captura_identificacion/index';
// import PreparacionLiveness from './pages/preparacion_liveness';
// import IndexComponenteLiveness from './components/liveness';
import LivenessPasivoTransitivo from './pages/liveness_pasivo_transitivo/flujo';
import LivenessPasivoTransitivoPDF from './pages/liveness_pasivo_transitivo/pdf';
import LivenessPasivoInteractivo from './pages/liveness_pasivo_interactivo/flujo';
import LivenessPasivoInteractivoPDF from './pages/liveness_pasivo_interactivo/pdf';
import IndicacionesLiveness from './pages/liveness_pasivo_interactivo/indicaciones_liveness';
import Videotoken from './pages/videotoken/flujo';
import VideotokenPDF from './pages/videotoken/pdf';
import Landscape from './components/landscape';
import CanceladoRenapo from './pages/cancelado_renapo';
import NotFound from './pages/not_found';

//const history = createBrowserHistory();

function App() {

  return (

    <Router history={history}>
      <Switch>
        <Route path="/terminos">
          <Terminos />
        </Route>
        <Route path="/preparacion_identificacion">
          <PreparacionIne />
        </Route>
        <Route path="/identificacion" >
          <IndexComponenteCapturaIdentificacion />
        </Route>
        {/* <Route path="/video_token" component={CameraVideoTokenIOS}>
        </Route> */}
        <Route path="/video_token" >
          <Videotoken />
        </Route>
        <Route path="/pdf_video_token">
          <VideotokenPDF />
        </Route>
        <Route path="/captura_comprobante">
          <CameraPhotoComprobante />
        </Route>
        <Route path="/resultados_identificacion">
          <ResultadosIdentificacion />
        </Route>
        <Route path="/informacion_domicilio">
          <InformacionDomicilio />
        </Route>
        <Route path="/validacion_documento">
          <ValidacionDocumento />
        </Route>
        <Route path="/autorizacion_solicitud">
          <AutorizacionSolicitud />
        </Route>
        <Route path="/autorizacion_documento">
          <AutorizacionDocumento />
        </Route>
        <Route path="/ayuda/:ruta">
          <Ayuda />
        </Route>
        <Route path="/envio_correo">
          <Finalizado />
        </Route>
        <Route path="/pdf_token_instructions">
          <PdfTokenInstructions />
        </Route>
        <Route path="/pdf_token_error">
          <PdfTokenError />
        </Route>
        <Route path="/pdf_token_result">
          <PdfTokenResult />
        </Route>
        <Route path="/clausula">
          <Clausula />
        </Route>
        <Route path="/cancelar_flujo/:ruta">
          <CancelarFlujo />
        </Route>
        <Route path="/pdf_token" >
          <PdfToken />
        </Route>
        <Route path="/pivote">
          <Pivote />
        </Route>
        <Route path="/inicio">
          <InicioSolicitud />
        </Route>
        <Route path="/causales">
          <Causales />
        </Route>
        <Route path="/cancelado">
          <Cancelado />
        </Route>
        <Route path="/informacion_personal">
          <FormularioInformacionPersonal />
        </Route>
        <Route path="/informacion_perfilamiento">
          <FormularioInformacionPerfilamiento />
        </Route>
        <Route path="/preparacion_comprobante_domicilio">
          <PreparacionComprobanteDomicilio />
        </Route>
        <Route path="/preparacion_comprobante_bancario">
          <PreparacionComprobanteBancario />
        </Route>
        <Route path="/cliente">
          <FormularioCliente />
        </Route>
        <Route path="/gracias_por_tu_interes">
          <GraciasPorTuInteres />
        </Route>
        <Route path="/preparacion_video_grabacion">
          <PreparacionVideoGrabacion />
        </Route>
        <Route path="/video_grabacion">
          <ComponenteVideoGrabacionIdentificacion />
        </Route>
        {/* <Route path="/preparacion_liveness">
          <PreparacionLiveness />
        </Route> */}
        {/* <Route path="/liveness">
          <IndexComponenteLiveness />
        </Route> */}
        <Route path="/selfie">
          <LivenessPasivoTransitivo />
        </Route>
        <Route path="/selfie_pdf">
          <LivenessPasivoTransitivoPDF />
        </Route>
        <Route path="/liveness">
          <LivenessPasivoInteractivo />
        </Route>
        <Route path="/liveness_pdf">
          <LivenessPasivoInteractivoPDF />
        </Route>
        <Route path="/preparacion_liveness">
          <IndicacionesLiveness />
        </Route>
        <Route path="/finalizado">
          <CanceladoRenapo />
        </Route>
        <Route path="/:apikey">
          <Inicio />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      <Landscape />
    </Router>
  );
}

export default App;
