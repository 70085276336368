import React, { useEffect, useState, useCallback } from 'react'
/* Componentes */
import Liveness from '../../../components/liveness_pasivo_transitivo'
import Loader from '../../../components/loader'
/* Funciones */
import { validarRenapo } from '../../../services/data'


const Index = () => {

    /* Hooks */
    const [apikey, setApikey] = useState('')
    const [uuidCliente, setUuidCliente] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [loading, setLoading] = useState(true)
    const [disabledButton, setDisabledButton] = useState(true)
    // const [nextStep, setNextStep] = useState('')
    const [dataOtorgante, setDataOtorgante] = useState([])

    const consultaRenapo = useCallback(async (paso = '') => {
        try {
            await validarRenapo(paso)
            let validacionRenapo = localStorage.getItem("validacionRENAPO")
            console.log(`🚀 ------------------------------------------------------------------------🚀`)
            console.log(`🚀 ~ file: index.jsx:45 ~ useEffect ~ validacionRenapo:`, validacionRenapo)
            console.log(`🚀 ------------------------------------------------------------------------🚀`)
            if (validacionRenapo && validacionRenapo === 'false') {
                setTimeout(async () => {
                    await consultaRenapo('videotoken')
                }, 30000)
            } else {
                setLoading(false)
                setDisabledButton(false)
            }
        } catch {
            await consultaRenapo('videotoken')
        }

    }, [])

    useEffect(() => {

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) {
            setApikey(apikeyOtorgante)
        }

        const uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser) {
            setUuidCliente(uuidUser)
        }

        const uuidTrx = localStorage.getItem("uuidTrx");
        if (uuidTrx) {
            setUuidTransaccion(uuidTrx)
        }

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            setDataOtorgante(dataOtorganteJSON)
        }

        // const consultaRenapo = async (paso = '') => {
        //     await validarRenapo(paso)
        //     let validacionRenapo = localStorage.getItem("validacionRENAPO")
        //     console.log(`🚀 ------------------------------------------------------------------------🚀`)
        //     console.log(`🚀 ~ file: index.jsx:45 ~ useEffect ~ validacionRenapo:`, validacionRenapo)
        //     console.log(`🚀 ------------------------------------------------------------------------🚀`)
        //     if (validacionRenapo && validacionRenapo === 'false') {
        //         setTimeout(async () => {
        //             await consultaRenapo('videotoken')
        //         }, 30000)
        //     } else {
        //         setLoading(false)
        //         setDisabledButton(false)
        //     }
        // }

        // consultaRenapo()

        // if (validarOtorganteCDC())
        //     setNextStep('/finalizado')
        // else
        //     setNextStep('/consulta_historial')

    }, [])

    useEffect(() => {
        consultaRenapo()
    }, [consultaRenapo])

    return (
        <>
            <Liveness
                apikey={apikey}
                uuidCliente={uuidCliente}
                uuidTransaccion={uuidTransaccion}
                dataOtorgante={dataOtorgante}
                service_call={'videotoken-embedding'}
                previousStep={`/${apikey}`}
                nextStep={'/envio_correo'}
                cancelStep={`/selfie`}
                type={'flujo'}
                intents={'3'}
                disabledButton={disabledButton}
            />
            {loading && <Loader />}
        </>
    );
}

export default Index;
