import React, { useEffect, useState } from 'react';
/* Componentes */
import Videotoken from '../../../components/videotoken';

const Index = () => {

    /* Hooks */
    const [apikey, setApikey] = useState('')
    const [uuidCliente, setUuidCliente] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) {
            setApikey(apikeyOtorgante)
        }

        const uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser) {
            setUuidCliente(uuidUser)
        }

        const uuidTrx = localStorage.getItem("uuidTrx");
        if (uuidTrx) {
            setUuidTransaccion(uuidTrx)
        }

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            setDataOtorgante(dataOtorganteJSON)
        }

    }, []);

    return (
        <>
            <Videotoken
                apikey={apikey}
                uuidCliente={uuidCliente}
                uuidTransaccion={uuidTransaccion}
                dataOtorgante={dataOtorgante}
                service_call={'videotoken-pdf'}
                previousStep={`/pdf_token?uuid=${uuidCliente}&idOtorgante=${localStorage.getItem("idOtorgante")}&uuidTrx=${uuidTransaccion}&fecha=${localStorage.getItem("fechavigencia")}`}
                nextStep={{ pathname: '/pdf_token_result'}}
                type='pdf'
                intents={'5'}
            />
        </>
    );
}

export default Index;
