/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
/*  Componentes */
import IdTransaccion from "../components/id_transaccion";
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom";

const Ayuda = ({ hide }) => {

	useScrollToTop()

	const [dataOtorgante, setDataOtorgante] = useState([])

	useEffect(() => {
		const dataOtorganteLS = localStorage.getItem("dataOtorgante")
		if (dataOtorganteLS) {
			setDataOtorgante(JSON.parse(dataOtorganteLS))
			configStyle(JSON.parse(dataOtorganteLS))
		}
	}, [])

	useEffect(() => {
		configStyle(dataOtorgante)
	}, [dataOtorgante])

	return (
		<div className="main_gradient screen_in_front">
			<div id="head_container">
				<a className="help_back" onClick={() => hide()}><img src={process.env.PUBLIC_URL + '/images/btn_square_back.svg'} alt="" /></a>
				<img className="comp_logo" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") !== "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
			</div>
			<div className="main_text_container flex_body">
				<h1 className="title_punto_trader animate__animated">Estamos para ti.</h1>
				<p className="text_punto_trader animate__animated">Si <b className="calibri_bold_punto_trader">necesitas ayuda</b> durante el proceso o tienes <b className="calibri_bold_punto_trader">algún problema</b> con el uso de la plataforma.</p>
				<br />
				<div className="help_container">
					<div className="text-color-p margin-div-help"><b className="calibri_bold_punto_trader">Escríbenos a:</b>
						<br />
						<div className="div_flex">
							<img src={process.env.PUBLIC_URL + '/images/arroba.svg'} /><a className="calibri_bold_punto_trader text_size_punto_trader" href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a>
						</div>
					</div>
					<p className="help_mail"><b className="calibri_bold_punto_trader">Llámanos al:</b>
						<br />
						<img src={process.env.PUBLIC_URL + '/images/phone.svg'} /><a className="calibri_bold_punto_trader text_size_punto_trader" href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a>
					</p>
					<p className="help_mail"><b className="calibri_bold_punto_trader">Visítanos en:</b>
						<br />
						<img src={process.env.PUBLIC_URL + '/images/arroba.svg'} /><a className="calibri_bold_punto_trader text_size_punto_trader" rel="external" href={'https://puntotrader.mx/'}>https://puntotrader.mx/</a>
					</p>
				</div>
			</div>
			{/* <Footer /> */}
			<IdTransaccion />
		</div>
	)
}

export default Ayuda