import React, { useState, useEffect } from "react"
/* Librerías */
import { useParams } from "react-router-dom"
import { Dicio } from '@dicio/pivot-react'
/* Componentes */
import Header from "../components/header"
import Footer from "../components/footer"

const Pivote = () => {

    /* Params */
    const { apikey } = useParams();
    /* Hooks */
    const [errores, setErrores] = useState('')
    const [status, setStatus] = useState([])

    useEffect(() => {
        console.log(`🚀 -----------------------------------------------------🚀`)
        console.log(`🚀 ~ file: pivote.jsx:19 ~ useEffect ~ status:`, status)
        console.log(`🚀 -----------------------------------------------------🚀`)
    }, [status])

    return (
        <div>
            <div className="main_gradient">
                <Header ruta={apikey} />
                <br />
                <div className="action_buttons">
                    <Dicio
                        auth={{
                            id: "eb97bcad-b465-49f2-b5e0-d2ed1751f0b1",
                            key: "i2MopevgEp2HSmOppDOR3MHUzT4SEZOi"
                        }}
                        text="Iniciar proceso"
                        variant="only_logo"
                        debug={true}
                        mode="dev"
                        status={(evento) => {
                            console.log("evento", evento);
                            setStatus([...status, { evento }])
                        }}
                        errors={(error => {
                            console.log("error", error);
                            setErrores(JSON.stringify(error))
                        })}
                        finish={(data) => {
                            console.log("finish", data);
                            setStatus([...status, { data }])
                        }}>
                    </Dicio>

                </div>
                <div className="main_text_container">
                    <table>
                        <thead>
                            <tr>
                                <th>Step</th>
                                <th>Status</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                    status.map(item => (
                                            (item.evento) ? 
                                            <tr key="">
                                                <td>{item.evento.step}</td>
                                                <td>{item.evento.result}</td>
                                                <td>{JSON.stringify(item.evento.data)}</td>
                                            </tr>
                                            :
                                            <tr key="">
                                                <td>{item.data.step}</td>
                                                <td>{item.data.result}</td>
                                                <td>{JSON.stringify(item.data.data)}</td>
                                            </tr>
                                    ))
                                }
                        </tbody>
                    </table>
                    {
                        (errores !== undefined) ?
                            <div>
                                <h3>Errores</h3>
                                <p>{errores}</p>
                            </div>
                            :
                            null
                    }

                </div>

                <Footer />
            </div>
        </div>
    )
}

export default Pivote