import React, { useState, useEffect } from "react"
/* Librerías */
import { useParams, useHistory, useLocation } from "react-router-dom"
import { isIOS } from 'react-device-detect'
/* Componentes */
import Loader from '../components/loader'
import PantallaCancelarFlujo from "./flujo/cancelar_flujo"
/* Funciones */
import { configStyle } from '../services/configStyle'
import { evento, sendData, generateZip, cancelarINE, validarParametroOtorgante } from "../services/data"
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"

const CancelarFlujo = () => {

    useScrollToTop()

    /* Location */
    const location = useLocation()
    /* Hooks */
    const history = useHistory()
    const { ruta } = useParams()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [loading, setLoading] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancelScreen, setShowCancelScreen] = useState(false)

    useEffect(() => {
        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])


    const continuarFlujo = () => {
        if (location.state) {
            if (location.state.passport) {
                history.replace({
                    pathname: '/' + ruta,
                    state: { passport: true }
                })
            } else {
                history.replace('/' + ruta)
            }
        } else {
            history.replace('/' + ruta)
        }
    }

    const salirFlujo = () => {
        cancelarINE()
        localStorage.setItem('flag', 'cancelado');
        evento('Cancelar Flujo', 'Click', { description: 'CANCELAR' }, true);
        statusE()
    }

    const statusE = async () => {
        setLoading(true);
        if (!validarRuta()) {
            await generateZip('TRUNCOS', 'trunco', isIOS)
            const json_final = {
                "status": 'INCOMPLETED',
                "step": "captura_video_token",
                "description": 'Cancelado'
            }
            const event = JSON.stringify(json_final)
            await sendData({ 'step': 'captura_video_token', 'event': event })
            localStorage.setItem('completado', 'true')
        }
        if (window.opener) {
            window.opener.postMessage(JSON.stringify({
                step: 'captura_video_token',
                status: 'INCOMPLETED',
                type: "PROCESS",
                error_text: 'Cancelado',
            }), localStorage.getItem("externalReference"))
        }
        if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
            setTimeout(() => {
                const rutaCancelar = (!validarRuta()) ? `/${apiKey}` : `/pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&uuidTrx=${uuidTransaccion}&fecha=${localStorage.getItem("fechavigencia")}`
                console.log(`🚀 --------------------------------------------------------------------------🚀`)
                console.log(`🚀 ~ file: cancelar_flujo.jsx:88 ~ setTimeout ~ rutaCancelar:`, rutaCancelar)
                console.log(`🚀 --------------------------------------------------------------------------🚀`)
                history.push(rutaCancelar)
                setLoading(false)
            }, 300);
        } else {
            setShowCancelScreen(true)
            setLoading(false)
        }
    }

    const validarRuta = () => {
        let validacion = false
        if (ruta.includes('pdf')) {
            validacion = true
        }
        return validacion
    }

    return (
        <>
            <div className="container">
                <div className="row" style={{ height: '100vh' }}>
                    <div className="col-2"></div>
                    <div className="col">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="card text-right">
                            <div className="card-body">
                                <h5 className="ubuntu_bold_punto_trader card-title">Cancelar proceso</h5>
                                <p className="calibri_punto_trader text_size_punto_trader card-text">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse.  <br /> ¿Deseas cancelar?</p>
                                <button type="button" className="btn btn-secondary text_color_punto_trader calibri_punto_trader text_size_punto_trader" onClick={continuarFlujo}>No</button>
                                <button type="button" className="btn btn-raised btn-primary button_punto_trader" onClick={salirFlujo}>Sí</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
            {loading && <Loader />}
            {showCancelScreen && <PantallaCancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} />}
        </>
    )

}

export default CancelarFlujo