import React from 'react';
import { obtenerValorConfig } from '../utilities/styles/configStyle';

const IconCheck = ({dataOtorgante}) => {
    return (
        <>
            <svg width="24px" height="24px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <title>AB60EA68-1829-4FCA-BDE4-5436FADD1F2E</title>
                <defs>
                    <circle id="path-1" cx="12" cy="12" r="12"></circle>
                    <filter x="-25.0%" y="-20.8%" width="150.0%" height="150.0%" filterUnits="objectBoundingBox" id="filter-2">
                        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                        <feColorMatrix values="0 0 0 0 0.0823529412   0 0 0 0 0.0980392157   0 0 0 0 0.129411765  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                </defs>
                <g id="OCR-iOS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="03.ID_capture_f" transform="translate(-38.000000, -242.000000)">
                        <g id="Group-2" transform="translate(42.000000, 245.000000)">
                            <g id="Oval">
                                <use fill="black" fillOpacity="1" filter="url(#filter-2)" href="#path-1"></use>
                                <circle stroke="#FFFFFF" strokeWidth="1" fill={obtenerValorConfig(dataOtorgante, "COLOR_ACENTUACION")} fillRule="evenodd" cx="12" cy="12" r="12.5"></circle>
                            </g>
                            <path d="M16.5761738,8.00628463 C16.4277173,8.02768571 16.2908315,8.09855948 16.1876805,8.20743029 C13.9649305,10.4345683 12.3013019,12.2724016 10.1799084,14.4221354 L7.7656998,12.3829346 C7.58364919,12.2282804 7.33278042,12.182764 7.10798688,12.2636023 C6.88319334,12.3444406 6.71879631,12.5392913 6.67698047,12.7744504 C6.63516463,13.0096095 6.72231438,13.249173 6.90546454,13.4025239 L9.79141509,15.8440161 C10.0570176,16.0670338 10.4493477,16.0489297 10.6932746,15.8023998 C13.0749944,13.4160409 14.7769701,11.5026435 17.1311643,9.14379803 C17.3377762,8.94328758 17.3921252,8.63273822 17.2658864,8.37399809 C17.1396476,8.11525795 16.8614067,7.96691658 16.5761738,8.00628463 L16.5761738,8.00628463 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
}

export default IconCheck;
