import React, { useState, useEffect } from "react"
/* Librerías */
import { Link, useHistory } from "react-router-dom"
import { isIOS } from 'react-device-detect'
/* Componentes */
import Header from "../components/header"
import Footer from "../components/footer"
import Ayuda from './ayuda'
import Loader from '../components/loader'
import CancelarFlujo from "./flujo/cancelar_flujo"
import IdTransaccion from "../components/id_transaccion"
/* Funciones */
import { configStyle } from '../services/configStyle'
import { status, statusError, validarRenapo, generateZip, cancelarINE, sendEventClick, validarParametroOtorgante } from '../services/data'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"


const PreparacionComprobanteDomicilio = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [showCancel, setShowCancel] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        status("preparacion_comprobante_domicilio_page", "Preparacion Comprobante Domicilio")
        validarRenapo()
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = async () => {
        setLoading(true)
        cancelarINE();
        sendEventClick('Preparacion Comprobante Domicilio', 'SI', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("preparacion_comprobante_domicilio", 'Cancelado', "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300)
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    return (
        <>
            {(showCancel) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={() => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => { setShowCancel(false); sendEventClick('Preparacion Comprobante Domicilio', 'NO') }} className="btn btn-secondary text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-primary button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className="main_gradient flex_container">
                <Header show={() => setShowHelp(true)} />
                <div className="flex_body">
                    <div className="main_text_container">
                        <h1 className="title_punto_trader">Envía tu comprobante de domicilio</h1>
                        <p className="text_punto_trader">Puedes capturar tu <b className="calibri_bold_punto_trader">recibo de luz</b>, <b className="calibri_bold_punto_trader">teléfono fijo</b>, <b className="calibri_bold_punto_trader">internet</b> o <b className="calibri_bold_punto_trader">gas</b>, no mayor a tres meses.</p>
                    </div>
                    <div className="main_icon_container">
                        <img src="images/bnw/doc_check_b.svg" alt="" />
                    </div>
                </div>

                <div className="action_buttons flex_buttons">
                    <Link to={{
                        pathname: "/captura_comprobante", state: { tipoComprobante: 'domicilio' }
                    }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader" onClick={() => sendEventClick('Preparacion Comprobante Domicilio', 'CAPTURAR')} replace>
                        Capturar</Link>
                    <button onClick={() => { setShowCancel(true); sendEventClick('Preparacion Comprobante Domicilio', 'CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader">
                        Cancelar</button>
                </div>
                <IdTransaccion />
                <Footer />
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {loading && <Loader />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </>
    )

}


export default PreparacionComprobanteDomicilio