import React, { useState, useEffect } from "react"
/* Librerías */
import { useHistory } from "react-router-dom"
import 'materialize-css'
import { Button } from 'react-materialize'
/* Componentes */
import Header from "../components/headerNA"
import Ayuda from './ayuda'
import CancelarFlujo from "./flujo/cancelar_flujo"
import Loader from "../components/loader"
/* Funciones */
import { configStyle } from '../services/configStyle'
import { status, statusError, sendEventClick, validarParametroOtorgante } from '../services/data'
/* Custom hooks */
import { useScrollToTop } from '../utilities/hooks/custom'
import IdTransaccion from "../components/id_transaccion";

const InicioSolicitud = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [showHelp, setShowHelp] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataUser, setDataUser] = React.useState({})
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {
        const dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS) setDataUser(JSON.parse(dataUserLS))

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        status("inicio_solicitud_page", "Inicio solicitud")
    }, [])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = async () => {
        setLoading(true)
        sendEventClick('Inicio solicitud', 'SI', { status: 'CANCELADO' })
        const data = "Cancelado"
        localStorage.setItem('flag', 'cancelado')
        await statusError("inicio_solicitud", data, "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    return (
        <>
            <div className="main_gradient">
                <div className="flex_container">
                    <Header show={() => setShowHelp(true)} />
                    <div className="flex_body">
                        <div className="main_text_container custom_padding">
                            <h1 className="title_punto_trader animate__animated animate__fadeIn animate__delay-1s" style={{ marginBottom: '0' }}>Inicia tu solicitud.</h1>
                            <div className="animate__animated animate__fadeIn animate__delay-2s">
                                <p className="text_punto_trader">Para el proceso de contratación, necesitas lo siguiente:</p>
                                <ul className="nomrgnnopdng bullets calibri_punto_trader animate__animated animate__fadeIn animate__delay-2s">
                                    <li><b className="calibri_bold_punto_trader">Una identificación oficial vigente:</b>
                                        <ul className="sub_list">
                                            <li className="spaceLi"><em>INE / IFE.</em></li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li>Estado de cuenta bancario.</li>
                                    <li>Comprobante de domicilio.</li>
                                    <li>Clave RFC.</li>
                                    <br />
                                    <li>Dar <b className="calibri_bold_punto_trader">acceso a tu cámara.</b></li>
                                    <li>Una <b className="calibri_bold_punto_trader">buena conexión</b> a internet.</li>
                                    <li>Audífonos <b className="calibri_bold_punto_trader">desconectados.</b></li>
                                </ul>
                                <br />
                                <p className="text_punto_trader">Límite operativo <b className="calibri_bold_punto_trader">3 mil UDIS</b>
                                    <Button
                                        className="button_info_udis"
                                        node="button"
                                        tooltip="UDIS: Unidades de Inversión."
                                        tooltipOptions={{
                                            position: 'bottom'
                                        }}
                                        waves="light"
                                    >
                                        <img className="info_icon_udis" src="../images/info_icon.svg" alt="" />
                                    </Button> </p>
                            </div>
                        </div>
                    </div>
                    <div className="action_buttons animate__animated animate__fadeIn animate__delay-2s flex_buttons">
                        <button className="btn btn-raised btn-primary forcewidth100 button_punto_trader" onClick={() => { sendEventClick('Inicio solicitud', 'EMPEZAR'); history.replace('/causales') }}>Empezar</button>
                        <button onClick={e => { setShowCancel(true); sendEventClick('Inicio solicitud', 'CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader">
                            Cancelar</button>
                    </div>
                    <IdTransaccion />
                </div>
            </div>
            {(showCancel) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => { setShowCancel(false); sendEventClick('Inicio solicitud', 'NO') }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {loading && <Loader />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </>
    )
}

export default InicioSolicitud