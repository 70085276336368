/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { isMobile, isIOS, isSafari } from 'react-device-detect'
import { useHistory } from "react-router-dom"
import NumberFormat from 'react-number-format'
/* Componentes */
import Header from "../../components/headerNA"
import Footer from "../../components/footer"
import Ayuda from '../ayuda'
import Loader from '../../components/loader'
import CancelarFlujo from "../flujo/cancelar_flujo"
import IdTransaccion from "../../components/id_transaccion"
import EfirmaInfo from "../../components/alerts_modals/efirma_info"
import { useScrollToTop } from "../../utilities/hooks/custom"
/* Funciones */
import { configStyle } from '../../services/configStyle'
import { agregarInfo, statusData, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario, status, statusError, findInArray, validarRenapo, validarListasNegras, generateZip, sendEventClick, validarParametroOtorgante, cancelarINE } from '../../services/data'
import { onCut, onPaste } from "../../services/data"
/* Servicios */
import { obtenerCatalogo } from '../../services/api'
/* Constantes */
import { cargos, servicios, constantEntidadesFederativas, constantPaises, constantNacionalidades, constantTransaccionalidades, constantActividadesEconomicas, constantFuentesIngresos, estadosCiviles, generos } from "../../services/constanst"

let FormularioInformacionPersonal = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [uuidUsuario, setUuidUsuario] = useState('')
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusApellidoPaterno, setFocusApellidoPaterno] = useState(false)
    const [focusApellidoMaterno, setFocusApellidoMaterno] = useState(false)
    const [focusGenero, setFocusGenero] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusTelefono, setFocusTelefono] = useState(false)
    const [focusEmail, setFocusEmail] = useState(false)
    const [focusEmpresa, setFocusEmpresa] = useState(false)
    const [focusRfc, setFocusRfc] = useState(false)
    const [focusResidenciaFiscal, setFocusResidenciaFiscal] = useState(false)
    const [focusNumeroCuenta, setFocusNumeroCuenta] = useState(false)
    const [focusCLABE, setFocusCLABE] = useState(false)
    const [focusNumeroDepositos, setFocusNumeroDepositos] = useState(false)
    const [focusNumeroRetiros, setFocusNumeroRetiros] = useState(false)
    const [continuar, setContinuar] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [sexoOcrVacio, setSexoOcrVacio] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [infoTrue, setInfoTrue] = useState(false)
    const [focusOtroCargoPolitico, setFocusOtroCargoPolitico] = useState(false)
    const [focusNombreCargoPolitico, setFocusNombreCargoPolitico] = useState(false)
    const [focusInstitucion, setFocusInstitucion] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [focusOtraFuenteIngresos, setFocusOtraFuenteIngresos] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [focusPais, setFocusPais] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [focusFirma, setFocusFirma] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [inputActive, setInputActive] = useState(false)
    const [disabledCheck, setDisabledCheck] = useState(false)
    const [validar, setValidar] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [entidadesFederativas, setEntidadesFederativas] = useState([])
    const [paises, setPaises] = useState([])
    const [nacionalidades, setNacionalidades] = useState([])
    const [transaccionalidades, setTransaccionalidades] = useState([])
    const [actividadesEconomicas, setActividadesEconomicas] = useState([])
    const [inputsErrors, setInputsErrors] = useState({})
    const [inputsValue, setInputsValue] = useState({
        nombres: '',
        apellidoMaterno: '',
        apellidoPaterno: '',
        telefono: '',
        email: '',
        entidadFederativa: '',
        pais: '',
        nacionalidad: '',
        actividadEconomica: '',
        rfc: '',
        numSerieEFirma: '',
        residenciaFiscal: '',
        numeroCuenta: '',
        clabe: '',
        numeroDepositos: '',
        numeroRetiros: '',
        fuenteIngresos: '',
        empresa: '',
        transaccionalidadMensualDepositos: '',
        transaccionalidadMensualRetiros: '',
        cargoPolitico: '',
        tipoServicio: '',
        estadoCivil: '',
    });
    const [showEfirmaInfo, setShowEfirmaInfo] = useState(false);

    useEffect(() => {

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        const uuidUserLS = localStorage.getItem("uuidUser")
        if (uuidUserLS) setUuidUsuario(uuidUserLS)

        const ocrFront = JSON.parse(localStorage.getItem("ocrFront"))
        if (ocrFront) {
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, 'fechaNacimiento': fechaNacimiento }))
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, 'fechaNacimiento': fechaNacimiento }))
                }
            }

            if (ocrFront.nombres)
                setInputsValue(inputsValue => ({ ...inputsValue, 'nombres': ocrFront.nombres }))
            if (ocrFront.apellidoMaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, 'apellidoMaterno': ocrFront.apellidoMaterno }))
            if (ocrFront.apellidoPaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, 'apellidoPaterno': ocrFront.apellidoPaterno }))
            if (ocrFront.sexo) {
                const genero = (ocrFront.sexo === "H") ? 'Masculino' : 'Femenino'
                setInputsValue(inputsValue => ({ ...inputsValue, 'genero': genero }))
            } else {
                setSexoOcrVacio(true)
            }
        }
        status("informacion_personal_page", "Informacion Personal")

        obtenerCatalogo('estado').then(({ status, data }) => {
            if (status === 200) {
                setEntidadesFederativas(data)
            }
        }).catch(error => {
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: formulario_informacion_personal.jsx:130 ~ obtenerCatalogo ~ error:`, { error })
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            setEntidadesFederativas(constantEntidadesFederativas)
        })

        obtenerCatalogo('paises').then(({ status, data }) => {
            if (status === 200) {
                setPaises(data)
            }
        }).catch(error => {
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: formulario_informacion_personal.jsx:141 ~ obtenerCatalogo ~ error:`, { error })
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            setPaises(constantPaises)
        })

        obtenerCatalogo('nacionalidad').then(({ status, data }) => {
            if (status === 200) {
                setNacionalidades(data)
            }
        }).catch(error => {
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: formulario_informacion_personal.jsx:152 ~ obtenerCatalogo ~ error:`, { error })
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            setNacionalidades(constantNacionalidades)
        })

        obtenerCatalogo('depositos').then(({ status, data }) => {
            if (status === 200) {
                setTransaccionalidades(data)
            }
        }).catch(error => {
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: formulario_informacion_personal.jsx:163 ~ obtenerCatalogo ~ error:`, { error })
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            setTransaccionalidades(constantTransaccionalidades)
        });

        obtenerCatalogo('actividad_economica').then(({ status, data }) => {
            if (status === 200) {
                setActividadesEconomicas(data)
            }
        }).catch(error => {
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: formulario_informacion_personal.jsx:174 ~ obtenerCatalogo ~ error:`, { error })
            console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
            setActividadesEconomicas(constantActividadesEconomicas)
        });

        /* obtenerCatalogo('db').then(({ status, data }) => {
            if (status === 200) {
                console.log('DB',data);
            }
        }).catch(error => {
            console.log('Error: ' + error);
        }); */

        setInputsValue(inputsValue => ({ ...inputsValue, 'pais': 'México' }))
        setInputsValue(inputsValue => ({ ...inputsValue, 'nacionalidad': 'Mexicana' }))
        setInputsValue(inputsValue => ({ ...inputsValue, 'residenciaFiscal': 'México' }))

        validarRenapo()
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        const validacion = validarValoresFormulario(inputsValue)
        if (validacion.correcto) {
            setContinuar(true)
            setInputsErrors(validacion.errores)
        } else {
            setContinuar(false)
            setInputsErrors(validacion.errores)
        }
    }, [inputsValue])

    useEffect(() => {
        if (validar) {
            const validacion = validarValoresFormulario(inputsValue)
            if (validacion.correcto) {
                validarFormulario()
                setInputsErrors(validacion.errores)
            } else {
                setContinuar(false)
                setInputsErrors(validacion.errores)
                setValidar(false)
            }
        }
    }, [validar, inputsValue])

    const sendData = (infoFormulario) => {
        const jsonObj = {}
        jsonObj.informacionPersonal = infoFormulario
        statusData("informacion_personal", jsonObj)
    }

    const handleChange = (event) => {
        event.persist()
        let validacion = null
        let value = event.target.value.trim()
        if (event.target.name === 'rfc') {
            let maxLength = 13;
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength)
                event.target.value = value
            }
            validacion = validarCampo(value, event.target.name)
        } else {
            validacion = validarCampo(value, event.target.name)
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }))
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }))
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }))
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }))
        }

    };

    const validarFormulario = () => {
        setDisabledCheck(true)
        sendEventClick('Información Personal', 'GUARDAR');
        // if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        let nextStep = ''
        let infoFormulario = []
        let actividadEconomicaObjeto = {}
        let paisObjeto = {}
        let entidadFederativaObjeto = {}
        let nacionalidadObjeto = {}
        let transaccionalidadDepositosObjeto = {}
        let transaccionalidadRetirosObjeto = {}
        let objetoNumeroOperacionesMensuales = {}

        actividadEconomicaObjeto.id = findInArray(actividadesEconomicas, inputsValue.actividadEconomica, 'id_fc_actividad_az').id_actividad_az
        actividadEconomicaObjeto.descripcion = findInArray(actividadesEconomicas, inputsValue.actividadEconomica, 'id_fc_actividad_az').id_fc_actividad_az
        paisObjeto.id = findInArray(paises, inputsValue.pais, 'descripcion').id_pais_az
        paisObjeto.descripcion = findInArray(paises, inputsValue.pais, 'descripcion').descripcion
        entidadFederativaObjeto.id = findInArray(entidadesFederativas, inputsValue.entidadFederativa, 'descripcion').id_estado_az
        entidadFederativaObjeto.descripcion = findInArray(entidadesFederativas, inputsValue.entidadFederativa, 'descripcion').descripcion
        nacionalidadObjeto.id = findInArray(nacionalidades, inputsValue.nacionalidad, 'descripcion').id_nac_az
        nacionalidadObjeto.descripcion = findInArray(nacionalidades, inputsValue.nacionalidad, 'descripcion').descripcion
        transaccionalidadDepositosObjeto.id = findInArray(transaccionalidades, inputsValue.transaccionalidadMensualDepositos, 'descripcion').id_depositos_az
        transaccionalidadDepositosObjeto.descripcion = findInArray(transaccionalidades, inputsValue.transaccionalidadMensualDepositos, 'descripcion').descripcion
        transaccionalidadRetirosObjeto.id = findInArray(transaccionalidades, inputsValue.transaccionalidadMensualRetiros, 'descripcion').id_depositos_az
        transaccionalidadRetirosObjeto.descripcion = findInArray(transaccionalidades, inputsValue.transaccionalidadMensualRetiros, 'descripcion').descripcion

        agregarInfo("nombres", inputsValue.nombres);
        infoFormulario.push({ description: "nombres", value: inputsValue.nombres });
        agregarInfo("apellidoPaterno", inputsValue.apellidoPaterno);
        infoFormulario.push({ description: "apellidoPaterno", value: inputsValue.apellidoPaterno });
        agregarInfo("apellidoMaterno", inputsValue.apellidoMaterno);
        infoFormulario.push({ description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
        agregarInfo("genero", inputsValue.genero);
        infoFormulario.push({ description: "genero", value: inputsValue.genero });
        agregarInfo("fechaNacimiento", inputsValue.fechaNacimiento);
        infoFormulario.push({ description: "nacimiento", value: inputsValue.fechaNacimiento });
        agregarInfo("estadoCivil", inputsValue.estadoCivil);
        infoFormulario.push({ description: "estadoCivil", value: inputsValue.estadoCivil });
        agregarInfo("telefono", inputsValue.telefono);
        infoFormulario.push({ description: "numeroCelular", value: inputsValue.telefono });
        agregarInfo("email", inputsValue.email);
        infoFormulario.push({ description: "correoElectronico", value: inputsValue.email });
        agregarInfo("lugarNacimiento", entidadFederativaObjeto);
        infoFormulario.push({ description: "entidadFederativa", value: entidadFederativaObjeto });
        infoFormulario.push({ description: "paisNacimiento", value: paisObjeto });
        paisObjeto.descripcion = paisObjeto.descripcion.toUpperCase();
        agregarInfo("paisNacimiento", paisObjeto);
        infoFormulario.push({ description: "nacionalidad", value: nacionalidadObjeto });
        nacionalidadObjeto.descripcion = nacionalidadObjeto.descripcion.toUpperCase();
        agregarInfo("nacionalidad", nacionalidadObjeto);
        agregarInfo("empresaTrabaja", inputsValue.empresa);
        infoFormulario.push({ description: "empresa", value: inputsValue.empresa });
        infoFormulario.push({ description: "actividadEconomica", value: actividadEconomicaObjeto });
        actividadEconomicaObjeto.descripcion = actividadEconomicaObjeto.descripcion.toUpperCase();
        agregarInfo("actividadEconomica", actividadEconomicaObjeto);
        agregarInfo("rfc", inputsValue.rfc);
        agregarInfo("numSerieEFirma", inputsValue.numSerieEFirma);
        infoFormulario.push({ descripcion: 'numSerieEFirma', value: inputsValue.numSerieEFirma })
        infoFormulario.push({ description: "rfc", value: inputsValue.rfc });
        agregarInfo("residenciaFiscal", inputsValue.residenciaFiscal.toLocaleUpperCase());
        infoFormulario.push({ description: "residenciaFiscal", value: inputsValue.residenciaFiscal });
        agregarInfo("numeroCuenta", inputsValue.numeroCuenta);
        infoFormulario.push({ description: "numeroCuenta", value: inputsValue.numeroCuenta });
        agregarInfo("clabe", inputsValue.clabe);
        infoFormulario.push({ description: "clabe", value: inputsValue.clabe });
        infoFormulario.push({ description: "numeroDepositos", value: inputsValue.numeroDepositos });
        objetoNumeroOperacionesMensuales.numeroDepositos = inputsValue.numeroDepositos;
        infoFormulario.push({ description: "transaccionalidadMensualDepositos", value: transaccionalidadDepositosObjeto });
        objetoNumeroOperacionesMensuales.transaccionalidadMensualDepositos = inputsValue.transaccionalidadMensualDepositos;
        infoFormulario.push({ description: "numeroRetiros", value: inputsValue.numeroRetiros });
        objetoNumeroOperacionesMensuales.numeroRetiros = inputsValue.numeroRetiros;
        infoFormulario.push({ description: "transaccionalidadMensualRetiros", value: transaccionalidadRetirosObjeto });
        objetoNumeroOperacionesMensuales.transaccionalidadMensualRetiros = inputsValue.transaccionalidadMensualRetiros;
        agregarInfo("numeroOperacionesMensuales", objetoNumeroOperacionesMensuales);
        agregarInfo("fuenteIngresos", inputsValue.fuenteIngresos);
        infoFormulario.push({ description: "fuenteIngresos", value: inputsValue.fuenteIngresos });
        agregarInfo("esPEP", inputsValue.cargoPolitico);
        infoFormulario.push({ description: "cargoPolitico", value: inputsValue.cargoPolitico });
        agregarInfo("tipoServicio", inputsValue.tipoServicio);
        infoFormulario.push({ description: "tipoServicio", value: inputsValue.tipoServicio });
        if (inputsValue.otroCargoPolitico) {
            infoFormulario.push({ description: "otroCargoPolitico", value: inputsValue.otroCargoPolitico });
        }
        if (inputsValue.nombreCargoPolitico) {
            infoFormulario.push({ description: "nombrePersonaCargoPolitico", value: inputsValue.nombreCargoPolitico });
        }
        if (inputsValue.institucion) {
            infoFormulario.push({ description: "nombreInstitucion", value: inputsValue.institucion });
        }
        if (inputsValue.numeroOperacionesMensuales) {
            infoFormulario.push({ description: "numeroOperacionesMensuales", value: inputsValue.numeroOperacionesMensuales });
        }

        /* Consulta listas negras */
        validarListasNegras(uuidUsuario, inputsValue.nombres, inputsValue.apellidoPaterno, inputsValue.apellidoMaterno, inputsValue.fechaNacimiento)
        nextStep = (inputsValue.tipoServicio === 'Gestión de Inversiones') ? '/informacion_perfilamiento' : (inputsValue.tipoServicio === 'Ejecución de Operaciones') && '/informacion_domicilio'
        setTimeout(() => {
            sendData(infoFormulario)
            history.replace(nextStep)
        }, 300);

    }

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase()

    useEffect(() => {
        if (inputsValue.cargoPolitico && inputsValue.cargoPolitico !== 'No') {
            setInputsValue(inputsValue => ({ ...inputsValue, 'nombreCargoPolitico': '' }))
            setInputsValue(inputsValue => ({ ...inputsValue, 'institucion': '' }))
            if (inputsValue.cargoPolitico === 'Otro')
                setInputsValue(inputsValue => ({ ...inputsValue, 'otroCargoPolitico': '' }))
        } else {
            delete inputsValue.nombreCargoPolitico;
            delete inputsValue.institucion;
            if (inputsValue.cargoPolitico !== 'Otro')
                delete inputsValue.otroCargoPolitico;
        }
    }, [inputsValue.cargoPolitico])

    useEffect(() => {
        if (inputsValue.transaccionalidadMensual)
            setInputsValue(inputsValue => ({ ...inputsValue, 'numeroOperacionesMensuales': '' }))
    }, [inputsValue.transaccionalidadMensual])

    const statusE = async () => {
        setLoading(true)
        //AGREGAR CANCELAR INE
        cancelarINE()
        sendEventClick('Información Personal', 'SI', { status: 'CANCELADO' })
        const data = "Cancelado"
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("informacion_personal", data, "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    useEffect(() => {
        if (inputsValue.fuenteIngresos && inputsValue.fuenteIngresos === 'Otros (especificar)') {
            setInputsValue(inputsValue => ({ ...inputsValue, 'otraFuenteIngresos': '' }))
        } else {
            delete inputsValue.otraFuenteIngresos
        }
    }, [inputsValue.fuenteIngresos])

    return (
        <>
            <div className="main_gradient flex_container">
                <Header ruta="resultados_identificacion" show={() => setShowHelp(true)} />
                <div className="flex_body">
                    <div className="main_text_container">
                        <h1 className="title_punto_trader animate__animated">Información personal</h1>
                        <p className="text_punto_trader animate__animated">Revisa y completa tus datos personales.</p>
                    </div>
                    <form>
                        <div className="ocr_data_display calibri_punto_trader animate__animated">
                            <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombres !== "") ? "is-focused" : ""), inputsErrors.nombres ? 'error' : ''].join(" ")}>
                                <label htmlFor="nombres" className="bmd-label-floating">Nombre(s):</label>
                                <input type="text" className="form-control none_border" id="nombres" name="nombres" defaultValue={inputsValue.nombres} onChange={handleChange} onFocus={e => {
                                    setFocusNombre(true)
                                    setInputActive(true)
                                }}
                                    onBlur={event => {
                                        if (inputsValue.nombres !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.nombres === "" || event.target.value === '') {
                                            setFocusNombre(false)
                                        }
                                        setInputActive(false)
                                    }}
                                    onPaste={onPaste}
                                    onCut={onCut}
                                />
                                {inputsErrors.nombres && (
                                    <span id="ht-nombres" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombres}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusApellidoPaterno || inputsValue.apellidoPaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoPaterno && 'error'].join(" ")}>
                                <label htmlFor="apellidoPaterno" className="bmd-label-floating">Apellido paterno:</label>
                                <input type="text" className="form-control none_border" id="apellidoPaterno" name="apellidoPaterno" defaultValue={inputsValue.apellidoPaterno} onChange={handleChange} onFocus={e => {
                                    setFocusApellidoPaterno(true)
                                    setInputActive(true)
                                }}
                                    onBlur={event => {
                                        if (inputsValue.apellidoPaterno !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.apellidoPaterno === "" || event.target.value === '') {
                                            setFocusApellidoPaterno(false)
                                        }
                                        setInputActive(false)
                                    }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.apellidoPaterno && (
                                    <span id="ht-apellidoPaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoPaterno}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusApellidoMaterno || inputsValue.apellidoMaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoMaterno && 'error'].join(" ")}>
                                <label htmlFor="apellidoMaterno" className="bmd-label-floating">Apellido materno:</label>
                                <input type="text" className="form-control none_border" id="apellidoMaterno" name="apellidoMaterno" defaultValue={inputsValue.apellidoMaterno} onChange={handleChange} onFocus={e => {
                                    setFocusApellidoMaterno(true)
                                    setInputActive(true)
                                }}
                                    onBlur={event => {
                                        if (inputsValue.apellidoMaterno !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.apellidoMaterno === "" || event.target.value === '') {
                                            setFocusApellidoMaterno(false)
                                        }
                                        setInputActive(false)
                                    }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.apellidoMaterno && (
                                    <span id="ht-apellidoMaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoMaterno}</span>
                                )}
                            </div>
                            {!sexoOcrVacio ?
                                <div className={["form-group", "bmd-form-group", ((focusGenero || inputsValue.genero !== "") ? "is-focused" : ""), inputsErrors.genero && 'error'].join(" ")}>
                                    <label htmlFor="genero" className="bmd-label-floating">Género:</label>
                                    <input type="text" className="form-control none_border" id="genero" name="genero" defaultValue={inputsValue.genero} onChange={handleChange} onFocus={e => {
                                        setFocusGenero(true)
                                        setInputActive(true)
                                    }}
                                        onBlur={event => {
                                            if (inputsValue.genero !== event.target.value) {
                                                handleChange(event)
                                            }
                                            if (inputsValue.genero === "" || event.target.value === '') {
                                                setFocusGenero(false)
                                            }
                                            setInputActive(false)
                                        }}
                                        onPaste={onPaste}
                                        onCut={onCut} />
                                    {inputsErrors.genero && (
                                        <span id="ht-genero" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.genero}</span>
                                    )}
                                </div>
                                :
                                <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                    <label htmlFor="genero" className="bmd-label-floating">Género:</label>
                                    <select className={["new-custom-select", inputsErrors.genero && 'red_border'].join(" ")} id="genero" name="genero" onChange={handleChange} value={inputsValue.genero || ''} >
                                        <option value="" disabled id="uno">Seleccionar:</option>
                                        {
                                            generos.map(
                                                (objeto, index) => (
                                                    <option key={`${objeto.id}`} value={`${objeto.value}`}>{objeto.value}</option>
                                                )
                                            )
                                        }
                                    </select>{inputsErrors.genero && (
                                        <span id="ht-genero" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.genero}</span>
                                    )}
                                </div>
                            }

                            <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                                <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                                <NumberFormat format="##/##/####" className="form-control none_border" id="fechaNacimiento" name="fechaNacimiento" value={inputsValue.fechaNacimiento} onChange={handleChange} onFocus={e => {
                                    setFocusNacimiento(true)
                                    setInputActive(true)
                                }}
                                    onBlur={event => {
                                        if (inputsValue.fechaNacimiento !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.fechaNacimiento === "" || event.target.value === '') {
                                            setFocusNacimiento(false)
                                        }
                                        setInputActive(false)
                                    }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.fechaNacimiento && (
                                    <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="estadoCivil" className="bmd-label-floating">Estado civil:</label>
                                <select className={["new-custom-select", inputsErrors.estadoCivil && 'red_border'].join(" ")} id="estadoCivil" name="estadoCivil" onChange={handleChange} value={inputsValue.estadoCivil || ''} >
                                    <option value="" disabled id="uno">Seleccionar:</option>
                                    {
                                        estadosCiviles.map(
                                            (objeto, index) => (
                                                <option key={`${objeto.id}`} value={`${objeto.value}`}>{objeto.value}</option>
                                            )
                                        )
                                    }
                                </select>{inputsErrors.estadoCivil && (
                                    <span id="ht-estadoCivil" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.estadoCivil}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusTelefono || inputsValue.telefono !== "") ? "is-focused" : ""), inputsErrors.telefono && 'error'].join(" ")}>
                                <label htmlFor="telefono" className="bmd-label-floating">Número celular:</label>
                                <input type="text" className="form-control none_border" id="telefono" name="telefono" defaultValue={inputsValue.telefono} maxLength="10" onChange={handleChange} onFocus={e => {
                                    setFocusTelefono(true)
                                    setInputActive(true)
                                }}
                                    onBlur={event => {
                                        if (inputsValue.telefono !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.telefono === "" || event.target.value === '') {
                                            setFocusTelefono(false)
                                        }
                                        setInputActive(false)
                                    }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.telefono && (
                                    <span id="ht-telefono" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.telefono}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusEmail || inputsValue.email !== "") ? "is-focused" : ""), inputsErrors.email && 'error'].join(" ")}>
                                <label htmlFor="email" className="bmd-label-floating">Correo electrónico:</label>
                                <input type="email" className="form-control none_border" id="email" name="email" defaultValue={inputsValue.email} onChange={handleChange} onFocus={e => {
                                    setFocusEmail(true)
                                    setInputActive(true)
                                }}
                                    onBlur={event => {
                                        if (inputsValue.email !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.email === "" || event.target.value === '') {
                                            setFocusEmail(false)
                                        }
                                        setInputActive(false)
                                    }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.email && (
                                    <span id="ht-email" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.email}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", "is-focused", inputsErrors.pais && 'error'].join(" ")}>
                                <label htmlFor="pais" className="bmd-label-floating">País de nacimiento:</label>
                                <input type="text" className="form-control none_border" id="pais" name="pais" defaultValue={inputsValue.pais} onChange={handleChange} onFocus={e => {
                                    setFocusPais(true)
                                    setInputActive(true)
                                }}
                                    onBlur={event => {
                                        if (inputsValue.pais !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.pais === "" || event.target.value === '') {
                                            setFocusPais(false)
                                        }
                                        setInputActive(false)
                                    }}
                                    onPaste={onPaste}
                                    onCut={onCut}
                                    disabled={true} />
                                {inputsErrors.pais && (
                                    <span id="ht-pais" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.pais}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="entidadFederativa" className="bmd-label-floating">Entidad Federativa de nacimiento:</label>
                                <select className={["new-custom-select", inputsErrors.entidadFederativa && 'red_border'].join(" ")} id="entidadFederativa" name="entidadFederativa" onChange={handleChange} value={inputsValue.entidadFederativa || ''} >
                                    <option value="" disabled id="uno">Seleccionar:</option>
                                    {
                                        entidadesFederativas.map(
                                            (objeto, index) => (
                                                <option key={`${objeto.id}`} value={`${objeto.descripcion}`}>{objeto.descripcion}</option>
                                            )
                                        )
                                    }
                                </select>{inputsErrors.entidadFederativa && (
                                    <span id="ht-entidadFederativa" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.entidadFederativa}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="nacionalidad" className="bmd-label-floating">Nacionalidad:</label>
                                <select className={["new-custom-select", inputsErrors.nacionalidad && 'red_border'].join(" ")} id="nacionalidad" name="nacionalidad" onChange={handleChange} value={inputsValue.nacionalidad || ''} >
                                    <option value="" disabled id="uno">Seleccionar:</option>
                                    {
                                        nacionalidades.map(
                                            (objeto) => (
                                                <option key={`${objeto.id}`} value={`${objeto.descripcion}`}>{objeto.descripcion}</option>
                                            )
                                        )
                                    }
                                </select>{inputsErrors.nacionalidad && (
                                    <span id="ht-nacionalidad" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nacionalidad}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusEmpresa || inputsValue.empresa !== "") ? "is-focused" : ""), inputsErrors.empresa && 'error'].join(" ")}>
                                <label htmlFor="empresa" className="bmd-label-floating">Empresa en la que trabaja:</label>
                                <input type="text" className="form-control none_border" id="empresa" name="empresa" defaultValue={inputsValue.empresa} onChange={handleChange} onFocus={e => {
                                    setFocusEmpresa(true)
                                    setInputActive(true)
                                }} onBlur={event => {
                                    if (inputsValue.empresa !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.empresa === "" || event.target.value === '') {
                                        setFocusEmpresa(false)
                                    }
                                    setInputActive(false)
                                }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.empresa && (
                                    <span id="ht-empresa" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.empresa}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="actividadEconomica" className="bmd-label-floating">Actividad o giro del negocio al que se dedica el cliente:</label>
                                <select className={["new-custom-select", inputsErrors.actividadEconomica && 'red_border'].join(" ")} id="actividadEconomica" name="actividadEconomica" onChange={handleChange} value={inputsValue.actividadEconomica || ''} >
                                    <option value="" disabled id="uno">Seleccionar:</option>
                                    {
                                        actividadesEconomicas.map(
                                            (objeto, index) => (
                                                <option key={`${objeto.id}`} value={`${objeto.id_fc_actividad_az}`}>{objeto.id_fc_actividad_az}</option>
                                            )
                                        )
                                    }
                                </select>{inputsErrors.actividadEconomica && (
                                    <span id="ht-actividadEconomica" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.actividadEconomica}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusRfc || inputsValue.rfc !== "") ? "is-focused" : ""), inputsErrors.rfc && 'error'].join(" ")}>
                                <label htmlFor="rfc" className="bmd-label-floating">RFC:</label>
                                <input type="text" className="form-control uppercase none_border" id="rfc" name="rfc" defaultValue={inputsValue.rfc} onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                                    setFocusRfc(true)
                                    setInputActive(true)
                                }} onBlur={event => {
                                    if (inputsValue.rfc !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.rfc === "" || event.target.value === '') {
                                        setFocusRfc(false)
                                    }
                                    setInputActive(false)
                                }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.rfc && (
                                    <span id="ht-rfc" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.rfc}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusFirma || inputsValue.numSerieEFirma !== "") ? "is-focused" : ""), inputsErrors.numSerieEFirma && 'error'].join(" ")}>
                                <label htmlFor="numSerieEFirma" className="bmd-label-floating">Número de serie de la firma electrónica (opcional):</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="numSerieEFirma" name="numSerieEFirma" maxLength="20" defaultValue={inputsValue.numSerieEFirma} onChange={handleChange}
                                        onFocus={() => {
                                            setFocusFirma(true)
                                            setInputActive(true)
                                        }} onBlur={event => {
                                            if (inputsValue.numSerieEFirma !== event.target.value) {
                                                handleChange(event)
                                            }
                                            if (inputsValue.numSerieEFirma === "" || event.target.value === '') {
                                                setFocusFirma(false)
                                            }
                                            setInputActive(false)
                                        }}
                                        onPaste={onPaste}
                                        onCut={onCut} />
                                    <button type="button" className={['button_info', inputsErrors.numSerieEFirma && 'red_border_bottom'].join(" ")} onClick={() => setShowEfirmaInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                                </div>
                                {inputsErrors.numSerieEFirma && (
                                    <span id="ht-numSerieEFirma" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numSerieEFirma}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusResidenciaFiscal || inputsValue.residenciaFiscal !== "") ? "is-focused" : ""), inputsErrors.residenciaFiscal && 'error'].join(" ")}>
                                <label htmlFor="residenciaFiscal" className="bmd-label-floating">Residencia fiscal:</label>
                                <input type="text" className="form-control none_border" id="residenciaFiscal" name="residenciaFiscal" defaultValue={inputsValue.residenciaFiscal} onChange={handleChange} onFocus={e => {
                                    setFocusResidenciaFiscal(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.residenciaFiscal === "") {
                                        setFocusResidenciaFiscal(false)
                                    }
                                    setInputActive(false)
                                }} disabled={true} />
                                {inputsErrors.residenciaFiscal && (
                                    <span id="ht-residenciaFiscal" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.residenciaFiscal}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusNumeroCuenta || inputsValue.numeroCuenta !== "") ? "is-focused" : ""), inputsErrors.numeroCuenta && 'error'].join(" ")}>
                                <label htmlFor="numeroCuenta" className="bmd-label-floating">Número de cuenta:</label>
                                <input type="text" className="form-control none_border" id="numeroCuenta" name="numeroCuenta" defaultValue={inputsValue.numeroCuenta} onChange={handleChange} onFocus={e => {
                                    setFocusNumeroCuenta(true)
                                    setInputActive(true)
                                }} onBlur={event => {
                                    if (inputsValue.numeroCuenta !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.numeroCuenta === "" || event.target.value === '') {
                                        setFocusNumeroCuenta(false)
                                    }
                                    setInputActive(false)
                                }}
                                    onCut={onCut} />
                                {inputsErrors.numeroCuenta && (
                                    <span id="ht-numeroCuenta" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroCuenta}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusCLABE || inputsValue.clabe !== "") ? "is-focused" : ""), inputsErrors.clabe && 'error'].join(" ")}>
                                <label htmlFor="clabe" className="bmd-label-floating">Clave Bancaria Estandarizada:</label>
                                <input type="text" className="form-control none_border" id="clabe" name="clabe" maxLength="18" defaultValue={inputsValue.clabe} onChange={handleChange} onFocus={e => {
                                    setFocusCLABE(true)
                                    setInputActive(true)
                                }} onBlur={event => {
                                    if (inputsValue.clabe !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.clabe === "" || event.target.value === '') {
                                        setFocusCLABE(false)
                                    }
                                    setInputActive(false)
                                }}
                                    onCut={onCut} />
                                {inputsErrors.clabe && (
                                    <span id="ht-clabe" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.clabe}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", (inputsValue.numeroDepositos || focusNumeroDepositos) && "is-focused", inputsErrors.numeroDepositos && 'error'].join(" ")}>
                                <label htmlFor="numeroDepositos" className="bmd-label-floating">Número de depósitos que plantea efectuar en el mes:</label>
                                <input type="text" className="form-control none_border" id="numeroDepositos" name="numeroDepositos" defaultValue={inputsValue.numeroDepositos} onChange={handleChange} onFocus={e => {
                                    setFocusNumeroDepositos(true)
                                    setInputActive(true)
                                }} onBlur={event => {
                                    if (inputsValue.numeroDepositos !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.numeroDepositos === "" || event.target.value === '') {
                                        setFocusNumeroDepositos(false)
                                    }
                                    setInputActive(false)
                                }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.numeroDepositos && (
                                    <span id="ht-numeroDepositos" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroDepositos}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="transaccionalidadMensualDepositos" className="bmd-label-floating">Transaccionalidad mensual esperada (depósitos):</label>
                                <select className={["new-custom-select", inputsErrors.transaccionalidadMensualDepositos && 'red_border'].join(" ")} id="transaccionalidadMensualDepositos" name="transaccionalidadMensualDepositos" onChange={handleChange} value={inputsValue.transaccionalidadMensualDepositos || ''} >
                                    <option value="" disabled id="uno">Seleccionar:</option>
                                    {
                                        transaccionalidades.map(
                                            (objeto, index) => (
                                                <option key={`${objeto.id}`} value={`${objeto.descripcion}`}>{objeto.descripcion}</option>
                                            )
                                        )
                                    }
                                </select>{inputsErrors.transaccionalidadMensualDepositos && (
                                    <span id="ht-transaccionalidadMensualDepositos" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.transaccionalidadMensualDepositos}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", (inputsValue.numeroRetiros || focusNumeroRetiros) && "is-focused", inputsErrors.numeroRetiros && 'error'].join(" ")}>
                                <label htmlFor="numeroRetiros" className="bmd-label-floating">Número de retiros que plantea efectuar en el mes:</label>
                                <input type="text" className="form-control none_border" id="numeroRetiros" name="numeroRetiros" defaultValue={inputsValue.numeroRetiros} onChange={handleChange} onFocus={e => {
                                    setFocusNumeroRetiros(true)
                                    setInputActive(true)
                                }}
                                    onBlur={event => {
                                        if (inputsValue.numeroDepositos !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.numeroDepositos === "" || event.target.value === '') {
                                            setFocusNumeroRetiros(false)
                                        }
                                        setInputActive(false)
                                    }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.numeroRetiros && (
                                    <span id="ht-numeroRetiros" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroRetiros}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="transaccionalidadMensualRetiros" className="bmd-label-floating">Transaccionalidad mensual esperada (retiros):</label>
                                <select className={["new-custom-select", inputsErrors.transaccionalidadMensualRetiros && 'red_border'].join(" ")} id="transaccionalidadMensualRetiros" name="transaccionalidadMensualRetiros" onChange={handleChange} value={inputsValue.transaccionalidadMensualRetiros || ''} >
                                    <option value="" disabled id="uno">Seleccionar:</option>
                                    {
                                        transaccionalidades.map(
                                            (objeto, index) => (
                                                <option key={`${objeto.id}`} value={`${objeto.descripcion}`}>{objeto.descripcion}</option>
                                            )
                                        )
                                    }
                                </select>{inputsErrors.transaccionalidadMensualRetiros && (
                                    <span id="ht-transaccionalidadMensualRetiros" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.transaccionalidadMensualRetiros}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="fuenteIngresos" className="bmd-label-floating">Principal fuente de ingresos:</label>
                                <select className={["new-custom-select", inputsErrors.fuenteIngresos && 'red_border'].join(" ")} id="fuenteIngresos" name="fuenteIngresos" onChange={handleChange} value={inputsValue.fuenteIngresos || ''} >
                                    <option value="" disabled id="uno">Seleccionar:</option>
                                    {
                                        constantFuentesIngresos.map(
                                            (objeto, index) => (
                                                <option key={`${objeto.id}`} value={`${objeto.value}`}>{objeto.value}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.fuenteIngresos && (
                                    <span id="ht-fuenteIngresos" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fuenteIngresos}</span>
                                )}
                            </div>
                            {inputsValue.fuenteIngresos === 'Otros (especificar)' &&
                                <div className={["form-group", "bmd-form-group", ((focusOtraFuenteIngresos || inputsValue.otraFuenteIngresos !== "") ? "is-focused" : ""), inputsErrors.otraFuenteIngresos && 'error'].join(" ")}>
                                    <label htmlFor="otraFuenteIngresos" className="bmd-label-floating">Especificar fuente de ingresos:</label>
                                    <input type="text" className="form-control none_border" id="otraFuenteIngresos" name="otraFuenteIngresos" defaultValue={inputsValue.otraFuenteIngresos || ''} onChange={handleChange} onFocus={e => {
                                        setFocusOtraFuenteIngresos(true)
                                        setInputActive(true)
                                    }}
                                        onBlur={event => {
                                            if (inputsValue.otraFuenteIngresos !== event.target.value) {
                                                handleChange(event)
                                            }
                                            if (inputsValue.otraFuenteIngresos === "" || event.target.value === '') {
                                                setFocusOtraFuenteIngresos(false)
                                            }
                                            setInputActive(false)
                                        }}
                                        onPaste={onPaste}
                                        onCut={onCut} />
                                    {inputsErrors.otraFuenteIngresos && (
                                        <span id="ht-otraFuenteIngresos" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.otraFuenteIngresos}</span>
                                    )}
                                </div>}
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="cargoPolitico" className="bmd-label-floating">¿Algún titular, cotitular, beneficiario desempeña o ha desempeñado en los últimos dos años alguno de los siguientes cargos políticos?</label>
                                <div className={(isMobile) ? 'div_select_10' : ''}>
                                    <select className={["new-custom-select", inputsErrors.cargoPolitico && 'red_border'].join(" ")} id="cargoPolitico" name="cargoPolitico" onChange={handleChange} value={inputsValue.cargoPolitico || ''} >
                                        <option value="" disabled id="uno">Seleccionar:</option>
                                        {
                                            cargos.map(
                                                (objeto, index) => (
                                                    <option key={`${objeto.id}`} value={`${objeto.value}`}>{objeto.value}</option>
                                                )
                                            )
                                        }
                                    </select>{inputsErrors.cargoPolitico && (
                                        <span id="ht-cargoPolitico" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.cargoPolitico}</span>
                                    )}
                                </div>
                            </div>
                            {(inputsValue.cargoPolitico && inputsValue.cargoPolitico === 'Otro' && !inputsErrors.cargoPolitico) &&
                                <>
                                    <div className={["form-group", "bmd-form-group", ((focusOtroCargoPolitico || inputsValue.otroCargoPolitico !== "") ? "is-focused" : ""), inputsErrors.otroCargoPolitico && 'error'].join(" ")}>
                                        <label htmlFor="otroCargoPolitico" className="bmd-label-floating">Otro (especificar):</label>
                                        <input type="text" className="form-control none_border" id="otroCargoPolitico" name="otroCargoPolitico" defaultValue={inputsValue.otroCargoPolitico} onChange={handleChange} onFocus={e => {
                                            setFocusOtroCargoPolitico(true)
                                            setInputActive(true)
                                        }}
                                            onBlur={event => {
                                                if (inputsValue.otroCargoPolitico !== event.target.value) {
                                                    handleChange(event)
                                                }
                                                if (inputsValue.otroCargoPolitico === "" || event.target.value === '') {
                                                    setFocusOtroCargoPolitico(false)
                                                }
                                                setInputActive(false)
                                            }}
                                            onPaste={onPaste}
                                            onCut={onCut} />
                                        {inputsErrors.otroCargoPolitico && (
                                            <span id="ht-otroCargoPolitico" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.otroCargoPolitico}</span>
                                        )}
                                    </div>
                                </>
                            }
                            {(inputsValue.cargoPolitico && inputsValue.cargoPolitico !== 'No' && !inputsErrors.cargoPolitico) &&
                                <>
                                    <div className={["form-group", "bmd-form-group", ((focusNombreCargoPolitico || inputsValue.nombreCargoPolitico !== "") ? "is-focused" : ""), inputsErrors.nombreCargoPolitico && 'error'].join(" ")}>
                                        <label htmlFor="nombreCargoPolitico" className={["bmd-label-floating", ((isMobile && !inputsValue.nombreCargoPolitico) && 'top_1_7_rem')].join(" ")}>En caso afirmativo por favor indíquenos el nombre(s):</label>
                                        <input type="text" className="form-control none_border" id="nombreCargoPolitico" name="nombreCargoPolitico" defaultValue={inputsValue.nombreCargoPolitico} onChange={handleChange} onFocus={e => {
                                            setFocusNombreCargoPolitico(true)
                                            setInputActive(true)
                                        }}
                                            onBlur={event => {
                                                if (inputsValue.nombreCargoPolitico !== event.target.value) {
                                                    handleChange(event)
                                                }
                                                if (inputsValue.nombreCargoPolitico === "" || event.target.value === '') {
                                                    setFocusNombreCargoPolitico(false)
                                                }
                                                setInputActive(false)
                                            }}
                                            onPaste={onPaste}
                                            onCut={onCut} />
                                        {inputsErrors.nombreCargoPolitico && (
                                            <span id="ht-nombreCargoPolitico" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombreCargoPolitico}</span>
                                        )}
                                    </div>
                                    <div className={["form-group", "bmd-form-group", ((focusInstitucion || inputsValue.institucion !== "") ? "is-focused" : ""), inputsErrors.institucion && 'error'].join(" ")}>
                                        <label htmlFor="institucion" className={["bmd-label-floating", ((isMobile && !inputsValue.institucion) && 'top_1_7_rem')].join(" ")}>Institución o dependencia donde presta o prestó sus servicios:</label>
                                        <input type="text" className="form-control none_border" id="institucion" name="institucion" defaultValue={inputsValue.institucion} onChange={handleChange} onFocus={e => {
                                            setFocusInstitucion(true)
                                            setInputActive(true)
                                        }}
                                            onBlur={event => {
                                                if (inputsValue.institucion !== event.target.value) {
                                                    handleChange(event)
                                                }
                                                if (inputsValue.institucion === "" || event.target.value === '') {
                                                    setFocusInstitucion(false)
                                                }
                                                setInputActive(false)
                                            }}
                                            onPaste={onPaste}
                                            onCut={onCut} />
                                        {inputsErrors.institucion && (
                                            <span id="ht-institucion" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.institucion}</span>
                                        )}
                                    </div>
                                </>
                            }
                            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                                <label htmlFor="tipoServicio" className="bmd-label-floating" style={{ position: 'static' }}>¿Qué tipo de servicio desea? </label><img className="icon_info_service" src="../images/info_icon.svg" alt="" onClick={() => setShowInfo(true)} />
                                <select className={["new-custom-select", inputsErrors.tipoServicio && 'red_border'].join(" ")} id="tipoServicio" name="tipoServicio" onChange={handleChange} value={inputsValue.tipoServicio || ''} >
                                    <option value="" disabled id="uno">Seleccionar:</option>
                                    {
                                        servicios.map(
                                            (objeto) => (
                                                <option key={`${objeto.id}`} value={`${objeto.value}`}>{objeto.value}
                                                </option>
                                            )
                                        )
                                    }
                                </select>{inputsErrors.tipoServicio && (
                                    <span id="ht-tipoServicio" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.tipoServicio}</span>
                                )}
                            </div>
                            <div className={["check_container"].join(" ")}>
                                <div className='div_checkbox'>
                                    {(isIOS || isSafari) ?
                                        <div>
                                            <label htmlFor="check_terms" className="custom-checkbox">
                                                <input id="check_terms" type="checkbox" onChange={() => { setInfoTrue(!infoTrue); sendEventClick('Información Personal', ' Declaro bajo protesta de decir verdad que los datos aquí mencionados son ciertos y que actúo a nombre y por cuenta propia.'); }} disabled={disabledCheck} />
                                                <span className="checkbox-decorator"><span className="check"></span></span>
                                                Declaro bajo protesta de decir verdad que los datos aquí mencionados son ciertos y que actúo a nombre y por cuenta propia.
                                            </label>
                                        </div>
                                        :
                                        <div className="checkbox">
                                            <label htmlFor="check_terms">
                                                <input id="check_terms" type="checkbox" onChange={() => { setInfoTrue(!infoTrue); sendEventClick('Información Personal', 'Declaro bajo protesta de decir verdad que los datos aquí mencionados son ciertos y que actúo a nombre y por cuenta propia.'); }} disabled={disabledCheck} />
                                                <span className="checkbox-decorator"><span className="check"></span></span>
                                                Declaro bajo protesta de decir verdad que los datos aquí mencionados son ciertos y que actúo a nombre y por cuenta propia.
                                            </label>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </form>

                </div>
                <div className="action_buttons animate__animated flex_buttons">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100", (infoTrue && continuar ? "button_punto_trader" : "button_gray_punto_trader")].join(" ")} disabled={(infoTrue && continuar) ? false : true} onClick={() => setValidar(true)}>Guardar</button>
                    <button type="button" onClick={() => { setShowCancel(true); sendEventClick('Información Personal', 'CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader">Cancelar</button>
                </div>
                <IdTransaccion />
                <div className="margin_top_10">
                    <Footer />
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {(showCancel) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => { setShowCancel(false); sendEventClick('Información Personal', 'NO'); }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={() => statusE()} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {(showInfo) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Tipos de servicio</h5>
                                <button onClick={e => setShowInfo(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* <p className="calibri_punto_trader">Gestión de Inversiones<br /><br />
                                    <span className="small">Punto Casa de Bolsa tomará decisiones de inversión por cuenta del cliente, al amparo de un contrato y un Marco General de Actuación en el que se pacta un manejo discrecional en función del objetivo de inversión y perfil del cliente. Para este servicio tenemos diferentes estrategias y se puede empezar a partir de $10,000.00.</span></p>
                                <hr /> */}
                                <p className="calibri_punto_trader">Ejecución de Operaciones<br /><br />
                                    <span className="small">El cliente podrá ejecutar órdenes en el mercado de acuerdo a sus conocimientos y dominio del mismo, sin recibir recomendación alguna por parte de Punto Casa de Bolsa. Para este servicio puedes iniciar desde $10,000.00.</span></p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => setShowInfo(false)} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Entendido</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {(showEfirmaInfo) &&
                <EfirmaInfo setShowEfirmaInfo={setShowEfirmaInfo} />
            }
            {loading && <Loader />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </>
    )
}

export default FormularioInformacionPersonal