import React, { useState, useEffect, Fragment } from "react"
/* Librerías */
import { Link, useHistory } from "react-router-dom"
import { isMobile } from 'react-device-detect'
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Ayuda from './ayuda'
import CancelarFlujo from "./flujo/cancelar_flujo"
import Loader from "../components/loader"
import IdTransaccion from "../components/id_transaccion"
/* Funciones */
import { configStyle } from '../services/configStyle'
import { status, statusError, sendEventClick, validarParametroOtorgante } from '../services/data'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"

const PreparacionIne = () => {

    useScrollToTop()
    /* Hooks */
    const history = useHistory()
    const [step, setStep] = useState(null)
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [showCancel, setShowCancel] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))
        
        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        setStep('/identificacion');
        status("preparacion_id_page", "Preparacion ID");
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = async () => {
        setLoading(true)
        sendEventClick('Preparacion ID','SI', { status: 'CANCELADO' })
        const data = "Cancelado"
        localStorage.setItem('flag', 'cancelado')
        await statusError("preparacion_id", data, "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    return (
        <Fragment>
            {(showCancel) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => {setShowCancel(false);sendEventClick('Preparacion ID','NO');}} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className="main_gradient">
                <div className="flex_container">
                    <Header show={() => setShowHelp(true)} />
                    <div className="flex_body">
                        <div className="main_text_container">
                            <h1 className="title_punto_trader">Captura tu identificación oficial</h1>
                            <p className="calibri_punto_trader">Para capturar tu identificación asegúrate que se encuentre <b className="calibri_bold_punto_trader">vigente</b>, evita <b className="calibri_bold_punto_trader">reflejos</b>, de preferencia frente a una <b className="calibri_bold_punto_trader">superficie obscura</b> para mejorar el contraste.</p>
                        </div>
                        <div className="row id_type_bx">
                            <div className="col-12">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h6 className="calibri_punto_trader">INE/IFE</h6>
                                    <div className={`maskface id_central ${isMobile ? 'rotate-image-instructions' : ''}`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="action_buttons flex_buttons">
                        <Link to={{
                            pathname: step,
                            state: { passport: false }
                        }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader" onClick={() => sendEventClick('Preparacion ID','CAPTURAR IDENTIFICACIÓN')} replace>
                            Capturar Identificación</Link>
                        <button type="button" onClick={e => { setShowCancel(true); sendEventClick('Preparacion ID','CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader">
                            Cancelar</button>
                    </div>
                    <IdTransaccion />
                </div>
                <Footer />
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {loading && <Loader />}
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </Fragment>
    )

}


export default PreparacionIne