import React, { useState, useEffect } from "react"
/* Librerías */
import { useHistory } from "react-router-dom"
import { isMobile, isIOS } from 'react-device-detect'
/* Componentes */
import Header from "../../components/headerNA"
import Footer from "../../components/footer"
import Ayuda from '../ayuda'
import Loader from '../../components/loader'
import CancelarFlujo from "../flujo/cancelar_flujo"
import IdTransaccion from "../../components/id_transaccion"
/* Funciones */
import { configStyle } from '../../services/configStyle'
import { agregarInfo, statusData, validarCampo, validarValoresFormulario, status, statusError, findInArray, validarRenapo, generateZip, cancelarINE, sendEventClick, validarParametroOtorgante } from '../../services/data'
/* Constantes */
import { encuenstaPerfilamiento } from "../../services/constanst"
/* Custom hooks */
import { useScrollToTop } from "../../utilities/hooks/custom"


let FormularioInformacionPerfilamiento = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [showHelp, setShowHelp] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [continuar, setContinuar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [inputsErrors, setInputsErrors] = useState({})
    const [inputsValue, setInputsValue] = useState({
        rangoEdad: '',
        nivelEstudios: '',
        estudiosEconomia: '',
        estrategiaInversionMayorExperiencia: '',
        instrumentosMercadoFinaciero: [],
        rangoInversiones: '',
        origenRecursos: '',
        porcentajePatrimonio: '',
        estrategiaInversionPasado: '',
        asumirPerdida: '',
        objetivosInversion: '',
        plazoEstimado: '',
        objetivoInversion: '',
    })

    useEffect(() => {
        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        status("informacion_perfilamiento_page", "Informacion perfilamiento")

        validarRenapo()
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const sendData = (infoFormulario) => {
        const jsonObj = {}
        jsonObj.informacionPerfilamiento = infoFormulario
        statusData("informacion_perfilamiento", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let value = null;
        if (event.target.name === 'instrumentosMercadoFinaciero') {
            value = Array.from(event.target.selectedOptions, option => option.value)
        } else {
            value = event.target.value
        }
        let validacion = validarCampo(value, event.target.name)
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }))
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }))
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }))
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }))
        }

    };

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        sendEventClick('Información Perfilamiento', 'GUARDAR')
        let infoFormulario = [];
        let respuestas = [];
        let totalScore = 0;
        Object.entries(inputsValue).forEach(([key, value]) => {
            let respuesta = {};
            if (key === 'instrumentosMercadoFinaciero') {
                value.forEach(objeto => {
                    let scoreValue = findInArray(encuenstaPerfilamiento[4], parseInt(objeto), 'value').score;
                    if (scoreValue) totalScore = parseInt(totalScore) + parseInt(scoreValue);
                });
            } else {
                if (value) totalScore = parseInt(totalScore) + parseInt(value);
            }
            switch (key) {
                case 'rangoEdad':
                    respuesta = encuenstaPerfilamiento[0].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '1.- ¿En qué rango de edad te encuentras?';
                    respuestas.push(respuesta);
                    break;
                case 'nivelEstudios':
                    respuesta = encuenstaPerfilamiento[1].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '2.- ¿Cuál es tu nivel de estudios máximo?';
                    respuestas.push(respuesta);
                    break;
                case 'estudiosEconomia':
                    respuesta = encuenstaPerfilamiento[2].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '3.- ¿Cuentas con estudios relacionados con la Economía, Finanzas o en general?';
                    respuestas.push(respuesta);
                    break;
                case 'estrategiaInversionMayorExperiencia':
                    if (value) {
                        respuesta = encuenstaPerfilamiento[3].find(objeto => objeto.value === parseInt(value));
                        respuesta.question = '4.- En caso de haber realizado operaciones con servicios de inversión asesorados, ¿En qué estrategia de inversión consideras tener mayor experiencia?';
                        respuestas.push(respuesta);
                    }
                    break;
                case 'instrumentosMercadoFinaciero':
                    let arrayRespuesta = [];
                    value.forEach(objeto => {
                        let object = findInArray(encuenstaPerfilamiento[4], parseInt(objeto), 'value');
                        arrayRespuesta.push({ answer: object.answer, value: object.score });
                    });
                    if (arrayRespuesta.length > 0) {
                        respuesta.answers = arrayRespuesta;
                        respuesta.question = '5.- ¿En qué instrumentos ha invertido en el mercado financiero?';
                        respuestas.push(respuesta);
                    }
                    break;
                case 'rangoInversiones':
                    respuesta = encuenstaPerfilamiento[5].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '6.- ¿Rango el cual empezaría a realizar sus inversiones?';
                    respuestas.push(respuesta);
                    break;
                case 'origenRecursos':
                    respuesta = encuenstaPerfilamiento[6].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '7.- ¿Cuál es el origen de los recursos destinados a la inversión?';
                    respuestas.push(respuesta);
                    break;
                case 'porcentajePatrimonio':
                    respuesta = encuenstaPerfilamiento[7].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '8.-  ¿Qué porcentaje de su patrimonio se encuentra invertido en instituciones financieras?';
                    respuestas.push(respuesta);
                    break;
                case 'objetivoInversion':
                    respuesta = encuenstaPerfilamiento[8].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '9.- ¿Cuál es el propósito/objetivo de tu inversión?';
                    respuestas.push(respuesta);
                    break;
                case 'estrategiaInversionPasado':
                    if (value) {
                        respuesta = encuenstaPerfilamiento[9].find(objeto => objeto.value === parseInt(value));
                        respuesta.question = '10.- ¿Bajo qué estrategia de inversión has operado instrumentos financieros en el pasado?';
                        respuestas.push(respuesta);
                    }
                    break;
                case 'asumirPerdida':
                    respuesta = encuenstaPerfilamiento[10].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '11.- Teniendo en cuenta que, con el tiempo a mayor riesgo, mayor es el rendimiento esperado ¿Estaría dispuesto a asumir una posible pérdida en un corto tiempo, con el objetivo de generar mayores rendimientos en el largo plazo? ';
                    respuestas.push(respuesta);
                    break;
                case 'objetivosInversion':
                    respuesta = encuenstaPerfilamiento[11].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '12.- ¿Cuál de las siguientes opciones describe mejor tus objetivos de inversión?';
                    respuestas.push(respuesta);
                    break;
                case 'plazoEstimado':
                    respuesta = encuenstaPerfilamiento[12].find(objeto => objeto.value === parseInt(value));
                    respuesta.question = '13.- ¿Cuál es el plazo estimado que buscas en tu inversión?';
                    respuestas.push(respuesta);
                    break;
                default:
                    break;
            }
        });
        agregarInfo("encuestaPerfilamiento", respuestas);
        infoFormulario.push({ description: "encuestaPerfilamiento", value: respuestas });
        agregarInfo('scoreEncuestaPerfilamiento', totalScore);
        infoFormulario.push({ description: "scoreEncuestaPerfilamiento", value: totalScore });

        setTimeout(() => {
            sendData(infoFormulario);
            history.replace('/informacion_domicilio');
        }, 300);
    }

    const statusE = async () => {
        setLoading(true);
        cancelarINE();
        sendEventClick('Información Perfilamiento', 'SI', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("informacion_perfilamiento", 'Cancelado', "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    useEffect(() => {
        let validacion = validarValoresFormulario(inputsValue)
        if (validacion.correcto) {
            setContinuar(true)
            setInputsErrors(validacion.errores)
        } else {
            setContinuar(false)
            setInputsErrors(validacion.errores)
        }
    }, [inputsValue])

    return (
        <>
            <div className="main_gradient">
                <Header ruta="resultados_identificacion" show={() => setShowHelp(true)} />
                <div className="flex-body">
                    <h1 className="title_punto_trader animate__animated">Información perfilamiento</h1>
                    <p className="text_punto_trader animate__animated">Revisa y completa tus datos de perfilamiento.</p>
                </div>
                <form>
                    <div className="ocr_data_display calibri_punto_trader animate__animated">
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="rangoEdad" className="bmd-label-floating">1.- ¿En qué rango de edad te encuentras?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.rangoEdad ? 'red_border' : ''].join(" ")} id="rangoEdad" name="rangoEdad" onChange={handleChange} value={inputsValue.rangoEdad} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[0].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.rangoEdad && (
                                    <span id="ht-rangoEdad" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.rangoEdad}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="nivelEstudios" className="bmd-label-floating">2.- ¿Cuál es tu nivel de estudios máximo?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.nivelEstudios ? 'red_border' : ''].join(" ")} id="nivelEstudios" name="nivelEstudios" onChange={handleChange} value={inputsValue.nivelEstudios} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[1].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.nivelEstudios && (
                                    <span id="ht-nivelEstudios" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nivelEstudios}</span>
                                )}
                            </div>

                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="estudiosEconomia" className="bmd-label-floating">3.- ¿Cuentas con estudios relacionados con la Economía, Finanzas o en general?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.estudiosEconomia ? 'red_border' : ''].join(" ")} id="estudiosEconomia" name="estudiosEconomia" onChange={handleChange} value={inputsValue.estudiosEconomia} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[2].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.estudiosEconomia && (
                                    <span id="ht-estudiosEconomia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.estudiosEconomia}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="estrategiaInversionMayorExperiencia" className="bmd-label-floating">4.- En caso de haber realizado operaciones con servicios de inversión asesorados, ¿En qué estrategia de inversión consideras tener mayor experiencia?</label>
                            <div className={isMobile ? 'div_select_10' : ''}>
                                <select className={["new-custom-select", inputsErrors.estrategiaInversionMayorExperiencia ? 'red_border' : ''].join(" ")} id="estrategiaInversionMayorExperiencia" name="estrategiaInversionMayorExperiencia" onChange={handleChange} value={inputsValue.estrategiaInversionMayorExperiencia} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[3].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.estrategiaInversionMayorExperiencia && (
                                    <span id="ht-estrategiaInversionMayorExperiencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.estrategiaInversionMayorExperiencia}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="instrumentosMercadoFinaciero" className="bmd-label-floating">5.- ¿En qué instrumentos ha invertido en el mercado financiero?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.instrumentosMercadoFinaciero ? 'red_border' : ''].join(" ")} id="instrumentosMercadoFinaciero" name="instrumentosMercadoFinaciero" onChange={handleChange} value={inputsValue.instrumentosMercadoFinaciero} multiple >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[4].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.instrumentosMercadoFinaciero && (
                                    <span id="ht-instrumentosMercadoFinaciero" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.instrumentosMercadoFinaciero}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="rangoInversiones" className="bmd-label-floating">6.- ¿Rango el cual empezaría a realizar sus inversiones?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.rangoInversiones ? 'red_border' : ''].join(" ")} id="rangoInversiones" name="rangoInversiones" onChange={handleChange} value={inputsValue.rangoInversiones} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[5].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.rangoInversiones && (
                                    <span id="ht-rangoInversiones" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.rangoInversiones}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="origenRecursos" className="bmd-label-floating">7.- ¿Cuál es el origen de los recursos destinados a la inversión?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.origenRecursos ? 'red_border' : ''].join(" ")} id="origenRecursos" name="origenRecursos" onChange={handleChange} value={inputsValue.origenRecursos} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[6].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.origenRecursos && (
                                    <span id="ht-origenRecursos" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.origenRecursos}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="porcentajePatrimonio" className="bmd-label-floating">8.- ¿Qué porcentaje de su patrimonio se encuentra invertido en instituciones financieras?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.porcentajePatrimonio ? 'red_border' : ''].join(" ")} id="porcentajePatrimonio" name="porcentajePatrimonio" onChange={handleChange} value={inputsValue.porcentajePatrimonio} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[7].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.porcentajePatrimonio && (
                                    <span id="ht-porcentajePatrimonio" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.porcentajePatrimonio}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="objetivoInversion" className="bmd-label-floating">9.- ¿Cuál es el propósito/objetivo de tu inversión?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.objetivoInversion ? 'red_border' : ''].join(" ")} id="objetivoInversion" name="objetivoInversion" onChange={handleChange} value={inputsValue.objetivoInversion} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[8].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.objetivoInversion && (
                                    <span id="ht-objetivoInversion" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.objetivoInversion}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="estrategiaInversionPasado" className="bmd-label-floating">10.- ¿Bajo qué estrategia de inversión has operado instrumentos financieros en el pasado?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.estrategiaInversionPasado ? 'red_border' : ''].join(" ")} id="estrategiaInversionPasado" name="estrategiaInversionPasado" onChange={handleChange} value={inputsValue.estrategiaInversionPasado} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[9].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.estrategiaInversionPasado && (
                                    <span id="ht-estrategiaInversionPasado" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.estrategiaInversionPasado}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="asumirPerdida" className="bmd-label-floating">11.- Teniendo en cuenta que, con el tiempo a mayor riesgo, mayor es el rendimiento esperado ¿Estaría dispuesto a asumir una posible pérdida en un corto tiempo, con el objetivo de generar mayores rendimientos en el largo plazo?</label>
                            <div className={isMobile ? 'div_select_15' : ''}>
                                <select className={["new-custom-select", inputsErrors.asumirPerdida ? 'red_border' : ''].join(" ")} id="asumirPerdida" name="asumirPerdida" onChange={handleChange} value={inputsValue.asumirPerdida} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[10].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.asumirPerdida && (
                                    <span id="ht-asumirPerdida" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.asumirPerdida}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="objetivosInversion" className="bmd-label-floating">12.- ¿Cuál de las siguientes opciones describe mejor tus objetivos de inversión?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.objetivosInversion ? 'red_border' : ''].join(" ")} id="objetivosInversion" name="objetivosInversion" onChange={handleChange} value={inputsValue.objetivosInversion} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[11].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.objetivosInversion && (
                                    <span id="ht-objetivosInversion" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.objetivosInversion}</span>
                                )}
                            </div>
                        </div>
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="plazoEstimado" className="bmd-label-floating">13.- ¿Cuál es el plazo estimado que buscas en tu inversión?</label>
                            <div className={isMobile ? 'div_select_5' : ''}>
                                <select className={["new-custom-select", inputsErrors.plazoEstimado ? 'red_border' : ''].join(" ")} id="plazoEstimado" name="plazoEstimado" onChange={handleChange} value={inputsValue.plazoEstimado} >
                                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                                    {
                                        encuenstaPerfilamiento[12].map(
                                            (objeto, index) => (
                                                <option key={`${index}`} value={`${objeto.value}`}>{objeto.answer}</option>
                                            )
                                        )
                                    }
                                </select>
                                {inputsErrors.plazoEstimado && (
                                    <span id="ht-plazoEstimado" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.plazoEstimado}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="action_buttons animate__animated">
                        <button type="button" className={["btn btn-raised btn-primary forcewidth100", (continuar ? "button_punto_trader" : "button_gray_punto_trader")].join(" ")} disabled={continuar ? false : true} onClick={event => validarFormulario(event)}>Guardar</button>
                        <button type="button" onClick={() => { setShowCancel(true); sendEventClick('Información Perfilamiento', 'CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader">Cancelar</button>
                    </div>
                </form>
                <IdTransaccion />
                <div className="margin_top_10">
                    <Footer />
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {(showCancel) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => { setShowCancel(false); sendEventClick('Información Perfilamiento', 'NO') }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={() => statusE()} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {loading && <Loader />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </>
    )
}

export default FormularioInformacionPerfilamiento