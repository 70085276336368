import React, { useState, useEffect } from 'react'
/* Componentes */
import Liveness from '../../../components/liveness_pasivo_interactivo'

const Index = () => {

    /* Variables */
    const apikey = localStorage.getItem("apikeyOtorgante") || ''
    const uuidCliente = localStorage.getItem("uuidUser") || ''
    const uuidTransaccion = localStorage.getItem("uuidTrx") || ''
    /* Hooks */
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            setDataOtorgante(dataOtorganteJSON)
        }

    }, [])

    return (
        <>
            <Liveness
                apikey={apikey}
                uuidCliente={uuidCliente}
                uuidTransaccion={uuidTransaccion}
                dataOtorgante={dataOtorgante}
                service_call={'videotoken-pdf'}
                previousStep={{ pathname: '/preparacion_liveness', state: { type: 'pdf' } }}
                nextStep={{ pathname: '/pdf_token_result', state: { type: 'interactivo' } }}
                cancelStep={`/pdf_token?uuid=${uuidCliente}&idOtorgante=${localStorage.getItem("idOtorgante")}&uuidTrx=${uuidTransaccion}&fecha=${localStorage.getItem("fechavigencia")}`}
                type='pdf'
                intents={'5'}
            />
        </>
    )
}

export default Index;
