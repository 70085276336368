import React, { useState, useEffect } from "react";
/* Librerías */
import { useHistory } from "react-router-dom";
import { isIOS, isMobile } from "react-device-detect";
/* Componentes */
import Header from "../components/headerNA";
import Footer from "../components/footer";
import Ayuda from './ayuda';
import IdTransaccion from "../components/id_transaccion";
import CancelarFlujo from "./cancelar_flujo";
import Loader from "../components/loader";
/* Funciones */
import {
    status, validarRenapo, generateZip, cancelarINE,
    statusError, validarRedireccionamiento, sendEventClick
} from '../services/data';
import { configStyle } from '../services/configStyle';

const PreparacionVideoGrabacion = () => {

    /* Hooks */
    const history = useHistory();
    const [estado, setEstado] = useState('');
    const [uuidTransaccion, setUuidTransaccion] = useState('');
    const [apikey, setApikey] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const [cancelProcess, setCancelProcess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showCancelScreen, setShowCancelScreen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [dataOtorgante, setDataOtorgante] = useState([]);

    useEffect(() => {
        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const apiKeyOtor = localStorage.getItem("apikeyOtorgante")
        if (apiKeyOtor) setApikey(apiKeyOtor)

        const uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) setUuidTransaccion(uuidTrx)

        status("preparacion_video_grabacion_page", "Preparacion Video Grabacion")
        validarRenapo()
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const cancelarFlujo = async () => {
        setIsLoading(true)
        cancelarINE()
        sendEventClick('Preparacion Video Grabacion', 'SÍ', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("preparacion_video_grabacion", 'Cancelado', "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarRedireccionamiento(dataOtorgante)) {
                setTimeout(() => {
                    history.replace('/' + apikey)
                    setIsLoading(false)
                }, 1000);
            } else {
                setIsLoading(false);
                setEstado('cancelado')
                setShowCancelScreen(true)
                setCancelProcess(false)
            }
        }
    }

    return (
        <div>
            <div className="main_gradient flex_container">
                <Header show={() => setShowHelp(true)} />
                <div className="main_text_container text-left flex_body">
                    <h1 className="ubuntu_bold_punto_trader animate__animated text-left">Videograbación</h1>
                    <p className="text_punto_trader">Prepárate para <b className="calibri_bold_punto_trader">capturar</b> el <b className="calibri_bold_punto_trader">frente</b> y el <b className="calibri_bold_punto_trader">reverso</b> de tu identificación <b className="calibri_bold_punto_trader">junto</b> con tu <b className="calibri_bold_punto_trader">rostro</b> como se muestra aquí.<br /></p>
                    <br></br>
                    <div className="items_video_token calibri_punto_trader">
                        <img className="image_videograbacion" src="images/videograbacion/front_girl.png" height="64" alt="" />
                    </div>
                </div>
                <div className="action_buttons flex_buttons">
                    <button type="button" onClick={() => { sendEventClick('Preparacion Video Grabacion', "CONTINUAR"); setShowAlert(true); }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader">Listo para grabar</button>
                    <button onClick={() => setCancelProcess(true)} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader" >Cancelar</button>
                </div>
                <IdTransaccion />
                <div style={{ marginTop: 10 }}>
                    <Footer />
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {(cancelProcess) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={() => setCancelProcess(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className='calibri_punto_trader'>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setCancelProcess(false); sendEventClick(`Preparacion Video Grabacion`, 'NO', {}); }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={cancelarFlujo} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {showCancelScreen &&
                <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={estado} />
            }
            {isLoading &&
                <Loader />
            }
            {(showAlert) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className={`modal-dialog ${isMobile ? 'modal-center' : ''}`} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Atención</h5>
                            </div>
                            <div className="modal-body">
                                <p>Considera ajustar el volumen del dispositivo para lograr escuchar las instrucciones que a continuación se reproducirán.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => history.replace('/video_grabacion')} className="btn btn-raised btn-primary button_punto_trader">Entendido</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PreparacionVideoGrabacion;
