import React, { useState, useEffect, Fragment } from "react"
/* Librerías */
import { Link, useHistory } from "react-router-dom"
import { isIOS } from 'react-device-detect'
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Ayuda from './ayuda'
import Loader from '../components/loader'
import CancelarFlujo from "./flujo/cancelar_flujo"
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { cancelarINE, status, statusError, validarRenapo, sendEventClick, generateZip, validarParametroOtorgante } from '../services/data'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"

const AutorizacionSolicitud = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [showCancel, setShowCancel] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {
        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        status("autorizacion_solicitud_page", "Autorizacion de Solicitud")
        validarRenapo()
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = async () => {
        setLoading(true)
        cancelarINE()
        sendEventClick('Cláusula', 'SI', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("autorizacion_solicitud_page", 'Cancelado', "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300)
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    return (
        <Fragment>
            {(showCancel) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-secondary modal_btn_w">SI</button>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className="main_gradient">
                <Header show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="animated">{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3")}</h1>
                    <p className="animated">Lee <b>atentamente</b> el documento que te presentaremos a <b>continuación</b> para proceder a su <b>aceptación</b> expresa.</p>
                </div>
                <div className="main_icon_container">
                    <img src="../images/wl/doc_check.png" alt="" />
                </div>
                <div className="action_buttons noscroll_screen">
                    <Link to="/autorizacion_documento" className="btn btn-raised btn-primary forcewidth100 main_bg_color" replace>LEER DOCUMENTO</Link>
                    <button onClick={e => setShowCancel(true)} className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</button>
                </div>
                <Footer />
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {loading && <Loader />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </Fragment>
    )
}

export default AutorizacionSolicitud