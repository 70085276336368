/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { useHistory } from "react-router-dom"
import { isMobile, isIOS } from 'react-device-detect'
/* Componentes */
import Header from "../../components/headerNA"
import Footer from "../../components/footer"
import Ayuda from '../ayuda'
import Loader from "../../components/loader"
import Cancelado from '../cancelado'
import CancelarFlujo from "../flujo/cancelar_flujo"
/* Funciones */
import { status, statusData, validarCampo, validarValoresFormulario, statusError, evento, sendEventClick, validarParametroOtorgante, onCut, onPaste } from '../../services/data'
/* Custom hooks */
import { useScrollToTop } from "../../utilities/hooks/custom"
import IdTransaccion from "../../components/id_transaccion";

const FormularioCliente = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [showHelp, setShowHelp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [focusNombre, setFocusNombre] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [focusApellidoPaterno, setFocusApellidoPaterno] = useState(false)
    const [focusApellidoMaterno, setFocusApellidoMaterno] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusNumeroCliente, setFocusNumeroCliente] = useState(false)
    const [getLocation, setGetLocation] = useState(false)
    const [showComponentCancel, setShowComponentCancel] = useState(false)
    const [errorMessageGPS, setErrorMessageGPS] = useState(false)
    const [errorLocation, setErrorLocation] = useState(false)
    const [validar, setValidar] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [inputsValue, setInputsValue] = useState({})
    const [inputsErrors, setInputsErrors] = useState({})

    useEffect(() => {

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        status("cliente_informacion_page", 'Cliente informacion')
        // Hooks para los valores de inicio de los inputs del formulario
        setInputsValue(inputsValue => ({ ...inputsValue, 'nombres': '' }));
        setInputsValue(inputsValue => ({ ...inputsValue, 'apellidoPaterno': '' }));
        setInputsValue(inputsValue => ({ ...inputsValue, 'apellidoMaterno': '' }));
        setInputsValue(inputsValue => ({ ...inputsValue, 'numeroCliente': '' }));
        setInputsValue(inputsValue => ({ ...inputsValue, 'curp': '' }));

    }, [])

    useEffect(() => {
        if (validar) {
            const continuar = validarValoresFormulario(inputsValue)
            if (continuar.correcto) {
                validarFormulario()
            } else {
                setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }))
                setValidar(false)
            }
        }
    }, [validar, inputsValue])

    const sendData = (infoFormulario) => {
        const jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("cliente_informacion", jsonObj)

        // cuando los datos del formulario son correctos
        setLoading(false);
        setTimeout(() => {
            history.replace(("/gracias_por_tu_interes"));
        }, 200);
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = null;
        if (event.target.name === 'curp') {
            let maxLength = 18;
            let value = event.target.value.trim().toUpperCase();
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength);
                event.target.value = value;
            }
            validacion = validarCampo(value, event.target.name);
        } else {
            validacion = validarCampo(event.target.value.trim().toUpperCase(), event.target.name);
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    };

    const validarFormulario = () => {
       // if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        let continuar = validarValoresFormulario(inputsValue)
        if (continuar.correcto) {
            // cuando los datos del formulario estan llenos y correctos se activa el permiso de geolocalización
            setGetLocation(true)
        } else {
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }))
        }
    }

    const statusE = async () => {
        setLoading(true)
        const data = "Cancelado"
        localStorage.setItem('flag', 'cancelado')
        await statusError("cliente_informacion", data, "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    const geoLocalizacion = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        initGPS();
        setGetLocation(false);
        setLoading(true);

        if (!navigator.geolocation) {
            setErrorLocation(true);
            return;
        }

        function success(position) {
            const { latitude, longitude } = position.coords;
            let infoFormulario = []
            infoFormulario.push({ description: "nombres", value: inputsValue.nombres })
            infoFormulario.push({ description: "apellidoPaterno", value: inputsValue.apellidoPaterno })
            infoFormulario.push({ description: "apellidoMaterno", value: inputsValue.apellidoMaterno })
            infoFormulario.push({ description: "numeroCliente", value: inputsValue.numeroCliente })
            infoFormulario.push({ description: "curp", value: inputsValue.curp })
            infoFormulario.push({ description: "gps", value: "[" + latitude + "," + longitude + "]" })
            localStorage.setItem('nombreCompleto', `${inputsValue.nombres.trim()} ${inputsValue.apellidoPaterno.trim()} ${inputsValue.apellidoMaterno.trim()}`)
            localStorage.setItem('flag', 'green')
            sendData(infoFormulario)

        }

        function error(error) {
            setErrorLocation(true)
            setLoading(false)
            const { code, message } = error
            let objetoError = {
                message: message
            }
            switch (code) {
                case 1:
                    // Permiso denegado.
                    objetoError.error = 'PERMISSION_DENIED';
                    setErrorMessageGPS('El permiso de localización fue denegado o se encuentra bloqueado en tu sistema. Por favor, permite el acceso e intenta nuevamente.');
                    evento('Obtener Geolocalización', 'Step', objetoError, false);
                    break;
                case 2:
                    // Posición no disponible.
                    objetoError.error = 'POSITION_UNAVAILABLE';
                    setErrorMessageGPS('No pudimos obtener información de localización, podría ser un error de red o de hardware, por favor verifica tu conexión o dispositivo e intenta nuevamente.');
                    evento('Obtener Geolocalización', 'Step', objetoError, false);
                    break;
                case 3:
                    // Se acabó el tiempo de espera.
                    objetoError.error = 'TIMEOUT';
                    setErrorMessageGPS('Se superó el tiempo de espera para obtener información de localización, por favor verifica tu conexión, permisos o dispositivo e intenta nuevamente.');
                    evento('Obtener Geolocalización', 'Step', objetoError, false);
                    break;
                default:
                    setErrorMessageGPS('No hemos podido obtener tu ubicación, por favor intenta nuevamente.');
                    evento('Obtener Geolocalización', 'Step', error, false);
                    break;
            }
        };

        navigator.geolocation.getCurrentPosition(success, error);
    }

    const errorGetLocation = async () => {
        setLoading(true)
        const data = "Cancelado"
        localStorage.setItem('flag', 'cancelado')
        await statusError("cliente_informacion", data, "cancelado")
        localStorage.setItem('completado', 'true')
        setLoading(false)
        setGetLocation(false)
        setShowComponentCancel(true)
    }

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase();

    const initGPS = () => {
        if (isMobile) {
            if (isIOS) {
                if (window.webkit) window.webkit.messageHandlers.BDMApplication.postMessage({ 'function': 'initGPS' })
            } else {
                if (window.Android) window.Android.initGPS()
            }
        } else {
            return
        }
    }

    return (
        <>
            <div className="main_gradient flex_container">
                <Header ruta="resultados_identificacion" show={() => setShowHelp(true)} />
                <div className="flex_body">
                    <div className="main_text_container">
                        <h1 className="title_punto_trader">¿Ya eres cliente?</h1>
                        <p className="text_punto_trader">Por favor, ingresa tus datos para validar tu identidad.</p>
                    </div>
                    <form>
                        <div className="ocr_data_display calibri_punto_trader">
                            <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombres !== "") ? "is-focused" : ""), inputsErrors.nombres && 'error'].join(" ")}>
                                <label htmlFor="nombres" className="bmd-label-floating">Nombre(s):</label>
                                <input type="text" className="form-control uppercase none_border" id="nombres" name="nombres" defaultValue={inputsValue.nombres} onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                                    setFocusNombre(true)
                                }} onBlur={event => {
                                    if (inputsValue.nombres !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.nombres === "" || event.target.value === '') {
                                        setFocusNombre(false)
                                    }
                                }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.nombres && (
                                    <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombres}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusApellidoPaterno || inputsValue.apellidoPaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoPaterno && 'error'].join(" ")}>
                                <label htmlFor="apellidoPaterno" className="bmd-label-floating">Apellido paterno:</label>
                                <input type="text" className="form-control uppercase none_border" id="apellidoPaterno" name="apellidoPaterno" defaultValue={inputsValue.apellidoPaterno} onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                                    setFocusApellidoPaterno(true)
                                }} onBlur={event => {
                                    if (inputsValue.apellidoPaterno !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.apellidoPaterno === "" || event.target.value === '') {
                                        setFocusApellidoPaterno(false)
                                    }
                                }}
                                    onPaste={onPaste}
                                    onCut={onCut} />
                                {inputsErrors.apellidoPaterno && (
                                    <span id="ht-apellidoPaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoPaterno}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusApellidoMaterno || inputsValue.apellidoMaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoMaterno && 'error'].join(" ")}>
                                <label htmlFor="apellidoMaterno" className="bmd-label-floating">Apellido materno:</label>
                                <input type="text" className="form-control uppercase none_border" id="apellidoMaterno" name="apellidoMaterno" defaultValue={inputsValue.apellidoMaterno} onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                                    setFocusApellidoMaterno(true)
                                }} onBlur={event => {
                                    if (inputsValue.apellidoMaterno !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.apellidoMaterno === "" || event.target.value === '') {
                                        setFocusApellidoMaterno(false)
                                    }
                                }}
                                onPaste={onPaste}
                                onCut={onCut}/>
                                {inputsErrors.apellidoMaterno && (
                                    <span id="ht-napellidoMaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoMaterno}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusNumeroCliente || inputsValue.numeroCliente !== "") ? "is-focused" : ""), inputsErrors.numeroCliente && 'error'].join(" ")}>
                                <label htmlFor="numeroCliente" className="bmd-label-floating">Número de cliente:</label>
                                <input type="text" className="form-control none_border" id="numeroCliente" name="numeroCliente" defaultValue={inputsValue.numeroCliente} maxLength="" onChange={handleChange} onFocus={e => {
                                    setFocusNumeroCliente(true)
                                }} onBlur={event => {
                                    if (inputsValue.numeroCliente !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.numeroCliente === "" || event.target.value === '') {
                                        setFocusNumeroCliente(false)
                                    }
                                }}
                                onPaste={onPaste}
                                onCut={onCut}/>
                                {inputsErrors.numeroCliente && (
                                    <span id="ht-numeroDeCliente" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroCliente}</span>
                                )}
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                                <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                                <input type="text" className="form-control uppercase none_border" id="curp" name="curp" maxLength="19" defaultValue={inputsValue.curp} onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                                    setFocusCurp(true)
                                }} onBlur={event => {
                                    if (inputsValue.curp !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.curp === "" || event.target.value === '') {
                                        setFocusCurp(false)
                                    }
                                }}
                                onPaste={onPaste}
                                onCut={onCut}/>
                                {inputsErrors.curp && (
                                    <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="action_buttons flex_buttons">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 button_punto_trader"].join(" ")} onClick={() => {setValidar(true); sendEventClick('Cliente información', 'INGRESAR');}}>Ingresar </button>
                    <button type="button" onClick={e => { setShowCancel(true); sendEventClick('Cliente información', 'CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader">Cancelar</button>
                </div>
                <IdTransaccion />
                <div style={{ marginBottom: 10 }}>
                    <Footer />
                </div>
            </div>

            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {(showCancel) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setShowCancel(false); sendEventClick('Cliente información', 'NO') }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={e => { statusE(); sendEventClick('Cliente información', 'SI', { status: 'CANCELADO' }) }} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {getLocation &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Activar localización.</h5>
                                <button onClick={e => setGetLocation(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">Por disposición oficial necesitamos validar tu ubicación para continuar.</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => { errorGetLocation(); sendEventClick('Cliente información', 'CANCELAR', { status: 'CANCELADO' }) }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">Cancelar</button>
                                <button type="button" onClick={(event) => { geoLocalizacion(event); sendEventClick('Cliente información', 'ACTIVAR', {}); }} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Activar</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {errorLocation &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Activar localización.</h5>
                                <button onClick={e => setErrorLocation(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">{errorMessageGPS}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={(event) => { geoLocalizacion(event); setErrorLocation(false); sendEventClick('Cliente información', 'REINTENTAR') }} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Reintentar</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loading && <Loader />}
            {showComponentCancel && <Cancelado tipo="location" />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </>
    )
}

export default FormularioCliente