/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useRef } from "react";
import Header from './header';
import IconCheck from "./assets/icon_check";

const LoaderOCR = ({side, show, dataOtorgante}) => {

    const [stateActionA, setStateActionA] = useState(true);
    const [stateActionB, setStateActionB] = useState(true);
    const [stateActionC, setStateActionC] = useState(true);
    const [stateActionD, setStateActionD] = useState(true);
    const [stateActionE] = useState(true);
    const [stateWord, setStateWord] = useState(true);
    let tiempo0 = useRef(500);
    let tiempo1 = useRef(800);
    let tiempo2 = useRef(1000);
    const timeout = useRef(null);
    const timeout1 = useRef(null);
    const timeout2 = useRef(null);
    const timeout3 = useRef(null);
    const timeout4 = useRef(null);

    useEffect(() => {
        if (side === "back") {
            //console.log("time: " + tiempo0, tiempo1, tiempo2);
            setStateWord(false);
            tiempo0.current = 500;
            tiempo1.current = 500;
            tiempo2.current = 1000;
        }
        timeout.current = setTimeout(() => {
            setStateActionA(false);
            timeout1.current = setTimeout(() => {
                setStateActionB(false);
                timeout2.current = setTimeout(() => {
                    setStateActionC(false);
                    timeout3.current = setTimeout(() => {
                        setStateActionD(false);
                        timeout4.current = setTimeout(() => {
                            //setStateActionE(false);
                        }, 1000)
                    }, 800)
                }, tiempo2.current)
            }, tiempo1.current)
        }, tiempo0.current)

        return (() => {
            if (timeout.current) clearTimeout(timeout.current)
            if (timeout1.current) clearTimeout(timeout1.current)
            if (timeout2.current) clearTimeout(timeout2.current)
            if (timeout3.current) clearTimeout(timeout3.current)
            if (timeout4.current) clearTimeout(timeout4.current)
        })

    }, [])

    return (
        <Fragment>
            <div className="id_progress  animate__animated animate__slideInUp">
                <Header show={() => show()} dataOtorgante={dataOtorgante}/>

                <div className="center_checks">
                    <h5>¡Espera unos segundos! <br /> Estamos analizando tu captura.</h5>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_a", ((stateActionA) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <IconCheck dataOtorgante={dataOtorgante} />
                        <p>Identificando tu documento</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_b", ((stateActionB) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <IconCheck dataOtorgante={dataOtorgante} />
                        <p>Detectando los bordes de tu documento</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_c", ((stateActionC) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <IconCheck dataOtorgante={dataOtorgante} />
                        <p>{[(stateWord) ? "Búsqueda de rostro" : "Reconocimiento la zona de lectura mecánica"].join("")}</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_d", ((stateActionD) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <IconCheck dataOtorgante={dataOtorgante} />
                        <p>Detectando texto en tu documento</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_e", ((stateActionE) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <IconCheck dataOtorgante={dataOtorgante} />
                        <p>Extrayendo tus datos</p>
                    </div>

                </div>
            </div>
        </Fragment>
    );
}

export default LoaderOCR;