/* eslint-disable react/jsx-no-target-blank */
import React from "react";

export default function EfirmaInfo({setShowEfirmaInfo}) {

    return (
        <div className="modal fade show" style={{ display: "block" }} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title ubuntu_bold_punto_trader">Número de serie de la firma electrónica</h5>
                        <button onClick={e => setShowEfirmaInfo(false)} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    <p className="calibri_punto_trader">Si deseas obtener el número de serie de tu firma electrónica, debes ingresar al Portal Web del SAT o en el siguiente enlace: </p>
                    <a href="https://portalsat.plataforma.sat.gob.mx/RecuperacionDeCertificados/" target="_blank" className="link_txt">Portal SAT - Recuperacion De Certificados</a>
                    </div>
                    <div className="modal-footer" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <p className="text_smaller">* Este campo es opcional</p>
                        <button type="button" onClick={() => setShowEfirmaInfo(false)} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Entendido</button>
                    </div>
                </div>
            </div>
        </div>
    )
}