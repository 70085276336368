/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react"
/* Librerías */
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { isIOS, isMobile } from "react-device-detect"
/* Componentes */
import LimitIntentsAlert from "../../components/alerts_modals/limit_intents"
import Header from "../../components/header"
import Ayuda from "../ayuda"
import Loader from "../../components/loader"
import CancelarFlujo from "../flujo/cancelar_flujo"
import IdTransaccion from "../../components/id_transaccion"
/* Funciones */
import { cancelarINE, generateZip, sendEventClick, statusError, validarRedireccionamiento, validarRenapo } from "../../services/data"
import { configStyle } from "../../services/configStyle"
/* Servicios */
import { cancelarRequest, cancelarRequestIne } from "../../services/api"

export default function IndicacionesLiveness() {

    /* Location */
    const location = useLocation()
    console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
    console.log(`🚀 ~ file: indicaciones_liveness.jsx:22 ~ IndicacionesLiveness ~ location:`, location)
    console.log(`🚀 -----------------------------------------------------------------------------------🚀`)
    /* Hooks */
    const history = useHistory()
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [pathname, setPathname] = useState('')
    const [estado, setEstado] = useState('')
    const [type, setType] = useState('')
    const [nextStep, setNextStep] = useState('')
    const [bloqueo, setBloqueo] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showHelp, setShowHelp] = useState(false)
    const [cancelProcess, setCancelProcess] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [disabledButtons, setDisabledButtons] = useState(true)
    const [dataOtorgante, setDataOtorgante] = useState([])

    const apikey = localStorage.getItem("apikeyOtorgante") || ''

    const consultaRenapo = useCallback(async (paso = '') => {
        try {
            await validarRenapo(paso)
            let validacionRenapo = localStorage.getItem("validacionRENAPO")
            console.log(`🚀 ------------------------------------------------------------------------🚀`)
            console.log(`🚀 ~ file: index.jsx:45 ~ useEffect ~ validacionRenapo:`, validacionRenapo)
            console.log(`🚀 ------------------------------------------------------------------------🚀`)
            if (validacionRenapo && validacionRenapo === 'false') {
                setTimeout(async () => {
                    await consultaRenapo('videotoken')
                }, 30000)
            } else {
                setLoading(false)
                setDisabledButtons(false)
            }
        } catch {
            await consultaRenapo('videotoken')
        }
    }, [])

    useEffect(() => {
        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            setDataOtorgante(dataOtorganteJSON)
        }

        setPathname(window.location.pathname)

        const uuidTrx = localStorage.getItem('uuidTrx')
        if (uuidTrx) setUuidTransaccion(uuidTrx)

        const block = localStorage.getItem("bloqueo")
        if (block === "true") setBloqueo(true);

        setType(location.state.type ? location.state.type : 'flujo')
        setNextStep((location.state.type && location.state.type === 'pdf') ? '/liveness_pdf' : '/liveness')

        if (type !== 'flujo') {
            setLoading(false)
            setDisabledButtons(false)
        }
    }, [])

    useEffect(() => {
        if (type === 'flujo') consultaRenapo()
    }, [type, consultaRenapo]);

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const cancelarFlujo = async () => {
        setLoading(true)
        setDisabledButtons(true)
        if (type === 'flujo') {
            cancelarINE()
            cancelarRequest()
            cancelarRequestIne()
            localStorage.setItem('flag', 'cancelado')
            await generateZip('TRUNCOS', 'trunco', isIOS)
            await statusError("captura_video_token", 'Cancelado', "cancelado")
            localStorage.setItem('completado', 'true')
        }
        sendEventClick('Liveness Pasivo Interactivo - ' + type, 'SÍ', { status: 'CANCELADO' }) //SE AGREGÓ TYPE PARA IDENTIFICAR SI ES FLUJO O PDF
        if (!window.opener) {
            if (validarRedireccionamiento(dataOtorgante)) {
                if (type === 'pdf') {
                    setTimeout(() => {
                        const previousStep = `/pdf_token?uuid=${localStorage.getItem("uuidUser") && localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("uuidOtorgante")}&uuidTrx=${localStorage.getItem("uuidTrx") && localStorage.getItem("uuidTrx")}&fecha=${localStorage.getItem("fechavigencia")}`
                        setLoading(false)
                        history.replace(previousStep)
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setLoading(false)
                        history.replace('/' + apikey)
                    }, 1000);
                }
            } else {
                setLoading(false)
                setCancelProcess(false)
                setShowCancelScreen(true)
                setEstado('cancelado')
            }
        }
    }

    return (
        <div className='main_gradient container-flex' style={{ overflowY: 'scroll' }}>
            <Header show={() => setShowHelp(true)} />
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            <div className="main_text_container text-left custom_padding">
                <h1 className="title_punto_trader text-left">Confirmación de identidad</h1>
                <p className="calibri_punto_trader">Mantén tu <b className="calibri_bold_punto_trader">rostro visible, sin más personas</b> en la toma, <b className="calibri_bold_punto_trader">no</b> utilices <b className="calibri_bold_punto_trader">gorra</b> y <b className="calibri_bold_punto_trader">evita</b> colocarte <b className="calibri_bold_punto_trader">frente</b> a <b>fuentes</b> de luz.<br /></p>
                <br></br>
            </div>
            <div className='container_selfie_instructions container-flex'>
                <div className='row'>
                    <div className={`${isMobile ? 'col-12  div-text-center' : 'col-6 div-text-right'}`}>
                        <img className={`${isMobile ? 'img_selfie_instructions' : 'img_selfie_instructions_desktop'}`} src={`images/componente_selfie/${apikey === 'gAxydegFi6Wfd7X1QRcvtA9ztXokyQXi' ? 'selfie_error_dy1@2x.png' : 'selfie_error_g1@2x.png'}`} alt='' />
                    </div>
                    <div className={`${isMobile ? 'col-12  div-text-center' : 'col-6 div-text-left'}`}>
                        <img className={`${isMobile ? 'img_selfie_instructions' : 'img_selfie_instructions_desktop'}`} src={`images/componente_selfie/${apikey === 'gAxydegFi6Wfd7X1QRcvtA9ztXokyQXi' ? 'selfie_error_dy2@2x.png' : 'selfie_error_g2@2x.png'}`} alt='' />
                    </div>
                </div>
            </div>

            <div className="action_buttons animate__animated animate__fadeIn animate__delay-1s custom_padding flex_buttons">
                <button onClick={() => {
                    sendEventClick('Liveness Pasivo Interactivo - ' + type, 'CONTINUAR')
                    history.replace(nextStep)
                }
                }
                    className="btn btn-raised btn-primary forcewidth100 button_punto_trader" disabled={disabledButtons}>Continuar</button>
                <button onClick={() => { setCancelProcess(true); sendEventClick('Liveness Pasivo Interactivo - ' + type, 'CANCELAR'); }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader" disabled={disabledButtons}>Cancelar</button>
            </div>
            <IdTransaccion />
            {(cancelProcess) &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={e => setCancelProcess(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setCancelProcess(false); sendEventClick('Liveness Pasivo Interactivo - ' + type, 'NO'); }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">NO</button>
                                <button type="button" onClick={cancelarFlujo} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">SÍ</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showCancelScreen &&
                <CancelarFlujo type={type} uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={estado} />
            }
            {loading && <Loader />}
            {bloqueo === true && <LimitIntentsAlert
                uuidTransaccion={uuidTransaccion}
                functionOnOut={() => { history.replace('/' + apikey) }}
                dataOtorgante={dataOtorgante}
                pathname={pathname}
            />}
        </div>
    )
}