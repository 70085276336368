/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { useHistory } from "react-router-dom"
import moment from 'moment'
import NumberFormat from 'react-number-format'
/* Componentes */
import Info from '../../components/encuentra_info'
import InfoIne from '../../components/info_ine'
import Loader from "../../components/loader"
import CamposListaNominal from './consulta_lista_nominal'
/* Funciones */
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import {
    agregarInfo, statusData, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo,
    validarValoresFormulario, evento, encolarPeticionINE, mapearInformacionFormulario,
    sendEventClick, actualizarScoreModeloC, mapearRespuesta, mapearError
} from '../../services/data'
import { onPaste, onCut } from "../../services/data"
/* Servicios */
import { enviarRenapo } from '../../services/api'


let FormularioIneConsulta = ({
    modeloIdentificacion,
    identificacionElectoral
}) => {
    /* Hooks */
    const history = useHistory()
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false)
    const [focusClave, setfocusClave] = useState(false)
    const [focusRegistro, setfocusRegistro] = useState(false)
    const [focusEmision, setfocusEmision] = useState(false)
    const [datosCorrectos, setDatosCorrectos] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorRenapo, setErrorRenapo] = useState(false)
    const [sendingForm, setSendingForm] = useState(false)
    const [validar, setValidar] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [inputsErrors, setInputsErrors] = useState({})
    const [inputsValue, setInputsValue] = useState({
        nombreCompleto: '',
        fechaNacimiento: '',
        clave: '',
        fechaRegistro: '',
        numeroEmision: '',
        curp: '',
        vigencia: ''
    })

    useEffect(() => {
        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
    }, [])

    useEffect(() => {
        console.log(`🚀 --------------------------------------------------------------------------------------🚀`)
        console.log(`🚀 ~ file: formulario_ine_consulta.jsx:20 ~ modeloIdentificacion:`, modeloIdentificacion)
        console.log(`🚀 --------------------------------------------------------------------------------------🚀`)
        let ocrFront = {}, ocrBack = {}

        const valoresOCR = {}
        const dataOCRFrontLS = localStorage.getItem("ocrFront")
        if (dataOCRFrontLS) ocrFront = JSON.parse(dataOCRFrontLS)
        const dataOCRBackLS = localStorage.getItem("ocrBack")
        if (dataOCRBackLS) ocrBack = JSON.parse(dataOCRBackLS)
        if (ocrFront) {
            if (ocrFront.nombreCompleto) {
                setInputsValue(inputsValue => ({ ...inputsValue, 'nombreCompleto': ocrFront.nombreCompleto.trim() }))
                valoresOCR.nombreCompleto = ocrFront.nombreCompleto.trim()
            } else {
                valoresOCR.nombreCompleto = ''
            }
            if (ocrFront.claveElector) {
                setInputsValue(inputsValue => ({ ...inputsValue, 'clave': (ocrFront.claveElector).trim() }))
                valoresOCR.clave = (ocrFront.claveElector).trim()
            }
            else {
                valoresOCR.clave = ''
            }
            if (ocrFront.anioRegistro) {
                setInputsValue(inputsValue => ({ ...inputsValue, 'fechaRegistro': (ocrFront.anioRegistro).trim() }))
                valoresOCR.fechaRegistro = (ocrFront.anioRegistro).trim()
            } else {
                valoresOCR.fechaRegistro = '';
            }
            if (ocrFront.numeroRegistro) {
                setInputsValue(inputsValue => ({ ...inputsValue, 'numeroEmision': (ocrFront.numeroRegistro).trim() }))
                valoresOCR.numeroEmision = (ocrFront.numeroRegistro).trim()
            } else {
                valoresOCR.numeroEmision = ''
            }
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento)
                    let fechaNacimiento = formatoFechaOcr(fechaOcr)
                    setInputsValue(inputsValue => ({ ...inputsValue, 'fechaNacimiento': fechaNacimiento }))
                    valoresOCR.fechaNacimiento = fechaNacimiento
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento)
                    setInputsValue(inputsValue => ({ ...inputsValue, 'fechaNacimiento': fechaNacimiento }))
                    valoresOCR.fechaNacimiento = fechaNacimiento
                }
            } else {
                valoresOCR.fechaNacimiento = ''
            }
            if (ocrFront.curp) {
                if (ocrFront.curp.length > 14) {
                    setInputsValue(inputsValue => ({ ...inputsValue, 'curp': (ocrFront.curp).trim() }))
                    valoresOCR.curp = (ocrFront.curp).trim()
                }
            } else {
                valoresOCR.curp = ''
            }
            let vigenciaC = ''
            if (ocrFront.vigencia) {
                vigenciaC = "20" + ocrFront.vigencia.substring(2)
                setInputsValue(inputsValue => ({ ...inputsValue, 'vigencia': vigenciaC }))
                valoresOCR.vigencia = vigenciaC
            } else {
                valoresOCR.vigencia = ''
            }
        }

        if (ocrBack) {
            let vigenciaC = ''
            if (ocrBack.ineVigencia) {
                vigenciaC = ocrBack.ineVigencia
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    setInputsValue(inputsValue => ({ ...inputsValue, 'vigencia': "20" + vigenciaC.substring(2) }))
                    valoresOCR.vigencia = "20" + vigenciaC.substring(2)
                }
            } else {
                if (ocrFront.vigencia) {
                    if (vigenciaC) {
                        setInputsValue(inputsValue => ({ ...inputsValue, 'vigencia': "20" + vigenciaC.substring(2) }))
                        valoresOCR.vigencia = "20" + vigenciaC.substring(2)
                    } else {
                        let vi = ''
                        if (ocrFront.vigencia.includes(' '))
                            vi = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vi = ocrFront.vigencia
                        setInputsValue(inputsValue => ({ ...inputsValue, 'vigencia': vi }))
                        valoresOCR.vigencia = vi
                    }
                } else {
                    if (ocrFront.vigencia) {
                        vigenciaC = "20" + ocrFront.vigencia.substring(2)
                        setInputsValue(inputsValue => ({ ...inputsValue, 'vigencia': vigenciaC }))
                        valoresOCR.vigencia = vigenciaC
                    } else {
                        valoresOCR.vigencia = ''
                    }
                }
            }
        }
        switch (modeloIdentificacion) {
            case 'D':
                setInputsValue(inputsValue => ({ ...inputsValue, 'cic': '' }))
                setInputsValue(inputsValue => ({ ...inputsValue, 'ocr': '' }))

                if (ocrBack) {
                    let ocr = ''
                    if (ocrBack.hasOwnProperty('seccionMRZ'))
                        ocr = ocrBack.seccionMRZ.trim()
                    if (ocrBack.hasOwnProperty('ciudadanoMRZ'))
                        ocr = `${ocr}${ocrBack.ciudadanoMRZ.trim()}`
                    setInputsValue(inputsValue => ({ ...inputsValue, 'ocr': ocr }))
                    valoresOCR.ocr = ocr
                    if (ocrBack.hasOwnProperty('cicMRZ')) {
                        const { cicMRZ } = ocrBack
                        setInputsValue(inputsValue => ({ ...inputsValue, 'cic': cicMRZ.trim() }))
                        valoresOCR.cic = cicMRZ.trim()
                    } else {
                        valoresOCR.cic = ''
                    }
                }
                validarDatos(valoresOCR)
                break
            case 'C':
                setInputsValue(inputsValue => ({ ...inputsValue, 'ocr': '' }))

                if (ocrBack) {
                    let ocr = ''
                    if (ocrBack.hasOwnProperty('seccionMRZ'))
                        ocr = ocrBack.seccionMRZ.trim()
                    if (ocrBack.hasOwnProperty('ciudadanoMRZ'))
                        ocr = `${ocr}${ocrBack.ciudadanoMRZ.trim()}`
                    setInputsValue(inputsValue => ({ ...inputsValue, 'ocr': ocr }));
                    valoresOCR.ocr = ocr
                }
                validarDatos(valoresOCR)
                break
            case 'A':
                setInputsValue(inputsValue => ({ ...inputsValue, 'ocr': '' }))

                if (ocrBack) {
                    let ocr = ''
                    if (ocrBack.hasOwnProperty('seccionMRZ'))
                        ocr = ocrBack.seccionMRZ.trim()
                    if (ocrBack.hasOwnProperty('ciudadanoMRZ'))
                        ocr = `${ocr}${ocrBack.ciudadanoMRZ.trim()}`
                    setInputsValue(inputsValue => ({ ...inputsValue, 'ocr': ocr }));
                    valoresOCR.ocr = ocr
                }
                validarDatos(valoresOCR)
                break
            default:
                setInputsValue(inputsValue => ({ ...inputsValue, 'identificadorCiudadano': '' }))
                setInputsValue(inputsValue => ({ ...inputsValue, 'cic': '' }))

                if (ocrBack) {
                    if (ocrBack.hasOwnProperty('cicMRZ')) {
                        const { cicMRZ } = ocrBack
                        setInputsValue(inputsValue => ({ ...inputsValue, 'cic': cicMRZ }))
                        valoresOCR.cic = cicMRZ
                    } else {
                        valoresOCR.cic = ''
                    }
                    if (ocrBack.ciudadanoMRZ) {
                        setInputsValue(inputsValue => ({ ...inputsValue, 'identificadorCiudadano': ocrBack.ciudadanoMRZ }))
                        valoresOCR.identificadorCiudadano = ''
                    } else {
                        valoresOCR.identificadorCiudadano = ''
                    }
                }
                validarDatos(valoresOCR)
                break
        }
    }, [modeloIdentificacion])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        if (validar) {
            sendEventClick('Datos personales OCR', 'MIS DATOS SON CORRECTOS')
            setDatosCorrectos(false)
            setLoading(true)
            const continuar = validarValoresFormulario(inputsValue)
            if (continuar.correcto) {
                validarFormulario()
            } else {
                evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false)
                setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }))
                setDatosCorrectos(true)
                setLoading(false)
                setSendingForm(false)
                setValidar(false)
            }
        }
    }, [validar, inputsValue])

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.datosPersonalesOCR = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = null;
        let value = event.target.value.trim().toUpperCase();
        if (event.target.name === 'curp') {
            let maxLength = 18;
            if (event.target.value.length > maxLength) {
                value = value.substring(0, maxLength);
                event.target.value = value;
            }
            validacion = validarCampo(value, event.target.name);
        } else {
            validacion = validarCampo(value, event.target.name);
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

        if (inputsErrors.length === 0) setDatosCorrectos(true); setSendingForm(false);

    };

    const validarFormulario = async () => {
        sendEventClick('Datos personales OCR', 'MIS DATOS SON CORRECTOS');
        // if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        // setDatosCorrectos(false)
        // setLoading(true)
        /* const continuar = validarValoresFormulario(inputsValue)
        if (continuar.correcto) {
            
        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false)
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }))
            setDatosCorrectos(true)
            setLoading(false)
            setSendingForm(false)
        } */
        let idCiudadano = ''
        if (modeloIdentificacion === 'A') {
            const lengthString = inputsValue.ocr.length
            idCiudadano = inputsValue.ocr.substring(4, lengthString)
        }

        if (obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "false") === "true")
            encolarPeticionINE(modeloIdentificacion, inputsValue, identificacionElectoral, idCiudadano)

        if (modeloIdentificacion === 'C') {
            try {
                const response = await actualizarScoreModeloC()
                const { status, data } = response
                if (status === 200) {
                    evento('Actualizar score INE modelo C', 'Success', mapearRespuesta(status, data), true)
                }
            } catch (error) {
                const errorMapeado = mapearError(error)
                evento('Actualizar score INE modelo C', errorMapeado.tipoError, errorMapeado.objetoError, false)
            }
        }

        const fechaNacimiento = document.getElementById("fechaNacimiento").value
        const infoFormulario = mapearInformacionFormulario(inputsValue, agregarInfo, fechaNacimiento)
        console.log(`🚀 -----------------------------------------------------------------------------------------------🚀`)
        console.log(`🚀 ~ file: formulario_ine_consulta.jsx:286 ~ validarFormulario ~ infoFormulario:`, infoFormulario)
        console.log(`🚀 -----------------------------------------------------------------------------------------------🚀`)
        sendData(infoFormulario);
        /* infoFormulario.push({ description: "nombreCompleto", value: inputsValue.nombreCompleto })
        localStorage.setItem('nombreCompleto', inputsValue.nombreCompleto)
        infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
        agregarInfo('claveElector', inputsValue.clave);
        infoFormulario.push({ description: "claveElector", value: inputsValue.clave })
        infoFormulario.push({ description: "fechaRegistro", value: inputsValue.fechaRegistro })
        infoFormulario.push({ description: "numeroEmision", value: inputsValue.numeroEmision })
        infoFormulario.push({ description: "cic", value: inputsValue.cic })
        agregarInfo("curp", inputsValue.curp)
        infoFormulario.push({ curp: inputsValue.curp })
        infoFormulario.push({ description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substring(2) });
        sendData(infoFormulario); */
        if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "true") === "true") {
            let tipo = '';
            let informacion = {};
            enviarRenapo(inputsValue.curp).then(({ status, data: { payload }, data: { payload: { job_id } } }) => {
                localStorage.setItem("jobRENAPO", job_id);
                localStorage.setItem("validacionRENAPO", false);
                informacion.status = status;
                informacion.payload = payload;
                evento('Encolar petición RENAPO', 'Success', informacion, true);
                setTimeout(() => {
                    history.replace('/informacion_personal');
                }, 300);
            }).catch(error => {
                if (error.response) {
                    let { data, status } = error.response
                    informacion.data = data;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Encolar petición RENAPO', tipo, informacion, false);
                /* setDatosCorrectos(true);
                setErrorRenapo(true);
                */
                setTimeout(() => {
                    setLoading(false)
                    history.replace('/informacion_personal');
                }, 300);
            })
        } else {
            setDatosCorrectos(true);
            setTimeout(() => {
                setLoading(false)
                history.replace('/informacion_personal');
            }, 300);
        }
    }

    const validarDatos = (valores) => {
        let validacion = validarValoresFormulario(valores);
        if (validacion.correcto) {
            return;
        } else {
            setInputsErrors(validacion.errores);
        }
    }

    useEffect(() => {
        let validacion = validarValoresFormulario(inputsValue);
        if (validacion.correcto) {
            setDatosCorrectos(true);
            setInputsErrors(validacion.errores);
        } else {
            setDatosCorrectos(false);
            setInputsErrors(validacion.errores);
        }
    }, [inputsValue]);

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase();

    const capturarNuevamente = () => {
        sendEventClick('Datos personales OCR', 'CAPTURAR NUEVAMENTE')
        history.replace({
            pathname: "/identificacion",
            state: { passport: false }
        })
    }

    return (
        <>
            <form>
                <div className="ocr_data_display calibri_punto_trader animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombreCompleto !== "") ? "is-focused" : ""), inputsErrors.nombreCompleto && 'error'].join(" ")}>
                        <label htmlFor="nombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control none_border" id="nombreCompleto" name="nombreCompleto" defaultValue={inputsValue.nombreCompleto} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                        }} onBlur={event => {
                            if (inputsValue.nombreCompleto !== event.target.value) {
                                handleChange(event)
                            }
                            if (inputsValue.nombreCompleto === "" || event.target.value === '') {
                                setFocusNombre(false)
                            }
                        }}
                            onPaste={onPaste}
                            onCut={onCut}
                        />
                        {inputsErrors.nombreCompleto && (
                            <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombreCompleto}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control none_border" id="fechaNacimiento" name="fechaNacimiento" value={inputsValue.fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                        }
                        } onBlur={event => {
                            if (inputsValue.fechaNacimiento !== event.target.value) {
                                handleChange(event)
                            }
                            if (inputsValue.fechaNacimiento === "" || event.target.value === '') {
                                setFocusNacimiento(false)
                            }
                        }}
                            onPaste={onPaste}
                            onCut={onCut}
                        />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusClave || inputsValue.clave !== "") ? "is-focused" : ""), inputsErrors.clave && 'error'].join(" ")}>
                        <label htmlFor="clave" className="bmd-label-floating">Clave de elector:</label>
                        <small> *Dato frontal</small>
                        <div className="display_flex">
                            <input type="text" className="form-control uppercase none_border" id="clave" name="clave" maxLength="20" defaultValue={inputsValue.clave} onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                                setfocusClave(true)
                            }} onBlur={event => {
                                if (inputsValue.clave !== event.target.value) {
                                    handleChange(event)
                                }
                                if (inputsValue.clave === "" || event.target.value === '') {
                                    setfocusClave(false)
                                }
                            }}
                                onPaste={onPaste}
                                onCut={onCut}
                            />
                            <button type="button" className={['button_info', inputsErrors.clave && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                        </div>
                        {inputsErrors.clave && (
                            <span id="ht-clave" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.clave}</span>
                        )}
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusRegistro || inputsValue.fechaRegistro !== "") ? "is-focused" : ""), inputsErrors.fechaRegistro && 'error'].join(" ")}>
                                <label htmlFor="fechaRegistro" className="bmd-label-floating">Año de registro:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="fechaRegistro" name="fechaRegistro" maxLength="4" value={inputsValue.fechaRegistro || ''} onChange={handleChange} onFocus={e => {
                                        setfocusRegistro(true)
                                    }
                                    } onBlur={event => {
                                        if (inputsValue.fechaRegistro !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.fechaRegistro === "" || event.target.value === '') {
                                            setfocusRegistro(false)
                                        }
                                    }}
                                        onPaste={onPaste}
                                        onCut={onCut}
                                    />
                                    <button type="button" className={['button_info', inputsErrors.fechaRegistro && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                                </div>
                                {inputsErrors.fechaRegistro && (
                                    <span id="ht-registro" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaRegistro}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusEmision || inputsValue.numeroEmision !== "") ? "is-focused" : ""), inputsErrors.numeroEmision && 'error'].join(" ")}>
                                <label htmlFor="numeroEmision" className="bmd-label-floating">Número de emisión:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="numeroEmision" name="numeroEmision" value={inputsValue.numeroEmision || ''} maxLength="2" onChange={handleChange} onFocus={e => {
                                        setfocusEmision(true)
                                    }
                                    } onBlur={event => {
                                        if (inputsValue.numeroEmision !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.numeroEmision === "" || event.target.value === '') {
                                            setfocusRegistro(false)
                                        }
                                    }}
                                        onPaste={onPaste}
                                        onCut={onCut}
                                    />
                                    <button type="button" className={['button_info', inputsErrors.numeroEmision && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                                </div>
                                {inputsErrors.numeroEmision && (
                                    <span id="ht-emision" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroEmision}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <CamposListaNominal
                        modeloIdentificacion={modeloIdentificacion}
                        inputsValue={inputsValue}
                        inputsErrors={inputsErrors}
                        setShowInfo={setShowInfo}
                        handleChange={handleChange}
                    />

                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                        <input type="text" className="form-control uppercase none_border" id="curp" name="curp" defaultValue={inputsValue.curp} maxLength="19" onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                            setFocusCurp(true)
                        }} onBlur={event => {
                            if (inputsValue.curp !== event.target.value) {
                                handleChange(event)
                            }
                            if (inputsValue.curp === "" || event.target.value === '') {
                                setfocusRegistro(false)
                            }
                        }}
                            onPaste={onPaste}
                            onCut={onCut} />
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigencia !== "") ? "is-focused" : ""), inputsErrors.vigencia && 'error'].join(" ")}>
                                <label htmlFor="vigencia" className="bmd-label-floating">Vencimiento identificación:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="vigencia" name="vigencia" maxLength="4" defaultValue={inputsValue.vigencia} onChange={handleChange} onFocus={e => {
                                        setFocusVigencia(true)
                                    }} onBlur={event => {
                                        if (inputsValue.vigencia !== event.target.value) {
                                            handleChange(event)
                                        }
                                        if (inputsValue.vigencia === "" || event.target.value === '') {
                                            setfocusRegistro(false)
                                        }
                                    }}
                                        onPaste={onPaste}
                                        onCut={onCut}
                                    />
                                    <button type="button" className={['button_info', inputsErrors.vigencia && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                                </div>
                                {inputsErrors.vigencia && (
                                    <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigencia}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">
                        </div>
                    </div>
                    <Info modeloIdentificacion={modeloIdentificacion} />
                    {showInfo && <InfoIne close={() => setShowInfo(false)} modeloIdentificacion={modeloIdentificacion} />}
                </div>
                <div className="action_buttons animate__animated">
                    {/* <button type="button" className={["btn btn-raised btn-primary forcewidth100", ((!datosCorrectos || sendingForm) ? "button_gray_punto_trader" : "button_punto_trader")].join(" ")} onClick={event => { validarFormulario(event); setSendingForm(true); }} disabled={(datosCorrectos || !sendingForm) ? false : true}>Mis datos son correctos</button> */}
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100", ((!datosCorrectos || sendingForm) ? "button_gray_punto_trader" : "button_punto_trader")].join(" ")} onClick={event => { setValidar(true); setSendingForm(true); }} disabled={(datosCorrectos || !sendingForm) ? false : true}>Mis datos son correctos</button>
                    <button type="button" className='btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader' onClick={() => capturarNuevamente()} disabled={sendingForm}>Capturar nuevamente</button>
                </div>
            </form>
            {loading && <Loader />}
            {(errorRenapo) &&
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Error</h5>
                                <button onClick={e => setErrorRenapo(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">Ocurrio un error en el proceso. Revisa tu conexión a internet e intenta nuevamente.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={e => { sendEventClick('Datos personales OCR', 'REINTENTAR'); setErrorRenapo(false); }} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Reintentar</button>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default FormularioIneConsulta