/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { useHistory, useLocation, Link } from "react-router-dom"
import { isChrome, isMobile, isIOS, isSafari } from 'react-device-detect'
/* Componentes */
import Header from "../components/header"
import Footer from "../components/footer"
import Ayuda from "./ayuda"
/* Funciones */
import { getNextStepIdentification } from "../services/data"
import { configStyle, obtenerValorConfig } from '../services/configStyle'
/* Servicios */
import { obtenerParametrosOtorgantePK } from '../services/api.js'

const PdfToken = () => {
    /* Hooks */
    const query = useQuery()
    const history = useHistory()
    const [fechaVigencia, setFechaVigencia] = useState("")
    const [nextStep, setNextStep] = useState('/pdf_video_token')
    const [browserDnotSupported, setNotSupported] = useState(false)
    const [browsernotSupportediOS, setNotSupportediOS] = useState(false)
    const [errorCarga, seterrorCarga] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {
        localStorage.clear()
        localStorage.setItem("uuidUser", query.get("uuid"))
        localStorage.setItem("idOtorgante", query.get("idOtorgante"))
        localStorage.setItem("fechavigencia", query.get("fecha"))
        localStorage.setItem("uuidTrx", query.get("uuidTrx"))
        setFechaVigencia(query.get("fecha"))
        obtenerParametros(query.get("idOtorgante"))

        setTimeout(() => {
            if (Date.now() > query.get("fecha")) {
                history.replace("/pdf_token_error")
            }
        }, 500);
    }, []);

    useEffect(() => {
        if (dataOtorgante.length !== 0) {
            configStyle(dataOtorgante)
            localStorage.setItem("dataOtorgante", JSON.stringify(dataOtorgante))
            const logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""))
            ////console.log("logo", logo);
            document.getElementById("imagenLogo").src = logo
            const apikey = (obtenerValorConfig(dataOtorgante, 'APIKEY', ""))
            localStorage.setItem('apikeyOtorgante', apikey)

            const nextStep = getNextStepIdentification(dataOtorgante, 'pdf');
            console.log(`🚀 ------------------------------------------------------------🚀`)
            console.log(`🚀 ~ file: pdf_token.jsx:55 ~ useEffect ~ nextStep:`, nextStep)
            console.log(`🚀 ------------------------------------------------------------🚀`)
            setNextStep(nextStep)
        }
    }, [dataOtorgante])

    useEffect(() => {
        if (!isMobile && !isChrome) {
            //console.log("desktop not supported browsers");
            setNotSupported(true)
        } else if (isMobile && isIOS && !isSafari) {
            //console.log("mobile not supported browsers in iOS");
            setNotSupportediOS(true)
        } else if (isMobile && !isIOS && !isChrome) {
            //console.log("Android, not supported browser");
            setNotSupported(true)
        }
    }, [])

    const obtenerParametros = async (apiKey) => {
        obtenerParametrosOtorgantePK(apiKey, apiKey).then((data) => {
            if (data.status === 200) {
                setDataOtorgante(data.data.payload)
            } else {
                seterrorCarga(true)
            }
        }).catch(() => {
            seterrorCarga(true)
        });
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }

    return (
        <>
            <div className="main_gradient">
                {(errorCarga) &&
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="calibri_punto_trader text_size_punto_trader animate__animated animate__slideInDown">Verifica tu <b className="calibri_bold_punto_trader">conexión de internet</b> y vuelve a intentar</p>
                            <button type="button" onClick={(e) => {
                                seterrorCarga(false); window.location.reload();
                            }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader alert_btn animate__animated animate__fadeIn animate__delay-2s">Volver a intentar</button>
                        </div>
                    </div>
                }
                <div className="flex_container">
                    <Header show={() => setShowHelp(true)} />
                    <div className="main_text_container flex_body">
                        <h1 className="animated fadeIn animate__delay-1s title_punto_trader">Resultado de proceso</h1>
                        <p className="calibri_punto_trader text_size_punto_trader animate__animated animate__fadeIn animate__delay-2s">
                            Para ver o descargar el resultado de tu proceso <b className="calibri_bold_punto_trader">asegúrate</b> que tu dispositivo tenga <b className="calibri_bold_punto_trader">acceso a la cámara</b> y que te encuentres en un <b className="calibri_bold_punto_trader">lugar silencioso</b>:
                            <br /><br />
                            <small>*<b className="calibri_bold_punto_trader">Respalda</b> tu documento, solo se puede <b className="calibri_bold_punto_trader">consultar una vez</b>.</small><br /><br />

                            <small>Vencimiento: <b className="calibri_bold_punto_trader">{new Intl.DateTimeFormat("es-MX", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit"
                            }).format(new Date(Number(fechaVigencia)))}</b></small>
                        </p>
                    </div>
                    <div className="action_buttons noscroll_screen">
                        <Link to={nextStep} 
                        className="btn btn-raised btn-primary forcewidth100 button_punto_trader animate__animated animate__fadeIn animate__delay-3s" replace>Continuar</Link>
                    </div>
                </div>
                {((browserDnotSupported) && <div className="browser_not_comp show_me_bx">
                    <div className="top_bx">
                        <img src="images/browser_alert_icon.svg" alt="" />
                        <h4>Lo sentimos tu navegador actual no es <br />
                            compatible con nuestra plataforma.</h4>
                        <div className="divider"></div>
                    </div>
                    <div className="bottom_bx">
                        <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>
                        <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                            <img src="images/browser_chrome.png" width="96" alt="" />
                            <a href={isMobile ? 'https://play.google.com/store/apps/details?id=com.android.chrome' : 'https://www.google.com/chrome/'}>Chrome</a>
                            <small>Google</small>
                        </div>
                    </div>

                </div>)}
                {(browsernotSupportediOS) && <div className="browser_not_comp show_me_bx">
                    <div className="top_bx">
                        <img src="images/browser_alert_icon.svg" alt="" />
                        <h4>Apple limita el uso de aplicaciones avanzadas en este navegador.</h4>
                        <div className="divider"></div>
                    </div>
                    <div className="bottom_bx">
                        <p><small>Para continuar con tu proceso utiliza o descarga Safari para iOS.</small></p>
                        <div className="browser_bx ios_browser">
                            <img src="images/browser_ios.png" width="96" alt="" />
                            <a href="https://apps.apple.com/mx/app/safari/id1146562112">Safari</a>
                            <small>Apple</small>
                        </div>
                    </div>
                </div>}
                <Footer />
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>)
}


export default PdfToken