import axios from 'axios';

const host = process.env.REACT_APP_URL
const servicio = process.env.REACT_APP_SERVICE
const bucket = process.env.REACT_APP_BUCKET
const token = process.env.REACT_APP_TOKEN_EVENTS

const correoH = window.location.hostname;

const endpoints = {
    otorgante: `${host}/v1/sec_${servicio}params`,
    otorgantePK: `${host}/v1/sec_${servicio}params_pk`,
    ocr: `${host}/v2/sec_${servicio}ocr`,
    //correo: `${host}/v1/sec_${servicio}async_zip_nom_151_correo`,
    nom151: `${host}/v1/sec_${servicio}flow_checker`, //Integración flow checker
    pdftoken: `${host}/v1/sec_${servicio}token_numbers_pk`,
    score: `${host}/v2/sec_${servicio}query_score`,
    generarSMS: `${host}/v1/sec_${servicio}token_sms_ac`,
    validarSMS: `${host}/v1/sec_${servicio}verify_token_sms/verify`,
    otp: `${host}/v1/sec_${servicio}otp`,
    actualizarScore: `${host}/v2/sec_${servicio}score_update`,
    gps: `${host}/v1/sec_${servicio}address`,
    actualizarEstado: `${host}/v2/sec_${servicio}update_cycle`,
    getCP: `${host}/v1/sec_${servicio}postalcodes`,
    renapo: `${host}/v1/sec_${servicio}valid_curp`,
    sendIne: `${host}/v1/sec_${servicio}valid_ine`,
    getDocuments: `${host}/v1/sec_${servicio}proccessdocuments`,
    sendFile: `${host}/v1/sec_${servicio}file`,
    blackList: `${host}/v1/sec_${servicio}black_list`,
    getCollections: `${host}/v1/sec_${servicio}catalogos_az/`,
    newVideoToken: `${host}/v2/sec_${servicio}video_token`,
    sendHologram: `${host}/v1/sec_${servicio}id_calibrate`,
    sendEvent: `${host}/v1/sec_${servicio}celd_events`,
    sendVideoTokenPDF: `${host}/v1/sec_${servicio}public_video_token`,
    iosRecord: `${host}/v1/sec_${servicio}video_format_ios_processing`,
    evaluateImage: `${host}/v1/sec_${servicio}h_prediction/classify`,
    info_device: `${host}/v1/sec_${servicio}check_status_device`,
    ine: `${host}/v1/sec_${servicio}valid_ine`,
};

let CancelToken = axios.CancelToken;
let cancel = () => { };
let cancelPeticionIne = () => { };
let cancelPeticionRenapo = () => { };
let cancelPeticionVideograbacion = () => { };
let cancelarPeticionVideoIOS = () => { };

const version = '24.03';

export function actualizarEstado(objeto) {
    var data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.actualizarEstado,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function obtenerParametrosOtorgante(idOtorgante) {
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerParametrosOtorgantePK(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgantePK,
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),

        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("ine", imagen, side + "-" + (new Date().getTime()) + ".png");
    data.append("side", side);
    data.append("bucket", bucket);
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("SERVICE_PROCESS_IMAGE", true);
    //console.log("enviando:"+side + "-" + (new Date().getTime()) + ".png");
    return axios({
        method: 'post',
        url: endpoints.ocr,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

//uuid, mailto, nombre, nacimiento, curp, otorgante, vigencia, coincidencia, liveness,textoConsentimiento, apiKey 
export function enviarCorreoAceptacion(data) {
    return axios({
        method: 'post',
        url: endpoints.nom151, //INTEGRACIÓN FLOW CHECKER -- SUSTITUYÓ ENDPOINT CORREO
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'mail-host': correoH,
            'Accept': 'application/json'
        },
        data: data
    });
}

export function obtenerTokenVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.token,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        params: {
            uuid: uuid
        }
    });
}

export function actualizarScore(uuid, calle, colonia, cp, estado, municipio, numeroExt, gps, empresa) {
    var data = JSON.stringify({
        "update": "true",
        "uuid": uuid,
        "otorgante_uuid": localStorage.getItem("uuidOtorgante"),
        "otorgante_name": empresa,
        "calle": calle,
        "colonia": colonia,
        "cp": cp,
        "estado": estado,
        "municipio": municipio,
        "numero": numeroExt,
        "gps": gps,
        'transaccion': localStorage.getItem("uuidTrx"),
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: data
    });
}

export function obtenerTokenPDFVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.pdftoken,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            uuid: uuid
        }
    });
}

export function verificarTokenVideo(uuid, token, video, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("created", token.created);
    data.append("expire", token.expire);
    data.append("videoBase64", video, "videoaceptacion.webm");
    data.append("bucket", bucket);
    data.append("folder", "video_aceptacion/");
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("process", "local");
    return axios({
        method: 'post',
        url: endpoints.verify,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function verificarTokenPDFVideo(uuid, token, video, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("created", token.created);
    data.append("expire", token.expire);
    data.append("videoBase64", video, "videoaceptacion.webm");
    data.append("bucket", bucket);
    data.append("folder", "video_aceptacion/");
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("process", "local");
    return axios({
        method: 'post',
        url: endpoints.pdfverify,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function enviarImagen(uuid, file) {
    var data = new FormData();
    data.append("file", file, (new Date().getTime()) + "_frame.jpg");
    data.append("uuid", uuid);
    //console.log("Creando OTP");
    return axios({
        method: 'post',
        url: endpoints.otp,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function crearSMS(uuid, numero, area) {
    return axios({
        method: 'post',
        url: endpoints.generarSMS,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            "client": {
                "uuid": uuid,
                "platform": "android"
            },
            "phone": {
                "number": numero,
                "areaCode": area
            }
        }
    });
}

export function validarSMSServicio(uuid, numero, area, codigo) {
    return axios({
        method: 'patch',
        url: endpoints.validarSMS,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            "client": {
                "uuid": uuid,
                "platform": "android"
            },
            "phone": {
                "areaCode": area,
                "number": area + numero,
                "token": codigo
            }
        }
    });
}

export function obtenerScore(uuid, isDesktop) {
    let transaccion = localStorage.getItem("uuidTrx");
    return axios({
        method: 'get',
        url: endpoints.score + '/' + uuid + '/' + transaccion,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        params: {
            isDesktop: isDesktop
        }
    });
}

export function obtenerEstadoEnlace(enlace) {
    return axios({
        method: 'get',
        url: enlace,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        }
    });
}

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const getImageDimensions = file => new Promise((resolved, rejected) => {
    var i = new Image();
    i.onload = function () {
        resolved({
            w: i.width,
            h: i.height
        });
    };
    i.src = file;
});

export function obtenerMaps(cp, uuid) {
    return axios({
        method: 'post',
        url: endpoints.gps,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            address: {
                cp: cp,
                street: ""
            },
            uuid: uuid
        }
    })
}

export function obtenerCP(cp, uuid) {
    return axios({
        method: 'post',
        url: endpoints.getCP,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            address: {
                cp: cp,
                street: ""
            },
            uuid: uuid
        }
    })
}

/** RENAPO */

export function enviarRenapo(curp) {
    return axios({
        method: 'POST',
        url: endpoints.renapo,
        headers: {
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        },
        data: {
            "uuid": localStorage.getItem("uuidUser"),
            "turn": -1,
            "curp": curp
        }
    })
}

export function obtenerResultadoRenapo(jobID) {
    return axios({
        method: 'GET',
        url: endpoints.renapo,
        headers: {
            'apikey': localStorage.getItem("apikeyOtorgante"),
        },
        params: {
            job_id: jobID
        }
    })
}

/** INE */

export function enviarIne(cic, claveElector, numeroEmision) {
    let data = {};
    if (cic.length === 13) {
        data = {
            "ocr": cic,
            "claveElector": claveElector,
            "numeroEmisionCredencial": numeroEmision,
        }
    } else {
        data = {
            "cic": cic
        }
    }
    return axios({
        method: 'POST',
        url: endpoints.sendIne,
        headers: {
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'Otorgante': localStorage.getItem("uuidUser"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        },
        data: {
            "uuid": localStorage.getItem("uuidUser"),
            "turn": -1,
            "data": data,
            "fingerprint": {
                "indexLeft": null,
                "indexRight": ""
            }
        }
    })
}

/* DOCUMENTOS */
export function getDocuments() {
    return axios({
        method: 'post',
        url: endpoints.getDocuments,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        },
        data: {
            uuidUser: localStorage.getItem("uuidUser")
        }
    })
}

/* Listas negras */
export function consultarListasNegras(uuid, nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento) {
    /* console.log("Uuid:", uuid);
    console.log("Nombres:", nombres);
    console.log("Apellido Paterno:", apellidoPaterno);
    console.log("Apellido Materno:", apellidoMaterno);
    console.log("Fecha nacimiento:", fechaNacimiento); */

    return axios({
        method: 'post',
        url: endpoints.blackList,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            "uuid": uuid,
            "name": nombres,
            "lastNameOne": apellidoPaterno,
            "lastNameTwo": apellidoMaterno,
            "dateOfBirth": fechaNacimiento,
            //"isValid": true
        }
    });
}

/* Catálogos Punto Casa de Bolsa */

export const obtenerCatalogo = (parametro) => {

    return axios({
        method: 'get',
        url: endpoints.getCollections + parametro,
        headers: {

            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        }
    });
}

export function sendFile(file, folder, typeFile) {
    var data = new FormData();
    let ruta = localStorage.getItem("uuidOtorgante") + "/" + localStorage.getItem("uuidUser") + "/" + localStorage.getItem("uuidTrx") + folder;
    data.append("bucket", bucket);
    data.append("folder", ruta);
    data.append("uuid", localStorage.getItem("uuidUser"));
    data.append("file", file, (new Date().getTime()) + typeFile);

    return axios({
        method: 'post',
        url: endpoints.sendFile,
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

// Enviar video
export function enviarVideoRostro(facefile, Acreated, Aexpire, isIOS, service_call) {
    let so = isIOS ? 'ios' : 'android';
    var data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", so);

    data.append("service_call", service_call);

    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);

    return axios({
        method: 'POST',
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        url: endpoints.newVideoToken,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),

        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}

export const enviarHologramaIdentificacion = (hologramFile, isIOS) => {
    let uuidUser = localStorage.getItem("uuidUser");
    let so = isIOS ? 'ios' : 'android';
    let data = new FormData();
    data.append("originOS", so);

    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("ine", hologramFile, VIDEO_OUTPUT_FILE);

    return axios({
        'method': 'POST',
        url: endpoints.sendHologram,
        'headers': {
            apikey: localStorage.getItem("apikeyOtorgante"),
            transaccion: localStorage.getItem("uuidTrx"),
            cliente: uuidUser,
        },
        data: data
    });
}

export const enviarEvento = (objeto) => {
    let uuidUser = localStorage.getItem("uuidUser");
    let uuidTrx = localStorage.getItem("uuidTrx");
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    //let data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.sendEvent,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'transaccion': uuidTrx,
            'cliente': uuidUser,
            'apikey': apikeyOtorgante,
            'xc-token': token,
        },
        data: objeto
    });
}

export function finalUpdateScore(objeto) {
    let uuidTrx = localStorage.getItem("uuidTrx");
    let cliente = localStorage.getItem("uuidUser");
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    objeto.uuid = cliente;
    objeto.transaccion = uuidTrx;
    let data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': uuidTrx,
            'cliente': cliente,
            'apikey': apikeyOtorgante
        },
        crossdomain: true,
        data: data
    });
}

// Enviar video
export function enviarVideoRostroPDF(facefile, Acreated, Aexpire, isIOS, service_call) {
    let so = isIOS ? 'ios' : 'android';
    let data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", so);

    data.append("service_call", service_call);

    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);

    return axios({
        method: 'POST',
        url: endpoints.sendVideoTokenPDF,
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Consumer-Custom-ID': localStorage.getItem("idOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}

export function cancelarRequest() {
    cancel();
}

export function enviarVideo(facefile, isIOS) {
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    var formData = new FormData();
    formData.append('file', facefile, (VIDEO_OUTPUT_FILE));
    formData.append("bucket", bucket);
    let carpeta = "/videotmprecord";
    formData.append('folder', localStorage.getItem("uuidOtorgante") + "/" + localStorage.getItem("uuidUser") + "/" + localStorage.getItem("uuidTrx") + carpeta);

    return axios({
        method: 'POST',
        url: endpoints.sendFile,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionVideograbacion = cancelar;
        }),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version,
        },
        data: formData
    });
}

export const cancelarRequestVideograbacion = () => {
    cancelPeticionVideograbacion();
}

export function procesarVideoIOS() {
    return axios({
        method: 'POST',
        url: endpoints.iosRecord,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelarPeticionVideoIOS = cancelar;
        }),
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version,
        },
        data: {
            "uuid_user": localStorage.getItem("uuidUser")
        }
    });
}

export const cancelarRequestVideoIOS = () => {
    cancelarPeticionVideoIOS();
}

//Evaluar imagen
export const enviarImagenEvaluar = (image, model, name, side) => {
    let uuidUser = localStorage.getItem("uuidUser");
    let data = new FormData();
    data.append("model", model);
    data.append("photo", image, name);
    data.append("useScore", true);
    data.append("event", side);
    return axios({
        method: 'POST',
        url: endpoints.evaluateImage,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': uuidUser,
            apikey: localStorage.getItem("apikeyOtorgante")

        },
        data: data
    });
}

//LIVENESS (¿opcionales?)
export const cancelarRequestIne = () => {
    cancelPeticionIne();
}

export const cancelarRequestRenapo = () => {
    cancelPeticionRenapo();
}

export const  nuevoVideotoken = (data) => {
    return axios({
        method: 'POST',
        url: endpoints.newVideoToken,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        timeout: 50000,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),

        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}

export const obtenerInfoDispositivo = () => {
    return axios({
        method: 'POST',
        url: endpoints.info_device,
        headers: {
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'uuid': localStorage.getItem("uuidUser"),
        }
    })
}

// ENVIAR INE DE CELD

export const enviarIneData = (data) => {
    return axios({
        method: 'POST',
        url: endpoints.ine,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionIne = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
            //'Otorgante': getHeaders().cliente,
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
        },
        data: data
    })
}

export const obtenerResultadoINE = (jobID) => {
    return axios({
        method: 'GET',
        url: endpoints.ine,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionIne = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
        },
        params: {
            job_id: jobID
        }
    })
}

const getHeaders = () => {
    let objeto = {};
    let uuidUser = localStorage.getItem("uuidUser") || '';
    let uuidTrx = localStorage.getItem("uuidTrx") || '';
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante") || '';

    objeto.cliente = uuidUser;
    objeto.transaccion = uuidTrx;
    objeto.apikey = apikeyOtorgante;

    return objeto;
}

/*--- Actualizar score ---*/
export const updateScore = (data, headers) => {
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: headers,
        crossdomain: true,
        data: data
    });
}

/*------*/