import React, { useState, useEffect } from 'react'
/* Funciones */
import { isMobile } from 'react-device-detect'
import { configStyle } from '../../../services/configStyle'
/* Componentes */
import Header from '../../../components/headerNA'
import Ayuda from '../../ayuda'


const Index = ({ uuidTransaccion, dataOtorgante, estado, apikeyOtorgante }) => {

    /* Hooks */
    const [showHelp, setShowHelp] = useState(false)

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    const generarTitulo = () => {
        switch (estado) {
            case 'cancelado':
                return 'Proceso cancelado'
            case 'fallido':
                return '¡Lo sentimos!'
            case 'exitoso':
                return 'Proceso terminado'
            default:
                return 'Sin resultado'
        }
    }

    const generarTexto = () => {
        switch (estado) {
            case 'cancelado':
                return 'Has cancelado el proceso, puedes intentarlo de nuevo cuando lo desees.'
            case 'fallido':
                return 'Has superado el número máximo de intentos para este proceso, inténtalo nuevamente más tarde.'
            case 'exitoso':
                return 'Has completado tu proceso de validación exitosamente.'
            default:
                return 'Sin texto'
        }
    }

    return (
        <>
            <div className='main_gradient container-flex screen_in_front'>
                <div className="flex_body">
                    <Header show={() => setShowHelp(true)} dataOtorgante={dataOtorgante} apikey={apikeyOtorgante} />
                    <div className='container-center-flex'>
                        <div className='main_text_container div-text-left'>
                            <h1 className='title_punto_trader'>{generarTitulo()}</h1>
                            <br />
                            <p className='calibri_punto_trader'>{generarTexto()}</p>
                            <br />
                            <br />
                            <div className='div-text-center'>
                                <p className='calibri_punto_trader'>
                                    Folio: <br /> <b>{uuidTransaccion.split('-')[0]}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-bottom-flex'>
                    <div className={isMobile ? 'action_buttons' : 'action_buttons_desk'}>
                    </div>
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>
    );
}

export default Index;
