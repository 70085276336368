/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
/* Librerías */
import { isIOS, isMobile, withOrientationChange } from "react-device-detect";
import { useHistory } from "react-router-dom";
/* Componentes*/
import Header from "../headerNA";
import Loader from "../loader";
import Ayuda from "../../pages/ayuda";
import CancelarFlujo from "../../pages/flujo/cancelar_flujo";
import IdTransaccion from "../id_transaccion";
/* Funciones */
import { getDocuments, cancelarRequest } from "../../services/api";
import {
  status,
  statusError,
  statusData,
  statusReintento,
  evento,
  flowIncompleted,
  sendEventClick,
  generateZip,
  cancelarINE,
  validarRedireccionamiento,
  enviarImagenVideotoken,
  mapearMensajeError,
  mapearRespuesta,
  mapearError,
  mapearRespuestaPruebaVida,
} from "../../services/data";
import { configStyle, obtenerValorConfig } from "../../services/configStyle";
/* Custom hooks */
import { useScrollToTop } from "../../utilities/hooks/custom";

let videoTag, responseError;

const Index = (props) => {
  useScrollToTop();

  /* Props */
  const {
    apikey,
    // uuidCliente,
    uuidTransaccion,
    dataOtorgante,
    // isLandscape,
    service_call,
    previousStep,
    nextStep,
    cancelStep,
    type,
    intents,
    disabledButton,
  } = props;

  /* Hooks */
  const history = useHistory();
  const [estado, setEstado] = useState("");
  const [pathname, setPathname] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [intentos, setIntentos] = useState(0);
  const [intentosVideotoken, setIntentosVideotoken] = useState(0);
  const [errorPermiso, setErrorPermiso] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [showCancelScreen, setShowCancelScreen] = useState(false);
  const [error, setError] = useState(false);
  const [sendingPhoto, setSendingPhoto] = useState(true);
  const [isStart, setIsStart] = useState(true);
  const [showCancel, setShowCancel] = useState(false);
  const [getPhoto, setGetPhoto] = useState(false);
  // const [stable, setStable] = useState(false)
  const [loading, setLoading] = useState(false);
  const [errorCamera, setErrorCamera] = useState(false);
  const [disableFinishButton, setDisableFinishButton] = useState(true);
  const [cancelProcess, setCancelProcess] = useState(false);
  /* Referencias */
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const tituloRef = useRef(null);

  useEffect(() => {
    if (type === "flujo") {
      setPathname(window.location.pathname);
      status("captura_video_token_page", "Captura Video Token");
    }
    videoTag = videoRef.current;
    return () => {
      const mediaStream = videoTag.srcObject;
      if (mediaStream) mediaStream.getTracks().forEach((track) => track.stop());
      responseError = null;
    };
  }, []);

  useEffect(() => {
    configStyle(dataOtorgante);
    if (dataOtorgante.length > 0) {
      setIntentos(
        Number(
          obtenerValorConfig(dataOtorgante, "INTENTOS_VIDEO_TOKEN", intents)
        )
      );
      const bloqueo = localStorage.getItem("bloqueo");
      if (bloqueo && bloqueo === "true") {
        setDisableFinishButton(false);
        setIntentosVideotoken(
          Number(
            obtenerValorConfig(dataOtorgante, "INTENTOS_VIDEO_TOKEN", intents)
          )
        );
      } else {
        localStorage.setItem("bloqueo", "false");
      }
    }
    return () => {};
  }, [dataOtorgante]);

  useEffect(() => {
    if (errorPermiso === false && getPhoto) {
      capturarImagen(false);
    } else if (errorPermiso === true && getPhoto) {
      setIsStart(false);
    }
    return () => {};
  }, [errorPermiso, getPhoto]);

  useEffect(() => {
    if (intentosVideotoken > 0 && errorStatus) {
      if (type === "flujo")
        enviarReintento("Intentos Video Token", intentosVideotoken);
      if (intentosVideotoken === intentos) {
        const mediaStream = videoTag.srcObject;
        if (mediaStream)
          mediaStream.getTracks().forEach((track) => track.stop());
        if (type === "pdf") setDisableFinishButton(false);
        if (type === "flujo") obtenerDocumentos();
      } else {
        if (type === "flujo")
          enviarReintento("Captura Video Token", errorStatus);
      }
    }
  }, [intentosVideotoken, errorStatus]);

  const permisoCamara = async () => {
    try {
      const localStream = await window.navigator.mediaDevices.getUserMedia({
        // audio: true,
        video: {
            facingMode: 'user'
        },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = localStream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };
        videoRef.current.addEventListener(
          "canplay",
          () => {
            canvasRef.current.setAttribute(
              "width",
              videoRef.current.videoWidth * 1.5
            );
            canvasRef.current.setAttribute(
              "height",
              videoRef.current.videoHeight * 1.5
            );
          },
          false
        );
      }
      if (localStream.getVideoTracks().length > 0) {
        setErrorCamera(false);
      } else {
        setErrorCamera(true);
        evento(
          "Liveness Pasivo Transitivo -" + type,
          "Step",
          { status: "ERROR GET VIDEO TRACKS" },
          true
        );
      }

      setErrorPermiso(false);
    } catch (error) {
      handleErrorGetMediaUser(error);
    }
  };

  const handleErrorGetMediaUser = (error) => {
    if (
      error.name === "NotFoundError" ||
      error.name === "DevicesNotFoundError"
    ) {
      //required track is missing
      evento(
        "Liveness Pasivo Transitivo -" + type,
        "User Media",
        { error: error.name, status: "NO SE ENCONTRO DISPOSITIVO Y/O TRACK" },
        true
      );
    } else if (
      error.name === "NotReadableError" ||
      error.name === "TrackStartError"
    ) {
      //webcam or mic are already in use
      evento(
        "Liveness Pasivo Transitivo -" + type,
        "User Media",
        {
          error: error.name,
          status: "LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO",
        },
        true
      );
    } else if (
      error.name === "OverconstrainedError" ||
      error.name === "ConstraintNotSatisfiedError"
    ) {
      //constraints can not be satisfied by avb. devices
      evento(
        "Liveness Pasivo Transitivo -" + type,
        "User Media",
        {
          error: error.name,
          status: "EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS",
        },
        true
      );
    } else if (
      error.name === "NotAllowedError" ||
      error.name === "PermissionDeniedError"
    ) {
      //permission denied in browser
      evento(
        "Liveness Pasivo Transitivo -" + type,
        "User Media",
        { error: error.name, status: "PERMISOS DENEGADOS" },
        true
      );
      setErrorPermiso(true);
    } else if (error.name === "TypeError" || error.name === "TypeError") {
      //empty constraints object
      evento(
        "Liveness Pasivo Transitivo -" + type,
        "User Media",
        { error: error.name, status: "CONSTRAINTS VACÍOS" },
        true
      );
    } else {
      //other errors
      evento(
        "Liveness Pasivo Transitivo -" + type,
        "User Media",
        { error: error.toString(), status: "OTRO TIPO DE ERROR" },
        true
      );
    }

    setErrorCamera(true);
    setGetPhoto(false);
  };

  const capturarImagen = (reIntento) => {
    // let description = reIntento ? 'REINTENTAR' : 'CONTINUAR'
    sendEventClick(`Liveness Pasivo Transitivo - ${type}`, "CONTINUAR", {});
    const bloqueo = localStorage.getItem("bloqueo");
    // if (!reIntento) {
    if (tituloRef.current) {
      tituloRef.current.className = "animate__animated animate__slideInDown";
      tituloRef.current.innerHTML =
        '<p class="font_calibri_punto_trader">Ubica tu rostro <b class="calibri_bold_punto_trader">en la guía</b>, mantente estable y <b class="calibri_bold_punto_trader">captura</b> tu rostro</p>';
      setTimeout(() => {
        //setStable(true)
        tituloRef.current.classList.remove("animate__slideInDown");
      }, 1000);
    }
    /* } else {
                tituloRef.current.classList.remove('animate__slideInDown')
                setStable(true)
            } */
    // setError(false)
    setErrorMessage("");
    if (bloqueo === "false" || bloqueo === null) {
      setIsStart(false);
      setSendingPhoto(false);
    } else {
      setIsStart(false);
    }
  };

  const obtenerImagen = () => {
    setSendingPhoto(true);
    setTimeout(() => {
      //console.log('CANVAS')
      let contextCanvas = canvasRef.current.getContext("2d");
      contextCanvas.drawImage(
        videoRef.current,
        0,
        0,
        videoRef.current.videoWidth * 1.5,
        videoRef.current.videoHeight * 1.5
      );
      canvasRef.current.toBlob(
        (blob) => {
          enviarImagen(blob);
          // descargarImagen(blob)
        },
        "image/png",
        0.9
      );
    }, 1350);
  };

  const enviarImagen = async (blob) => {
    if (blob) {
      tituloRef.current.className = "animate__animated animate__slideInDown";
      tituloRef.current.innerHTML =
        '<p class="animate__animated animate__slideInDown font_calibri_punto_trader">Procesando, <b class="calibri_bold_punto_trader">gracias</b> por tu paciencia.</b></p>';
      // setStable(false)
      setLoading(true);
      videoRef.current.classList.add("blur_video");
      setTimeout(() => {
        tituloRef.current.classList.remove("animate__slideInDown");
      }, 1000);
      try {
        const response = await enviarImagenVideotoken(
          blob,
          isIOS,
          service_call
        );
        const {
          status,
          data,
          data: { payload },
        } = response;
        if (status === 200) {
          evento(
            "Liveness Pasivo Transitivo -" + type,
            "Succes",
            mapearRespuesta(status, data),
            true
          );
          if (type === "flujo") {
            sendData(data.payload.match);
            mapearRespuestaPruebaVida(payload);
          }
          setLocalStorageResultFace(data.payload);
          if (type === "pdf")
            localStorage.setItem("enlace", data.payload.documentLink);
          setTimeout(() => {
            history.replace(nextStep);
          }, 300);
        }
      } catch (error) {
        console.log(
          `🚀 ------------------------------------------------------🚀`
        );
        console.error(`🚀 ~ file: index.jsx:239 ~ enviarImagen ~ error:`, {
          error,
        });
        console.log(
          `🚀 ------------------------------------------------------🚀`
        );
        const errorMapeado = mapearError(error);
        evento(
          "Liveness Pasivo Transitivo -" + type,
          errorMapeado.tipoError,
          errorMapeado.objetoError,
          false
        );
        if (errorMapeado.objetoError.tipo === "RESPONSE") {
          responseError = errorMapeado;
          let mensaje = "";
          if (error.response.data) {
            mensaje = error.response.data.message_client;
          }
          const errorMessage = mapearMensajeError(mensaje);
          if (error.response.status === 500 || error.response.status === 400) {
            if (
              mensaje === "No se encontro rostro" ||
              mensaje === "Se detecto cubrebocas" ||
              mensaje === "Condiciones de luz no adecuadas" ||
              mensaje === "Imagen borrosa" ||
              mensaje === "Se detectaron multiples caras" ||
              mensaje === "Falta de luz" ||
              mensaje === "No se pudo realizar la comparacion de rostro" ||
              mensaje === "No logramos detectar un rostro"
            ) {
              setErrorMessage(errorMessage);
              setErrorStatus(errorMessage);
              setError(true);
              setLoading(false);
            } else {
              if (
                mensaje ===
                "Lo sentimos no hay coincidencia entre tu selfie registrada y la persona actual"
              ) {
                statusData("captura_video_token", { coincidencia: false });
              }
              setErrorMessage(errorMessage);
              setErrorStatus(errorMessage);
              setError(true);
              setLoading(false);
              setIntentosVideotoken(intentosVideotoken + 1);
            }
          } else if (error.response.status === 404) {
            setErrorMessage(errorMessage);
            setErrorStatus(errorMessage);
            setError(true);
            setLoading(false);
            setIntentosVideotoken(intentosVideotoken + 1);
          } else {
            setErrorMessage(
              "Revisa tu conexión a internet e intenta nuevamente"
            );
            setErrorStatus(
              "Revisa tu conexión a internet e intenta nuevamente"
            );
            setError(true);
            setLoading(false);
          }
        } else {
          if (error.toString().includes("Network")) {
            setErrorMessage(
              "Revisa tu conexión a internet e intenta nuevamente"
            );
            setErrorStatus(
              "Revisa tu conexión a internet e intenta nuevamente"
            );
            setError(true);
            setLoading(false);
          } else if (error.toString().includes("cancel")) {
            setErrorMessage("Se canceló el flujo");
            setErrorStatus("Se canceló el flujo");
            setLoading(false);
          } else {
            setErrorMessage(
              "El servicio no se encuentra disponible, lo solucionaremos en breve"
            );
            setErrorStatus("Error en la captura");
            setError(true);
            setLoading(false);
          }
        }
        if (videoRef.current) {
          videoRef.current.classList.remove("blur_video");
        }
      }
    }
  };

  const reintentarCapturarImagen = () => {
    sendEventClick(`Liveness Pasivo Transitivo - ${type}`, "REINTENTAR", {});
    if (tituloRef.current) {
      tituloRef.current.className = "animate__animated animate__slideInDown";
      tituloRef.current.innerHTML =
        '<p className="font_calibri_punto_trader">Ubica tu rostro <b class="calibri_bold_punto_trader">en la guía</b>, mantente estable y <b class="calibri_bold_punto_trader">captura</b> tu rostro</p>';
      setTimeout(() => {
        tituloRef.current.classList.remove("animate__slideInDown");
      }, 1000);
    }
    setError(false);
    setSendingPhoto(false);
  };

  const sendData = (match) => {
    //Cuando es exitoso, revisar la bandera  de speech
    statusData("captura_video_token", { coincidencia: match });
  };

  const setLocalStorageResultFace = (result) => {
    let face = {};
    if (result.match) {
      face = {
        match: result.match,
        liveness: result.liveness,
      };
    } else {
      face = {
        match: false,
        gender: "Indeterminado",
        age: 0,
        expressions: {
          happy: 0.0,
        },
        liveness: "No superada",
      };
    }
    localStorage.setItem("face", JSON.stringify(face));
  };

  const enviarReintento = (step, description) => {
    statusReintento(step, description, "RETRY");
    // statusData("captura_video_token", { coincidencia: false })
  };

  const obtenerDocumentos = async () => {
    try {
      const response = await getDocuments();
      const {
        status,
        data,
        data: { payload },
      } = response;
      evento(
        "Obtener documentos - " + type,
        "Succes",
        mapearRespuesta(status, data),
        true
      );
      if (status === 200) {
        if (payload.links.length > 0) {
          let links = JSON.stringify(payload.links);
          localStorage.setItem("links", links);
        }
      }
      setTimeout(() => {
        finalizarFlujo();
      }, 300);
    } catch (error) {
      const errorMapeado = mapearError(error);
      evento(
        "Obtener documentos - " + type,
        errorMapeado.tipoError,
        errorMapeado.objetoError,
        false
      );
      setTimeout(() => {
        finalizarFlujo();
      }, 300);
    }
  };

  const finalizarFlujo = async () => {
    setLoading(true);
    await generateZip("SEMAFORO", "red", isIOS);
    localStorage.setItem("bloqueo", "true");
    let newDate = new Date().getTime();
    let acceptDta = new Date();
    acceptDta.setTime(newDate + 60 * 60 * 1000);
    localStorage.setItem("time", acceptDta.getTime());
    let res = convertdataJSON();
    const event_t = JSON.stringify(res);
    var json_final = {};
    let status = "FAIL";
    json_final = {
      status: status,
      step: "finished",
      description: event_t,
      response: responseError,
      finished: true,
    };
    const event = JSON.stringify(json_final);
    await flowIncompleted({ step: "captura_video_token", event: event }, "red");
    localStorage.setItem("bloqueo", "true");
    setLoading(false);
    setDisableFinishButton(false);
  };

  const convertdataJSON = () => {
    const jsonObj = {};
    jsonObj.uuidUser = localStorage.getItem("uuidUser") || "";
    jsonObj.uuidTrx = localStorage.getItem("uuidTrx") || "";
    jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
    jsonObj.links = JSON.parse(localStorage.getItem("links")) || {};
    return jsonObj;
  };

  const cancelarFlujo = async () => {
    if (type === "flujo") {
      cancelarINE();
      cancelarRequest();
      sendEventClick(`Liveness Pasivo Transitivo - ${type}`, "SÍ", {
        status: "CANCELADO",
      });
      localStorage.setItem("flag", "cancelado");
      setLoading(true);
      await generateZip("TRUNCOS", "trunco", isIOS);
      await statusError("captura_video_token", "Cancelado", "cancelado");
      localStorage.setItem("completado", "true");
      if (!window.opener) {
        if (validarRedireccionamiento(dataOtorgante)) {
          setTimeout(() => {
            setLoading(false);
            history.replace(previousStep);
          }, 1000);
        } else {
          setLoading(false);
          setShowCancelScreen(true);
          setCancelProcess(false);
          setEstado("cancelado");
        }
      }
    } else {
      setTimeout(() => {
        history.replace(previousStep);
      }, 300);
    }
  };

  // const descargarImagen = (blob) => {
  //     let url = URL.createObjectURL(blob)
  //     let a = document.createElement('a')
  //     document.body.appendChild(a)
  //     a.style = 'display: none'
  //     a.href = url
  //     a.download = 'photo.png'
  //     a.click()
  // }

  const redirect = () => {
    const mediaStream = videoTag.srcObject;
    if (mediaStream) mediaStream.getTracks().forEach((track) => track.stop());
    if (type === "flujo") {
      if (validarRedireccionamiento(dataOtorgante)) {
        history.replace(previousStep);
      } else {
        setShowCancelScreen(true);
        setEstado("fallido");
      }
    } else {
      history.replace(previousStep);
    }
  };

  const cancelarFlujoIntento = () => {
    sendEventClick(`Liveness Pasivo Transitivo - ${type}`, "CANCELAR");
    history.replace(`/cancelar_flujo${cancelStep}`);
  };

  return (
    <>
      {error &&
        intentosVideotoken <
          Number(
            obtenerValorConfig(dataOtorgante, "INTENTOS_VIDEO_TOKEN", intents)
          ) && (
          <div
            className="modal fade show"
            style={{
              display: "block",
              color: "#212529",
              textAlign: "left",
              fontFamily:
                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
              fontWeight: "400",
            }}
            role="dialog"
          >
            <div
              className={[
                "modal-dialog",
                isMobile ? "modal-center" : "modal-center-desktop",
              ].join(" ")}
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title ubuntu_bold_punto_trader">
                    Lo sentimos
                  </h5>
                </div>
                <div className="modal-body">
                  <p className="font_calibri_punto_trader text_size_punto_trader">
                    {errorMessage}
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader"
                    data-dismiss="modal"
                    onClick={cancelarFlujoIntento}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={reintentarCapturarImagen}
                    className="btn btn-raised btn-secondary modal_btn_w button_punto_trader"
                  >
                    Reintentar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      <div
        className="module_container overflow_hddn"
        style={{ position: "fixed" }}
      >
        <div className="camera_capture_frame speech_mod">
          <video
            src=""
            id="video_wrt_environment"
            playsInline
            muted
            ref={videoRef}
          ></video>
          <canvas id="canvas_wrt_environment" ref={canvasRef} hidden></canvas>
        </div>
        <div className="module_gradident_overlay"></div>
        <img
          className="id_mask animate__animated animate__fadeIn animate__delay-2s"
          src={"/images/componente_selfie/face_id_on_grdnt.svg"}
          alt=""
        />
        <div className="recorder_container">
          <div
            className="module_buttons animate__animated"
            style={{ position: "fixed" }}
          >
            <button
              onClick={obtenerImagen}
              className={`btn btn-raised btn-primary forcewidth100 ${
                sendingPhoto
                  ? "button_gray_punto_trader"
                  : "button_punto_trader"
              }  ${isMobile ? "" : "desk_id_capture_btn"}`}
              disabled={sendingPhoto ? true : false}
            >
              {/* {(sendingPhoto) ? 'PROCESANDO...' : 'CAPTURAR ROSTRO'}</button> */}
              Capturar rostro
            </button>
          </div>
        </div>
      </div>
      {isStart && !showHelp && localStorage.getItem("bloqueo") !== "true" && (
        <>
          <div
            className="hollo_gif_bx show_id_hold container-flex"
            style={{
              backgroundImage:
                "linear-gradient(180deg, var(--color-secondary) 0%, var(--color-primary) 100%)",
              overflowY: "scroll",
            }}
          >
            {showCancel && (
              <div
                className="modal fade show"
                style={{
                  display: "block",
                  color: "#212529",
                  textAlign: "left",
                }}
                role="dialog"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title ubuntu_bold_punto_trader">
                        Cancelar proceso
                      </h5>
                      <button
                        onClick={() => setShowCancel(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p className="calibri_punto_trader">
                        En este momento se cancelará el proceso, ningún dato
                        será guardado y perderás el avance, esta acción no podrá
                        deshacerse <br /> ¿Deseas cancelar?
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={(e) => {
                          setShowCancel(false);
                          sendEventClick(
                            `Liveness Pasivo Transitivo - ${type}`,
                            "NO",
                            {}
                          );
                        }}
                        className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader"
                        data-dismiss="modal"
                      >
                        No
                      </button>
                      <button
                        type="button"
                        onClick={cancelarFlujo}
                        className="btn btn-raised btn-secondary modal_btn_w button_punto_trader"
                      >
                        Sí
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Header show={() => setShowHelp(true)} />
            <div className="main_text_container text-left custom_padding">
              <h1 className="title_punto_trader text-left">
                Confirmación de identidad
              </h1>
              <p className="calibri_punto_trader">
                Mantén tu{" "}
                <b className="calibri_bold_punto_trader">
                  rostro visible, sin más personas
                </b>{" "}
                en la toma, <b className="calibri_bold_punto_trader">no</b>{" "}
                utilices <b className="calibri_bold_punto_trader">gorra</b> y{" "}
                <b className="calibri_bold_punto_trader">evita</b> colocarte{" "}
                <b className="calibri_bold_punto_trader">frente</b> a{" "}
                <b className="calibri_bold_punto_trader">fuentes</b> de luz.
                <br />
              </p>
              <br></br>
            </div>
            <div className="container_selfie_instructions container-flex">
              <div className="row">
                <div
                  className={`${
                    isMobile
                      ? "col-12  div-text-center"
                      : "col-6 div-text-right"
                  }`}
                >
                  <img
                    className={`${
                      isMobile
                        ? "img_selfie_instructions"
                        : "img_selfie_instructions_desktop"
                    }`}
                    src={`images/componente_selfie/${
                      apikey === "gAxydegFi6Wfd7X1QRcvtA9ztXokyQXi"
                        ? "selfie_error_dy1@2x.png"
                        : "selfie_error_g1@2x.png"
                    }`}
                    alt=""
                  />
                </div>
                <div
                  className={`${
                    isMobile ? "col-12  div-text-center" : "col-6 div-text-left"
                  }`}
                >
                  <img
                    className={`${
                      isMobile
                        ? "img_selfie_instructions"
                        : "img_selfie_instructions_desktop"
                    }`}
                    src={`images/componente_selfie/${
                      apikey === "gAxydegFi6Wfd7X1QRcvtA9ztXokyQXi"
                        ? "selfie_error_dy2@2x.png"
                        : "selfie_error_g2@2x.png"
                    }`}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="action_buttons animate__animated animate__fadeIn animate__delay-1s custom_padding">
              <button
                type="button"
                onClick={() => {
                  setGetPhoto(true);
                  permisoCamara();
                }}
                className="btn btn-raised btn-primary forcewidth100 button_punto_trader"
                disabled={(getPhoto ? true : false) || disabledButton}
              >
                Continuar
              </button>
              <button
                onClick={(e) => {
                  setShowCancel(true);
                  sendEventClick(
                    `Liveness Pasivo Transitivo - ${type}`,
                    "CANCELAR",
                    {}
                  );
                }}
                className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader"
                disabled={disabledButton}
              >
                Cancelar
              </button>
            </div>
            {type === "flujo" && <IdTransaccion />}
          </div>
        </>
      )}

      <div
        id="cuadro"
        className="animate__animated animate__slideInDown delay-3s"
      >
        <div id="head_shop" className="txt_videotoken" ref={tituloRef}></div>
      </div>
      {/* {stable &&
                <div id="div-stable" className="animate__animated animate__fadeIn">
                    <div className="sprite_stay_b animado " style={{ top: "25%" }}></div>
                </div>} */}
      {intentosVideotoken >=
        Number(
          obtenerValorConfig(dataOtorgante, "INTENTOS_VIDEO_TOKEN", intents)
        ) && (
        <div className="err_finale animate__animated animate__slideInUp">
          <div className="center_checks">
            <h5 className="ubuntu_bold_punto_trader">Proceso no exitoso</h5>
            <p className="calibri_punto_trader text_size_punto_trader">
              Has superado el número{" "}
              <b className="calibri_bold_punto_trader">máximo</b> de intentos
              para este proceso inténtalo nuevamente{" "}
              <b className="calibri_bold_punto_trader">más tarde</b>.
            </p>
            <p className="calibri_punto_trader text_size_punto_trader">
              Si deseas mayor información no dudes en contactarnos con el{" "}
              <b className="calibri_bold_punto_trader">ID</b> de proceso{" "}
              <b className="calibri_bold_punto_trader">
                {uuidTransaccion.split("-")[0]}
              </b>{" "}
            </p>
            <br />
            {pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X" && (
              <div className="container">
                <div className="row">
                  <div className="col">
                    <p className="calibri_punto_trader text_size_punto_trader">
                      <b className="calibri_bold_punto_trader">Llámanos al:</b>
                    </p>
                    <p>
                      <a
                        className="help_phone"
                        href={
                          "tel:" +
                          obtenerValorConfig(
                            dataOtorgante,
                            "TEL_CONTACTO_PRINCIPAL"
                          )
                        }
                      >
                        {obtenerValorConfig(
                          dataOtorgante,
                          "TEL_CONTACTO_PRINCIPAL"
                        )}
                      </a>
                    </p>
                  </div>
                  <div className="col">
                    <p className="calibri_punto_trader text_size_punto_trader">
                      <b> className="calibri_bold_punto_trader"Escríbenos a:</b>
                    </p>
                    <p>
                      <a
                        className="help_mail"
                        href={
                          "mailto:" +
                          obtenerValorConfig(
                            dataOtorgante,
                            "EMAIL_CONTACTO_PRINCIPAL"
                          )
                        }
                      >
                        {obtenerValorConfig(
                          dataOtorgante,
                          "EMAIL_CONTACTO_PRINCIPAL"
                        )}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {!window.opener && (
              <div className="action_buttons noscroll_screen">
                <button
                  className="btn btn-raised btn-primary forcewidth100 button_punto_trader"
                  disabled={disableFinishButton}
                  onClick={redirect}
                >
                  Entendido
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {loading && <Loader />}
      {errorCamera && (
        <div className="overlay_box">
          <div className="alert_box">
            {!errorPermiso ? (
              <p className="animate__animated animate__slideInDown calibri_punto_trader text_size_punto_trader">
                Hemos detectado que la{" "}
                <b className="calibri_bold_punto_trader">
                  cámara de tu dispositivo
                </b>{" "}
                está en uso, por favor asegúrate de{" "}
                <b className="calibri_bold_punto_trader">cerrar las pestañas</b>{" "}
                abiertas e inténtalo nuevamente.
              </p>
            ) : isIOS ? (
              <p className="animate__animated animate__slideInDown calibri_punto_trader text_size_punto_trader">
                Por favor{" "}
                <b className="calibri_bold_punto_trader">acepta el permiso</b>{" "}
                de uso de la camará y da click en volver a intentar para
                continuar.
              </p>
            ) : (
              <p className="animate__animated animate__slideInDown calibri_punto_trader text_size_punto_trader">
                Revisa las instrucciones para restablecer los{" "}
                <b className="calibri_bold_punto_trader">
                  permisos de tu navegador
                </b>{" "}
                y da click en volver a intentar para continuar.
                <img
                  src="images/permisos.gif"
                  className="imgPermisos"
                  alt="Reestablecer permisos"
                />
              </p>
            )}
            <button
              type="button"
              onClick={(e) => {
                if (errorPermiso) {
                  window.location.reload();
                } else {
                  setErrorCamera(false);
                  permisoCamara();
                }
              }}
              className="btn btn-raised btn-primary forcewidth100 button_punto_trader alert_btn  animate__animated animate__fadeIn animate__delay-2s"
            >
              VOLVER A INTENTAR
            </button>
          </div>
        </div>
      )}
      {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
      {cancelProcess && (
        <div
          className="modal fade show"
          style={{ display: "block", color: "#212529", textAlign: "left" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title ubuntu_bold_punto_trader">
                  Cancelar proceso
                </h5>
                <button
                  onClick={() => setCancelProcess(false)}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="calibri_punto_trader">
                  En este momento se cancelará el proceso, ningún dato será
                  guardado y perderás el avance, esta acción no podrá deshacerse{" "}
                  <br /> ¿Deseas cancelar?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  onClick={(e) => {
                    setCancelProcess(false);
                    sendEventClick(
                      `Liveness Pasivo Transitivo - ${type}`,
                      "NO",
                      {}
                    );
                  }}
                  className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={cancelarFlujo}
                  className="btn btn-raised btn-secondary modal_btn_w button_punto_trader"
                >
                  Sí
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCancelScreen && (
        <CancelarFlujo
          uuidTransaccion={uuidTransaccion}
          dataOtorgante={dataOtorgante}
          estado={estado}
        />
      )}
    </>
  );
};

export default withOrientationChange(Index);
