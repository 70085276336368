/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
/* Librerías */
import { useHistory } from "react-router-dom";
import { isIOS, isMobileSafari, isMobile, isIPad13 } from 'react-device-detect';
import RecordRTC, { bytesToSize } from 'recordrtc';
import { Howl } from 'howler';
/* Componentes */
import Loader from "../components/loader";
import ErrorPermissions from './error_permisos';
import CancelarFlujo from '../pages/flujo/cancelar_flujo';
/* Funciones */
import ConnectionClient from '../services/ConnectionClientWebRTC';
import { evento, status, generateZip, sendData, getNextStepIdentification, cancelarINE, mapErrorGetUserMedia, sendEventClick, validarParametroOtorgante } from '../services/data';
import { configStyle } from '../services/configStyle';
/* Servicios */
import { cancelarRequestVideoIOS, cancelarRequestVideograbacion, enviarVideo, procesarVideoIOS } from '../services/api';

const bucket = process.env.REACT_APP_BUCKET;

let interval = null;
let stream = null;
let startCamera = true;

const ComponenteVideoGrabacionIdentificacion = () => {
    /* Hooks */
    const history = useHistory();
    const [apikey, setApikey] = useState('');
    const [uuidTransaccion, setUuidTransaccion] = useState('');
    const [localStream, setLocalStream] = useState(null);
    const [recorder, setRecorder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorVideo, setErrorVideo] = useState(false);
    const [estable, setEstable] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [suportMediaRecorder, setSuportMediaRecorder] = useState(true);
    const [idsmallimg, setIdsmallimg] = useState(false);
    const [idbigimg, setIdbigimg] = useState(false);
    const [errorPermissions, setErrorPermissions] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showCancelScreen, setShowCancelScreen] = useState(false);
    const [error, setError] = useState(false);
    const [errorAudios, setErrorAudios] = useState(false);
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [hasStarted, setHasStarted] = useState(false)
    /* Referencias */
    const refCanvas = useRef(null);
    const refVideo = useRef(null);
    // const titleRecord = useRef(null)

    const soundCuadro = new Howl({
        src: process.env.PUBLIC_URL + "/audios/Cuadro.mp3",
        volume: 0.9,
        onload: function () {
            //console.log('Cargue cuadro!');
        },
    });
    const soundRostro = new Howl({
        src: process.env.PUBLIC_URL + "/audios/Rostro.mp3",
        volume: 0.9,
        onload: function () {
        },
    });
    const soundUbicareverso = new Howl({
        src: process.env.PUBLIC_URL + "/audios/Ubicareverso.mp3",
        volume: 0.9,
        onload: function () {
        },
    });
    const soundUbicafrente = new Howl({
        src: process.env.PUBLIC_URL + "/audios/Ubicafrente.mp3",
        volume: 0.9,
        onload: function () {
        },
    });
    const soundProcesando = new Howl({
        src: process.env.PUBLIC_URL + "/audios/Procesando.mp3",
        volume: 0.9,
        onload: function () {
        },
    });
    const soundAcerca = new Howl({
        src: process.env.PUBLIC_URL + "/audios/Acerca.mp3",
        volume: 0.9,
        onload: function () {
        },
    });

    useEffect(() => {
        const dataOtorganteLS = localStorage.getItem("dataOtorgante");
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS));

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
        if (apikeyOtorgante) setApikey(apikeyOtorgante);

        const uuidTransaccionLS = localStorage.getItem("uuidTrx");
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS);

        if (!isMobile) refVideo.current.classList.add("myVideo_rotate");


        //if (refDivFooter.current) refDivFooter.current.style.position = positionDiv(window.innerWidth, window.innerHeight);
        status("captura_video_grabacion_page", "Captura Video Grabacion");
        startCamera = true;
        setLoading(true);
        permisoCamara();

    }, []);

    useEffect(() => {
        if (recorder !== null) initRecord();
    }, [recorder]);

    useEffect(() => {
        return () => {
            startCamera = false;
            if (stream) stream.getTracks().forEach(track => track.stop());
            clearInterval(interval);
            soundAcerca.stop();
            soundCuadro.stop();
            soundProcesando.stop();
            soundRostro.stop();
            soundUbicafrente.stop();
            soundUbicareverso.stop();
        }
    }, []);

    const permisoCamara = () => {
        let deviceFuncional = false;
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (var i = 0; i < devices.length; i++) {
                let device = devices[i];
                if (device.kind === 'videoinput') {
                    deviceFuncional = true
                }
            }
            comprobarAudios(deviceFuncional);
        });
    }

    const comprobarAudios = async (deviceFuncional) => {
        if (soundRostro.state() === 'loaded' && soundUbicafrente.state() === 'loaded' && soundUbicareverso.state() === 'loaded' &&
            soundProcesando.state() === 'loaded' && soundAcerca.state() === 'loaded' && startCamera === true) {
            // start(deviceFuncional);
            setLocalStream(null);
            setHasStarted(true)
            setErrorAudios(false)
            start();
        } else {
            setTimeout(() => {
                comprobarAudios(deviceFuncional);
                comprobarInicio()
            }, 10000);
        }
    }

    const comprobarInicio = () => {
        setTimeout(() => {
            if (!hasStarted) setErrorAudios(true)
        }, 30000);
    }

    const start = async () => {
        setHasStarted(true)
        //if (stream) stream.getTracks().forEach(track => track.stop());
        const constraints = {
            audio: false,
            video: {
                facingMode: "user",
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 960, height: 720 }
                ]
            }
        };

        //console.log("Reconfiguracion",constraints);
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            setLocalStream(mediaStream);
            stream = mediaStream;
            refVideo.current.srcObject = mediaStream;
            refVideo.current.onloadedmetadata = function () {
                refVideo.current.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    if (window.MediaRecorder) {
                        let mymeType = null;
                        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
                            mymeType = 'video/webm; codecs=vp9';
                        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
                            mymeType = 'video/webm; codecs=vp8';
                        }
                        const type = isIOS ? 'video/mp4' : mymeType;
                        const optionNormal = {
                            type: 'video',
                            mimeType: type,
                        };

                        const recorder = new RecordRTC(mediaStream, optionNormal);
                        setRecorder(recorder);
                        setErrorVideo(false);
                    } else {
                        //console.log('No soporta MediaRecorder');
                        setErrorVideo(true);
                        setSuportMediaRecorder(false);
                    }
                } else {
                    //console.log("No disponibles");    
                    setErrorVideo(true);
                    setSuportMediaRecorder(false);
                }
            };
            refVideo.current.addEventListener('canplay', function () {
                refCanvas.current.setAttribute('width', refVideo.current.videoWidth * 1.5);
                refCanvas.current.setAttribute('height', refVideo.current.videoHeight * 1.5);
            }, false);
        } catch (error) {
            handleErrorMediaUser(error)
        }
    }

    const stopProcess = () => {
        //evento('Captura Video Token', 'Click', { description: 'CANCELAR' }, true);
        setLoading(false);
        setShowCancel(true);
    }

    const initRecord = (reintento) => {
        setError(false);
        setTimeout(() => {
            if (suportMediaRecorder) {
                recordMediaRecorder(reintento);
            } else {
                recordWebrtc(reintento);
            }
        }, 1000);

    }

    const recordMediaRecorder = (reintento) => {
        evento('Captura Video Grabacion', 'MediaRecorder', { status: 'OK' }, true);
        const localVideo = refVideo.current;
        if (!localVideo) return;
        localVideo.autoplay = true;
        localVideo.muted = true;

        recorder.startRecording();
        let intentos = 0;
        let blobvideo = null

        localVideo.classList.remove("blur_video");
        const titulo = document.getElementById("head_shop");
        if (reintento) titulo.className = "animate__animated animate__slideOutUp";

        setTimeout(() => {
            setLoading(false);
            soundRostro.play();
            interval = setInterval(() => {
                intentos++;
                if (intentos === 1 && reintento) {
                    titulo.className = "animate__animated animate__fadeInDown";
                    titulo.innerHTML = '<p className="calibri_punto_trader text_size_videorecord">Ubica tu rostro de <b className="calibri_bold_punto_trader">frente</b> para empezar</p>';
                }
                if (intentos === 2) {
                    titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                }
                if (intentos === 3) {
                    setIdbigimg(false);
                    setIdsmallimg(false);
                    setEstable(false);
                    setIsRecording(true);
                    titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                    titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__fadeInDown">Coloca tu identificación de <b class="calibri_bold_punto_trader">frente</b> junto a tu rostro</p><img class="dicio-img-instructions animate__animated animate__fadeInDown" src="images/videograbacion/video-record/front_id_alert.png" alt="" srcset="" />';
                }
                if (intentos === 4) {
                    soundUbicafrente.play();
                }
                if (intentos === 7) {
                    titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                }
                if (intentos === 8) {
                    //new design video recording
                    setIdsmallimg(true)
                    titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                    titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord  animate__animated animate__fadeInDown">Coloca tu identificación de <b class="calibri_bold_punto_trader">frente</b> junto a tu rostro</p><img class="dicio-ine-small animate__animated animate__fadeInDown" src="images/videograbacion/video-record/front_id_circle.png" alt="" srcset="" />';
                }
                if (intentos === 11) {
                    setEstable(true);
                }
                if (intentos === 13) {
                    titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                    setIdbigimg(false);
                    setIdsmallimg(false);
                    setEstable(false);
                }
                if (intentos === 14) {
                    soundAcerca.play();
                    setIdbigimg(true);
                    titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                    titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Acerca tu identificación de <b class="calibri_bold_punto_trader">frente</b> a tu rostro</p><img class="dicio-ine-small animate__animated animate__fadeInDown" src="images/videograbacion/video-record/front_id_circle_b.png" alt="" srcset="" />';
                }
                if (intentos === 17) {
                    setEstable(true);
                }
                if (intentos === 19) {
                    titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                    setIdbigimg(false);
                    setIdsmallimg(false);
                    setEstable(false);
                }
                if (intentos === 20) {
                    soundUbicareverso.play();
                    titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                    titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Coloca el <b class="calibri_bold_punto_trader">reverso</b> de tu identificación junto a tu rostro</p><img class="dicio-img-instructions animate__animated animate__fadeInDown" src="images/videograbacion/video-record/back_id_alert.png" alt="" srcset="" />';
                }
                if (intentos === 23) {
                    titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                }
                if (intentos === 24) {
                    setIdsmallimg(true);
                    titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                    titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Coloca el <b class="calibri_bold_punto_trader">reverso</b> de tu identificación junto a tu rostro</p><img class="dicio-ine-small animate__animated animate__fadeInDown" src="images/videograbacion/video-record/back_id_circle.png" alt="" srcset="" />';
                }
                if (intentos === 26) {
                    setEstable(true);
                }
                if (intentos === 28) {
                    titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                    setIdbigimg(false);
                    setIdsmallimg(false);
                    setEstable(false);
                }
                if (intentos === 29) {
                    soundAcerca.play();
                }
                if (intentos === 30) {
                    setIdbigimg(true);
                    titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                    titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Acerca el <b class="calibri_bold_punto_trader">reverso</b> de tu identificación junto a tu rostro</p><img class="dicio-ine-small animate__animated animate__fadeInDown" src="images/videograbacion/video-record/back_id_circle_b.png" alt="" srcset="" />';
                }
                if (intentos === 32) {
                    setEstable(true);
                }
                if (intentos === 34) {
                    setLoading(true);
                    titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                    setIdbigimg(false);
                    setIdsmallimg(false);
                    setEstable(false);
                    recorder.stopRecording(() => {
                        blobvideo = recorder.getBlob();
                        // console.log(blobvideo);
                        //downloadVideo(blobvideo);
                        sendVideoRecord(blobvideo)
                    });
                }
                if (intentos === 35) {
                    setIsRecording(false);
                    soundProcesando.play();
                    titulo.className = "animate__animated animate__fadeInDown animate__delay-1s txt_videotoken";
                    titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Procesando, <b class="calibri_bold_punto_trader">gracias</b> por tu <b class="calibri_bold_punto_trader">paciencia</b></p>';
                    localVideo.classList.add("blur_video");
                    clearInterval(interval);
                }
            }, 1000);
        }, 1000);

    }

    const recordWebrtc = (reintento) => {
        evento('Captura Video Grabacion', 'WebRTC', { status: 'OK' }, true);
        setTimeout(async () => {
            const connectionClient = new ConnectionClient();
            let peerConnection1 = null;

            const localVideo = refVideo.current;
            if (!localVideo) return;
            localVideo.autoplay = true;
            localVideo.muted = true;
            const titulo = document.getElementById("head_shop");
            if (reintento) titulo.className = "animate__animated animate__slideOutUp";
            localVideo.classList.remove("blur_video")
            async function beforeAnswer(peerConnection) {
                localStream.then(function (mediaStream) {
                    mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                })
                peerConnection1 = peerConnection;
                let dataChannel = null;
                let interval = null;
                let timeoutt = null;
                function onMessage({ data }) {
                    const responseWRT = JSON.parse(data);
                    // let mensaje = (responseWRT.res.message_client) ? responseWRT.res.message_client : ""
                    //console.log("mensaje", mensaje);
                    if (responseWRT.status === 200) {
                        let msj = responseWRT.res.payload.message;
                        if (msj === "OK" || msj === "Ok") {
                            // console.log("se envio el video");
                            refVideo.current.srcObject.getTracks()[0].stop();
                            const nextStep = getNextStepIdentification(dataOtorgante, 'flujo');
                            setTimeout(() => {
                                history.replace(nextStep);
                            }, 300)
                        } else {
                            localVideo.classList.remove("blur_video");
                        }
                        peerConnection.close();
                    } else {
                        //that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                        peerConnection.close();
                    }
                    titulo.className = "animate__animated animate__fadeIn animate__delay-1s txt_videotoken";
                    titulo.innerHTML = '<p class="calibri_punto_trader text_size_punto_trader animate__animated animate__slideInDown">Procesando, <b class="calibri_bold_punto_trader">gracias</b> por tu <b class="calibri_bold_punto_trader">paciencia</b></p>';
                    localVideo.classList.remove("blur_video");
                }
                let intentos = 0
                function onDataChannel({ channel }) {
                    if (channel.label !== 'ping-pong') {
                        return;
                    }
                    dataChannel = channel;
                    dataChannel.addEventListener('message', onMessage);

                    soundRostro.play();
                    let interval = setInterval(() => {
                        intentos++;
                        setLoading(false);
                        if (intentos === 1 && reintento) {
                            titulo.className = "animate__animated animate__fadeInDown";
                            titulo.innerHTML = '<p className="calibri_punto_trader text_size_videorecord">Ubica tu rostro de <b className="calibri_bold_punto_trader">frente</b> para empezar</p>';
                        }
                        if (intentos === 2) {
                            // soundCuadro.play();
                            titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                        }
                        if (intentos === 3) {
                            setIdbigimg(false);
                            setIdsmallimg(false);
                            setEstable(false);
                            setIsRecording(true);
                            titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                            titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__fadeInDown">Coloca tu identificación de <b class="calibri_bold_punto_trader">frente</b> junto a tu rostro</p><img class="dicio-img-instructions animate__animated animate__fadeInDown" src="images/videograbacion/video-record/front_id_alert.png" alt="" srcset="" />';
                        }
                        if (intentos === 4) {
                            soundUbicafrente.play();
                        }
                        if (intentos === 7) {
                            titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                        }
                        if (intentos === 8) {
                            //new design video recording
                            setIdsmallimg(true);
                            titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                            titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord  animate__animated animate__fadeInDown">Coloca tu identificación de <b class="calibri_bold_punto_trader">frente</b> junto a tu rostro</p><img class="dicio-ine-small animate__animated animate__fadeInDown" src="images/videograbacion/video-record/front_id_circle.png" alt="" srcset="" />';
                        }
                        if (intentos === 11) {
                            setEstable(true);
                        }
                        if (intentos === 13) {
                            titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                            setIdbigimg(false);
                            setIdsmallimg(false);
                            setEstable(false);
                        }
                        if (intentos === 14) {
                            soundAcerca.play();
                            setIdbigimg(true);
                            titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                            titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Acerca tu identificación de <b class="calibri_bold_punto_trader">frente</b> a tu rostro</p><img class="dicio-ine-small animate__animated animate__fadeInDown" src="images/videograbacion/video-record/front_id_circle_b.png" alt="" srcset="" />';
                        }
                        if (intentos === 17) {
                            setEstable(true);
                        }
                        if (intentos === 19) {
                            titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                            setIdbigimg(false);
                            setIdsmallimg(false);
                            setEstable(false);
                        }
                        if (intentos === 20) {
                            soundUbicareverso.play();
                            titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                            titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Coloca el <b class="calibri_bold_punto_trader">reverso</b> de tu identificación junto a tu rostro</p><img class="dicio-img-instructions animate__animated animate__fadeInDown" src="images/videograbacion/video-record/back_id_alert.png" alt="" srcset="" />';
                        }
                        if (intentos === 23) {
                            titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                        }
                        if (intentos === 24) {
                            setIdsmallimg(true)
                            titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                            titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Coloca el <b class="calibri_bold_punto_trader">reverso</b> de tu identificación junto a tu rostro</p><img class="dicio-ine-small animate__animated animate__fadeInDown" src="images/videograbacion/video-record/back_id_circle.png" alt="" srcset="" />';
                        }
                        if (intentos === 26) {
                            setEstable(true);
                        }
                        if (intentos === 28) {
                            titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                            setIdbigimg(false);
                            setIdsmallimg(false);
                            setEstable(false);
                        }
                        if (intentos === 29) {
                            soundAcerca.play();
                        }
                        if (intentos === 30) {
                            setIdbigimg(true);
                            titulo.className = "animate__animated animate__fadeInDown txt_videotoken";
                            titulo.innerHTML = '<p class="calibri_punto_trader text_size_videorecord animate__animated animate__slideInDown">Acerca el <b class="calibri_bold_punto_trader">reverso</b> de tu identificación junto a tu rostro</p><img class="dicio-ine-small animate__animated animate__fadeInDown" src="images/videograbacion/video-record/back_id_circle_b.png" alt="" srcset="" />';
                        }
                        if (intentos === 32) {
                            setEstable(true);
                        }
                        if (intentos === 34) {
                            setLoading(true);
                            titulo.className = "animate__animated animate__slideOutUp txt_videotoken";
                            setIdbigimg(false);
                            setIdsmallimg(false);
                            setEstable(false);
                            setTimeout(async () => {
                                dataChannel.send("finish_record");
                                //that.contador()
                            }, 200);
                        }
                        if (intentos === 35) {
                            setIsRecording(false);
                            soundProcesando.play();
                            titulo.className = "animate__animated animate__fadeIn animate__delay-1s txt_videotoken";
                            titulo.innerHTML = '<p class="calibri_punto_trader text_size_punto_trader animate__animated animate__slideInDown">Procesando, <b class="calibri_bold_punto_trader">gracias</b> por tu <b class="calibri_bold_punto_trader">paciencia</b></p>';
                            localVideo.classList.add("blur_video");
                            clearInterval(interval);
                        }
                    }, 500);

                    timeoutt = setTimeout(() => {
                        let envio = JSON.stringify({ uuid: localStorage.getItem("uuidUser"), bucket: bucket, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "FILE", isMobile: isMobile, key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")) });
                        dataChannel.send(envio);
                    }, 600);
                }
                peerConnection.addEventListener('datachannel', onDataChannel);
                const { close } = peerConnection;
                peerConnection.close = function () {
                    if (dataChannel) {
                        dataChannel.removeEventListener('message', onMessage);
                    }
                    if (interval) {
                        clearInterval(interval);
                    }
                    if (timeoutt) {
                        clearTimeout(timeoutt);
                    }
                    return close.apply(this, arguments);
                };
            }
            try {
                peerConnection1 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer });
                window.peerConnection1 = peerConnection1;
            } catch (error) {
                titulo.innerHTML = "<p></p>";
                //that.setState({ errorMessage: "Por favor verifica tu conexión para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
            }
        }, 500)
    }

    const sendVideoRecord = (blobvideo) => {
        let informacion = {};
        let tipo = "";
        setLoading(true)
        if (bytesToSize(blobvideo?.size).substring(0, 1)?.trim() !== 'O' && blobvideo.size > 0) {
            enviarVideo(blobvideo, isIOS)
                .then((responseWRT) => {
                    informacion.status = responseWRT.status;
                    informacion.payload = responseWRT.data.payload;
                    evento('Enviar video', 'Success', informacion, true);
                    if (responseWRT.status === 200) {
                        refVideo.current.srcObject.getTracks()[0].stop();
                        processIOS();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        let { data, status } = error.response
                        informacion.data = data;
                        informacion.status = status;
                        tipo = 'Error';
                    } else {
                        informacion.error = error.toString();
                        tipo = 'Exception';
                    }
                    evento('Enviar video', tipo, informacion, false);
                    setLoading(false);
                    setError(true);
                    let video = refVideo.current;
                    if (video) {
                        video.classList.remove("blur_video");
                    } else {
                        return;
                    }
                })
        } else {
            setLoading(false);
            setError(true);
            evento('Enviar video', 'Exception', `Peso del video ${bytesToSize(blobvideo?.size)} - Tamaño del video ${blobvideo?.size}`, false);
            let video = refVideo.current;
            if (video) {
                video.classList.remove("blur_video");
            } else {
                return;
            }
        }

    }

    const processIOS = (blobvideo) => {
        let informacion = {};
        let tipo = "";
        setLoading(true)
        procesarVideoIOS(blobvideo, isIOS)
            .then((responseWRT) => {
                informacion.status = responseWRT.status;
                informacion.payload = responseWRT.data.payload;
                evento('Procesando Video', 'Success', informacion, true);
                if (responseWRT.status === 200) {
                    const nextStep = getNextStepIdentification(dataOtorgante, 'flujo')
                    setTimeout(() => {
                        history.replace(nextStep)
                    }, 300)
                }
            })
            .catch((error) => {
                if (error.response) {
                    let { data, status } = error.response
                    informacion.data = data;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Procesando Video', tipo, informacion, false);
                const video = refVideo.current;
                if (video) {
                    video.classList.remove("blur_video");
                } else {
                    return;
                }
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        configStyle(dataOtorgante);
    }, [dataOtorgante]);

    const statusCancelado = async () => {
        setLoading(true);
        //Cancelar envio de video
        sendEventClick('Captura Video Grabacion', 'Sí', { status: 'CANCELADO' });
        cancelarRequestVideograbacion();
        cancelarRequestVideoIOS();
        localStorage.setItem('flag', 'cancelado');
        if (stream) stream.getTracks().forEach(track => track.stop());
        clearInterval(interval);
        soundAcerca.stop();
        soundCuadro.stop();
        soundProcesando.stop();
        soundRostro.stop();
        soundUbicafrente.stop();
        soundUbicareverso.stop();
        cancelarINE();
        await generateZip('TRUNCOS', 'trunco', isIOS);
        const json_final = {
            "status": 'INCOMPLETED',
            "step": "captura_video_grabacion",
            "description": 'Flujo cancelado'
        };
        const event = JSON.stringify(json_final);
        sendData({ 'step': 'captura_video_grabacion', 'event': event });
        localStorage.setItem('completado', 'true');
        if (window.opener) {
            window.opener.postMessage(JSON.stringify({
                step: 'captura_video_grabacion',
                status: 'INCOMPLETED',
                type: "PROCESS",
                error_text: 'Cancelado',
            }), localStorage.getItem("externalReference"));
        } else {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apikey}`);
                    setLoading(false);
                }, 300)
            } else {
                setShowCancelScreen(true);
                setLoading(false);
                setShowCancel(false);
            }
        }
    }

    const handleErrorMediaUser = (error) => {
        const errorObject = mapErrorGetUserMedia(error);
        evento('Captura ID', 'User Media', errorObject, true);
        if (errorObject.status === 'PERMISOS DENEGADOS')
            setErrorPermissions(true);
        else
            setErrorVideo(true);
        setLoading(false);
    }

    return (
        <>
            <div className="module_container overflow_hddn" style={{ position: 'fixed' }}>
                <img className="img_background" src={process.env.PUBLIC_URL + '/images/bg_id_gr.png'} alt="" />
                <div className="camera_capture_frame">
                    <video ref={refVideo} id="video_wrt_environment" playsInline></video>
                    <canvas ref={refCanvas} id="canvas_wrt_environment" hidden></canvas>
                </div>

                <div className="module_gradident_overlay"></div>

                <div className="cuadro">
                    <div id="head_shop" className="animate__animated animate__fadeInDown animate__delay-3s txt_videotoken" >
                        <p className="calibri_punto_trader text_size_videorecord">Ubica tu rostro de <b className="calibri_bold_punto_trader">frente</b> para empezar</p>
                    </div>
                </div>

                {(estable) &&
                    <div className="sprite_stay_a animado animate__fadeInDown animate__delay-2s"></div>
                }

                {isRecording &&
                    <div id='recordText' className="dicio-txt-record margin_bottom_10 animate__animated animate__flash">
                        <i className="dicio-icon-record "></i>
                        <span>Grabando…</span>
                    </div>
                }
                {
                    (idbigimg) &&
                    <img className={`${isMobile ? 'idbig_mask_mobile' : 'idbig_mask'} animate__animated animate__fadeInDown`} src={process.env.PUBLIC_URL + '/images/videograbacion/video-record/RectangleA.svg'} alt="" />
                }
                {
                    (idsmallimg) &&
                    <img className={`${isMobile ? 'idsmall_mask_mobile' : 'idsmall_mask'} animate__animated animate__fadeInDown`} src={process.env.PUBLIC_URL + '/images/videograbacion/video-record/RectangleB.svg'} alt="" />
                }

                {(loading) && <Loader />}
                <div className="recorder_container">
                    <div className="module_buttons button_cancel_white animate__animated" style={{ position: 'fixed' }}>
                        <button onClick={() => { stopProcess(); sendEventClick('Captura Video Grabacion', 'CANCELAR'); }} className={["btn btn-primary forcewidth100 button_outline_punto_trader", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")}>
                            Cancelar</button>
                    </div>
                </div>
                {(showCancel) &&
                    <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                    <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className='calibri_punto_trader text_size_punto_trader'>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={e => { setShowCancel(false); sendEventClick('Captura Video Grabacion', "NO") }} className="btn btn-secondary text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                    <button type="button" onClick={e => statusCancelado()} className="btn btn-raised btn-primary button_punto_trader">Sí</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                {errorVideo &&
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="calibri_punto_trader text_size_punto_trader animated slideInDown">Hemos detectado que la <b className="calibri_bold_punto_trader">cámara de tu dispositivo</b> está siendo usada por otra web en <b className="calibri_bold_punto_trader">alguna pestaña</b>, por favor asegúrate de <b className="calibri_bold_punto_trader">cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                setErrorVideo(false);
                                //comprobarAudios();
                                console.log("recargado ....");
                                sendEventClick('Captura Video Grabacion', 'VOLVER A INTENTAR');
                                window.location.reload();
                            }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader alert_btn text_size_punto_trader animated fadeIn delay-2s">Volver a intentar</button>
                        </div>
                    </div>}
                {errorPermissions && <ErrorPermissions permisosDenegados={errorPermissions} setErrorPermisos={setErrorPermissions} permisoCamara={comprobarAudios} />}
                {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apikey} />}
            </div>
            {error &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className={['modal-dialog', isMobile ? 'modal-center' : 'modal-center-desktop'].join(' ')} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Lo sentimos</h5>
                            </div>
                            <div className="modal-body">
                                <p className='font_calibri_punto_trader text_size_punto_trader'>Revisa tu conexión a internet e intenta nuevamente</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => initRecord(true)} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Reintentar</button>
                            </div>
                        </div>
                    </div>
                </div>}

            {errorAudios && !hasStarted &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className={['modal-dialog', isMobile ? 'modal-center' : 'modal-center-desktop'].join(' ')} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Lo sentimos</h5>
                            </div>
                            <div className="modal-body">
                                <p className='font_calibri_punto_trader text_size_punto_trader'>Tenemos problemas para establecer contacto, asegúrate de tener una buena conexión a internet o inténtalo más tarde.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => window.location.reload()} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Reintentar</button>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default ComponenteVideoGrabacionIdentificacion;
