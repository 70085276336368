/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { useHistory } from "react-router-dom"
import { isIOS, isSafari } from 'react-device-detect'
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import FormularioDomicilio from '../pages/forms/formulario_domicilio'
import Ayuda from './ayuda'
import Loader from '../components/loader'
import CancelarFlujo from "./flujo/cancelar_flujo"
import IdTransaccion from "../components/id_transaccion";
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { agregarInfo, statusData, validarValoresFormulario, status, statusError, validarRenapo, generateZip, sendEventClick, validarParametroOtorgante } from '../services/data'
/* Servicios */
import { actualizarScore } from '../services/api'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"

let InformacionDomicilio = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [domicilioCorrespondencia, setDomicilioCorrespondencia] = useState(true)
    const [domicilioFiscal, setDomicilioFiscal] = useState(true)
    const [showCancel, setShowCancel] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [continuar, setContinuar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [disabledCheck, setDisabledCheck] = useState(false)
    const [validar, setValidar] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataUser, setDataUser] = useState({})
    const [inputsValue, setInputsValue] = useState({})
    const [inputsErrors, setInputsErrors] = useState({})

    useEffect(() => {
        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS) setDataUser(JSON.parse(dataUserLS))

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        status("informacion_direccion_ocr_page", "Informacion de direccion OCR")
        validarRenapo()
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        if (dataUser.length > 0) localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    useEffect(() => {
        if (validar) {
            const validacion = validarValoresFormulario(inputsValue)
            if (validacion.correcto) {
                validarFormulario()
                setInputsErrors(validacion.errores)
            } else {
                setContinuar(false)
                setInputsErrors(validacion.errores)
                setValidar(false)
            }
        }
    }, [validar, inputsValue])

    const sendData = (infoFormulario) => {
        const jsonObj = {};
        jsonObj.infoDireccion = infoFormulario;
        statusData("informacion_direccion_ocr", jsonObj)
    }

    const setValue = (key, value) => {
        switch (key) {
            default:
                setInputsValue(inputsValue => ({ ...inputsValue, [key]: value }))
                break
        }
    }

    const setErrors = (value) => {
        setInputsErrors(inputsErrors => ({ ...inputsErrors, ...value }))
    }

    const validarFormulario = () => {
        setLoading(true)
        setDisabledCheck(true)
        // if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        sendEventClick('Información de dirección OCR', 'GUARDAR')
        let infoFormulario = []
        const step = '/preparacion_comprobante_domicilio'
        let infoDomicilio = {}
        //let validacionOCR = validateInfoOCR(inputsValue, pais, entidadFederativa);
        let coloniaScore = null
        /* IFORMACIÓN DOMICILIO PARTICULAR */
        infoDomicilio.codigoPostal = inputsValue.codigoPostalDomicilio
        infoDomicilio.pais = inputsValue.paisDomicilio
        infoDomicilio.entidadFederativa = inputsValue.entidadFederativaDomicilio
        infoDomicilio.municipio = inputsValue.municipioDomicilio
        infoDomicilio.calle = inputsValue.calleDomicilio
        infoDomicilio.numeroExterior = inputsValue.numeroCalleExteriorDomicilio
        infoDomicilio.calleCompleta = inputsValue.calleDomicilio + ' ' + inputsValue.numeroCalleExteriorDomicilio
        if (inputsValue.otraColoniaDomicilio) {
            infoDomicilio.colonia = inputsValue.otraColoniaDomicilio
            coloniaScore = inputsValue.otraColoniaDomicilio
        } else {
            infoDomicilio.colonia = inputsValue.coloniaDomicilio
            coloniaScore = inputsValue.coloniaDomicilio
        }
        if (inputsValue.numeroCalleInteriorDomicilio) {
            infoDomicilio.numeroInterior = inputsValue.numeroCalleInteriorDomicilio
        }
        infoFormulario.push({ description: "informacionDomicilioParticular", value: infoDomicilio })
        infoDomicilio.calle = inputsValue.calleDomicilio + ' ' + inputsValue.numeroCalleExteriorDomicilio
        infoDomicilio.pais = infoDomicilio.pais.toUpperCase()
        infoDomicilio.entidadFederativa = infoDomicilio.entidadFederativa.toUpperCase()
        infoDomicilio.municipio = infoDomicilio.municipio.toUpperCase()
        delete infoDomicilio.calleCompleta
        agregarInfo('domicilio', infoDomicilio)

        if (!domicilioCorrespondencia) {
            infoDomicilio.codigoPostal = inputsValue.codigoPostalDomicilioCorrespondencia
            infoDomicilio.pais = inputsValue.paisDomicilioCorrespondencia
            infoDomicilio.entidadFederativa = inputsValue.entidadFederativaDomicilioCorrespondencia
            infoDomicilio.municipio = inputsValue.municipioDomicilioCorrespondencia
            infoDomicilio.calle = inputsValue.calleDomicilioCorrespondencia
            infoDomicilio.numeroExterior = inputsValue.numeroCalleExteriorDomicilioCorrespondencia
            infoDomicilio.calleCompleta = inputsValue.calleDomicilioCorrespondencia + ' ' + inputsValue.numeroCalleExteriorDomicilioCorrespondencia
            if (inputsValue.otraColoniaDomicilio) {
                infoDomicilio.colonia = inputsValue.otraColoniaDomicilioCorrespondencia
            } else {
                infoDomicilio.colonia = inputsValue.coloniaDomicilioCorrespondencia
            }
            if (inputsValue.numeroCalleInteriorDomicilioCorrespondencia) {
                infoDomicilio.numeroInterior = inputsValue.numeroCalleInteriorDomicilioCorrespondencia
            }
            infoFormulario.push({ description: "informacionDomicilioCorrespondencia", value: infoDomicilio })
        }

        if (!domicilioFiscal) {
            infoDomicilio.codigoPostal = inputsValue.codigoPostalDomicilioFiscal
            infoDomicilio.pais = inputsValue.paisDomicilioFiscal
            infoDomicilio.entidadFederativa = inputsValue.entidadFederativaDomicilioFiscal
            infoDomicilio.municipio = inputsValue.municipioDomicilioFiscal
            infoDomicilio.calle = inputsValue.calleDomicilioFiscal
            infoDomicilio.numeroExterior = inputsValue.numeroCalleExteriorDomicilioFiscal
            infoDomicilio.calleCompleta = inputsValue.calleDomicilioFiscal + ' ' + inputsValue.numeroCalleExteriorDomicilioFiscal
            if (inputsValue.otraColoniaDomicilio) {
                infoDomicilio.colonia = inputsValue.otraColoniaDomicilioFiscal
            } else {
                infoDomicilio.colonia = inputsValue.coloniaDomicilioFiscal
            }
            if (inputsValue.numeroCalleInteriorDomicilioFiscal) {
                infoDomicilio.numeroInterior = inputsValue.numeroCalleInteriorDomicilioFiscal
            }
            infoFormulario.push({ description: "informacionDomicilioFiscal", value: infoDomicilio })
        }

        /* if (!ocrValidado || !validacionOCR) {
            step = "/preparacion_comprobante_domicilio";
        } else {
            step = "/preparacion_comprobante_bancario";
        } */

        const uuid = localStorage.getItem("uuidUser")
        const empresa = (obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""))

        actualizarScore(uuid, inputsValue.calleDomicilio, inputsValue.calleDomicilio, coloniaScore, inputsValue.codigoPostalDomicilio, inputsValue.entidadFederativaDomicilio, inputsValue.municipioDomicilio, inputsValue.numeroCalleExteriorDomicilio, empresa)
            .then(({ status, data }) => {
                console.log(`🚀 -----------------------------------------------------------🚀`)
                console.log(`🚀 ~ file: identificacion_edit.jsx:166 ~ .then ~ data:`, data)
                console.log(`🚀 -----------------------------------------------------------🚀`)
                if (status === 200) {
                    sendData(infoFormulario)
                    setTimeout(() => {
                        history.replace(step)
                    }, 300);
                }
            }).catch((err) => {
                console.log(`🚀 -------------------------------------------------------------------🚀`)
                console.error(`🚀 ~ file: identificacion_edit.jsx:173 ~ actualizarScore ~ err:`, { err })
                console.log(`🚀 -------------------------------------------------------------------🚀`)
                sendData(infoFormulario)
                setTimeout(() => {
                    history.replace(step)
                }, 300)
            }).finally(() => setLoading(false));
    }

    useEffect(() => {
        if (domicilioCorrespondencia) {
            setInputsValue(prevValues => {
                const inputs = { ...prevValues };
                if (inputs['codigoPostalDomicilioCorrespondencia'] || inputs['codigoPostalDomicilioCorrespondencia'] === '')
                    delete inputs['codigoPostalDomicilioCorrespondencia'];
                if (inputs['paisDomicilioCorrespondencia'] || inputs['paisDomicilioCorrespondencia'] === '')
                    delete inputs['paisDomicilioCorrespondencia'];
                if (inputs['entidadFederativaDomicilioCorrespondencia'] || inputs['entidadFederativaDomicilioCorrespondencia'] === '')
                    delete inputs['entidadFederativaDomicilioCorrespondencia'];
                if (inputs['municipioDomicilioCorrespondencia'] || inputs['municipioDomicilioCorrespondencia'] === '')
                    delete inputs['municipioDomicilioCorrespondencia'];
                if (inputs['coloniaDomicilioCorrespondencia'] || inputs['coloniaDomicilioCorrespondencia'] === '')
                    delete inputs['coloniaDomicilioCorrespondencia'];
                if (inputs['calleDomicilioCorrespondencia'] || inputs['calleDomicilioCorrespondencia'] === '')
                    delete inputs['calleDomicilioCorrespondencia'];
                if (inputs['numeroCalleExteriorDomicilioCorrespondencia'] || inputs['numeroCalleExteriorDomicilioCorrespondencia'] === '')
                    delete inputs['numeroCalleExteriorDomicilioCorrespondencia'];
                if (inputs['otraColoniaDomicilioCorrespondencia'] || inputs['otraColoniaDomicilioCorrespondencia'] === '')
                    delete inputs['otraColoniaDomicilioCorrespondencia'];
                return inputs;
            });
        }

    }, [domicilioCorrespondencia])

    const statusE = async () => {
        setLoading(true)
        sendEventClick('Información de dirección OCR', 'SI', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("informacion_direccion", 'Cancelado', "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    useEffect(() => {
        if (domicilioFiscal) {
            setInputsValue(prevValues => {
                const inputs = { ...prevValues };
                if (inputs['codigoPostalDomicilioFiscal'] || inputs['codigoPostalDomicilioFiscal'] === '')
                    delete inputs['codigoPostalDomicilioFiscal'];
                if (inputs['paisDomicilioFiscal'] || inputs['paisDomicilioFiscal'] === '')
                    delete inputs['paisDomicilioFiscal'];
                if (inputs['entidadFederativaDomicilioFiscal'] || inputs['entidadFederativaDomicilioFiscal'] === '')
                    delete inputs['entidadFederativaDomicilioFiscal'];
                if (inputs['municipioDomicilioFiscal'] || inputs['municipioDomicilioFiscal'] === '')
                    delete inputs['municipioDomicilioFiscal'];
                if (inputs['coloniaDomicilioFiscal'] || inputs['coloniaDomicilioFiscal'] === '')
                    delete inputs['coloniaDomicilioFiscal'];
                if (inputs['calleDomicilioFiscal'] || inputs['calleDomicilioFiscal'] === '')
                    delete inputs['calleDomicilioFiscal'];
                if (inputs['numeroCalleExteriorDomicilioFiscal'] || inputs['numeroCalleExteriorDomicilioFiscal'] === '')
                    delete inputs['numeroCalleExteriorDomicilioFiscal'];
                if (inputs['otraColoniaDomicilioFiscal'] || inputs['otraColoniaDomicilioFiscal'] === '')
                    delete inputs['otraColoniaDomicilioFiscal'];
                return inputs;
            });
        }

    }, [domicilioFiscal])

    useEffect(() => {
        const validacion = validarValoresFormulario(inputsValue)
        if (validacion.correcto) {
            setContinuar(true)
            setInputsErrors(validacion.errores)
        } else {
            setContinuar(false)
            setInputsErrors(validacion.errores)
        }
    }, [inputsValue])

    return (
        <>
            <div className="main_gradient flex_container">
                <Header show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="title_punto_trader">Verifica y completa tu información de domicilio</h1>
                    <p className="text_punto_trader">Asegúrate que la información sea <b className="calibri_bold_punto_trader">fiel</b> a la de tu <b className="calibri_bold_punto_trader">documentación</b>.</p>
                </div>
                <form>
                    <div className="ocr_data_display calibri_punto_trader">
                        <FormularioDomicilio tipoDomicilio="Domicilio" inputsErrors={inputsErrors} setInputsValue={setValue} setInputsErrors={setErrors} />
                        <div className="check_container">
                            <div className='div_checkbox'>
                                {(isIOS || isSafari) ?
                                    <div>
                                        <label htmlFor="check_correspondencia" className="custom-checkbox">
                                            <input id="check_correspondencia" type="checkbox" onChange={() => { setDomicilioCorrespondencia(!domicilioCorrespondencia); sendEventClick('Información de dirección OCR', ' Mi domicilio de correspondencia es igual a mi domicilio particular.', { check: !domicilioCorrespondencia }); }} defaultChecked={domicilioCorrespondencia} disabled={disabledCheck} />
                                            <span className="checkbox-decorator"><span className="check"></span></span>
                                            Mi domicilio de correspondencia es igual a mi domicilio particular.
                                        </label>
                                    </div>
                                    :
                                    <div className="checkbox">
                                        <label htmlFor="check_correspondencia">
                                            <input id="check_correspondencia" type="checkbox" onChange={e => { setDomicilioCorrespondencia(!domicilioCorrespondencia); sendEventClick('Información de dirección OCR', ' Mi domicilio de correspondencia es igual a mi domicilio particular.', { check: !domicilioCorrespondencia }); }} defaultChecked={domicilioCorrespondencia} disabled={disabledCheck} />
                                            <span className="checkbox-decorator"><span className="check"></span></span>
                                            Mi domicilio de correspondencia es igual a mi domicilio particular.
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                        {!domicilioCorrespondencia &&
                            <>
                                <FormularioDomicilio tipoDomicilio="DomicilioCorrespondencia" inputsErrors={inputsErrors} setInputsValue={setValue} setInputsErrors={setErrors} />
                            </>}
                        <div className="check_container ">
                            <div className='div_checkbox'>
                                {(isIOS || isSafari) ?
                                    <div>
                                        <label htmlFor="check_fiscal" className="custom-checkbox">
                                            <input id="check_fiscal" type="checkbox" onChange={() => { setDomicilioFiscal(!domicilioFiscal); sendEventClick('Información de dirección OCR', ' Mi domicilio de fiscal es igual a mi domicilio particular.', { check: !domicilioFiscal }); }} defaultChecked={domicilioFiscal} disabled={disabledCheck} />
                                            <span className="checkbox-decorator"><span className="check"></span></span>
                                            Mi domicilio de fiscal es igual a mi domicilio particular.
                                        </label>
                                    </div>
                                    :
                                    <div className="checkbox">
                                        <label htmlFor="check_fiscal">
                                            <input id="check_fiscal" type="checkbox" onChange={() => { setDomicilioFiscal(!domicilioFiscal); sendEventClick('Información de dirección OCR', ' Mi domicilio de fiscal es igual a mi domicilio particular.', { check: !domicilioFiscal }); }} defaultChecked={domicilioFiscal} disabled={disabledCheck} />
                                            <span className="checkbox-decorator"><span className="check"></span></span>
                                            Mi domicilio de fiscal es igual a mi domicilio particular.
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                        {!domicilioFiscal &&
                            <>
                                <FormularioDomicilio tipoDomicilio="DomicilioFiscal" inputsErrors={inputsErrors} setInputsValue={setValue} setInputsErrors={setErrors} />
                            </>}
                    </div>
                    <div className="action_buttons">
                        <button type="button" className={["btn btn-raised btn-primary forcewidth100", (continuar ? "button_punto_trader" : "button_gray_punto_trader")].join(' ')} disabled={continuar ? false : true} onClick={() => setValidar(true)}>Guardar</button>
                        <button type="button" onClick={() => { setShowCancel(true); sendEventClick('Información de dirección OCR', 'CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader">Cancelar</button>
                    </div>
                    {(showCancel) &&
                        <div className="modal fade show" style={{ display: "block" }} role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                        <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={() => { setShowCancel(false); sendEventClick('Información de dirección OCR', 'NO') }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                        <button type="button" onClick={() => statusE()} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </form>
                <IdTransaccion />
                <div style={{ marginTop: 10 }}>
                    <Footer />
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {loading && <Loader />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </>
    )
}

export default InformacionDomicilio