/* eslint-disable react-hooks/exhaustive-deps */
/* React hooks */
import React, { useState, useCallback, useRef, useEffect } from 'react'
/* Dependencies/libraries */
import Cropper from 'react-perspective-cropper';
import { isIOS, isSafari, isMobile } from 'react-device-detect';
import { sendEventClick } from './utilities/events/eventos';
import { obtenerValorConfig } from './utilities/styles/configStyle';

import ImageRotation from 'image-rotation';

let colorPointBGColor = '', imageRotate;
const urlCreator = window.URL || window.webkitURL;

const ComponenteRecorte = ({ tipo, imageUrl, enviarImagen, reload, side, configuration, setShowAlertDimensions }) => {
    // eslint-disable-next-line no-unused-vars
    const [cropState, setCropState] = useState();
    const [img, setImg] = useState(null);
    const [guardar, setGuardar] = useState(false);
    const [doCrop, setDoCrop] = useState(true);
    const [capturing, setCapturing] = useState(true);
    const [blobImage, setBlobImage] = useState(true);
    const [disabledCheck, setDisabledCheck] = useState(false)
    const [rotateValue, setRotateValue] = useState(-90)
    /* Refs */
    const cropperRef = useRef(null);
    const divCropperRef = useRef(null);
    const divInstruccionRef = useRef(null);

    const onDragStop = useCallback((s) => setCropState(s), []);
    const onChange = useCallback((s) => setCropState(s), []);


    useEffect(() => {
        if (configuration.dataOtorgante.length > 0) {
            let colorAcentuacion = obtenerValorConfig(configuration.dataOtorgante, 'COLOR_ACENTUACION', '');
            if (colorAcentuacion) {
                if (colorAcentuacion.includes(',1)')) {
                    colorPointBGColor = colorAcentuacion.replace(',1)', ',0.5)');
                } else {
                    colorPointBGColor = colorAcentuacion.replace(')', ',0.5)');
                }
            }
        }
        return () => { };
    }, [configuration]);

    useEffect(() => {
        if (divInstruccionRef.current) divInstruccionRef.current.innerHTML = tipo !== 'comprobante' ? "<b>Ajusta</b> tu identificación" : "<b>Ajusta</b> tu comprobante";
        return () => { };
    }, [divInstruccionRef]);

    const mostrarRecorte = async () => {
        try {
            let imageCanvas = document.querySelector('#imageCanvas');
            if (imageCanvas) imageCanvas.setAttribute('hidden', '');
            const blob = await cropperRef.current.done({
                preview: true,
                filterCvParams: {
                    thMeanCorrection: 13,
                    thMode: window.cv.ADAPTIVE_THRESH_GAUSSIAN_C,
                    grayScale: false
                }
            });
            if (isMobile && (imageCanvas.width > imageCanvas.height)) {
                setShowAlertDimensions(true);
                reload();
            } else {
                setDoCrop(false);
                //setImg(blob);
                if (isMobile) {
                    setBlobImage(blob);
                    if (tipo !== 'comprobante')
                        rotateImage(blob);
                    else
                        setImg(blob);
                } else {
                    setImg(blob);
                }
                switch (tipo) {
                    case "identificacion":
                        divInstruccionRef.current.innerHTML = "<b>Verifica</b> tu identificación";
                        break;
                    case "comprobante":
                        divInstruccionRef.current.innerHTML = "<b>Verifica</b> tu comprobante";
                        break;
                    default:
                        break;
                }
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const generarRecorte = () => {
        sendEventClick('Captura ' + tipo, `GUARDAR (${side.toUpperCase()})`, {}, configuration);
        setDisabledCheck(true);
        enviarImagen(img);
    };

    // const descargarImagen = (blob) => {
    //     let url = URL.createObjectURL(blob);
    //     let a = document.createElement('a');
    //     document.body.appendChild(a);
    //     a.style = 'display: none';
    //     a.href = url;
    //     a.download = 'captura_ine.png';
    //     a.click();
    // }
    // const descargarImagen = (blob) => {
    //     let url = URL.createObjectURL(blob);
    //     let a = document.createElement('a');
    //     document.body.appendChild(a);
    //     a.style = 'display: none';
    //     a.href = url;
    //     a.download = 'captura_ine.png';
    //     a.click();
    // }

    useEffect(() => {
        if (img) {
            let url = urlCreator.createObjectURL(img);
            let imageCanvas = document.querySelector('#imageCanvas');
            let imgPreview = document.querySelector('#imgPreview');
            let divImgPreview = document.querySelector('#div-imgPreview');
            if (divImgPreview && isMobile && (tipo !== 'comprobante')) {
                divImgPreview.style.width = `${imageCanvas.height}px`;
                divImgPreview.style.height = `${imageCanvas.width}px`;
            }
            if (imageCanvas && imgPreview) {
                imgPreview.removeAttribute('hidden');
                imgPreview.setAttribute('src', url);
                imgPreview.style.position = 'absolute';
                imgPreview.style.width = `${(isMobile && (tipo !== 'comprobante')) ? imageCanvas.height : imageCanvas.width}px`;
                imgPreview.style.height = `${(isMobile && (tipo !== 'comprobante')) ? imageCanvas.width : imageCanvas.height}px`;
                isMobile && (imgPreview.style.transform = `scale(0.84)`);
            }
        }
        return () => { };
    }, [img]);

    const rotateImage = (image, rotate = -90) => {
        setRotateValue(rotate);
        imageRotate = new ImageRotation(image);
        imageRotate.generate(rotate, 'image/png').then(res => {
            const blob = imageRotate.toBlob(res);
            setImg(blob);
        })
    }

    return (
        <>
            <div className="module_container fondo_negro" style={{ position: 'fixed', overflowY: 'scroll' }}>
                <div className="container-flex">
                    <div className="module_title">
                        <p ref={divInstruccionRef}></p>
                    </div>
                    <div ref={divCropperRef} className="content-container">
                        <Cropper
                            ref={cropperRef}
                            image={imageUrl}
                            onChange={onChange}
                            onDragStop={onDragStop}
                            pointBgColor={colorPointBGColor}
                            pointBorder={`4px solid ${obtenerValorConfig(configuration.dataOtorgante, "COLOR_ACENTUACION")}`}
                            lineColor={obtenerValorConfig(configuration.dataOtorgante, "COLOR_ACENTUACION")}
                            maxWidth={(!isMobile) ? ((window.innerWidth <= 800) ? 640 : 854) : ((window.innerHeight <= 650) ? 240 : 288)}
                            maxHeight={(!isMobile) ? ((window.innerWidth <= 800) ? 360 : 480) : ((window.innerHeight <= 650) ? 320 : 512)}
                        />
                        {(!doCrop && img) && <>
                            <div className='div-rotate-buttons'>
                                {(isMobile && tipo !== 'comprobante') && <button type="button" className="btn btn-primary forcewidth100 button_outline_punto_trader" onClick={() => rotateImage(blobImage, (rotateValue === -90) ? 90 : -90)} disabled={guardar}>Rotar imagen</button>}
                            </div>
                            <div id="div-check" className="check_container div_relative">
                                <form>
                                    {(isIOS || isSafari) ?
                                        <div>
                                            <label htmlFor="check_terms" className="custom-checkbox white_label">
                                                <input id="check_terms" type="checkbox" onChange={e => { setGuardar(!guardar); setCapturing(!capturing); sendEventClick('Captura ' + tipo, `Confirmo que la captura es clara y los datos legibles (${side.toUpperCase()})`, {}, configuration); }} disabled={disabledCheck} />
                                                <span className="checkbox-decorator"><span className="check"></span></span>
                                                Confirmo que la captura es clara, los datos legibles y la toma no está de cabeza.
                                            </label>
                                        </div>
                                        :
                                        <div className="checkbox">
                                            <label htmlFor="check_terms" className="white_label">
                                                <input id="check_terms" type="checkbox" onChange={e => { setGuardar(!guardar); setCapturing(!capturing); sendEventClick('Captura ' + tipo, `Confirmo que la captura es clara y los datos legibles (${side.toUpperCase()})`, {}, configuration); }} disabled={disabledCheck} />
                                                <span className="checkbox-decorator"><span className="check"></span></span>
                                                Confirmo que la captura es clara, los datos legibles y la toma no está de cabeza.
                                            </label>
                                        </div>
                                    }
                                </form>

                            </div>
                        </>}
                    </div>
                    <div>
                        {doCrop ?
                            <div className="module_buttons-cropper">
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color button_punto_trader", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={() => mostrarRecorte()}>Ajuste listo</button>
                                <button type="button" className={["btn btn-primary forcewidth100 button_outline_punto_trader", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={e => reload()}>Capturar de nuevo</button>

                            </div>
                            :
                            <div className="module_buttons-cropper">
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100", (capturing ? 'button_gray_punto_trader' : 'button_punto_trader'), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={() => generarRecorte()} disabled={!guardar}> Continuar </button>
                                <button type="button" className={["btn btn-primary forcewidth100 button_outline_punto_trader", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={() => reload()}>Capturar de nuevo</button>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </>

    )
}

export default ComponenteRecorte;