/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react"
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"

const Cancelado = ({ tipo }) => {

	useScrollToTop()

	/* Hooks */
	const [dataOtorgante, setDataOtorgante] = useState([])

	useEffect(() => {
		const dataOtorganteLS = localStorage.getItem("dataOtorgante")
		if (dataOtorganteLS) {
			setDataOtorgante(JSON.parse(dataOtorganteLS))
			configStyle(JSON.parse(dataOtorganteLS))
		}
	}, [])

	useEffect(() => {
		configStyle(dataOtorgante)
	}, [dataOtorgante])

	return (
		<div className="main_gradient">
			<div id="head_container">
				<img className="comp_logo" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") !== "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
			</div>
			<div className="main_text_container flex_body">
				<h1 className="title_punto_trader">Lo sentimos</h1>
				<p className="text_punto_trader">
					{(tipo === 'location') ?
						'No es posible continuar con tu proceso. Si deseas más información, comunícate con nosotros.'
						:
						'Para continuar con el proceso favor de comunicarse a Punto Casa de Bolsa.'
					}</p>
				<br />
				<div className="help_container">
					<div className="text-color-p margin-div-help"><b className="calibri_bold_punto_trader">Escríbenos a:</b>
						<br />
						<div className="div_flex">
							<img src={process.env.PUBLIC_URL + '/images/arroba.svg'} /><a className="calibri_bold_punto_trader text_size_punto_trader" href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a>
						</div>
					</div>
					<p className="help_phone calibri_bold_punto_trader">Llámanos al:
						<br />
						<img src={process.env.PUBLIC_URL + '/images/phone.svg'} /><a className="calibri_bold_punto_trader text_size_punto_trader" href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a>
					</p>
					<p className="help_mail calibri_bold_punto_trader">Visítanos en:
						<br />
						<img src={process.env.PUBLIC_URL + '/images/arroba.svg'} /><a className="calibri_bold_punto_trader text_size_punto_trader" rel="external" href={'https://puntotrader.mx/'}>https://puntotrader.mx/</a>
					</p>
				</div>
			</div>
			{/* <Footer /> */}
		</div>
	)
}

export default Cancelado