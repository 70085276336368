import React, { useState, useEffect } from "react"
/* Librerías */
import { useHistory } from "react-router-dom"
import { isMobile, isIOS } from 'react-device-detect'
/* Componentes */
import Header from "../components/headerNA"
import Ayuda from './ayuda'
import Loader from '../components/loader'
import Cancelado from './cancelado'
import CancelarFlujo from "./flujo/cancelar_flujo"
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { agregarInfo, status, statusError, statusData, evento, cancelarINE, sendEventClick, validarParametroOtorgante } from '../services/data'
/* Custom hooks */
import { useScrollToTop } from '../utilities/hooks/custom'
import IdTransaccion from "../components/id_transaccion";
import { useLocation } from "react-router-dom"

const Causales = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [showHelp, setShowHelp] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [getLocation, setGetLocation] = useState(false)
    const [errorLocation, setErrorLocation] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showComponentCancel, setShowComponentCancel] = useState(false)
    const [gettingGPS, setGettingGPS] = useState(false)
    const [errorMessageGPS, setErrorMessageGPS] = useState(false)
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {
        const dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS) setDataUser(JSON.parse(dataUserLS))

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        status("causales_page", "Causales")
    }, [])
    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser));
    }, [dataUser]);


    useEffect(() => {
        configStyle(dataOtorgante);
    }, [dataOtorgante]);

    const statusE = async () => {
        cancelarINE();
        setLoading(true)
        sendEventClick('Causales', 'SI', { status: 'CANCELADO' })
        let data = "Cancelado"
        localStorage.setItem('flag', 'cancelado');
        await statusError("causales", data, "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    const geoLocalizacion = (event, paso) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        if (isIOS && paso === 'REINTENTAR') {
            window.location.reload()
        } else {
            initGPS();
            setGetLocation(false);
            setLoading(true);

            if (!navigator.geolocation) {
                setErrorLocation(true);
                return;
            }

            function success(position) {
                const { latitude, longitude } = position.coords;
                setLoading(false);
                let objetoGPS = { description: "gps_aceptado", value: true, gps: "[" + latitude + "," + longitude + "]" }
                agregarInfo('gps', objetoGPS);
                statusData("obtener_gps", { description: "gps_aceptado", value: true, gps: "[" + latitude + "," + longitude + "]" });
                setTimeout(() => {
                    history.replace(((obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_1', "true") === "true") ? "/terminos" : "/preparacion_identificacion"));
                }, 200);
            };

            function error(error) {
                setGettingGPS(false);
                setErrorLocation(true);
                setLoading(false);
                const { code, message } = error;
                let objetoError = {
                    message: message
                }
                switch (code) {
                    case 1:
                        // Permiso denegado.
                        objetoError.error = 'PERMISSION_DENIED';
                        setErrorMessageGPS('El permiso de localización fue denegado o se encuentra bloqueado en tu sistema. Por favor, permite el acceso e intenta nuevamente.');
                        evento('Obtener Geolocalización', 'Step', objetoError, false);
                        break;
                    case 2:
                        // Posición no disponible.
                        objetoError.error = 'POSITION_UNAVAILABLE';
                        setErrorMessageGPS('No pudimos obtener información de localización, podría ser un error de red o de hardware, por favor verifica tu conexión o dispositivo e intenta nuevamente.');
                        evento('Obtener Geolocalización', 'Step', objetoError, false);
                        break;
                    case 3:
                        // Se acabó el tiempo de espera.
                        objetoError.error = 'TIMEOUT';
                        setErrorMessageGPS('Se superó el tiempo de espera para obtener información de localización, por favor verifica tu conexión, permisos o dispositivo e intenta nuevamente.');
                        evento('Obtener Geolocalización', 'Step', objetoError, false);
                        break;
                    default:
                        setErrorMessageGPS('No hemos podido obtener tu ubicación, por favor intenta nuevamente.');
                        evento('Obtener Geolocalización', 'Step', error, false);
                        break;
                }
            };

            navigator.geolocation.getCurrentPosition(success, error, { maximumAge: 600_000, timeout: 10_000 });
        }
    }

    const errorGetLocation = async () => {
        setLoading(true)
        sendEventClick('Causales', 'CANCELAR', { status: 'CANCELADO' })
        const data = "Cancelado"
        localStorage.setItem('flag', 'cancelado')
        await statusError("causales", data, "cancelado")
        localStorage.setItem('completado', 'true')
        setLoading(false)
        setGetLocation(false)
        setShowComponentCancel(true)
    }

    const initGPS = () => {
        if (isMobile) {
            if (isIOS) {
                if (window.webkit) window.webkit.messageHandlers.BDMApplication.postMessage({ 'function': 'initGPS' })
            } else {
                if (window.Android) window.Android.initGPS()
            }
        } else {
            return
        }
    }

    return (
        <>
            <div className="main_gradient">
                <div className="flex_container">
                    <Header show={() => setShowHelp(true)} />
                    <div className="main_text_container custom_padding flex_body">
                        <h1 className="title_punto_trader" style={{ marginBottom: '0' }}>Causales por los cuales PCB deberá suspender el proceso de contratación.</h1>
                        <br />
                        <br />
                        <div className="">
                            <ul className="calibri_punto_trader nomrgnnopdng opacity">
                                <li className="list-style">La calidad de la imagen y/o la del sonido no permitan realizar una identificación plena del solicitante.</li>
                                <li className="list-style">El solicitante no presente un INE enviado junto con el formulario de identificación, los datos obtenidos de esta no coincidan con los registros del INE o el resultado de la validación de los elementos de seguridad de la INE o de las verificaciones biométricas del rostro del solicitante, de ser el caso, no alcancen la efectividad del 90%.</li>
                                <li className="list-style">La Clave Única de Registro de Población no coincida con la información del Registro Nacional de Población.</li>
                                <li className="list-style">El código de un solo uso requerido al solicitante no sea confirmado por el solicitante.</li>
                                <li className="list-style">Se presenten situaciones atípicas o riesgosas, o bien, la Entidad tenga dudas acerca de la autenticidad del documento válido de identificación o de la identidad del solicitante.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="action_buttons flex_buttons">
                        <button onClick={() => { setGetLocation(true); sendEventClick('Causales', 'ENTERADO') }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader" disabled={gettingGPS}>Enterado</button>
                        <button onClick={e => { setShowCancel(true); sendEventClick('Causales', 'CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader" disabled={gettingGPS}>
                            Cancelar</button>
                    </div>
                    <IdTransaccion />
                </div>
            </div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setShowCancel(false); sendEventClick('Causales', 'NO') }} className="btn btn-secondary text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            {getLocation &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Activar localización.</h5>
                                <button onClick={e => setGetLocation(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">Por disposición oficial necesitamos validar tu ubicación para continuar.</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={errorGetLocation} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal" disabled={gettingGPS}>Cancelar</button>
                                <button type="button" onClick={(event) => { geoLocalizacion(event, 'ACTIVAR'); sendEventClick('Causales', 'ACTIVAR'); setGettingGPS(true); }} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader" disabled={gettingGPS}>Activar</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {errorLocation &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Activar localización.</h5>
                                <button onClick={e => setErrorLocation(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="calibri_punto_trader">{errorMessageGPS}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={(event) => { geoLocalizacion(event, 'REINTENTAR'); setErrorLocation(false); sendEventClick('Causales', 'REINTENTAR'); setGettingGPS(true); }} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader" disabled={gettingGPS}>Reintentar</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {loading && <Loader />}
            {showComponentCancel && <Cancelado tipo="location" />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </>
    )
}

export default Causales