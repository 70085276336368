/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const Header = (props) => {

    /* Hooks */
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showVersion, setShowVersion] = useState(false)
    const [hayCookies] = useState(navigator.cookieEnabled)

    useEffect(() => {
        if (hayCookies) {
            const dataOtorganteLS = localStorage.getItem("dataOtorgante")
            if (dataOtorganteLS) {
                setDataOtorgante(JSON.parse(dataOtorganteLS))
                configStyle(JSON.parse(dataOtorganteLS))
            }
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    return (
        <Fragment>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo animate__animated animate__slideInDown" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") !== "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" onClick={() => setShowVersion(true)} />
                <a className="help_btn animate__animated animate__fadeIn animate__delay-1s"><img src={process.env.PUBLIC_URL + '/images/btn_circle_help_call.svg'} alt="" onClick={() => props.show()} /></a>
                {(showVersion) &&
                    <div className="modal fade show" style={{ display: "block" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><b>Versión de la aplicación</b></h5>
                                </div>
                                <div className="modal-body text-justify">
                                    <p>La version de la aplicación actual es: <b>V_2.0.0</b></p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={e => { setShowVersion(false) }} className="btn btn-raised btn-primary button_punto_trader main_bg_color" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </Fragment>
    );
}

export default Header;