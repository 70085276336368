export const constantEntidadesFederativas = [
    {
        "id": 1,
        "id_estado_az": 1,
        "descripcion": "Aguascalientes"
    },
    {
        "id": 2,
        "id_estado_az": 2,
        "descripcion": "Baja California Norte"
    },
    {
        "id": 3,
        "id_estado_az": 3,
        "descripcion": "Baja California Sur"
    },
    {
        "id": 4,
        "id_estado_az": 4,
        "descripcion": "Campeche"
    },
    {
        "id": 5,
        "id_estado_az": 5,
        "descripcion": "Coahuila"
    },
    {
        "id": 6,
        "id_estado_az": 6,
        "descripcion": "Colima"
    },
    {
        "id": 7,
        "id_estado_az": 7,
        "descripcion": "Chiapas"
    },
    {
        "id": 8,
        "id_estado_az": 8,
        "descripcion": "Chihuahua"
    },
    {
        "id": 9,
        "id_estado_az": 9,
        "descripcion": "Ciudad De México"
    },
    {
        "id": 10,
        "id_estado_az": 10,
        "descripcion": "Durango"
    },
    {
        "id": 11,
        "id_estado_az": 11,
        "descripcion": "Guanajuato"
    },
    {
        "id": 12,
        "id_estado_az": 12,
        "descripcion": "Guerrero"
    },
    {
        "id": 13,
        "id_estado_az": 13,
        "descripcion": "Hidalgo"
    },
    {
        "id": 14,
        "id_estado_az": 14,
        "descripcion": "Jalisco"
    },
    {
        "id": 15,
        "id_estado_az": 15,
        "descripcion": "México"
    },
    {
        "id": 16,
        "id_estado_az": 16,
        "descripcion": "Michoacan"
    },
    {
        "id": 17,
        "id_estado_az": 17,
        "descripcion": "Morelos"
    },
    {
        "id": 18,
        "id_estado_az": 18,
        "descripcion": "Nayarit"
    },
    {
        "id": 19,
        "id_estado_az": 19,
        "descripcion": "Nuevo Leon"
    },
    {
        "id": 20,
        "id_estado_az": 20,
        "descripcion": "Oaxaca"
    },
    {
        "id": 21,
        "id_estado_az": 21,
        "descripcion": "Puebla"
    },
    {
        "id": 22,
        "id_estado_az": 22,
        "descripcion": "Queretaro"
    },
    {
        "id": 23,
        "id_estado_az": 23,
        "descripcion": "Quintana Roo"
    },
    {
        "id": 24,
        "id_estado_az": 24,
        "descripcion": "San Luis Potosi"
    },
    {
        "id": 25,
        "id_estado_az": 25,
        "descripcion": "Sinaloa"
    },
    {
        "id": 26,
        "id_estado_az": 26,
        "descripcion": "Sonora"
    },
    {
        "id": 27,
        "id_estado_az": 27,
        "descripcion": "Tabasco"
    },
    {
        "id": 28,
        "id_estado_az": 28,
        "descripcion": "Tamaulipas"
    },
    {
        "id": 29,
        "id_estado_az": 29,
        "descripcion": "Tlaxcala"
    },
    {
        "id": 30,
        "id_estado_az": 30,
        "descripcion": "Veracruz"
    },
    {
        "id": 31,
        "id_estado_az": 31,
        "descripcion": "Yucatan"
    },
    {
        "id": 32,
        "id_estado_az": 32,
        "descripcion": "Zacatecas"
    },
    {
        "id": 33,
        "id_estado_az": 99,
        "descripcion": "Migración"
    }
];

export const constantPaises = [
    {
        "id": 1,
        "id_pais_az": 4,
        "descripcion": "Afganistan"
    },
    {
        "id": 2,
        "id_pais_az": 8,
        "descripcion": "Albania"
    },
    {
        "id": 3,
        "id_pais_az": 12,
        "descripcion": "Argelia"
    },
    {
        "id": 4,
        "id_pais_az": 16,
        "descripcion": "Samoa Americana"
    },
    {
        "id": 5,
        "id_pais_az": 20,
        "descripcion": "Andorra"
    },
    {
        "id": 6,
        "id_pais_az": 24,
        "descripcion": "Angola"
    },
    {
        "id": 7,
        "id_pais_az": 28,
        "descripcion": "Antigua Y Barbuda"
    },
    {
        "id": 8,
        "id_pais_az": 31,
        "descripcion": "Azerbaiyan"
    },
    {
        "id": 9,
        "id_pais_az": 32,
        "descripcion": "Argentina"
    },
    {
        "id": 10,
        "id_pais_az": 36,
        "descripcion": "Australia"
    },
    {
        "id": 11,
        "id_pais_az": 40,
        "descripcion": "Austria"
    },
    {
        "id": 12,
        "id_pais_az": 44,
        "descripcion": "Bahamas"
    },
    {
        "id": 13,
        "id_pais_az": 48,
        "descripcion": "Bahrein"
    },
    {
        "id": 14,
        "id_pais_az": 50,
        "descripcion": "Bangladesh"
    },
    {
        "id": 15,
        "id_pais_az": 51,
        "descripcion": "Republica De Armenia"
    },
    {
        "id": 16,
        "id_pais_az": 52,
        "descripcion": "Barbados"
    },
    {
        "id": 17,
        "id_pais_az": 56,
        "descripcion": "Belgica"
    },
    {
        "id": 18,
        "id_pais_az": 60,
        "descripcion": "Bermudas"
    },
    {
        "id": 19,
        "id_pais_az": 64,
        "descripcion": "Butan"
    },
    {
        "id": 20,
        "id_pais_az": 68,
        "descripcion": "Bolivia"
    },
    {
        "id": 21,
        "id_pais_az": 70,
        "descripcion": "Bosnia Herzegobina"
    },
    {
        "id": 22,
        "id_pais_az": 72,
        "descripcion": "Botswana"
    },
    {
        "id": 23,
        "id_pais_az": 76,
        "descripcion": "Brasil"
    },
    {
        "id": 24,
        "id_pais_az": 84,
        "descripcion": "Belice"
    },
    {
        "id": 25,
        "id_pais_az": 86,
        "descripcion": "Territorio Britanico Del Oceano Indico"
    },
    {
        "id": 26,
        "id_pais_az": 90,
        "descripcion": "Islas Salomon"
    },
    {
        "id": 27,
        "id_pais_az": 96,
        "descripcion": "Brunei Darussalam"
    },
    {
        "id": 28,
        "id_pais_az": 100,
        "descripcion": "Bulgaria"
    },
    {
        "id": 29,
        "id_pais_az": 104,
        "descripcion": "Myanmar"
    },
    {
        "id": 30,
        "id_pais_az": 108,
        "descripcion": "Burundi"
    },
    {
        "id": 31,
        "id_pais_az": 112,
        "descripcion": "Bielorrusia"
    },
    {
        "id": 32,
        "id_pais_az": 116,
        "descripcion": "Camboya"
    },
    {
        "id": 33,
        "id_pais_az": 120,
        "descripcion": "Camerun"
    },
    {
        "id": 34,
        "id_pais_az": 124,
        "descripcion": "Canada"
    },
    {
        "id": 35,
        "id_pais_az": 132,
        "descripcion": "Cabo Verde"
    },
    {
        "id": 36,
        "id_pais_az": 136,
        "descripcion": "Cayman Islands"
    },
    {
        "id": 37,
        "id_pais_az": 140,
        "descripcion": "Rep. Centro Africana"
    },
    {
        "id": 38,
        "id_pais_az": 144,
        "descripcion": "Sri Lanka"
    },
    {
        "id": 39,
        "id_pais_az": 148,
        "descripcion": "Chad"
    },
    {
        "id": 40,
        "id_pais_az": 152,
        "descripcion": "Chile"
    },
    {
        "id": 41,
        "id_pais_az": 156,
        "descripcion": "China"
    },
    {
        "id": 42,
        "id_pais_az": 158,
        "descripcion": "Taiwan"
    },
    {
        "id": 43,
        "id_pais_az": 162,
        "descripcion": "Isla De Navidad"
    },
    {
        "id": 44,
        "id_pais_az": 166,
        "descripcion": "Islas Cocos"
    },
    {
        "id": 45,
        "id_pais_az": 170,
        "descripcion": "Colombia"
    },
    {
        "id": 46,
        "id_pais_az": 174,
        "descripcion": "Comoras"
    },
    {
        "id": 47,
        "id_pais_az": 175,
        "descripcion": "Mayotte"
    },
    {
        "id": 48,
        "id_pais_az": 178,
        "descripcion": "Congo"
    },
    {
        "id": 49,
        "id_pais_az": 180,
        "descripcion": "Republica Democratica Del Congo"
    },
    {
        "id": 50,
        "id_pais_az": 184,
        "descripcion": "Islas Cook"
    },
    {
        "id": 51,
        "id_pais_az": 188,
        "descripcion": "Costa Rica"
    },
    {
        "id": 52,
        "id_pais_az": 191,
        "descripcion": "Croacia"
    },
    {
        "id": 53,
        "id_pais_az": 192,
        "descripcion": "Cuba"
    },
    {
        "id": 54,
        "id_pais_az": 196,
        "descripcion": "Chipre"
    },
    {
        "id": 55,
        "id_pais_az": 203,
        "descripcion": "Checoslovaquia"
    },
    {
        "id": 56,
        "id_pais_az": 204,
        "descripcion": "Benin"
    },
    {
        "id": 57,
        "id_pais_az": 208,
        "descripcion": "Dinamarca"
    },
    {
        "id": 58,
        "id_pais_az": 212,
        "descripcion": "Dominica"
    },
    {
        "id": 59,
        "id_pais_az": 214,
        "descripcion": "Republica Dominicana"
    },
    {
        "id": 60,
        "id_pais_az": 218,
        "descripcion": "Ecuador"
    },
    {
        "id": 61,
        "id_pais_az": 222,
        "descripcion": "El Salvador"
    },
    {
        "id": 62,
        "id_pais_az": 226,
        "descripcion": "Guinea Ecuatorial"
    },
    {
        "id": 63,
        "id_pais_az": 231,
        "descripcion": "Etiopia"
    },
    {
        "id": 64,
        "id_pais_az": 232,
        "descripcion": "Eritrea"
    },
    {
        "id": 65,
        "id_pais_az": 233,
        "descripcion": "Estonia"
    },
    {
        "id": 66,
        "id_pais_az": 234,
        "descripcion": "Islas Feroe"
    },
    {
        "id": 67,
        "id_pais_az": 238,
        "descripcion": "Islas Malvinas"
    },
    {
        "id": 68,
        "id_pais_az": 239,
        "descripcion": "Islas Georgias Del Sur Y Sandwich Del Sur"
    },
    {
        "id": 69,
        "id_pais_az": 242,
        "descripcion": "Fiyi"
    },
    {
        "id": 70,
        "id_pais_az": 246,
        "descripcion": "Finlandia"
    },
    {
        "id": 71,
        "id_pais_az": 250,
        "descripcion": "Francia"
    },
    {
        "id": 72,
        "id_pais_az": 254,
        "descripcion": "Guayana Francesa"
    },
    {
        "id": 73,
        "id_pais_az": 258,
        "descripcion": "Polinesia Francesa"
    },
    {
        "id": 74,
        "id_pais_az": 260,
        "descripcion": "Territorios Australes Franceses"
    },
    {
        "id": 75,
        "id_pais_az": 262,
        "descripcion": "República De Yibuti"
    },
    {
        "id": 76,
        "id_pais_az": 266,
        "descripcion": "Gabon"
    },
    {
        "id": 77,
        "id_pais_az": 268,
        "descripcion": "Georgia"
    },
    {
        "id": 78,
        "id_pais_az": 270,
        "descripcion": "Gambia"
    },
    {
        "id": 79,
        "id_pais_az": 276,
        "descripcion": "Alemania"
    },
    {
        "id": 80,
        "id_pais_az": 288,
        "descripcion": "Ghana"
    },
    {
        "id": 81,
        "id_pais_az": 292,
        "descripcion": "Gibraltar"
    },
    {
        "id": 82,
        "id_pais_az": 296,
        "descripcion": "Kiribati"
    },
    {
        "id": 83,
        "id_pais_az": 300,
        "descripcion": "Grecia"
    },
    {
        "id": 84,
        "id_pais_az": 304,
        "descripcion": "Groenlandia"
    },
    {
        "id": 85,
        "id_pais_az": 308,
        "descripcion": "Granada"
    },
    {
        "id": 86,
        "id_pais_az": 312,
        "descripcion": "Guada"
    },
    {
        "id": 87,
        "id_pais_az": 316,
        "descripcion": "Guam"
    },
    {
        "id": 88,
        "id_pais_az": 320,
        "descripcion": "Guatemala"
    },
    {
        "id": 89,
        "id_pais_az": 324,
        "descripcion": "Guinea"
    },
    {
        "id": 90,
        "id_pais_az": 328,
        "descripcion": "Guyana"
    },
    {
        "id": 91,
        "id_pais_az": 332,
        "descripcion": "Haiti"
    },
    {
        "id": 92,
        "id_pais_az": 336,
        "descripcion": "Estado De La Ciudad Del Vaticano"
    },
    {
        "id": 93,
        "id_pais_az": 340,
        "descripcion": "Honduras"
    },
    {
        "id": 94,
        "id_pais_az": 348,
        "descripcion": "Hungria"
    },
    {
        "id": 95,
        "id_pais_az": 352,
        "descripcion": "Islandia"
    },
    {
        "id": 96,
        "id_pais_az": 356,
        "descripcion": "India"
    },
    {
        "id": 97,
        "id_pais_az": 360,
        "descripcion": "Indonesia"
    },
    {
        "id": 98,
        "id_pais_az": 364,
        "descripcion": "Iran"
    },
    {
        "id": 99,
        "id_pais_az": 368,
        "descripcion": "Irak"
    },
    {
        "id": 100,
        "id_pais_az": 372,
        "descripcion": "Irlanda"
    },
    {
        "id": 101,
        "id_pais_az": 376,
        "descripcion": "Israel"
    },
    {
        "id": 102,
        "id_pais_az": 380,
        "descripcion": "Italia"
    },
    {
        "id": 103,
        "id_pais_az": 384,
        "descripcion": "Costa De Marfil"
    },
    {
        "id": 104,
        "id_pais_az": 388,
        "descripcion": "Jamaica"
    },
    {
        "id": 105,
        "id_pais_az": 392,
        "descripcion": "Japon"
    },
    {
        "id": 106,
        "id_pais_az": 400,
        "descripcion": "Jordania"
    },
    {
        "id": 107,
        "id_pais_az": 404,
        "descripcion": "Kenia"
    },
    {
        "id": 108,
        "id_pais_az": 408,
        "descripcion": "Korea Del Norte"
    },
    {
        "id": 109,
        "id_pais_az": 410,
        "descripcion": "Korea Del Sur"
    },
    {
        "id": 110,
        "id_pais_az": 414,
        "descripcion": "Kuwait"
    },
    {
        "id": 111,
        "id_pais_az": 417,
        "descripcion": "Kirguistan"
    },
    {
        "id": 112,
        "id_pais_az": 418,
        "descripcion": "Laos"
    },
    {
        "id": 113,
        "id_pais_az": 422,
        "descripcion": "Libano"
    },
    {
        "id": 114,
        "id_pais_az": 426,
        "descripcion": "Lesotho"
    },
    {
        "id": 115,
        "id_pais_az": 428,
        "descripcion": "Letonia"
    },
    {
        "id": 116,
        "id_pais_az": 430,
        "descripcion": "Liberia"
    },
    {
        "id": 117,
        "id_pais_az": 434,
        "descripcion": "Libia"
    },
    {
        "id": 118,
        "id_pais_az": 435,
        "descripcion": "Ucrania"
    },
    {
        "id": 119,
        "id_pais_az": 438,
        "descripcion": "Liechtenstein"
    },
    {
        "id": 120,
        "id_pais_az": 440,
        "descripcion": "Republica De Lituania"
    },
    {
        "id": 121,
        "id_pais_az": 442,
        "descripcion": "Luxemburgo"
    },
    {
        "id": 122,
        "id_pais_az": 446,
        "descripcion": "Macao"
    },
    {
        "id": 123,
        "id_pais_az": 450,
        "descripcion": "Madagascar"
    },
    {
        "id": 124,
        "id_pais_az": 454,
        "descripcion": "Malawi"
    },
    {
        "id": 125,
        "id_pais_az": 458,
        "descripcion": "Malasia"
    },
    {
        "id": 126,
        "id_pais_az": 462,
        "descripcion": "Maldivas"
    },
    {
        "id": 127,
        "id_pais_az": 466,
        "descripcion": "Mali"
    },
    {
        "id": 128,
        "id_pais_az": 470,
        "descripcion": "Malta"
    },
    {
        "id": 129,
        "id_pais_az": 474,
        "descripcion": "Martinica"
    },
    {
        "id": 130,
        "id_pais_az": 478,
        "descripcion": "Mauritania"
    },
    {
        "id": 131,
        "id_pais_az": 480,
        "descripcion": "Mauricio"
    },
    {
        "id": 132,
        "id_pais_az": 484,
        "descripcion": "México"
    },
    {
        "id": 133,
        "id_pais_az": 492,
        "descripcion": "Monaco"
    },
    {
        "id": 134,
        "id_pais_az": 496,
        "descripcion": "Mongolia"
    },
    {
        "id": 135,
        "id_pais_az": 498,
        "descripcion": "Moldavia"
    },
    {
        "id": 136,
        "id_pais_az": 500,
        "descripcion": "Montserrat"
    },
    {
        "id": 137,
        "id_pais_az": 504,
        "descripcion": "Marruecos"
    },
    {
        "id": 138,
        "id_pais_az": 508,
        "descripcion": "Mozambique"
    },
    {
        "id": 139,
        "id_pais_az": 512,
        "descripcion": "Oman"
    },
    {
        "id": 140,
        "id_pais_az": 516,
        "descripcion": "Namibia"
    },
    {
        "id": 141,
        "id_pais_az": 520,
        "descripcion": "Nauru"
    },
    {
        "id": 142,
        "id_pais_az": 524,
        "descripcion": "Nepal"
    },
    {
        "id": 143,
        "id_pais_az": 528,
        "descripcion": "Holanda"
    },
    {
        "id": 144,
        "id_pais_az": 530,
        "descripcion": "Antillas Neerlandesas"
    },
    {
        "id": 145,
        "id_pais_az": 533,
        "descripcion": "Aruba"
    },
    {
        "id": 146,
        "id_pais_az": 540,
        "descripcion": "Nueva Caledonia"
    },
    {
        "id": 147,
        "id_pais_az": 548,
        "descripcion": "Vanuatu"
    },
    {
        "id": 148,
        "id_pais_az": 554,
        "descripcion": "Nueva Zelanda"
    },
    {
        "id": 149,
        "id_pais_az": 558,
        "descripcion": "Nicaragua"
    },
    {
        "id": 150,
        "id_pais_az": 562,
        "descripcion": "Niger"
    },
    {
        "id": 151,
        "id_pais_az": 566,
        "descripcion": "Nigeria"
    },
    {
        "id": 152,
        "id_pais_az": 570,
        "descripcion": "Isla Niue"
    },
    {
        "id": 153,
        "id_pais_az": 574,
        "descripcion": "Isla Norfolk"
    },
    {
        "id": 154,
        "id_pais_az": 578,
        "descripcion": "Noruega"
    },
    {
        "id": 155,
        "id_pais_az": 580,
        "descripcion": "Islas Marianas Del Norte"
    },
    {
        "id": 156,
        "id_pais_az": 583,
        "descripcion": "Micronesia"
    },
    {
        "id": 157,
        "id_pais_az": 584,
        "descripcion": "Islas Marshall"
    },
    {
        "id": 158,
        "id_pais_az": 585,
        "descripcion": "Palaos"
    },
    {
        "id": 159,
        "id_pais_az": 586,
        "descripcion": "Paquistan"
    },
    {
        "id": 160,
        "id_pais_az": 591,
        "descripcion": "Panama"
    },
    {
        "id": 161,
        "id_pais_az": 598,
        "descripcion": "Papua Nueva Guinea"
    },
    {
        "id": 162,
        "id_pais_az": 600,
        "descripcion": "Paraguay"
    },
    {
        "id": 163,
        "id_pais_az": 604,
        "descripcion": "Peru"
    },
    {
        "id": 164,
        "id_pais_az": 608,
        "descripcion": "Filipinas"
    },
    {
        "id": 165,
        "id_pais_az": 612,
        "descripcion": "Islas Pitcairn"
    },
    {
        "id": 166,
        "id_pais_az": 616,
        "descripcion": "Polonia"
    },
    {
        "id": 167,
        "id_pais_az": 620,
        "descripcion": "Portugal"
    },
    {
        "id": 168,
        "id_pais_az": 624,
        "descripcion": "Guinea Bissau"
    },
    {
        "id": 169,
        "id_pais_az": 626,
        "descripcion": "Timor Oriental"
    },
    {
        "id": 170,
        "id_pais_az": 630,
        "descripcion": "Puerto Rico"
    },
    {
        "id": 171,
        "id_pais_az": 631,
        "descripcion": "Trinidad Y Tobago"
    },
    {
        "id": 172,
        "id_pais_az": 634,
        "descripcion": "Qatar"
    },
    {
        "id": 173,
        "id_pais_az": 638,
        "descripcion": "Reunion"
    },
    {
        "id": 174,
        "id_pais_az": 642,
        "descripcion": "Rumania"
    },
    {
        "id": 175,
        "id_pais_az": 643,
        "descripcion": "Federacion Rusa"
    },
    {
        "id": 176,
        "id_pais_az": 646,
        "descripcion": "Ruanda"
    },
    {
        "id": 177,
        "id_pais_az": 654,
        "descripcion": "Santa Helena"
    },
    {
        "id": 178,
        "id_pais_az": 659,
        "descripcion": "San Cristobal Y Nieves"
    },
    {
        "id": 179,
        "id_pais_az": 660,
        "descripcion": "Anguila"
    },
    {
        "id": 180,
        "id_pais_az": 662,
        "descripcion": "Santa Lucia"
    },
    {
        "id": 181,
        "id_pais_az": 666,
        "descripcion": "San Pedro Y Miquelon"
    },
    {
        "id": 182,
        "id_pais_az": 670,
        "descripcion": "San Vicente Y Las Granadinas"
    },
    {
        "id": 183,
        "id_pais_az": 674,
        "descripcion": "República De San Marino"
    },
    {
        "id": 184,
        "id_pais_az": 678,
        "descripcion": "Santo Tome Y Principe"
    },
    {
        "id": 185,
        "id_pais_az": 682,
        "descripcion": "Arabia"
    },
    {
        "id": 186,
        "id_pais_az": 686,
        "descripcion": "Senegal"
    },
    {
        "id": 187,
        "id_pais_az": 688,
        "descripcion": "Serbia"
    },
    {
        "id": 188,
        "id_pais_az": 690,
        "descripcion": "Seychelles"
    },
    {
        "id": 189,
        "id_pais_az": 694,
        "descripcion": "Sierra Leona"
    },
    {
        "id": 190,
        "id_pais_az": 702,
        "descripcion": "Singapur"
    },
    {
        "id": 191,
        "id_pais_az": 703,
        "descripcion": "Eslovaquia"
    },
    {
        "id": 192,
        "id_pais_az": 704,
        "descripcion": "Vietnam"
    },
    {
        "id": 193,
        "id_pais_az": 705,
        "descripcion": "Eslovenia"
    },
    {
        "id": 194,
        "id_pais_az": 706,
        "descripcion": "Somalia"
    },
    {
        "id": 195,
        "id_pais_az": 710,
        "descripcion": "Sud Africa"
    },
    {
        "id": 196,
        "id_pais_az": 716,
        "descripcion": "Zimbabwe"
    },
    {
        "id": 197,
        "id_pais_az": 724,
        "descripcion": "España"
    },
    {
        "id": 198,
        "id_pais_az": 732,
        "descripcion": "Sahara Occidental"
    },
    {
        "id": 199,
        "id_pais_az": 736,
        "descripcion": "Sudan"
    },
    {
        "id": 200,
        "id_pais_az": 740,
        "descripcion": "Surinam"
    },
    {
        "id": 201,
        "id_pais_az": 744,
        "descripcion": "Islas Svalbard Y Jan Mayen"
    },
    {
        "id": 202,
        "id_pais_az": 748,
        "descripcion": "Swazilandia"
    },
    {
        "id": 203,
        "id_pais_az": 752,
        "descripcion": "Suecia"
    },
    {
        "id": 204,
        "id_pais_az": 756,
        "descripcion": "Suiza"
    },
    {
        "id": 205,
        "id_pais_az": 760,
        "descripcion": "Siria"
    },
    {
        "id": 206,
        "id_pais_az": 762,
        "descripcion": "Tayikistan"
    },
    {
        "id": 207,
        "id_pais_az": 764,
        "descripcion": "Tailandia"
    },
    {
        "id": 208,
        "id_pais_az": 768,
        "descripcion": "Togo"
    },
    {
        "id": 209,
        "id_pais_az": 772,
        "descripcion": "Tokelau"
    },
    {
        "id": 210,
        "id_pais_az": 776,
        "descripcion": "Tonga"
    },
    {
        "id": 211,
        "id_pais_az": 784,
        "descripcion": "Emiratos Arabes Unidos"
    },
    {
        "id": 212,
        "id_pais_az": 788,
        "descripcion": "Tunez"
    },
    {
        "id": 213,
        "id_pais_az": 792,
        "descripcion": "Turquia"
    },
    {
        "id": 214,
        "id_pais_az": 795,
        "descripcion": "Turkmenistan"
    },
    {
        "id": 215,
        "id_pais_az": 796,
        "descripcion": "Islas Turcas Y Caicos"
    },
    {
        "id": 216,
        "id_pais_az": 798,
        "descripcion": "Tuvalu"
    },
    {
        "id": 217,
        "id_pais_az": 800,
        "descripcion": "Uganda"
    },
    {
        "id": 218,
        "id_pais_az": 807,
        "descripcion": "Macedonia"
    },
    {
        "id": 219,
        "id_pais_az": 818,
        "descripcion": "Egipto"
    },
    {
        "id": 220,
        "id_pais_az": 826,
        "descripcion": "Inglaterra"
    },
    {
        "id": 221,
        "id_pais_az": 832,
        "descripcion": "Jersey"
    },
    {
        "id": 222,
        "id_pais_az": 834,
        "descripcion": "Tanzania"
    },
    {
        "id": 223,
        "id_pais_az": 840,
        "descripcion": "Estados Unidos De Norteamerica"
    },
    {
        "id": 224,
        "id_pais_az": 854,
        "descripcion": "Burkina Faso"
    },
    {
        "id": 225,
        "id_pais_az": 858,
        "descripcion": "Uruguay"
    },
    {
        "id": 226,
        "id_pais_az": 860,
        "descripcion": "Uzbekistan"
    },
    {
        "id": 227,
        "id_pais_az": 862,
        "descripcion": "Venezuela"
    },
    {
        "id": 228,
        "id_pais_az": 876,
        "descripcion": "Islas Wallis Y Futuna"
    },
    {
        "id": 229,
        "id_pais_az": 882,
        "descripcion": "Samoa"
    },
    {
        "id": 230,
        "id_pais_az": 887,
        "descripcion": "Yemen"
    },
    {
        "id": 231,
        "id_pais_az": 894,
        "descripcion": "Zambia"
    }
];

export const constantNacionalidades = [
    {
        "id": 1,
        "id_nac_az": 1,
        "abreviacion": "Afg",
        "descripcion": "Afgano"
    },
    {
        "id": 2,
        "id_nac_az": 2,
        "abreviacion": "Alb",
        "descripcion": "Albanes"
    },
    {
        "id": 3,
        "id_nac_az": 3,
        "abreviacion": "Ale",
        "descripcion": "Alemana"
    },
    {
        "id": 4,
        "id_nac_az": 5,
        "abreviacion": "And",
        "descripcion": "Andorrano"
    },
    {
        "id": 5,
        "id_nac_az": 6,
        "abreviacion": "Ang",
        "descripcion": "Angoleno"
    },
    {
        "id": 6,
        "id_nac_az": 7,
        "abreviacion": "Ang",
        "descripcion": "Anguila"
    },
    {
        "id": 7,
        "id_nac_az": 8,
        "abreviacion": "Ant",
        "descripcion": "Antiguano"
    },
    {
        "id": 8,
        "id_nac_az": 9,
        "abreviacion": "Ant",
        "descripcion": "Antillas Neerlandesas"
    },
    {
        "id": 9,
        "id_nac_az": 10,
        "abreviacion": "Sau",
        "descripcion": "Saudi"
    },
    {
        "id": 10,
        "id_nac_az": 12,
        "abreviacion": "Arg",
        "descripcion": "Argelino"
    },
    {
        "id": 11,
        "id_nac_az": 13,
        "abreviacion": "Arg",
        "descripcion": "Argentina"
    },
    {
        "id": 12,
        "id_nac_az": 14,
        "abreviacion": "Arm",
        "descripcion": "Armenio"
    },
    {
        "id": 13,
        "id_nac_az": 15,
        "abreviacion": "Aru",
        "descripcion": "Arubena"
    },
    {
        "id": 14,
        "id_nac_az": 17,
        "abreviacion": "Aus",
        "descripcion": "Australiana"
    },
    {
        "id": 15,
        "id_nac_az": 18,
        "abreviacion": "Aus",
        "descripcion": "Austriaca"
    },
    {
        "id": 16,
        "id_nac_az": 19,
        "abreviacion": "Aze",
        "descripcion": "Azerbaiyano"
    },
    {
        "id": 17,
        "id_nac_az": 20,
        "abreviacion": "Bah",
        "descripcion": "Bahamena"
    },
    {
        "id": 18,
        "id_nac_az": 21,
        "abreviacion": "Bah",
        "descripcion": "Bahreini"
    },
    {
        "id": 19,
        "id_nac_az": 22,
        "abreviacion": "Ban",
        "descripcion": "Bangladesi"
    },
    {
        "id": 20,
        "id_nac_az": 23,
        "abreviacion": "Bar",
        "descripcion": "Barbadense"
    },
    {
        "id": 21,
        "id_nac_az": 24,
        "abreviacion": "Bie",
        "descripcion": "Bielorusa"
    },
    {
        "id": 22,
        "id_nac_az": 25,
        "abreviacion": "Bel",
        "descripcion": "Belga"
    },
    {
        "id": 23,
        "id_nac_az": 26,
        "abreviacion": "Bel",
        "descripcion": "Beliceño"
    },
    {
        "id": 24,
        "id_nac_az": 27,
        "abreviacion": "Ber",
        "descripcion": "Bermudenos"
    },
    {
        "id": 25,
        "id_nac_az": 28,
        "abreviacion": "Ben",
        "descripcion": "Benines"
    },
    {
        "id": 26,
        "id_nac_az": 29,
        "abreviacion": "Bol",
        "descripcion": "Boliviano"
    },
    {
        "id": 27,
        "id_nac_az": 30,
        "abreviacion": "Bos",
        "descripcion": "Bosnio"
    },
    {
        "id": 28,
        "id_nac_az": 31,
        "abreviacion": "Bot",
        "descripcion": "Botsuano"
    },
    {
        "id": 29,
        "id_nac_az": 32,
        "abreviacion": "Bra",
        "descripcion": "Brasileña"
    },
    {
        "id": 30,
        "id_nac_az": 33,
        "abreviacion": "Bru",
        "descripcion": "Bruneano"
    },
    {
        "id": 31,
        "id_nac_az": 34,
        "abreviacion": "Bul",
        "descripcion": "Bulgaro"
    },
    {
        "id": 32,
        "id_nac_az": 35,
        "abreviacion": "Bur",
        "descripcion": "Burundes"
    },
    {
        "id": 33,
        "id_nac_az": 36,
        "abreviacion": "But",
        "descripcion": "Butanes"
    },
    {
        "id": 34,
        "id_nac_az": 37,
        "abreviacion": "Cam",
        "descripcion": "Camerunes"
    },
    {
        "id": 35,
        "id_nac_az": 38,
        "abreviacion": "Cab",
        "descripcion": "Caboverdiano"
    },
    {
        "id": 36,
        "id_nac_az": 39,
        "abreviacion": "Can",
        "descripcion": "Canadiense"
    },
    {
        "id": 37,
        "id_nac_az": 40,
        "abreviacion": "Col",
        "descripcion": "Colombiana"
    },
    {
        "id": 38,
        "id_nac_az": 41,
        "abreviacion": "Con",
        "descripcion": "Congoleno"
    },
    {
        "id": 39,
        "id_nac_az": 42,
        "abreviacion": "Redem",
        "descripcion": "Coreano"
    },
    {
        "id": 40,
        "id_nac_az": 43,
        "abreviacion": "Corco",
        "descripcion": "Coreano"
    },
    {
        "id": 41,
        "id_nac_az": 44,
        "abreviacion": "Mar",
        "descripcion": "Marfileno"
    },
    {
        "id": 42,
        "id_nac_az": 45,
        "abreviacion": "Cos",
        "descripcion": "Costarricense"
    },
    {
        "id": 43,
        "id_nac_az": 46,
        "abreviacion": "Cro",
        "descripcion": "Croata"
    },
    {
        "id": 44,
        "id_nac_az": 47,
        "abreviacion": "Cub",
        "descripcion": "Cubana"
    },
    {
        "id": 45,
        "id_nac_az": 48,
        "abreviacion": "Cam",
        "descripcion": "Camboyano"
    },
    {
        "id": 46,
        "id_nac_az": 50,
        "abreviacion": "Che",
        "descripcion": "Checoslovaco"
    },
    {
        "id": 47,
        "id_nac_az": 51,
        "abreviacion": "Chi",
        "descripcion": "Chilena"
    },
    {
        "id": 48,
        "id_nac_az": 52,
        "abreviacion": "Chi",
        "descripcion": "China"
    },
    {
        "id": 49,
        "id_nac_az": 53,
        "abreviacion": "Chip",
        "descripcion": "Chipriota"
    },
    {
        "id": 50,
        "id_nac_az": 54,
        "abreviacion": "Dan",
        "descripcion": "Danes"
    },
    {
        "id": 51,
        "id_nac_az": 55,
        "abreviacion": "Dom",
        "descripcion": "Dominiques"
    },
    {
        "id": 52,
        "id_nac_az": 56,
        "abreviacion": "Ecu",
        "descripcion": "Ecuatoriano"
    },
    {
        "id": 53,
        "id_nac_az": 57,
        "abreviacion": "Egi",
        "descripcion": "Egipcio"
    },
    {
        "id": 54,
        "id_nac_az": 58,
        "abreviacion": "Sal",
        "descripcion": "Salvadorena"
    },
    {
        "id": 55,
        "id_nac_az": 59,
        "abreviacion": "Ara",
        "descripcion": "Arabe"
    },
    {
        "id": 56,
        "id_nac_az": 60,
        "abreviacion": "Esp",
        "descripcion": "Española"
    },
    {
        "id": 57,
        "id_nac_az": 61,
        "abreviacion": "Kuw",
        "descripcion": "Kuwaiti"
    },
    {
        "id": 58,
        "id_nac_az": 62,
        "abreviacion": "Cat",
        "descripcion": "Catari"
    },
    {
        "id": 59,
        "id_nac_az": 63,
        "abreviacion": "Nor",
        "descripcion": "Estadounidense"
    },
    {
        "id": 60,
        "id_nac_az": 64,
        "abreviacion": "Est",
        "descripcion": "Estonia"
    },
    {
        "id": 61,
        "id_nac_az": 65,
        "abreviacion": "Eti",
        "descripcion": "Etiope"
    },
    {
        "id": 62,
        "id_nac_az": 66,
        "abreviacion": "Fil",
        "descripcion": "Filipino"
    },
    {
        "id": 63,
        "id_nac_az": 67,
        "abreviacion": "Fin",
        "descripcion": "Finlandes"
    },
    {
        "id": 64,
        "id_nac_az": 68,
        "abreviacion": "Fran",
        "descripcion": "Francesa"
    },
    {
        "id": 65,
        "id_nac_az": 69,
        "abreviacion": "Gab",
        "descripcion": "Gabones"
    },
    {
        "id": 66,
        "id_nac_az": 70,
        "abreviacion": "Gam",
        "descripcion": "Gambiano"
    },
    {
        "id": 67,
        "id_nac_az": 71,
        "abreviacion": "Geor",
        "descripcion": "Georgiano"
    },
    {
        "id": 68,
        "id_nac_az": 72,
        "abreviacion": "Gha",
        "descripcion": "Ghanes"
    },
    {
        "id": 69,
        "id_nac_az": 73,
        "abreviacion": "Gib",
        "descripcion": "Gibraltareno"
    },
    {
        "id": 70,
        "id_nac_az": 74,
        "abreviacion": "Gra",
        "descripcion": "Granadino"
    },
    {
        "id": 71,
        "id_nac_az": 75,
        "abreviacion": "Gri",
        "descripcion": "Griega"
    },
    {
        "id": 72,
        "id_nac_az": 76,
        "abreviacion": "Gro",
        "descripcion": "Groelandesa"
    },
    {
        "id": 73,
        "id_nac_az": 77,
        "abreviacion": "Gua",
        "descripcion": "Guameno"
    },
    {
        "id": 74,
        "id_nac_az": 78,
        "abreviacion": "Gua",
        "descripcion": "Guatemalteca"
    },
    {
        "id": 75,
        "id_nac_az": 79,
        "abreviacion": "Gua",
        "descripcion": "Guayanes"
    },
    {
        "id": 76,
        "id_nac_az": 80,
        "abreviacion": "Gui",
        "descripcion": "Guineano"
    },
    {
        "id": 77,
        "id_nac_az": 81,
        "abreviacion": "Ecua",
        "descripcion": "Ecuatoguinenao"
    },
    {
        "id": 78,
        "id_nac_az": 82,
        "abreviacion": "Guine",
        "descripcion": "Guineabissau"
    },
    {
        "id": 79,
        "id_nac_az": 83,
        "abreviacion": "Guaya",
        "descripcion": "Guayanes"
    },
    {
        "id": 80,
        "id_nac_az": 84,
        "abreviacion": "Guada",
        "descripcion": "Guadalupense"
    },
    {
        "id": 81,
        "id_nac_az": 86,
        "abreviacion": "Hait",
        "descripcion": "Haitiano"
    },
    {
        "id": 82,
        "id_nac_az": 87,
        "abreviacion": "Nee",
        "descripcion": "Neerlandes"
    },
    {
        "id": 83,
        "id_nac_az": 88,
        "abreviacion": "Hon",
        "descripcion": "Hondureño"
    },
    {
        "id": 84,
        "id_nac_az": 89,
        "abreviacion": "China",
        "descripcion": "China"
    },
    {
        "id": 85,
        "id_nac_az": 90,
        "abreviacion": "Hun",
        "descripcion": "Hungaro"
    },
    {
        "id": 86,
        "id_nac_az": 91,
        "abreviacion": "Indio",
        "descripcion": "Indio"
    },
    {
        "id": 87,
        "id_nac_az": 92,
        "abreviacion": "Ind",
        "descripcion": "Indonesia"
    },
    {
        "id": 88,
        "id_nac_az": 93,
        "abreviacion": "Brit",
        "descripcion": "Britanico"
    },
    {
        "id": 89,
        "id_nac_az": 94,
        "abreviacion": "Iraki",
        "descripcion": "Iraki"
    },
    {
        "id": 90,
        "id_nac_az": 95,
        "abreviacion": "Irani",
        "descripcion": "Irani"
    },
    {
        "id": 91,
        "id_nac_az": 96,
        "abreviacion": "Irla",
        "descripcion": "Irlandesa"
    },
    {
        "id": 92,
        "id_nac_az": 97,
        "abreviacion": "Caim",
        "descripcion": "Caimanes"
    },
    {
        "id": 93,
        "id_nac_az": 98,
        "abreviacion": "Islnf",
        "descripcion": "Isla De Norfolk"
    },
    {
        "id": 94,
        "id_nac_az": 99,
        "abreviacion": "Islnav",
        "descripcion": "Isla Navidad"
    },
    {
        "id": 95,
        "id_nac_az": 100,
        "abreviacion": "Reu",
        "descripcion": "Reuniones"
    },
    {
        "id": 96,
        "id_nac_az": 101,
        "abreviacion": "Fero",
        "descripcion": "Feroeses"
    },
    {
        "id": 97,
        "id_nac_az": 102,
        "abreviacion": "Nor",
        "descripcion": "Normarianense"
    },
    {
        "id": 98,
        "id_nac_az": 103,
        "abreviacion": "Isl",
        "descripcion": "Islandes"
    },
    {
        "id": 99,
        "id_nac_az": 104,
        "abreviacion": "Islaz",
        "descripcion": "Islas Azores"
    },
    {
        "id": 100,
        "id_nac_az": 105,
        "abreviacion": "Islca",
        "descripcion": "Islas Canarias"
    },
    {
        "id": 101,
        "id_nac_az": 106,
        "abreviacion": "Ava",
        "descripcion": "Avarua"
    },
    {
        "id": 102,
        "id_nac_az": 107,
        "abreviacion": "Aus",
        "descripcion": "Australiana"
    },
    {
        "id": 103,
        "id_nac_az": 108,
        "abreviacion": "Jers",
        "descripcion": "Jerseita"
    },
    {
        "id": 104,
        "id_nac_az": 109,
        "abreviacion": "Malvi",
        "descripcion": "Malvinense"
    },
    {
        "id": 105,
        "id_nac_az": 110,
        "abreviacion": "Mars",
        "descripcion": "Marshales"
    },
    {
        "id": 106,
        "id_nac_az": 111,
        "abreviacion": "Islpa",
        "descripcion": "Islas Pacífico"
    },
    {
        "id": 107,
        "id_nac_az": 112,
        "abreviacion": "Salo",
        "descripcion": "Salomonense"
    },
    {
        "id": 108,
        "id_nac_az": 113,
        "abreviacion": "Turc",
        "descripcion": "Turcocaicones"
    },
    {
        "id": 109,
        "id_nac_az": 114,
        "abreviacion": "Bri",
        "descripcion": "Británica"
    },
    {
        "id": 110,
        "id_nac_az": 115,
        "abreviacion": "Vires",
        "descripcion": "Vírginense Estadounidense"
    },
    {
        "id": 111,
        "id_nac_az": 116,
        "abreviacion": "Isr",
        "descripcion": "Israelita"
    },
    {
        "id": 112,
        "id_nac_az": 117,
        "abreviacion": "Ital",
        "descripcion": "Italiana"
    },
    {
        "id": 113,
        "id_nac_az": 118,
        "abreviacion": "Jama",
        "descripcion": "Jamaicano"
    },
    {
        "id": 114,
        "id_nac_az": 119,
        "abreviacion": "Jap",
        "descripcion": "Japonesa"
    },
    {
        "id": 115,
        "id_nac_az": 120,
        "abreviacion": "Jor",
        "descripcion": "Jordano"
    },
    {
        "id": 116,
        "id_nac_az": 121,
        "abreviacion": "Kaz",
        "descripcion": "Kazajo"
    },
    {
        "id": 117,
        "id_nac_az": 122,
        "abreviacion": "Keni",
        "descripcion": "Keniano"
    },
    {
        "id": 118,
        "id_nac_az": 123,
        "abreviacion": "Kir",
        "descripcion": "Kirguis"
    },
    {
        "id": 119,
        "id_nac_az": 124,
        "abreviacion": "Kiri",
        "descripcion": "Kiribati"
    },
    {
        "id": 120,
        "id_nac_az": 125,
        "abreviacion": "Kuw",
        "descripcion": "Kuwaiti"
    },
    {
        "id": 121,
        "id_nac_az": 126,
        "abreviacion": "Lab",
        "descripcion": "Labuán"
    },
    {
        "id": 122,
        "id_nac_az": 127,
        "abreviacion": "Lao",
        "descripcion": "Laosiano"
    },
    {
        "id": 123,
        "id_nac_az": 128,
        "abreviacion": "Let",
        "descripcion": "Leton"
    },
    {
        "id": 124,
        "id_nac_az": 129,
        "abreviacion": "Les",
        "descripcion": "Lesotense"
    },
    {
        "id": 125,
        "id_nac_az": 130,
        "abreviacion": "Liba",
        "descripcion": "Libanes"
    },
    {
        "id": 126,
        "id_nac_az": 131,
        "abreviacion": "Libe",
        "descripcion": "Liberiano"
    },
    {
        "id": 127,
        "id_nac_az": 132,
        "abreviacion": "Lib",
        "descripcion": "Libia"
    },
    {
        "id": 128,
        "id_nac_az": 133,
        "abreviacion": "Liec",
        "descripcion": "Liechensteiniano"
    },
    {
        "id": 129,
        "id_nac_az": 134,
        "abreviacion": "Litu",
        "descripcion": "Lituano"
    },
    {
        "id": 130,
        "id_nac_az": 135,
        "abreviacion": "Lux",
        "descripcion": "Luxemburgues"
    },
    {
        "id": 131,
        "id_nac_az": 136,
        "abreviacion": "Portu",
        "descripcion": "Portugues"
    },
    {
        "id": 132,
        "id_nac_az": 137,
        "abreviacion": "Mac",
        "descripcion": "Macedonia"
    },
    {
        "id": 133,
        "id_nac_az": 138,
        "abreviacion": "Mal",
        "descripcion": "Malgache"
    },
    {
        "id": 134,
        "id_nac_az": 139,
        "abreviacion": "Mala",
        "descripcion": "Malasio"
    },
    {
        "id": 135,
        "id_nac_az": 140,
        "abreviacion": "Mali",
        "descripcion": "Mali"
    },
    {
        "id": 136,
        "id_nac_az": 141,
        "abreviacion": "Mali",
        "descripcion": "Maliense"
    },
    {
        "id": 137,
        "id_nac_az": 142,
        "abreviacion": "Malt",
        "descripcion": "Maltes"
    },
    {
        "id": 138,
        "id_nac_az": 143,
        "abreviacion": "Marr",
        "descripcion": "Marroqui"
    },
    {
        "id": 139,
        "id_nac_az": 144,
        "abreviacion": "Mart",
        "descripcion": "Martiniquais"
    },
    {
        "id": 140,
        "id_nac_az": 145,
        "abreviacion": "Maur",
        "descripcion": "Mauritano"
    },
    {
        "id": 141,
        "id_nac_az": 146,
        "abreviacion": "Mx",
        "descripcion": "Mexicana"
    },
    {
        "id": 142,
        "id_nac_az": 147,
        "abreviacion": "Micr",
        "descripcion": "Micronesia"
    },
    {
        "id": 143,
        "id_nac_az": 148,
        "abreviacion": "Mone",
        "descripcion": "Monegasco"
    },
    {
        "id": 144,
        "id_nac_az": 149,
        "abreviacion": "Mon",
        "descripcion": "Mongol"
    },
    {
        "id": 145,
        "id_nac_az": 150,
        "abreviacion": "Mont",
        "descripcion": "Montserratense"
    },
    {
        "id": 146,
        "id_nac_az": 151,
        "abreviacion": "Moza",
        "descripcion": "Mozambiqueño"
    },
    {
        "id": 147,
        "id_nac_az": 152,
        "abreviacion": "Nami",
        "descripcion": "Namibia"
    },
    {
        "id": 148,
        "id_nac_az": 153,
        "abreviacion": "Nau",
        "descripcion": "Nauruano"
    },
    {
        "id": 149,
        "id_nac_az": 154,
        "abreviacion": "Nepa",
        "descripcion": "Nepales"
    },
    {
        "id": 150,
        "id_nac_az": 156,
        "abreviacion": "Nic",
        "descripcion": "Nicaraguense"
    },
    {
        "id": 151,
        "id_nac_az": 157,
        "abreviacion": "Nig",
        "descripcion": "Nigerino"
    },
    {
        "id": 152,
        "id_nac_az": 158,
        "abreviacion": "Nige",
        "descripcion": "Nigeriano"
    },
    {
        "id": 153,
        "id_nac_az": 159,
        "abreviacion": "Niu",
        "descripcion": "Niuano"
    },
    {
        "id": 154,
        "id_nac_az": 160,
        "abreviacion": "Nor",
        "descripcion": "Noruego"
    },
    {
        "id": 155,
        "id_nac_az": 161,
        "abreviacion": "Nvma",
        "descripcion": "Nueva Caledonia"
    },
    {
        "id": 156,
        "id_nac_az": 162,
        "abreviacion": "Nvaze",
        "descripcion": "Nueva Zelanda"
    },
    {
        "id": 157,
        "id_nac_az": 163,
        "abreviacion": "Omani",
        "descripcion": "Omani"
    },
    {
        "id": 158,
        "id_nac_az": 164,
        "abreviacion": "Palau",
        "descripcion": "Palau"
    },
    {
        "id": 159,
        "id_nac_az": 165,
        "abreviacion": "Panam",
        "descripcion": "Panameno"
    },
    {
        "id": 160,
        "id_nac_az": 166,
        "abreviacion": "Paki",
        "descripcion": "Pakistaní"
    },
    {
        "id": 161,
        "id_nac_az": 167,
        "abreviacion": "Para",
        "descripcion": "Paraguayo"
    },
    {
        "id": 162,
        "id_nac_az": 168,
        "abreviacion": "Peru",
        "descripcion": "Peruano"
    },
    {
        "id": 163,
        "id_nac_az": 169,
        "abreviacion": "Pitca",
        "descripcion": "Pitcairn"
    },
    {
        "id": 164,
        "id_nac_az": 170,
        "abreviacion": "Poli",
        "descripcion": "Polinesio"
    },
    {
        "id": 165,
        "id_nac_az": 171,
        "abreviacion": "Polaca",
        "descripcion": "Polaca"
    },
    {
        "id": 166,
        "id_nac_az": 172,
        "abreviacion": "Por",
        "descripcion": "Portugues"
    },
    {
        "id": 167,
        "id_nac_az": 173,
        "abreviacion": "Puer",
        "descripcion": "Puertorriqueño"
    },
    {
        "id": 168,
        "id_nac_az": 174,
        "abreviacion": "Catari",
        "descripcion": "Catari"
    },
    {
        "id": 169,
        "id_nac_az": 175,
        "abreviacion": "Mol",
        "descripcion": "Moldavo"
    },
    {
        "id": 170,
        "id_nac_az": 176,
        "abreviacion": "Cent",
        "descripcion": "Centroafricana"
    },
    {
        "id": 171,
        "id_nac_az": 177,
        "abreviacion": "Tanza",
        "descripcion": "Tanzano"
    },
    {
        "id": 172,
        "id_nac_az": 178,
        "abreviacion": "Sirio",
        "descripcion": "Sirio"
    },
    {
        "id": 173,
        "id_nac_az": 179,
        "abreviacion": "Yib",
        "descripcion": "Yibutiano"
    },
    {
        "id": 174,
        "id_nac_az": 180,
        "abreviacion": "Domi",
        "descripcion": "Dominicana"
    },
    {
        "id": 175,
        "id_nac_az": 181,
        "abreviacion": "Mald",
        "descripcion": "Maldivo"
    },
    {
        "id": 176,
        "id_nac_az": 182,
        "abreviacion": "Rum",
        "descripcion": "Rumano"
    },
    {
        "id": 177,
        "id_nac_az": 183,
        "abreviacion": "Rus",
        "descripcion": "Ruso"
    },
    {
        "id": 178,
        "id_nac_az": 184,
        "abreviacion": "Rua",
        "descripcion": "Ruandes"
    },
    {
        "id": 179,
        "id_nac_az": 185,
        "abreviacion": "Saha",
        "descripcion": "Saharauis"
    },
    {
        "id": 180,
        "id_nac_az": 186,
        "abreviacion": "Saiki",
        "descripcion": "Saint Kitss"
    },
    {
        "id": 181,
        "id_nac_az": 187,
        "abreviacion": "Sam",
        "descripcion": "Samoano"
    },
    {
        "id": 182,
        "id_nac_az": 189,
        "abreviacion": "San",
        "descripcion": "Sanmarinense"
    },
    {
        "id": 183,
        "id_nac_az": 190,
        "abreviacion": "Dspm",
        "descripcion": "De San Pedro Y Miquelon"
    },
    {
        "id": 184,
        "id_nac_az": 191,
        "abreviacion": "Sanv",
        "descripcion": "Sanvicentino"
    },
    {
        "id": 185,
        "id_nac_az": 192,
        "abreviacion": "Sant",
        "descripcion": "Santaelenero"
    },
    {
        "id": 186,
        "id_nac_az": 193,
        "abreviacion": "Santa",
        "descripcion": "Santalucense"
    },
    {
        "id": 187,
        "id_nac_az": 194,
        "abreviacion": "Seyc",
        "descripcion": "Seychellense"
    },
    {
        "id": 188,
        "id_nac_az": 195,
        "abreviacion": "Santo",
        "descripcion": "Santotomense"
    },
    {
        "id": 189,
        "id_nac_az": 196,
        "abreviacion": "Sene",
        "descripcion": "Senegales"
    },
    {
        "id": 190,
        "id_nac_az": 197,
        "abreviacion": "Serbio",
        "descripcion": "Serbio"
    },
    {
        "id": 191,
        "id_nac_az": 198,
        "abreviacion": "Sier",
        "descripcion": "Sierraleones"
    },
    {
        "id": 192,
        "id_nac_az": 199,
        "abreviacion": "Singa",
        "descripcion": "Singapurense"
    },
    {
        "id": 193,
        "id_nac_az": 200,
        "abreviacion": "Soma",
        "descripcion": "Somali"
    },
    {
        "id": 194,
        "id_nac_az": 201,
        "abreviacion": "Ceilan",
        "descripcion": "Ceilandes"
    },
    {
        "id": 195,
        "id_nac_az": 202,
        "abreviacion": "Suda",
        "descripcion": "Sudafricano"
    },
    {
        "id": 196,
        "id_nac_az": 203,
        "abreviacion": "Eslov",
        "descripcion": "Eslovaco"
    },
    {
        "id": 197,
        "id_nac_az": 204,
        "abreviacion": "Eslove",
        "descripcion": "Esloveno"
    },
    {
        "id": 198,
        "id_nac_az": 205,
        "abreviacion": "Sudan",
        "descripcion": "Sudanes"
    },
    {
        "id": 199,
        "id_nac_az": 207,
        "abreviacion": "Sueco",
        "descripcion": "Sueco"
    },
    {
        "id": 200,
        "id_nac_az": 208,
        "abreviacion": "Suizo",
        "descripcion": "Suizo"
    },
    {
        "id": 201,
        "id_nac_az": 209,
        "abreviacion": "Surin",
        "descripcion": "Surinames"
    },
    {
        "id": 202,
        "id_nac_az": 210,
        "abreviacion": "Swazi",
        "descripcion": "Swazi"
    },
    {
        "id": 203,
        "id_nac_az": 212,
        "abreviacion": "Tai",
        "descripcion": "Tailandesa"
    },
    {
        "id": 204,
        "id_nac_az": 213,
        "abreviacion": "Taiw",
        "descripcion": "Taiwanes"
    },
    {
        "id": 205,
        "id_nac_az": 214,
        "abreviacion": "Tayi",
        "descripcion": "Tayiko"
    },
    {
        "id": 206,
        "id_nac_az": 215,
        "abreviacion": "Timor",
        "descripcion": "Timorense"
    },
    {
        "id": 207,
        "id_nac_az": 216,
        "abreviacion": "Togol",
        "descripcion": "Togoles"
    },
    {
        "id": 208,
        "id_nac_az": 217,
        "abreviacion": "Tokel",
        "descripcion": "Tokelauano"
    },
    {
        "id": 209,
        "id_nac_az": 218,
        "abreviacion": "Tong",
        "descripcion": "Tongano"
    },
    {
        "id": 210,
        "id_nac_az": 219,
        "abreviacion": "Trinit",
        "descripcion": "Trinitario"
    },
    {
        "id": 211,
        "id_nac_az": 220,
        "abreviacion": "Tunec",
        "descripcion": "Tunecino"
    },
    {
        "id": 212,
        "id_nac_az": 221,
        "abreviacion": "Turco",
        "descripcion": "Turcomano"
    },
    {
        "id": 213,
        "id_nac_az": 222,
        "abreviacion": "Turco",
        "descripcion": "Turco"
    },
    {
        "id": 214,
        "id_nac_az": 223,
        "abreviacion": "Tuva",
        "descripcion": "Tuvaluano"
    },
    {
        "id": 215,
        "id_nac_az": 224,
        "abreviacion": "Ucra",
        "descripcion": "Ucraniano"
    },
    {
        "id": 216,
        "id_nac_az": 225,
        "abreviacion": "Ugan",
        "descripcion": "Ugandes"
    },
    {
        "id": 217,
        "id_nac_az": 226,
        "abreviacion": "Uzbek",
        "descripcion": "Uzbeko"
    },
    {
        "id": 218,
        "id_nac_az": 227,
        "abreviacion": "Vanua",
        "descripcion": "Vanuatense"
    },
    {
        "id": 219,
        "id_nac_az": 228,
        "abreviacion": "Vene",
        "descripcion": "Venezuela"
    },
    {
        "id": 220,
        "id_nac_az": 229,
        "abreviacion": "Viet",
        "descripcion": "Vietnam"
    },
    {
        "id": 221,
        "id_nac_az": 230,
        "abreviacion": "Yemeni",
        "descripcion": "Yemeni"
    },
    {
        "id": 222,
        "id_nac_az": 231,
        "abreviacion": "Zambi",
        "descripcion": "Zambiano"
    },
    {
        "id": 223,
        "id_nac_az": 232,
        "abreviacion": "Zimba",
        "descripcion": "Zimbabuense"
    },
    {
        "id": 224,
        "id_nac_az": 234,
        "abreviacion": "Myan",
        "descripcion": "Myanmar"
    },
    {
        "id": 225,
        "id_nac_az": 235,
        "abreviacion": "Mxnac",
        "descripcion": "Mexicano Nacionalizado"
    },
    {
        "id": 226,
        "id_nac_az": 236,
        "abreviacion": "Uru",
        "descripcion": "Uruguaya"
    }
];

export const servicios = [
    // { id: 1, value: 'Gestión de Inversiones' },
    { id: 2, value: 'Ejecución de Operaciones' }
];

export const constantTransaccionalidades = [
    {
        "id": 1,
        "id_depositos_az": 5,
        "descripcion": "De $ 0 - $ 50,000.00"
    },
    {
        "id": 2,
        "id_depositos_az": 6,
        "descripcion": "De $ 50,000.01 - $ 100,000.00"
    },
    {
        "id": 3,
        "id_depositos_az": 7,
        "descripcion": "De $ 100,000.01 - $ 250,000.00"
    },
    {
        "id": 4,
        "id_depositos_az": 8,
        "descripcion": "De $ 250,000.01 - $ 500,000.00"
    },
    {
        "id": 5,
        "id_depositos_az": 9,
        "descripcion": "De $ 500,000.01 - $ 1,000,000.00"
    },
    {
        "id": 6,
        "id_depositos_az": 10,
        "descripcion": "Más De $ 1,000,000.00"
    }
];

export const encuenstaPerfilamiento = [
    [{ answer: 'Menos de 25 años', value: 5 },
    { answer: 'Entre 26 y 35 años', value: 4 },
    { answer: 'Entre 36 y 45 años', value: 3 },
    { answer: 'Entre 46 y 60 años', value: 2 },
    { answer: 'Más de 60 años', value: 1 }],
    [{ answer: 'Secundaria o menos', value: 1 },
    { answer: 'Preparatoria', value: 2 },
    { answer: 'Licenciatura', value: 3 },
    { answer: 'Maestría o un grado mayor', value: 4 }],
    [{ answer: 'Si', value: 2 },
    { answer: 'No', value: 1 }],
    [{ answer: 'Agresiva', value: 3 },
    { answer: 'Moderada', value: 2 },
    { answer: 'Conservadora', value: 1 },
    { answer: 'Ninguna ', value: 0 }],
    [{ answer: 'Sociedades de Inversión de deuda', value: 0, score: 2 },
    { answer: 'Sociedades de Inversión de renta variable', value: 1, score: 3 },
    { answer: 'Instrumentos de deuda', value: 2, score: 1 },
    { answer: 'Acciones de la BMV', value: 3, score: 4 },
    { answer: 'Derivados', value: 4, score: 5 },
    { answer: 'Chequera', value: 5, score: 1 },
    { answer: 'Ninguno ', value: 6, score: 0 }],
    [{ answer: '$1,000 a $10,000', value: 1 },
    { answer: '$10,000 a $100,000', value: 2 },
    { answer: '$100,000 a $1,000,000', value: 3 },
    { answer: '$1,000,000 en adelante', value: 4 }],
    [{ answer: 'Ingresos laborales', value: 2 },
    { answer: 'Venta de un bien', value: 1 },
    { answer: 'Herencia, premios y otros', value: 3 }],
    [{ answer: 'Menos del 25%', value: 2 },
    { answer: 'De 25% al 50%', value: 3 },
    { answer: 'De 50% al 75%', value: 4 },
    { answer: 'De 75% al 100%', value: 5 },
    { answer: 'No deseo proporcionar esta información', value: 1 }],
    [{ answer: 'Ahorrar para un proyecto de vida particular (Estudios, comprar una casa, universidad de hijos)', value: 2 },
    { answer: 'Preparar mi jubilación', value: 1 },
    { answer: 'Obtener rentas periódicas desde un inicio', value: 3 },
    { answer: 'Aprovechar oportunidades de mercado para incrementar considerablemente mi patrimonio', value: 4 }],
    [{ answer: 'Inversión especulativa', value: 4 },
    { answer: 'Inversión de cobertura', value: 3 },
    { answer: 'Inversión patrimonial', value: 2 },
    { answer: 'Es mi primera vez invirtiendo', value: 1 }],
    [{ answer: 'Sí estoy dispuesto', value: 3 },
    { answer: 'Algunas veces estaría dispuesto', value: 2 },
    { answer: 'No estaría dispuesto', value: 1 }],
    [{ answer: 'Invertir en instrumentos con un riesgo y rendimiento mayor, pero con la posibilidad de tener pérdidas. Ejemplo: acciones de alta, media, baja y mínima bursatilidad, fondos de inversión, de renta variable y acciones del SIC', value: 3 },
    { answer: 'Invertir en instrumentos patrimoniales que permitan un crecimiento a mediano plazo. Ejemplo: Fondos de Inversión, de deuda y renta variable. Acciones de alta, media y baja bursatilidad.', value: 2 },
    { answer: 'Invertir en instrumentos de poco riesgo que impliquen un rendimiento. Ejemplo: Cetes y Fondos de deuda gubernamental de corto plazo, Acciones de alta y media bursatilidad.', value: 1 }],
    [{ answer: 'Corto Plazo (menor a 1 año)', value: 3 },
    { answer: 'Mediano Plazo (entre 1 y 3 años)', value: 2 },
    { answer: 'Largo Plazo (mayor a 3 años)', value: 1 }]
]

export const constantGrupoEconomico = [
    {
        id: 1,
        id_ge_az: 1,
        descripcion: 'PERSONAS FÍSICAS'
    },
    {
        id: 2,
        id_ge_az: 2,
        descripcion: 'AGRICULTURA'
    },
    {
        id: 3,
        id_ge_az: 3,
        descripcion: 'GANADERÍA'
    },
    {
        id: 4,
        id_ge_az: 4,
        descripcion: 'SILVICULTURA'
    },
    {
        id: 5,
        id_ge_az: 5,
        descripcion: 'CAZA Y PESCA'
    },
    {
        id: 6,
        id_ge_az: 6,
        descripcion: 'MINERIA'
    },
    {
        id: 7,
        id_ge_az: 7,
        descripcion: 'INDUSTRIA ENERGETICA'
    },
    {
        id: 8,
        id_ge_az: 8,
        descripcion: 'MANUFACTURAS'
    },
    {
        id: 9,
        id_ge_az: 9,
        descripcion: 'SIDERÚRGICA PRODUCTOS METÁLICOS'
    },
    {
        id: 10,
        id_ge_az: 10,
        descripcion: 'ORGANIZACIONES Y ASOCIACIONES'
    },
    {
        id: 11,
        id_ge_az: 11,
        descripcion: 'CONSTRUCCIÓN'
    },
    {
        id: 12,
        id_ge_az: 12,
        descripcion: 'VIVIENDA'
    },
    {
        id: 13,
        id_ge_az: 13,
        descripcion: 'COMUNICACIÓN Y TRANSPORTE'
    },
    {
        id: 14,
        id_ge_az: 14,
        descripcion: 'COMERCIO'
    },
    {
        id: 15,
        id_ge_az: 15,
        descripcion: 'TURISMO'
    },
    {
        id: 16,
        id_ge_az: 16,
        descripcion: 'SECTOR FINANCIERO RESIDENTES EN EL PAÍS'
    },
    {
        id: 17,
        id_ge_az: 17,
        descripcion: 'SECTOR EXTERNO NO RESIDENTES EN EL PAÍS'
    },
    {
        id: 18,
        id_ge_az: 18,
        descripcion: 'SERVICIOS SOCIALES'
    },
    {
        id: 19,
        id_ge_az: 19,
        descripcion: 'SECTOR PÚBLICO NO FINANCIERO'
    },
    {
        id: 20,
        id_ge_az: 20,
        descripcion: 'GOBIERNO DE LOS ESTADOS Y MUNICIPIOS'
    },
    {
        id: 21,
        id_ge_az: 21,
        descripcion: 'OTROS INTERMEDIARIOS FINANCIEROS PRIVADOS'
    }
];

export const cargos = [
    { id: 1, value: 'Si' },
    { id: 2, value: 'No' },
    { id: 3, value: 'Presidente de la República' },
    { id: 4, value: 'Jefe de Gobierno' },
    { id: 5, value: 'Ejecutivo de Empresa Estatal' },
    { id: 6, value: 'Consejeros Electorales' },
    { id: 7, value: 'Gobernador del Estado' },
    { id: 8, value: 'Secretario del Estado' },
    { id: 9, value: 'Funcionario Judicial' },
    { id: 10, value: 'Presidente Municipal' },
    { id: 11, value: 'Senador Federal o Local' },
    { id: 12, value: 'Diputado Federal o Local' },
    { id: 13, value: 'Secretario de Finanzas de los Estados' },
    { id: 14, value: 'Ministro de la Suprema Corte de la Nación' },
    { id: 15, value: 'Presidente de Partido Político' },
    { id: 16, value: 'Miembro de Partido Político' },
    { id: 17, value: 'Director General de Justicia o de los Estados' },
    { id: 18, value: 'Consejero de la Judicatura' },
    { id: 19, value: 'Magistrado' },
    { id: 20, value: 'Juez' },
    { id: 21, value: 'Otro' },
];


export const constantActividadesEconomicas = [
    {
        "id": 1,
        "id_actividad_az": 1,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Comercio"
    },
    {
        "id": 2,
        "id_actividad_az": 2,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Conductores"
    },
    {
        "id": 3,
        "id_actividad_az": 3,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Trabajadores Administrativos"
    },
    {
        "id": 4,
        "id_actividad_az": 4,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Trabajadores De La Educación"
    },
    {
        "id": 5,
        "id_actividad_az": 5,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Trabajadores Del Arte Y Espectáculos"
    },
    {
        "id": 6,
        "id_actividad_az": 6,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Trabajadores De Act. Agricolas Y Ganaderas"
    },
    {
        "id": 7,
        "id_actividad_az": 7,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Trabajadores En Servicios De Protección"
    },
    {
        "id": 8,
        "id_actividad_az": 8,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Trabajadores Fabriles"
    },
    {
        "id": 9,
        "id_actividad_az": 9,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Técnicos"
    },
    {
        "id": 10,
        "id_actividad_az": 10,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Profesionista Asalariado"
    },
    {
        "id": 11,
        "id_actividad_az": 11,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Profesionista Independiente"
    },
    {
        "id": 12,
        "id_actividad_az": 12,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Funcionario De Inst. O Empresas Públicas"
    },
    {
        "id": 13,
        "id_actividad_az": 13,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Funcionario De Inst. O Empresas Privadas"
    },
    {
        "id": 14,
        "id_actividad_az": 14,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Estudiante"
    },
    {
        "id": 15,
        "id_actividad_az": 15,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Menor De Edad"
    },
    {
        "id": 16,
        "id_actividad_az": 16,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Eclesiástico"
    },
    {
        "id": 17,
        "id_actividad_az": 17,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Hogar"
    },
    {
        "id": 18,
        "id_actividad_az": 18,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Jubilados O Pensionado"
    },
    {
        "id": 19,
        "id_actividad_az": 19,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Desempleado"
    },
    {
        "id": 20,
        "id_actividad_az": 20,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Prestador De Servicios"
    },
    {
        "id": 21,
        "id_actividad_az": 21,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Tecnología Empresarial"
    },
    {
        "id": 22,
        "id_actividad_az": 22,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Otros"
    },
    {
        "id": 23,
        "id_actividad_az": 23,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Cereales Y Otros Granos"
    },
    {
        "id": 24,
        "id_actividad_az": 24,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Verduras"
    },
    {
        "id": 25,
        "id_actividad_az": 25,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Plantas Para Alimento De Ganado"
    },
    {
        "id": 26,
        "id_actividad_az": 26,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Plantas Frutales"
    },
    {
        "id": 27,
        "id_actividad_az": 27,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Algodón"
    },
    {
        "id": 28,
        "id_actividad_az": 28,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Caña De Azúcar"
    },
    {
        "id": 29,
        "id_actividad_az": 29,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Tabaco"
    },
    {
        "id": 30,
        "id_actividad_az": 30,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Semillas Aceitosas"
    },
    {
        "id": 31,
        "id_actividad_az": 31,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Especias Y Condimentos"
    },
    {
        "id": 32,
        "id_actividad_az": 32,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Flores"
    },
    {
        "id": 33,
        "id_actividad_az": 33,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Café"
    },
    {
        "id": 34,
        "id_actividad_az": 34,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De árboles Frutales"
    },
    {
        "id": 35,
        "id_actividad_az": 35,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Henequén Para Fibras Textiles"
    },
    {
        "id": 36,
        "id_actividad_az": 36,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Agave Para Bebidas"
    },
    {
        "id": 37,
        "id_actividad_az": 37,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Palma De Coco"
    },
    {
        "id": 38,
        "id_actividad_az": 38,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Cultivo De Cacao"
    },
    {
        "id": 39,
        "id_actividad_az": 39,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Otros Cultivos"
    },
    {
        "id": 40,
        "id_actividad_az": 40,
        "id_g_ecom_az": 2,
        "id_fc_actividad_az": "Preparación De Tierras Para Cultivos Y Cosechas"
    },
    {
        "id": 41,
        "id_actividad_az": 41,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Engorda De Ganado Vacuno Para Carne"
    },
    {
        "id": 42,
        "id_actividad_az": 42,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Ganado Vacuno Para Leche"
    },
    {
        "id": 43,
        "id_actividad_az": 43,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría De Ganado Para Lidia"
    },
    {
        "id": 44,
        "id_actividad_az": 44,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría De Ganado Caballar"
    },
    {
        "id": 45,
        "id_actividad_az": 45,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría De Ganado Para Trabajo"
    },
    {
        "id": 46,
        "id_actividad_az": 46,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Ganado Porcino"
    },
    {
        "id": 47,
        "id_actividad_az": 47,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Ganado Ovino"
    },
    {
        "id": 48,
        "id_actividad_az": 48,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Ganado Caprino"
    },
    {
        "id": 49,
        "id_actividad_az": 49,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Gallinas Para Huevos"
    },
    {
        "id": 50,
        "id_actividad_az": 50,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Pollos"
    },
    {
        "id": 51,
        "id_actividad_az": 51,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Otras Aves Para Alimentación"
    },
    {
        "id": 52,
        "id_actividad_az": 52,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Abejas"
    },
    {
        "id": 53,
        "id_actividad_az": 53,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Cría Y Explotación De Conejos"
    },
    {
        "id": 54,
        "id_actividad_az": 54,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Producción De Animales Domésticos Para Fines No Alimenticios"
    },
    {
        "id": 55,
        "id_actividad_az": 55,
        "id_g_ecom_az": 3,
        "id_fc_actividad_az": "Otros Servicios De Ganadería"
    },
    {
        "id": 56,
        "id_actividad_az": 56,
        "id_g_ecom_az": 4,
        "id_fc_actividad_az": "Plantación Y Reforestación De Bosques"
    },
    {
        "id": 57,
        "id_actividad_az": 57,
        "id_g_ecom_az": 4,
        "id_fc_actividad_az": "Extracción De Troncos Para Aserraderos"
    },
    {
        "id": 58,
        "id_actividad_az": 58,
        "id_g_ecom_az": 4,
        "id_fc_actividad_az": "Extracción De Leña Y Producción De Carbón Vegetal"
    },
    {
        "id": 59,
        "id_actividad_az": 59,
        "id_g_ecom_az": 4,
        "id_fc_actividad_az": "Extracción De Productos Forestales No Maderables"
    },
    {
        "id": 60,
        "id_actividad_az": 60,
        "id_g_ecom_az": 4,
        "id_fc_actividad_az": "Servicios De Cortado, Protección De Bosques Y Otros"
    },
    {
        "id": 61,
        "id_actividad_az": 61,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Captura De Peces Marinos"
    },
    {
        "id": 62,
        "id_actividad_az": 62,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Captura De Crustáceos Y Moluscos Marinos"
    },
    {
        "id": 63,
        "id_actividad_az": 63,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Captura De Animales Marinos Diversos"
    },
    {
        "id": 64,
        "id_actividad_az": 64,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Recolección De Corales, Perlas, Conchas O Esponjas"
    },
    {
        "id": 65,
        "id_actividad_az": 65,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Recolección De Algas Y Otras Plantas Acuáticas"
    },
    {
        "id": 66,
        "id_actividad_az": 66,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Captura De Peces De Agua Dulce"
    },
    {
        "id": 67,
        "id_actividad_az": 67,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Captura De Crustáceos, Moluscos, Anfibios Y Reptiles De Agua Dulce"
    },
    {
        "id": 68,
        "id_actividad_az": 68,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Cría Artificial De Peces Y Plantas Acuáticas"
    },
    {
        "id": 69,
        "id_actividad_az": 69,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Servicios De Pesca Por Contrato"
    },
    {
        "id": 70,
        "id_actividad_az": 70,
        "id_g_ecom_az": 5,
        "id_fc_actividad_az": "Caza, Captura Y Preservación De Especies Salvajes"
    },
    {
        "id": 71,
        "id_actividad_az": 71,
        "id_g_ecom_az": 6,
        "id_fc_actividad_az": "Extracción Y Beneficio De Carbón Mineral"
    },
    {
        "id": 72,
        "id_actividad_az": 72,
        "id_g_ecom_az": 6,
        "id_fc_actividad_az": "Fabricación De Derivados Del Carbón Mineral"
    },
    {
        "id": 73,
        "id_actividad_az": 73,
        "id_g_ecom_az": 6,
        "id_fc_actividad_az": "Extracción De Petróleo Y Crudo Y Gas Natural"
    },
    {
        "id": 74,
        "id_actividad_az": 74,
        "id_g_ecom_az": 6,
        "id_fc_actividad_az": "Extracción Y Beneficio De Minerales De Hierro"
    },
    {
        "id": 75,
        "id_actividad_az": 75,
        "id_g_ecom_az": 6,
        "id_fc_actividad_az": "Extracción Y Beneficio De Metales Preciosos"
    },
    {
        "id": 76,
        "id_actividad_az": 76,
        "id_g_ecom_az": 6,
        "id_fc_actividad_az": "Extracción Y Beneficio De Otros Metales No Ferrosos"
    },
    {
        "id": 77,
        "id_actividad_az": 77,
        "id_g_ecom_az": 6,
        "id_fc_actividad_az": "Extracción De Arena, Grava Y Arcilla"
    },
    {
        "id": 78,
        "id_actividad_az": 78,
        "id_g_ecom_az": 6,
        "id_fc_actividad_az": "Extracción De Minerales No Metálicos"
    },
    {
        "id": 79,
        "id_actividad_az": 79,
        "id_g_ecom_az": 7,
        "id_fc_actividad_az": "Captación, Tratamiento, Conducción Y Distribución De Agua Potable"
    },
    {
        "id": 80,
        "id_actividad_az": 80,
        "id_g_ecom_az": 7,
        "id_fc_actividad_az": "Distribución De Energía Eléctrica"
    },
    {
        "id": 81,
        "id_actividad_az": 81,
        "id_g_ecom_az": 7,
        "id_fc_actividad_az": "Generación Y Suministro De Energía Eléctrica"
    },
    {
        "id": 82,
        "id_actividad_az": 82,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Productos Cárnicos Y Lácteos"
    },
    {
        "id": 83,
        "id_actividad_az": 83,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Envasado De Frutas Y Legumbres"
    },
    {
        "id": 84,
        "id_actividad_az": 84,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Molienda De Trigo Y Sus Productos"
    },
    {
        "id": 85,
        "id_actividad_az": 85,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Molienda De Nixtamal Y Productos De Maíz"
    },
    {
        "id": 86,
        "id_actividad_az": 86,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Procesamiento De Café"
    },
    {
        "id": 87,
        "id_actividad_az": 87,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Azúcar Y Sus Derivados"
    },
    {
        "id": 88,
        "id_actividad_az": 88,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Aceites Y Grasas Vegetales Comestibles"
    },
    {
        "id": 89,
        "id_actividad_az": 89,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Alimentos Para Animales"
    },
    {
        "id": 90,
        "id_actividad_az": 90,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Otros Productos Alimenticios"
    },
    {
        "id": 91,
        "id_actividad_az": 91,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Bebidas Alcohólicas"
    },
    {
        "id": 92,
        "id_actividad_az": 92,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Cerveza"
    },
    {
        "id": 93,
        "id_actividad_az": 93,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Refrescos Embotellados"
    },
    {
        "id": 94,
        "id_actividad_az": 94,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Tabaco Y Sus Productos"
    },
    {
        "id": 95,
        "id_actividad_az": 95,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Hilado Y Tejido De Fibras Textiles"
    },
    {
        "id": 96,
        "id_actividad_az": 96,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Fabricación De Telas"
    },
    {
        "id": 97,
        "id_actividad_az": 97,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Confección De Prendas De Vestir"
    },
    {
        "id": 98,
        "id_actividad_az": 98,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Cuero Y Sus Productos"
    },
    {
        "id": 99,
        "id_actividad_az": 99,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Aserraderos"
    },
    {
        "id": 100,
        "id_actividad_az": 100,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Otras Industrias De La Madera"
    },
    {
        "id": 101,
        "id_actividad_az": 101,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Papel Y Cartón"
    },
    {
        "id": 102,
        "id_actividad_az": 102,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Imprentas Y Editoriales"
    },
    {
        "id": 103,
        "id_actividad_az": 103,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Refinación De Petróleo"
    },
    {
        "id": 104,
        "id_actividad_az": 104,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Petroquímica Básica"
    },
    {
        "id": 105,
        "id_actividad_az": 105,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Química Básica"
    },
    {
        "id": 106,
        "id_actividad_az": 106,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Abonos Y Fertilizantes"
    },
    {
        "id": 107,
        "id_actividad_az": 107,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Resinas Sintéticas, Plásticos Y Hules"
    },
    {
        "id": 108,
        "id_actividad_az": 108,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Productos Medicinales"
    },
    {
        "id": 109,
        "id_actividad_az": 109,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Jabones, Detergentes, Perfumes Y Cosméticos"
    },
    {
        "id": 110,
        "id_actividad_az": 110,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Otras Industrias Químicas"
    },
    {
        "id": 111,
        "id_actividad_az": 111,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Productos De Hule"
    },
    {
        "id": 112,
        "id_actividad_az": 112,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Artículos De Plástico"
    },
    {
        "id": 113,
        "id_actividad_az": 113,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Vidrio Y Sus Productos"
    },
    {
        "id": 114,
        "id_actividad_az": 114,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Cemento"
    },
    {
        "id": 115,
        "id_actividad_az": 115,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Otros Productos De Minerales No Metálicos"
    },
    {
        "id": 116,
        "id_actividad_az": 116,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Otras Industrias Manufactureras"
    },
    {
        "id": 117,
        "id_actividad_az": 117,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Industrias Básicas De Hierro Y Acero"
    },
    {
        "id": 118,
        "id_actividad_az": 118,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Industrias Básicas De Metales No Ferrosos"
    },
    {
        "id": 119,
        "id_actividad_az": 119,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Muebles Y Sus Accesorios Metálicos"
    },
    {
        "id": 120,
        "id_actividad_az": 120,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Productos Metálicos Estructurales"
    },
    {
        "id": 121,
        "id_actividad_az": 121,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Maquinaria Y Productos No Eléctricos"
    },
    {
        "id": 122,
        "id_actividad_az": 122,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Maquinaria Y Aparatos Eléctricos"
    },
    {
        "id": 123,
        "id_actividad_az": 123,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Aparatos Electrodomésticos"
    },
    {
        "id": 124,
        "id_actividad_az": 124,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Equipo Y Accesorios Electrónicos"
    },
    {
        "id": 125,
        "id_actividad_az": 125,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Vehículos Y Automóviles"
    },
    {
        "id": 126,
        "id_actividad_az": 126,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Carrocerías Y Partes Automotrices"
    },
    {
        "id": 127,
        "id_actividad_az": 127,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Otros Equipos Y Materiales De Transporte"
    },
    {
        "id": 128,
        "id_actividad_az": 128,
        "id_g_ecom_az": 9,
        "id_fc_actividad_az": "Otros Productos Metálicos"
    },
    {
        "id": 129,
        "id_actividad_az": 129,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Asociaciones Y Confederaciones"
    },
    {
        "id": 130,
        "id_actividad_az": 130,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Cámaras De Comercio"
    },
    {
        "id": 131,
        "id_actividad_az": 131,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Cámaras Industriales"
    },
    {
        "id": 132,
        "id_actividad_az": 132,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Sociedades Cooperativas"
    },
    {
        "id": 133,
        "id_actividad_az": 133,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Organizaciones De Abogados, Médicos Y Otros Profesionales"
    },
    {
        "id": 134,
        "id_actividad_az": 134,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Organizaciones De Caridad"
    },
    {
        "id": 135,
        "id_actividad_az": 135,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Organizaciones Cívicas"
    },
    {
        "id": 136,
        "id_actividad_az": 136,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Organizaciones Laborales Y Sindicales"
    },
    {
        "id": 137,
        "id_actividad_az": 137,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Organizaciones Políticas"
    },
    {
        "id": 138,
        "id_actividad_az": 138,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Organizaciones Religiosas"
    },
    {
        "id": 139,
        "id_actividad_az": 139,
        "id_g_ecom_az": 10,
        "id_fc_actividad_az": "Otras Organizaciones"
    },
    {
        "id": 140,
        "id_actividad_az": 140,
        "id_g_ecom_az": 11,
        "id_fc_actividad_az": "Casas Y Edificios Residenciales"
    },
    {
        "id": 141,
        "id_actividad_az": 141,
        "id_g_ecom_az": 11,
        "id_fc_actividad_az": "Carreteras, Puentes, Puertos Y Otras Vías De Comunicación"
    },
    {
        "id": 142,
        "id_actividad_az": 142,
        "id_g_ecom_az": 11,
        "id_fc_actividad_az": "Construcción De Presas, Estadios Y Otras Obras De Ingeniería"
    },
    {
        "id": 143,
        "id_actividad_az": 143,
        "id_g_ecom_az": 11,
        "id_fc_actividad_az": "Servicios Vinculados A Obras De Ingeniería"
    },
    {
        "id": 144,
        "id_actividad_az": 144,
        "id_g_ecom_az": 11,
        "id_fc_actividad_az": "Instalaciones Eléctricas, De Plomería O Aire Acondicionado"
    },
    {
        "id": 145,
        "id_actividad_az": 145,
        "id_g_ecom_az": 12,
        "id_fc_actividad_az": "Prestamos Para La Adquisición De Vivienda"
    },
    {
        "id": 146,
        "id_actividad_az": 146,
        "id_g_ecom_az": 12,
        "id_fc_actividad_az": "Compra Venta De Casas Y Otros Inmuebles"
    },
    {
        "id": 147,
        "id_actividad_az": 147,
        "id_g_ecom_az": 12,
        "id_fc_actividad_az": "Compra Venta De Terreno"
    },
    {
        "id": 148,
        "id_actividad_az": 148,
        "id_g_ecom_az": 12,
        "id_fc_actividad_az": "Alquiler De Viviendas"
    },
    {
        "id": 149,
        "id_actividad_az": 149,
        "id_g_ecom_az": 12,
        "id_fc_actividad_az": "Alquiler De Terrenos, Locales Y Edificios"
    },
    {
        "id": 150,
        "id_actividad_az": 150,
        "id_g_ecom_az": 12,
        "id_fc_actividad_az": "Corredores De Bienes Raíces"
    },
    {
        "id": 151,
        "id_actividad_az": 151,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Servicios Telegráficos Y Radiotelegráficos"
    },
    {
        "id": 152,
        "id_actividad_az": 152,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Servicios Telefónicos"
    },
    {
        "id": 153,
        "id_actividad_az": 153,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Servicios Postales"
    },
    {
        "id": 154,
        "id_actividad_az": 154,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Usuarios Menores De Comunicaciones"
    },
    {
        "id": 155,
        "id_actividad_az": 155,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Transportes En Autobús"
    },
    {
        "id": 156,
        "id_actividad_az": 156,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Transportes En Automóvil"
    },
    {
        "id": 157,
        "id_actividad_az": 157,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Autotransporte Escolar"
    },
    {
        "id": 158,
        "id_actividad_az": 158,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Autotransporte De Mudanzas"
    },
    {
        "id": 159,
        "id_actividad_az": 159,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Autotransporte De Carga"
    },
    {
        "id": 160,
        "id_actividad_az": 160,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Transporte Por Ferrocarril"
    },
    {
        "id": 161,
        "id_actividad_az": 161,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Transporte Marítimo"
    },
    {
        "id": 162,
        "id_actividad_az": 162,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Transporte Fluvial"
    },
    {
        "id": 163,
        "id_actividad_az": 163,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Transportes En Aeronaves"
    },
    {
        "id": 164,
        "id_actividad_az": 164,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Administración De Caminos Y Puentes"
    },
    {
        "id": 165,
        "id_actividad_az": 165,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Administración De Aeropuertos"
    },
    {
        "id": 166,
        "id_actividad_az": 166,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Administración De Puertos"
    },
    {
        "id": 167,
        "id_actividad_az": 167,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Administración De Centrales Camioneras"
    },
    {
        "id": 168,
        "id_actividad_az": 168,
        "id_g_ecom_az": 13,
        "id_fc_actividad_az": "Servicios De Almacenamiento Y Refrigeración"
    },
    {
        "id": 169,
        "id_actividad_az": 169,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Alimentos, Bebidas Y Tabaco"
    },
    {
        "id": 170,
        "id_actividad_az": 170,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Prendas De Vestir"
    },
    {
        "id": 171,
        "id_actividad_az": 171,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Artículos De Uso Personal"
    },
    {
        "id": 172,
        "id_actividad_az": 172,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Artículos Para El Hogar"
    },
    {
        "id": 173,
        "id_actividad_az": 173,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Tiendas De Autoservicio"
    },
    {
        "id": 174,
        "id_actividad_az": 174,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compra Venta De Gas, Combustibles Y Lubricantes"
    },
    {
        "id": 175,
        "id_actividad_az": 175,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Materias Primas Y Materiales"
    },
    {
        "id": 176,
        "id_actividad_az": 176,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Maquinaria, Equipo Y Herramienta"
    },
    {
        "id": 177,
        "id_actividad_az": 177,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Automóviles Y Camiones"
    },
    {
        "id": 178,
        "id_actividad_az": 178,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Motocicletas, Bicicletas Y Sus Accesorios"
    },
    {
        "id": 179,
        "id_actividad_az": 179,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compra Venta De Llantas Y Cámaras"
    },
    {
        "id": 180,
        "id_actividad_az": 180,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compra Venta Accesorios Para Automóviles Y Camiones"
    },
    {
        "id": 181,
        "id_actividad_az": 181,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Otro Tipo De Transportes Y Sus Accesorios"
    },
    {
        "id": 182,
        "id_actividad_az": 182,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compraventa De Computadoras"
    },
    {
        "id": 183,
        "id_actividad_az": 183,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compra Venta De Otro Tipo De Artículos"
    },
    {
        "id": 184,
        "id_actividad_az": 184,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compra Venta De Antig Y Objetos De Arte"
    },
    {
        "id": 185,
        "id_actividad_az": 185,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Comp/Vent De Joyas, Piedr.Y Met. Precios"
    },
    {
        "id": 186,
        "id_actividad_az": 186,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Compr/Vta, Prod O Dist. Armas Y Art.Mili"
    },
    {
        "id": 187,
        "id_actividad_az": 187,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Agencia Aduanal"
    },
    {
        "id": 188,
        "id_actividad_az": 188,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Agencia De Ferrocarril"
    },
    {
        "id": 189,
        "id_actividad_az": 189,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Agencia De Viajes"
    },
    {
        "id": 190,
        "id_actividad_az": 190,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Casinos, Loterias Y Otros Juegos De Azar"
    },
    {
        "id": 191,
        "id_actividad_az": 191,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Alquiler De Lanchas Y Veleros"
    },
    {
        "id": 192,
        "id_actividad_az": 192,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Renta De Vehículos"
    },
    {
        "id": 193,
        "id_actividad_az": 193,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Renta De Aviones"
    },
    {
        "id": 194,
        "id_actividad_az": 194,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Centros Nocturnos"
    },
    {
        "id": 195,
        "id_actividad_az": 195,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Servicios De Alojamiento En Hoteles Y Moteles"
    },
    {
        "id": 196,
        "id_actividad_az": 196,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Servicios De Alimentos En Restaurantes Y Cafeterías"
    },
    {
        "id": 197,
        "id_actividad_az": 197,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Servicios De Bares Y Cantinas"
    },
    {
        "id": 198,
        "id_actividad_az": 198,
        "id_g_ecom_az": 15,
        "id_fc_actividad_az": "Otros Servicios De Turismo"
    },
    {
        "id": 199,
        "id_actividad_az": 199,
        "id_g_ecom_az": 16,
        "id_fc_actividad_az": "Banco De México"
    },
    {
        "id": 200,
        "id_actividad_az": 200,
        "id_g_ecom_az": 16,
        "id_fc_actividad_az": "Banca De Desarrollo"
    },
    {
        "id": 201,
        "id_actividad_az": 201,
        "id_g_ecom_az": 16,
        "id_fc_actividad_az": "Banca Múltiple"
    },
    {
        "id": 202,
        "id_actividad_az": 202,
        "id_g_ecom_az": 16,
        "id_fc_actividad_az": "Otros Intermediarios Financieros Públicos"
    },
    {
        "id": 203,
        "id_actividad_az": 203,
        "id_g_ecom_az": 16,
        "id_fc_actividad_az": "Bancos Extranjeros"
    },
    {
        "id": 204,
        "id_actividad_az": 204,
        "id_g_ecom_az": 16,
        "id_fc_actividad_az": "Otros Intermediarios Financieros Privados"
    },
    {
        "id": 205,
        "id_actividad_az": 205,
        "id_g_ecom_az": 17,
        "id_fc_actividad_az": "Entidades Financieras Del Extranjero"
    },
    {
        "id": 206,
        "id_actividad_az": 206,
        "id_g_ecom_az": 17,
        "id_fc_actividad_az": "Empresas Y Particulares Del Extranjero"
    },
    {
        "id": 207,
        "id_actividad_az": 207,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Notarias Públicas"
    },
    {
        "id": 208,
        "id_actividad_az": 208,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Bufetes Jurídicos"
    },
    {
        "id": 209,
        "id_actividad_az": 209,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Contaduría Y Auditoría"
    },
    {
        "id": 210,
        "id_actividad_az": 210,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Arquitectura E Ingeniería"
    },
    {
        "id": 211,
        "id_actividad_az": 211,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Asesoría En Administración Y Organización De Empresas"
    },
    {
        "id": 212,
        "id_actividad_az": 212,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Investigación De Mercados Y Solvencia Financiera"
    },
    {
        "id": 213,
        "id_actividad_az": 213,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Análisis De Sistemas Y Procesamiento De Datos"
    },
    {
        "id": 214,
        "id_actividad_az": 214,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Agencias De Publicidad"
    },
    {
        "id": 215,
        "id_actividad_az": 215,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Agencias De Información"
    },
    {
        "id": 216,
        "id_actividad_az": 216,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Otro Tipo De Servicios Profesionales"
    },
    {
        "id": 217,
        "id_actividad_az": 217,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Enseñanza Preprimaria Y Primaria"
    },
    {
        "id": 218,
        "id_actividad_az": 218,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Enseñanza Secundaria"
    },
    {
        "id": 219,
        "id_actividad_az": 219,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Enseñanza Preparatoria Y Profesional"
    },
    {
        "id": 220,
        "id_actividad_az": 220,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Enseñanza Con Combinación De Las Anteriores"
    },
    {
        "id": 221,
        "id_actividad_az": 221,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Enseñanza Comercial Y De Idiomas"
    },
    {
        "id": 222,
        "id_actividad_az": 222,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Capacitación Técnica Y Oficios"
    },
    {
        "id": 223,
        "id_actividad_az": 223,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Otro Tipo De Enseñanzas"
    },
    {
        "id": 224,
        "id_actividad_az": 224,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Investigación Científica"
    },
    {
        "id": 225,
        "id_actividad_az": 225,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Bibliotecas, Museos Y Otro Tipo Difusión Cultural"
    },
    {
        "id": 226,
        "id_actividad_az": 226,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Hospitales, Clínicas, Maternidades Y Sanatorios"
    },
    {
        "id": 227,
        "id_actividad_az": 227,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Consultorios Y Clínicas Dentales"
    },
    {
        "id": 228,
        "id_actividad_az": 228,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Laboratorios"
    },
    {
        "id": 229,
        "id_actividad_az": 229,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Otros Servicios Médicos"
    },
    {
        "id": 230,
        "id_actividad_az": 230,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Casas De Cuna, Orfanatorios Y Asilos"
    },
    {
        "id": 231,
        "id_actividad_az": 231,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Producción, Transmisión Y Repetición De Programas De Tv"
    },
    {
        "id": 232,
        "id_actividad_az": 232,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Cinematografia Y Esparcimiento"
    },
    {
        "id": 233,
        "id_actividad_az": 233,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Otros Servicios De Esparcimiento"
    },
    {
        "id": 234,
        "id_actividad_az": 234,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Otros Servicios Sociales O Personales"
    },
    {
        "id": 235,
        "id_actividad_az": 235,
        "id_g_ecom_az": 19,
        "id_fc_actividad_az": "Gobierno Federal"
    },
    {
        "id": 236,
        "id_actividad_az": 236,
        "id_g_ecom_az": 19,
        "id_fc_actividad_az": "Gobierno Del Distrito Federal"
    },
    {
        "id": 237,
        "id_actividad_az": 237,
        "id_g_ecom_az": 19,
        "id_fc_actividad_az": "Gobierno Extranjero"
    },
    {
        "id": 238,
        "id_actividad_az": 238,
        "id_g_ecom_az": 19,
        "id_fc_actividad_az": "Organismos Descentralizados Y Empresas De Participación Estatal"
    },
    {
        "id": 239,
        "id_actividad_az": 239,
        "id_g_ecom_az": 20,
        "id_fc_actividad_az": "Gobierno Estatal"
    },
    {
        "id": 240,
        "id_actividad_az": 240,
        "id_g_ecom_az": 20,
        "id_fc_actividad_az": "Gobierno Municipal"
    },
    {
        "id": 241,
        "id_actividad_az": 241,
        "id_g_ecom_az": 20,
        "id_fc_actividad_az": "Prestaciones De Servicios Públicos Y Sociales"
    },
    {
        "id": 242,
        "id_actividad_az": 242,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Uniones De Crédito"
    },
    {
        "id": 243,
        "id_actividad_az": 243,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Casas De Cambio"
    },
    {
        "id": 244,
        "id_actividad_az": 244,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Servicios De Montepío / Casas De Empeño"
    },
    {
        "id": 245,
        "id_actividad_az": 245,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Transmisor Y Dispersor De Fondos"
    },
    {
        "id": 246,
        "id_actividad_az": 246,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Almacenes De Depósito"
    },
    {
        "id": 247,
        "id_actividad_az": 247,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Instituciones De Fianzas"
    },
    {
        "id": 248,
        "id_actividad_az": 248,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Instituciones De Seguros"
    },
    {
        "id": 249,
        "id_actividad_az": 249,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Casas De Bolsa"
    },
    {
        "id": 250,
        "id_actividad_az": 250,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Sociedades De Inversión"
    },
    {
        "id": 251,
        "id_actividad_az": 251,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Asesoría En Inversiones"
    },
    {
        "id": 252,
        "id_actividad_az": 252,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Otros Servicios Financieros"
    },
    {
        "id": 253,
        "id_actividad_az": 253,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Empresas De Factoraje Financiero"
    },
    {
        "id": 254,
        "id_actividad_az": 254,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Sociedades Controladoras De Grupos Financieros"
    },
    {
        "id": 255,
        "id_actividad_az": 255,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Sociedades Financieras De Objeto Limitado"
    },
    {
        "id": 256,
        "id_actividad_az": 256,
        "id_g_ecom_az": 21,
        "id_fc_actividad_az": "Arrendadoras Financieras"
    },
    {
        "id": 257,
        "id_actividad_az": 257,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Juegos Con Apuesta, Concursos O Sorteos"
    },
    {
        "id": 258,
        "id_actividad_az": 258,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Juegos Con Apuesta, Concursos O Sorteos"
    },
    {
        "id": 259,
        "id_actividad_az": 259,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Op. De Mutuo, De Garantía O De Otorgamiento De Préstamos O Créditos"
    },
    {
        "id": 260,
        "id_actividad_az": 260,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Op. De Mutuo, De Garantía O De Otorgamiento De Préstamos O Créditos"
    },
    {
        "id": 261,
        "id_actividad_az": 261,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Compra Venta De Antig Y Objetos De Arte"
    },
    {
        "id": 262,
        "id_actividad_az": 262,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Comp/Vent De Joyas, Piedr.Y Met. Precios"
    },
    {
        "id": 263,
        "id_actividad_az": 263,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Compr/Vta, Prod O Dist. Armas Y Art.Mili"
    },
    {
        "id": 264,
        "id_actividad_az": 264,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Casinos, Loterias Y Otros Juegos De Azar"
    },
    {
        "id": 265,
        "id_actividad_az": 265,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Centros Nocturnos"
    },
    {
        "id": 266,
        "id_actividad_az": 266,
        "id_g_ecom_az": 8,
        "id_fc_actividad_az": "Comp/Vent De Joyas, Piedr.Y Met. Precios"
    },
    {
        "id": 267,
        "id_actividad_az": 267,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Compraventa De Automóviles Y Camiones"
    },
    {
        "id": 268,
        "id_actividad_az": 268,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Compra Venta Accesorios Para Automóviles Y Camiones"
    },
    {
        "id": 269,
        "id_actividad_az": 269,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Compraventa De Otro Tipo De Transportes Y Sus Accesorios"
    },
    {
        "id": 270,
        "id_actividad_az": 270,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Serv. Blindaje De Vehículos Terrestres, Nuevos O Usados  E Inmuebles"
    },
    {
        "id": 271,
        "id_actividad_az": 271,
        "id_g_ecom_az": 14,
        "id_fc_actividad_az": "Serv. Blindaje De Vehículos Terrestres, Nuevos O Usados  E Inmuebles"
    },
    {
        "id": 272,
        "id_actividad_az": 272,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Notarias Públicas"
    },
    {
        "id": 273,
        "id_actividad_az": 273,
        "id_g_ecom_az": 18,
        "id_fc_actividad_az": "Corredores Públicos"
    },
    {
        "id": 274,
        "id_actividad_az": 274,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Corredores Públicos"
    },
    {
        "id": 275,
        "id_actividad_az": 275,
        "id_g_ecom_az": 1,
        "id_fc_actividad_az": "Agente O Apoderado Aduanal"
    }
];

export const constantFuentesIngresos = [
    {
        id: 1, value: 'Honorarios'
    },
    {
        id: 2, value: 'Sueldo'
    },
    {
        id: 3, value: 'Inversiones'
    },
    {
        id: 4, value: 'Comisiones'
    },
    {
        id: 5, value: 'Venta de bienes'
    },
    {
        id: 6, value: 'Negocio propio'
    },
    {
        id: 7, value: 'Herencia'
    },
    {
        id: 8, value: 'Arrendamiento'
    },
    {
        id: 9, value: 'Dividendo'
    },
    {
        id: 10, value: 'Otros (especificar)'
    },
]

export const estadosCiviles = [
    { id: 1, value: 'Soltero' },
    { id: 2, value: 'Casado' },
]

export const generos = [
    { id: 1, value: 'Femenino' },
    { id: 2, value: 'Masculino' },
]