/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { isChrome, isMobile, isIOS, isSafari } from 'react-device-detect'
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Ayuda from "./ayuda"
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const PdfTokenError = () => {

    /* Hooks */
    const [browserDnotSupported, setNotSupported] = useState(false)
    const [browsernotSupportediOS, setNotSupportediOS] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {
        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            configStyle(JSON.parse(dataOtorganteLS))
        }
        const logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""))
        document.getElementById("imagenLogo").src = logo
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        if (!isMobile && !isChrome) {
            //console.log("desktop not supported browsers");
            setNotSupported(true)
        } else if (isMobile && isIOS && !isSafari) {
            //console.log("mobile not supported browsers in iOS");
            setNotSupportediOS(true)
        } else if (isMobile && !isIOS && !isChrome) {
            //console.log("Android, not supported browser");
            setNotSupported(true)
        }
    }, [])

    return (
        <>
            <div className="main_gradient">
                <Header show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="animate__animated animate__fadeIn  animate__delay-1s title_punto_trader">Resultado de proceso</h1>
                    <p className="animate__animated animate__fadeIn animate__delay-2s calibri_punto_trader">
                        Lo sentimos la vigencia de este link caducó el día <b>{new Intl.DateTimeFormat("es-MX", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                        }).format(new Date(Number(localStorage.getItem("fechavigencia"))))}</b> si deseas mayor información comunícate con tu folio: <b> {localStorage.getItem("uuidUser").split("-")[0]}</b> al número: <a href="#!" className="main_color"><b>5512345678</b></a>
                    </p>

                </div>
                <Footer />
                {((browserDnotSupported) && <div className="browser_not_comp show_me_bx">
                    <div className="top_bx">
                        <img src="images/browser_alert_icon.svg" alt="" />
                        <h4>Lo sentimos tu navegador actual no es <br />
                            compatible con nuestra plataforma.</h4>
                        <div className="divider"></div>
                    </div>
                    <div className="bottom_bx">
                        <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>
                        <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                            <img src="images/browser_chrome.png" width="96" alt="" />
                            <a href={isMobile ? 'https://play.google.com/store/apps/details?id=com.android.chrome' : 'https://www.google.com/chrome/'}>Chrome</a>
                            <small>Google</small>
                        </div>
                    </div>
                </div>)}
                {(browsernotSupportediOS) && <div className="browser_not_comp show_me_bx">
                    <div className="top_bx">
                        <img src="images/browser_alert_icon.svg" alt="" />
                        <h4>Apple limita el uso de aplicaciones avanzadas en este navegador.</h4>
                        <div className="divider"></div>
                    </div>
                    <div className="bottom_bx">
                        <p><small>Para continuar con tu proceso utiliza o descarga Safari para iOS.</small></p>
                        <div className="browser_bx ios_browser">
                            <img src="images/browser_ios.png" width="96" alt="" />
                            <a href="https://apps.apple.com/mx/app/safari/id1146562112">Safari</a>
                            <small>Apple</small>
                        </div>
                    </div>
                </div>}
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>
    )

}

export default PdfTokenError