/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Funciones */
import { validarCampo } from '../../services/data'
import { onPaste, onCut } from "../../services/data";
/* Servicios */
import { obtenerCP, obtenerCatalogo } from '../../services/api'
/* Constantes */
import { constantEntidadesFederativas, constantPaises } from "../../services/constanst";

const FormularioDomicilio = ({ tipoDomicilio, inputsErrors, setInputsValue, setInputsErrors }) => {

    /* Props */
    const paramCodigoPostal = 'codigoPostal' + tipoDomicilio;
    const paramPais = 'pais' + tipoDomicilio;
    const paramEntidadFederativa = 'entidadFederativa' + tipoDomicilio;
    const paramMunicipio = 'municipio' + tipoDomicilio;
    const paramColonia = 'colonia' + tipoDomicilio;
    const paramCalle = 'calle' + tipoDomicilio;
    const paramNumeroCalleExterior = 'numeroCalleExterior' + tipoDomicilio;
    const paramNumeroCalleInterior = 'numeroCalleInterior' + tipoDomicilio;
    const paramOtraColonia = 'otraColonia' + tipoDomicilio;

    /* Hooks */
    const [otro, setOtro] = useState(false)
    const [colonias, setColonias] = useState([])
    const [entidadesFederativas, setEntidadesFederativas] = useState([])
    const [paises, setPaises] = useState([])
    const [focus, setFocus] = useState({
        [paramCodigoPostal]: false,
        [paramPais]: false,
        [paramEntidadFederativa]: false,
        [paramMunicipio]: false,
        [paramColonia]: false,
        [paramCalle]: false,
        [paramNumeroCalleExterior]: false,
        [paramNumeroCalleInterior]: false,
        [paramOtraColonia]: false
    })
    const [inputs, setInputs] = useState({
        [paramCodigoPostal]: '',
        [paramPais]: '',
        [paramEntidadFederativa]: '',
        [paramMunicipio]: '',
        [paramColonia]: '',
        [paramOtraColonia]: '',
        [paramCalle]: '',
        [paramNumeroCalleExterior]: '',
        [paramNumeroCalleInterior]: '',
    })

    useEffect(() => {
        const dataOCRFrontLS = localStorage.getItem("ocrFront");
        if (dataOCRFrontLS) {
            const ocrFront = JSON.parse(dataOCRFrontLS)
            setInputs(inputs => ({ ...inputs, [paramCodigoPostal]: ocrFront.cp ? ocrFront.cp.trim() : '' }));
            setInputsValue(paramCodigoPostal, ocrFront.cp ? ocrFront.cp.trim() : '');
            setInputs(inputs => ({ ...inputs, [paramPais]: 'México' }));
            setInputsValue(paramPais, 'México');
            setInputs(inputs => ({ ...inputs, [paramCalle]: ocrFront.calle || '' }));
            setInputsValue(paramCalle, ocrFront.calle || '');
            setInputs(inputs => ({ ...inputs, [paramNumeroCalleExterior]: (ocrFront.numero && ocrFront.numero.trim()) || '' }));
            setInputsValue(paramNumeroCalleExterior, (ocrFront.numero && ocrFront.numero.trim()) || '');
            setInputs(inputs => ({ ...inputs, [paramEntidadFederativa]: ocrFront.estado || '' }));
            setInputsValue(paramEntidadFederativa, ocrFront.estado || '');
            setInputs(inputs => ({ ...inputs, [paramMunicipio]: ocrFront.municipio || '' }));
            setInputsValue(paramMunicipio, ocrFront.municipio || '');
            setInputs(inputs => ({ ...inputs, [paramColonia]: ocrFront.colonia || '' }));
            setInputsValue(paramColonia, ocrFront.colonia || '');
        } else {
            setInputsValue(paramCodigoPostal, inputs[paramCodigoPostal]);
            setInputsValue(paramPais, inputs[paramPais]);
            setInputsValue(paramCalle, inputs[paramCalle]);
            setInputsValue(paramNumeroCalleExterior, inputs[paramNumeroCalleExterior]);
            setInputsValue(paramEntidadFederativa, inputs[paramEntidadFederativa]);
            setInputsValue(paramMunicipio, inputs[paramMunicipio]);
            setInputsValue(paramColonia, inputs[paramColonia])
        }

        obtenerCatalogo('estado').then(({ status, data }) => {
            if (status === 200) {
                setEntidadesFederativas(data);
            }
        }).catch(error => {
            console.log(`🚀 ----------------------------------------------------------------------🚀`)
            console.log(`🚀 ~ file: formulario_domicilio.js:82 ~ obtenerCatalogo ~ error:`, error)
            console.log(`🚀 ----------------------------------------------------------------------🚀`)
            setEntidadesFederativas(constantEntidadesFederativas);
        });

        obtenerCatalogo('paises').then(({ status, data }) => {
            if (status === 200) {
                setPaises(data);
            }
        }).catch(error => {
            console.log(`🚀 ----------------------------------------------------------------------🚀`)
            console.log(`🚀 ~ file: formulario_domicilio.js:93 ~ obtenerCatalogo ~ error:`, error)
            console.log(`🚀 ----------------------------------------------------------------------🚀`)
            setPaises(constantPaises);
        });
    }, [])

    useEffect(() => {
        if (inputs[paramCodigoPostal].length === 5) {
            obtenerCP(inputs[paramCodigoPostal], localStorage.getItem("uuidUser")).then((response) => {
                console.log(`🚀 -----------------------------------------------------------------------🚀`);
                console.log(`🚀 ~ file: formulario_domicilio.js:102 ~ obtenerCP ~ response:`, response);
                console.log(`🚀 -----------------------------------------------------------------------🚀`);
                if (response.status === 200) {
                    let dir = response.data.payload.address[0]
                    let delegacionW = dir.municipio
                    const estadoW = dir.estado
                    let arreglo = []
                    let tam = (response.data.payload.address).length
                    let coloniaW = null;
                    if (tam > 1) {
                        for (let i = 0; i < tam; i++) {
                            let colonia = response.data.payload.address[i].asentamiento
                            arreglo.push(colonia)
                        }
                    } else {
                        let colonia1 = dir.asentamiento
                        arreglo.push(colonia1)
                    }

                    if (inputs[paramColonia]) {
                        arreglo.forEach(colonia => {
                            if (inputs[paramColonia].includes(colonia.toUpperCase())) {
                                coloniaW = colonia
                            }
                        })
                    } else {
                        coloniaW = '';
                    }
                    setColonias(arreglo)
                    setInputs(inputs => ({ ...inputs, [paramCodigoPostal]: inputs[paramCodigoPostal] }));
                    setInputs(inputs => ({ ...inputs, [paramColonia]: coloniaW ? coloniaW : '' }));
                    setInputsValue(paramColonia, coloniaW ? coloniaW : '');
                    setInputs(inputs => ({ ...inputs, [paramMunicipio]: delegacionW }));
                    setInputsValue(paramMunicipio, delegacionW);
                    setInputs(inputs => ({ ...inputs, [paramEntidadFederativa]: estadoW }));
                    setInputsValue(paramEntidadFederativa, estadoW);
                    setInputs(inputs => ({ ...inputs, [paramPais]: 'México' }));
                    setInputsValue(paramPais, 'México');
                    setInputsValue('paisOCR', 'México');
                    setInputsValue('entidadFederativaOCR', estadoW);
                }
            }).catch((error) => {
                console.log(`🚀 -----------------------------------------------------------------🚀`);
                console.error(`🚀 ~ file: formulario_domicilio.js:144 ~ obtenerCP ~ error:`, { error });
                console.log(`🚀 -----------------------------------------------------------------🚀`);
            })
        }
    }, [inputs[paramCodigoPostal]]);

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    }

    const handleChange = (event) => {
        event.persist();
        let value = event.target.value.trim()
        if (event.target.name === paramColonia)
            if (value === 'otro') {
                setOtro(true)
            } else {
                setOtro(false)
            }
        let validacion = validarCampo(value, event.target.name)
        if (validacion.correcto) {
            setInputs(inputs => ({ ...inputs, [event.target.name]: value }))
            setInputsValue(event.target.name, value)
            setInputsErrors(validacion.errores)
        } else {
            setInputs(inputs => ({ ...inputs, [event.target.name]: value }))
            setInputsValue(event.target.name, value)
            setInputsErrors(validacion.errores)
        }
    }

    return (
        <>
            <div className="row_ext">
                <div className={["form-group", "bmd-form-group", "col-50", ((focus[paramCodigoPostal] || inputs[paramCodigoPostal] !== '') ? "is-focused" : ''), inputsErrors[paramCodigoPostal] && 'error'].join(" ")}>
                    <label htmlFor={paramCodigoPostal} className="bmd-label-floating">Código Postal:</label>
                    <input type="number" max="99999" className="form-control none_border" id={paramCodigoPostal} name={paramCodigoPostal} defaultValue={inputs[paramCodigoPostal]} onChange={handleChange} onFocus={e => {
                        setFocus(focus => ({ ...focus, [paramCodigoPostal]: true }))
                    }} onBlur={event => {
                        if (inputs[paramCodigoPostal] !== event.target.value) {
                            handleChange(event)
                        }
                        if (inputs[paramCodigoPostal] || event.target.value === '') {
                            setFocus(focus => ({ ...focus, [paramCodigoPostal]: false }))
                        }
                    }
                    }
                        maxLength="5" onInput={e => maxLengthCheck(e)}
                        onPaste={onPaste}
                        onCut={onCut} />
                    {inputsErrors[paramCodigoPostal] && (
                        <span id={"ht-" + paramCodigoPostal} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramCodigoPostal]}</span>
                    )}
                </div>
            </div>
            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                <label htmlFor={paramPais} className="bmd-label-floating">País:</label>
                <select className={["new-custom-select", inputsErrors[paramPais] && 'red_border'].join(" ")} id={paramPais} name={paramPais} onChange={handleChange} value={inputs[paramPais]} >
                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                    {
                        paises.map(
                            (objeto, index) => (
                                <option key={`${objeto.id}`} value={`${objeto.descripcion}`}>{objeto.descripcion}</option>
                            )
                        )
                    }
                </select>
                {inputsErrors[paramPais] && (
                    <span id={"ht-" + paramPais} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramPais]}</span>
                )}
            </div>
            <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                <label htmlFor={paramEntidadFederativa} className="bmd-label-floating">Estado / Provincia / Región:</label>
                <select className={["new-custom-select", inputsErrors[paramEntidadFederativa] && 'red_border'].join(" ")} id={paramEntidadFederativa} name={paramEntidadFederativa} onChange={handleChange} value={inputs[paramEntidadFederativa].toLowerCase()} >
                    <option value="" disabled="disabled" id="uno">Seleccionar:</option>
                    {
                        entidadesFederativas.map(
                            (objeto, index) => (
                                <option key={`${objeto.id}`} value={`${objeto.descripcion.toLowerCase()}`}>{objeto.descripcion}</option>
                            )
                        )
                    }
                </select>
                {inputsErrors[paramEntidadFederativa] && (
                    <span id={"ht-" + paramEntidadFederativa} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramEntidadFederativa]}</span>
                )}
            </div>
            <div className={["form-group", "bmd-form-group", "col-50", ((focus[paramMunicipio] || inputs[paramMunicipio] !== '') ? "is-focused" : ''), inputsErrors[paramMunicipio] && 'error'].join(" ")}>
                <label htmlFor={paramMunicipio} className="bmd-label-floating">Alcaldía / Municipio:</label>
                <input type="text" className="form-control none_border" id={paramMunicipio} name={paramMunicipio} defaultValue={inputs[paramMunicipio]} onChange={handleChange} onFocus={e => {
                    setFocus(focus => ({ ...focus, [paramMunicipio]: true }))
                }} onBlur={event => {
                    if (inputs[paramMunicipio] !== event.target.value) {
                        handleChange(event)
                    }
                    if (inputs[paramMunicipio] || event.target.value === '') {
                        setFocus(focus => ({ ...focus, [paramMunicipio]: false }))
                    }
                }
                }
                    onPaste={onPaste}
                    onCut={onCut} />
                {inputsErrors[paramMunicipio] && (
                    <span id={"ht-" + paramMunicipio} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramMunicipio]}</span>
                )}
            </div>
            <div className={["form-group", "bmd-form-group", "is-focused", inputsErrors[paramColonia] && 'error'].join(" ")}>
                <label htmlFor={paramColonia} className="bmd-label-floating">Colonia / Asentamiento:</label>
                <select className={["new-custom-select", inputsErrors[paramColonia] && 'red_border'].join(" ")} id={paramColonia} name={paramColonia} onChange={handleChange} value={inputs[paramColonia]} >
                    <option value='' disabled>Seleccionar:</option>
                    {
                        colonias.map(
                            (objeto, index) => (
                                <option key={`${index}`} value={`${objeto}`}>{objeto}</option>
                            )
                        )
                    }
                    <option value="otro" >Otro</option>
                </select>
                {inputsErrors[paramColonia] && (
                    <span id={"ht-" + paramColonia} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramColonia]}</span>
                )}
            </div>
            {(otro) &&
                <div className={["form-group", "bmd-form-group", ((focus[paramOtraColonia] || inputs[paramOtraColonia] !== '') ? "is-focused" : ''), inputsErrors[paramOtraColonia] && 'error'].join(" ")}>
                    <label htmlFor={paramOtraColonia} className="bmd-label-floating">Colonia / Asentamiento:</label>
                    <input type="text" className="form-control none_border" id={paramOtraColonia} name={paramOtraColonia} defaultValue={inputs[paramOtraColonia]} onChange={handleChange} onFocus={e => {
                        setFocus(focus => ({ ...focus, [paramOtraColonia]: true }))
                    }} onBlur={event => {
                        if (inputs[paramOtraColonia] !== event.target.value) {
                            handleChange(event)
                        }
                        if (inputs[paramOtraColonia] === '' || event.target.value === '') {
                            setFocus(focus => ({ ...focus, [paramOtraColonia]: false }))
                        }
                    }}
                        onPaste={onPaste}
                        onCut={onCut} />
                    {inputsErrors[paramOtraColonia] && (
                        <span id={"ht-" + paramOtraColonia} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramOtraColonia]}</span>
                    )}
                </div>}
            <div className={["form-group", "bmd-form-group", ((focus[paramCalle] || inputs[paramCalle] !== '') ? "is-focused" : ''), inputsErrors[paramCalle] && 'error'].join(" ")}>
                <label htmlFor={paramCalle} className="bmd-label-floating">Calle:</label>
                <input type="text" className="form-control none_border" id={paramCalle} name={paramCalle} defaultValue={inputs[paramCalle]} onChange={handleChange} onFocus={e => {
                    setFocus(focus => ({ ...focus, [paramCalle]: true }))
                }} onBlur={event => {
                    if (inputs[paramCalle] !== event.target.value) {
                        handleChange(event)
                    }
                    if (inputs[paramCalle] === '' || event.target.value === '') {
                        setFocus(focus => ({ ...focus, [paramCalle]: false }))
                    }
                }}
                    onPaste={onPaste}
                    onCut={onCut} />
                {inputsErrors[paramCalle] && (
                    <span id={"ht-" + paramCalle} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramCalle]}</span>
                )}
            </div>
            <div className="row">
                <div className="col-6">
                    <div className={["form-group", "bmd-form-group", ((focus[paramNumeroCalleExterior] || inputs[paramNumeroCalleExterior] !== '') ? "is-focused" : ''), inputsErrors[paramNumeroCalleExterior] && 'error'].join(" ")}>
                        <label htmlFor={paramNumeroCalleExterior} className="bmd-label-floating">Número exterior:</label>
                        <input type="text" className="form-control none_border" id={paramNumeroCalleExterior} name={paramNumeroCalleExterior} defaultValue={inputs[paramNumeroCalleExterior]} onChange={handleChange} onFocus={e => {
                            setFocus(focus => ({ ...focus, [paramNumeroCalleExterior]: true }))
                        }} onBlur={event => {
                            if (inputs[paramNumeroCalleExterior] !== event.target.value) {
                                handleChange(event)
                            }
                            if (inputs[paramNumeroCalleExterior] === '' || event.target.value === '') {
                                setFocus(focus => ({ ...focus, [paramNumeroCalleExterior]: false }))
                            }
                        }}
                            onPaste={onPaste}
                            onCut={onCut} />
                        {inputsErrors[paramNumeroCalleExterior] && (
                            <span id={"ht-" + paramNumeroCalleExterior} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramNumeroCalleExterior]}</span>)}
                    </div>
                </div>
                <div className="col-6">
                    <div className={["form-group", "bmd-form-group", ((focus[paramNumeroCalleInterior] || inputs[paramNumeroCalleInterior] !== '') ? "is-focused" : ''), inputsErrors[paramNumeroCalleInterior] && 'error'].join(" ")}>
                        <label htmlFor={paramNumeroCalleInterior} className="bmd-label-floating">Número interior:</label>
                        <input type="text" className="form-control none_border" id={paramNumeroCalleInterior} name={paramNumeroCalleInterior} defaultValue={inputs[paramNumeroCalleInterior]} onChange={handleChange} onFocus={e => {
                            setFocus(focus => ({ ...focus, [paramNumeroCalleInterior]: true }))
                        }} onBlur={event => {
                            if (inputs[paramNumeroCalleInterior] !== event.target.value) {
                                handleChange(event)
                            }
                            if (inputs[paramNumeroCalleInterior] === '' || event.target.value === '') {
                                setFocus(focus => ({ ...focus, [paramNumeroCalleInterior]: false }))
                            }
                        }}
                            onPaste={onPaste}
                            onCut={onCut} />
                        {inputsErrors[paramNumeroCalleInterior] && (
                            <span id={"ht-" + paramNumeroCalleInterior} className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors[paramNumeroCalleInterior]}</span>)}
                    </div>
                </div>
            </div>
        </>
    );
};


export default FormularioDomicilio;
