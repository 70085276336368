import React, { useState } from 'react'
/* Componentes */
import Header from '../../headerNA'
import CancelarFlujo from '../modals/cancelar_flujo'
import IdTransaccion from '../../id_transaccion'
/* Funciones */
import { sendEventClick } from '../../../services/data'
/* Imágenes */
import BackLight from '../../../assets/videotoken/instrucciones/selfie_no_back_light.svg'
import Caps from '../../../assets/videotoken/instrucciones/selfie_no_caps.svg'
import FaceMask from '../../../assets/videotoken/instrucciones/selfie_no_face_mask.svg'
import Glasses from '../../../assets/videotoken/instrucciones/selfie_no_glasses.svg'

const Index = ({
    type,
    previousStep,
    startProcess,
    dataOtorgante,
    disabledButton,
    setEstado,
    setShowHelp,
    sendingVideo,
    setStartProcess,
    setIstart,
    setLoading,
    setShowCancelScreen,
    reiniciarProceso,
    cancelarProceso,
    cancelarServicioOTP,
}) => {

    /* Variable */
    const bloqueo = localStorage.getItem('bloqueo')
    /* Hooks */
    const [showCancel, setShowCancel] = useState(false)

    return (
        <>
            <div className="hollo_gif_bx show_id_hold container-flex" style={{ backgroundImage: 'linear-gradient(180deg, var(--color-secondary) 0%, var(--color-primary) 100%)', overflowY: 'scroll' }}>
                {(showCancel) &&
                    <CancelarFlujo
                        tipo={'instrucciones'}
                        type={type}
                        previousStep={previousStep}
                        sendingVideo={sendingVideo}
                        dataOtorgante={dataOtorgante}
                        setEstado={setEstado}
                        setShowCancel={setShowCancel}
                        setShowCancelScreen={setShowCancelScreen}
                        setLoading={setLoading}
                        reiniciarProceso={reiniciarProceso}
                        cancelarProceso={cancelarProceso}
                        cancelarServicioOTP={cancelarServicioOTP}
                    />
                }
                <div className='container-center-flex'>
                    <Header show={() => setShowHelp(true)} />
                    <div className="main_text_container text-left custom_padding">
                        <h1 className="animate__animated text-left title_punto_trader">Confirmación de identidad</h1>
                        <p className="calibri_punto_trader">Mantén tu <b className="calibri_bold_punto_trader">rostro visible, sin más personas</b> en la toma y <b className="calibri_bold_punto_trader">asegúrate</b> de lo siguiente:<br /></p>
                        <br></br>
                        <div className="items_video_token">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><img src={Glasses} height="64" alt="" /></td>
                                        <td><p className="instructions_video_token calibri_punto_trader">No utilices lentes</p></td>
                                    </tr>
                                    <tr>
                                        <td><img src={Caps} height="64" alt="" /></td>
                                        <td><p className="instructions_video_token calibri_punto_trader">No utilices gorras</p></td>
                                    </tr>
                                    <tr>
                                        <td><img src={FaceMask} height="64" alt="" /></td>
                                        <td><p className="instructions_video_token calibri_punto_trader">Retira tu cubreboca</p></td>
                                    </tr>
                                    <tr>
                                        <td><img src={BackLight} height="64" alt="" /></td>
                                        <td><p className="instructions_video_token calibri_punto_trader">Evita colocarte a contraluz</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='container-bottom-flex'>
                    <div className="action_buttons animate__animated animate__fadeIn animate__delay-1s">
                        <button type="button" onClick={() => { sendEventClick('Videotoken', 'Continuar'); setStartProcess((bloqueo && bloqueo === 'true') ? false : true); setIstart(false) }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader" disabled={startProcess || disabledButton}>Continuar</button>
                        <button onClick={() => { setShowCancel(true); sendEventClick('Videotoken', 'CANCELAR'); }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader" disabled={disabledButton}>
                            Cancelar</button>
                    </div>
                </div>
                {(type === 'flujo') && <IdTransaccion />}
            </div>

        </>
    );
}

export default Index;
