import React from 'react'
/* Librerías */
import { isIOS } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
/* Funciones */
import { cancelarINE, sendEventClick, generateZip, statusError, validarRedireccionamiento } from '../../../services/data'
/* Servicios */
import { cancelarRequest } from '../../../services/api'

const CancelarFlujo = ({
    tipo,
    type,
    previousStep,
    sendingVideo,
    dataOtorgante,
    setShowCancelScreen,
    setShowCancel,
    setLoading,
    setEstado,
    reiniciarProceso,
    cancelarProceso,
    cancelarServicioOTP,
}) => {

    /* Hooks */
    const history = useHistory()

    const cancelarFlujo = async () => {
        cancelarProceso()
        cancelarServicioOTP()
        if (type === 'flujo') {
            cancelarINE()
            cancelarRequest()
            sendEventClick('Videotoken', 'SÍ', { status: 'CANCELADO' })
            localStorage.setItem('flag', 'cancelado')
            setLoading(true)
            await generateZip('TRUNCOS', 'trunco', isIOS)
            await statusError("captura_video_token", 'Cancelado', "cancelado")
            localStorage.setItem('completado', 'true')
            if (!window.opener) {
                if (validarRedireccionamiento(dataOtorgante)) {
                    setTimeout(() => {
                        setLoading(false)
                        history.replace(previousStep)
                    }, 1000);
                } else {
                    setLoading(false)
                    setShowCancelScreen(true)
                    setShowCancel(false)
                    setEstado('cancelado')
                }
            }
        } else {
            setTimeout(() => {
                history.replace(previousStep)
            }, 300);
        }
    }

    const retomarFLujo = () => {
        setShowCancel(false)
        sendEventClick(`Videotoken ${type}`, 'NO', {})
        if (tipo === 'proceso' && !sendingVideo) {
            reiniciarProceso()
        }
    }

    return (
        <>
            <div className='modal fade show' style={{ display: 'block', color: '#212529', textAlign: 'left' }} role='dialog'>
                <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                            <button onClick={() => setShowCancel(false)} className='close' data-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <p className='calibri_punto_trader'>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                        </div>
                        <div className='modal-footer'>
                            <button onClick={retomarFLujo} className='btn btn-secondary text_color_punto_trader calibri_punto_trader text_size_punto_trader' data-dismiss='modal'>No</button>
                            <button type='button' onClick={() => cancelarFlujo()} className='btn btn-raised btn-primary button_punto_trader'>Sí</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CancelarFlujo;
