/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
/* Librerías */
import { useHistory } from 'react-router-dom'
import { isIOS } from 'react-device-detect'
/* Componentes */
import LivenessInteractivo from 'npm-liveness_interactivo-main'
import Loader from '../loader'
import CancelarFlujo from '../../pages/flujo/cancelar_flujo'
import ErrorMessageAlert from '../alerts_modals/error_message_alert'
import LimitIntentsAlert from '../alerts_modals/limit_intents'
import ErrorPermisos from '../error_permisos'
/* Funciones */
import {
    sendEventClick, evento, status, statusData, /* statusError ,*/ statusReintento, enviarImagenVideotoken,
    mapearRespuesta,
    setLocalStorageResultFace,
    mapearError,
    mapearMensajeError,
    convertBase64ToBlob,
    flowIncompleted,
    generateZip,
    // cancelarINE,
    // cancelarRENAPO,
    validarRedireccionamiento,
    mapearRespuestaPruebaVida
} from '../../services/data'
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
/* Servicios */
import { /* cancelarRequestRenapo, cancelarRequestIne, */ getDocuments, /* cancelarRequest */ } from '../../services/api';

const Index = ({
    apikey,
    uuidCliente,
    uuidTransaccion,
    dataOtorgante,
    service_call,
    previousStep,
    nextStep,
    cancelStep,
    type,
    intents,
}) => {

    /* Variables */
    const urlEnvironment = process.env.REACT_APP_URL
    const environment = (process.env.REACT_APP_SERVICE)
    const ambiente = (process.env.REACT_APP_SERVICE).replace('_', '')
    const host = 'api.' + ambiente + 'dicio.net'
    const bucket = 'dco-bucket-ciencia'
    /* Hooks */
    const history = useHistory()
    const [reintentos, setReintentos] = useState(0)
    const [intentos, setIntentos] = useState(0)
    const [mensajeError, setMensajeError] = useState('')
    const [response, setResponse] = useState('')
    const [errorStatus, setErrorStatus] = useState('')
    const [error, setError] = useState('')
    const [pathname, setPathname] = useState('')
    const [estado, setEstado] = useState('')
    const [bloqueo, setBloqueo] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorPermisos, setErrorPermisos] = useState(false)
    const [permisosDenegados, setPermisosDenegados] = useState(false)
    const [disableFinishButton, setDisableFinishButton] = useState(true)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [insertComponent, setInsertComponent] = useState(true)
    const [playStreaming, setPlayStreaming] = useState(false)
    const [cancelAction, setCancelAction] = useState(false)
    const [evalularImagen, setEvaluarImagen] = useState(false)
    const [retriFunction, setRetryFunction] = useState(null)
    const [imagen, setImagen] = useState(null)
    const [umbral, setUmbral] = useState(null)
    const [respuestaPersonaReal, setRespuestaReal] = useState(null)
    /* Refs */
    const refLiveness = useRef(null)

    useEffect(() => {
        if (type === 'flujo') {
            setPathname(window.location.pathname)
            status('captura_video_token_page', 'Captura Video Token')
        }
        setRetryFunction(() => functionOnRetryLiveness)

        return () => {
            if (refLiveness.current) {
                stopVideo()
                cancelarProceso()
                setInsertComponent(false)
            }
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
        if (dataOtorgante.length > 0) {
            setIntentos(Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_VIDEO_TOKEN', intents)))
            setUmbral(parseFloat(obtenerValorConfig(dataOtorgante, 'UMBRAL_SERVICIO_REAL', 0.95)))
            const bloqueo = localStorage.getItem('bloqueo')
            if (bloqueo && bloqueo === 'true') {
                setBloqueo(true)
                setDisableFinishButton(false)
                setReintentos(Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_VIDEO_TOKEN', intents)))
            } else {
                localStorage.setItem('bloqueo', 'false')
            }
        }
        return () => { }
    }, [dataOtorgante])

    useEffect(() => {
        if (reintentos > 0 && errorStatus) {
            if (type === 'flujo') enviarReintento('Intentos Video Token', reintentos);
            if (reintentos === intentos) {
                localStorage.setItem('bloqueo', 'true')
                if (type === 'pdf') {
                    setDisableFinishButton(false)
                    stopVideo()
                    cancelarProceso()
                    setTimeout(() => {
                        setInsertComponent(false)
                    }, 500)
                }
                if (type === 'flujo' && response) obtenerDocumentos(response)
            } else {
                if (type === 'flujo') enviarReintento('Captura Video Token', errorStatus)
            }
        }
    }, [reintentos, errorStatus, response])

    useEffect(() => {
        if (playStreaming && cancelAction) {
            setTimeout(() => {
                history.replace(previousStep, { type: type })
            }, 300)
        }
    }, [playStreaming, cancelAction])

    useEffect(() => {
        if (evalularImagen) enviarImagen(imagen)
    }, [evalularImagen, imagen])

    useEffect(() => {
        if (umbral !== null && respuestaPersonaReal !== null) {
            const { clase, score } = respuestaPersonaReal
            console.log(`🚀 -----------------------------------------------------🚀`)
            console.log(`🚀 ~ file: index.jsx:319 ~ getResponse ~ score:`, parseFloat(score.trim()))
            console.log(`🚀 -----------------------------------------------------🚀`)

            console.log(`🚀 -------------------------------------------------------🚀`)
            console.log(`🚀 ~ file: index.jsx:317 ~ getResponse ~ umbral:`, umbral)
            console.log(`🚀 -------------------------------------------------------🚀`)

            // if (0.95 <= umbral) {
            if (parseFloat(score.trim()) <= umbral) {
                const error = {
                    mensaje: 'Error en la validación del umbral',
                    umbral_servicio: umbral,
                    umbral_evaluacion: parseFloat(score.trim()),
                }
                evento(`Liveness Pasivo Interactivo - ${type}`, 'Error', error, false)
                setRetryFunction(() => functionOnRetryLiveness)
                setMensajeError('Lo sentimos detectamos inconsistencias en tu captura, por favor intenta nuevamente')
                setResponse(error)
                // setReintentos(reintentos => reintentos + 1)
                setErrorStatus('Error en la validación del umbral')
                setError(true)
                setRespuestaReal(null)
            } else {
                localStorage.setItem('scoreObject', JSON.stringify({
                    claseLiveness: clase.trim(),
                    porcentajeLiveness: parseFloat(score.trim())
                }))
                setEvaluarImagen(true)
            }
        }
    }, [umbral, respuestaPersonaReal]);


    const obtenerDocumentos = async (respuesta) => {
        try {
            const response = await getDocuments()
            const { status, data, data: { payload } } = response
            evento('Obtener documentos - ' + type, 'Succes', mapearRespuesta(status, data), true)
            if (status === 200) {
                if (payload.links.length > 0) {
                    let links = JSON.stringify(payload.links);
                    localStorage.setItem('links', links);
                }
            }
            setTimeout(() => {
                finalizarFlujo(respuesta)
            }, 300);
        } catch (error) {
            let errorMapeado = mapearError(error)
            evento('Obtener documentos - ' + type, errorMapeado.tipoError, errorMapeado.objetoError, false)
            setTimeout(() => {
                finalizarFlujo(respuesta)
            }, 300);
        }
    }

    const enviarImagen = async (image) => {
        if (image) {
            try {
                const imageBlob = await convertBase64ToBlob(image)
                const response = await enviarImagenVideotoken(imageBlob, isIOS, service_call)
                const { status, data, data: { payload } } = response
                if (status === 200) {
                    evento('Liveness Pasivo Interactivo - ' + type, 'Success', mapearRespuesta(status, data), true)
                    if (type === 'flujo') {
                        sendData(data.payload.match)
                        mapearRespuestaPruebaVida(payload)
                    }
                    setLocalStorageResultFace(data.payload)
                    if (type === 'pdf') localStorage.setItem('enlace', data.payload.documentLink)
                    setTimeout(() => {
                        history.replace(nextStep)
                    }, 300);
                }
            } catch (error) {
                let errorMapeado = mapearError(error)
                evento('Liveness Pasivo Interactivo - ' + type, errorMapeado.tipoError, errorMapeado.objetoError, false)
                if (errorMapeado.objetoError.tipo === 'RESPONSE') {
                    setResponse(errorMapeado)
                    let mensaje = ''
                    if (error.response.data) {
                        mensaje = error.response.data.message_client
                    }
                    let errorMessage = mapearMensajeError(mensaje)
                    if (error.response.status === 500 || error.response.status === 400) {
                        if (mensaje === 'No se encontro rostro' || mensaje === 'Se detecto cubrebocas' || mensaje === 'Condiciones de luz no adecuadas' || mensaje === 'Imagen borrosa' || mensaje === 'Se detectaron multiples caras' || mensaje === 'Falta de luz' || mensaje === 'No se pudo realizar la comparacion de rostro' || mensaje === 'No logramos detectar un rostro') {
                            setMensajeError(errorMessage)
                            setErrorStatus(errorMessage)
                            setError(true)
                            setLoading(false)
                        } else {
                            if (mensaje === 'Lo sentimos no hay coincidencia entre tu selfie registrada y la persona actual') {
                                statusData("captura_video_token", { coincidencia: false })
                            }
                            setMensajeError(errorMessage)
                            setErrorStatus(errorMessage)
                            setLoading(false)
                            setReintentos(reintentos => reintentos + 1)
                            setError(true)
                        }
                    } else if (error.response.status === 404) {
                        setMensajeError(errorMessage)
                        setErrorStatus(errorMessage)
                        setError(true)
                        setLoading(false)
                        setReintentos(reintentos => reintentos + 1)
                    } else {
                        setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        setError(true)
                        setLoading(false)
                    }
                } else {
                    if (error.toString().includes('Network')) {
                        setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        setError(true)
                        setLoading(false)
                    } else if (error.toString().includes('cancel')) {
                        setMensajeError('Se canceló el flujo')
                        setErrorStatus('Se canceló el flujo')
                        setError(true)
                        setLoading(false)
                    } else {
                        setMensajeError('El servicio no se encuentra disponible, lo solucionaremos en breve')
                        setErrorStatus('Error en la captura')
                        setError(true)
                        setLoading(false)
                    }
                }
            }
        }
    }

    const finalizarFlujo = async (response) => {
        stopVideo()
        cancelarProceso()
        setInsertComponent(false)
        setLoading(true)
        await generateZip('SEMAFORO', 'red', isIOS)
        localStorage.setItem('bloqueo', 'true')
        const newDate = new Date().getTime()
        const acceptDta = new Date()
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem('time', acceptDta.getTime())
        const res = convertdataJSON();
        const event_t = await JSON.stringify(res)
        const json_final = {
            'status': 'FAIL',
            'step': 'finished',
            'description': event_t,
            'response': response,
            'finished': true
        }
        const event = JSON.stringify(json_final)
        await flowIncompleted({ 'step': 'captura_video_token', 'event': event }, 'red')
        setLoading(false)
        setDisableFinishButton(false)
    }

    const convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem('uuidUser') || '';
        jsonObj.uuidTrx = localStorage.getItem('uuidTrx') || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem('ocrFront')) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem('ocrBack')) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem('data_user')) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    const redirect = () => {
        if (type === 'flujo') {
            if (validarRedireccionamiento(dataOtorgante)) {
                history.replace(cancelStep)
            } else {
                setShowCancelScreen(true)
                setEstado('fallido')
            }
        } else {
            history.replace(cancelStep)
        }
    }

    const cancelarFlujoIntento = () => {
        sendEventClick(`Liveness Pasivo Interactivo - ${type}`, 'CANCELAR')
        history.replace(previousStep, { type: type })
    }

    const enviarReintento = (step, description) => {
        statusReintento(step, description, 'RETRY');
        // statusData('captura_video_token', { coincidencia: false })
    }

    const sendData = (match) => {
        //Cuando es exitoso, revisar la bandera  de speech
        statusData('captura_video_token', { coincidencia: match })
    }

    const getPermissions = () => {
        setPermisosDenegados(false)
        window.location.reload()
    }

    /* Mapear respuestas componente */
    const getResponse = response => {
        // console.log('RESPONSE', response)
        const { mensaje } = response
        switch (mensaje) {
            case 'Persona real':
                evento(`Liveness Pasivo Interactivo - ${type}`, 'Success', response, true)
                setRespuestaReal(response)
                break
            case 'Cancelar':
                sendEventClick(`Liveness Pasivo Interactivo - ${type}`, 'CANCELAR')
                setCancelAction(true)
                break
            case 'No se detectó rostro válido':
                evento(`Liveness Pasivo Interactivo - ${type}`, 'Error', response, false)
                setRetryFunction(() => functionOnRetryLiveness)
                setMensajeError('Lo sentimos detectamos inconsistencias en tu captura, por favor intenta nuevamente')
                setResponse(response)
                setReintentos(reintentos => reintentos + 1)
                setErrorStatus(mensaje)
                setError(true)
                break
            case 'Se excedio el tiempo':
                evento(`Liveness Pasivo Interactivo - ${type}`, 'Error', response, false)
                setRetryFunction(() => functionOnRetryLiveness)
                setMensajeError('No hemos logrado validar tu captura, por favor sigue las instrucciones e intenta nuevamente')
                setResponse(response)
                setReintentos(reintentos => reintentos + 1)
                setErrorStatus(mensaje)
                setError(true)
                break
            case 'Reproduciendo streaming':
                setPlayStreaming(true)
                const bloqueo = localStorage.getItem('bloqueo')
                if (bloqueo && bloqueo === 'true') {
                    stopVideo()
                    cancelarProceso()
                    setInsertComponent(false)
                }
                break
            case 'Falló la carga del modelo':
                evento(`Liveness Pasivo Interactivo - ${type}`, 'Error', response, false)
                setRetryFunction(() => loadModels)
                setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                setErrorStatus(mensaje)
                setError(true)
                break
            case 'Error en el servicio de guardar imagen':
                evento(`Liveness Pasivo Interactivo - ${type}`, 'Error', response, false)
                break
            default:
                evento(`Liveness Pasivo Interactivo - ${type}`, 'Error', response, false)
                setRetryFunction(() => functionOnRetryLiveness)
                setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                setErrorStatus(mensaje)
                setError(true)
                break
        }
    }

    const getImage = payload => {
        const image = payload.imagen;
        setImagen(image)
        // enviarImagen(image)
    }
    const getErrorPermissions = error => {
        const { status } = error
        if (status === 'PERMISOS DENEGADOS') {
            setPermisosDenegados(true)
        }
        evento(`Liveness Pasivo Interactivo - ${type}`, 'User Media', status, true)
        setErrorPermisos(true)
    }

    /* Funciones componente */
    const stopVideo = () => {
        if (refLiveness.current) refLiveness.current.detenerVideo()
    }

    const cancelarProceso = () => {
        if (refLiveness.current) refLiveness.current.cancelarProceso()
    }

    const loadModels = async () => {
        setError(false)
        setMensajeError('')
        setErrorStatus('')
        sendEventClick(`Liveness Pasivo Interactivo - ${type}`, 'REINTENTAR ')
        if (refLiveness.current) await refLiveness.current.cargarModelos()
    }

    const functionOnRetryLiveness = () => {
        setError(false)
        setMensajeError('')
        setErrorStatus('')
        sendEventClick(`Liveness Pasivo Interactivo - ${type}`, 'REINTENTAR ')
        if (refLiveness.current) refLiveness.current.reiniciarProceso()
    }

    return (
        <>
            <div id='webview' className=''>
                {insertComponent &&
                    <LivenessInteractivo
                        uuidTransaccion={uuidTransaccion}
                        uuidCliente={uuidCliente}
                        uuidOtorgante={localStorage.getItem('uuidOtorgante')}
                        apikey={apikey}
                        environment={environment}
                        urlEnvironment={urlEnvironment}
                        bucket={bucket}
                        host={host}
                        getErrorPermissions={getErrorPermissions}
                        getResponse={getResponse}
                        getImage={getImage}
                        videoFolder='test-pruebasFront'
                        imageFolderReal={ambiente}
                        imageFolderSpoof={ambiente}
                        ref={refLiveness}
                    />
                }
            </div>

            {showCancelScreen &&
                <CancelarFlujo
                    uuidTransaccion={uuidTransaccion}
                    dataOtorgante={dataOtorgante}
                    estado={estado} />}

            {(error && (reintentos < intentos)) &&
                <ErrorMessageAlert
                    errorMessage={mensajeError}
                    functionOnRetry={retriFunction}
                    functionOnCancel={cancelarFlujoIntento}
                />
            }

            {((reintentos >= intentos) || bloqueo) &&
                <LimitIntentsAlert
                    functionOnOut={redirect}
                    uuidTransaccion={uuidTransaccion}
                    dataOtorgante={dataOtorgante}
                    pathname={pathname}
                    disableFinishButton={disableFinishButton}
                />
            }

            {loading && (
                <Loader />
            )}

            {errorPermisos &&
                <ErrorPermisos
                    permisosDenegados={permisosDenegados}
                    setErrorPermisos={setErrorPermisos}
                    permisoCamara={getPermissions}
                />
            }
        </>
    );
}

export default Index;
