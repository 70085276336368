import React from "react";

export default function IdTransaccion() {
    let uuidTransaccion = localStorage.getItem('uuidTrx');

    return (
        <div className="id-trans-bottom flex_id_footer animate__animated animate__fadeIn animate__delay-2s">
            <p className="animate__animated calibri_punto_trader txt_trans_comp">FP: <b>{uuidTransaccion && uuidTransaccion.split("-")[0]}</b></p> 
        </div>
    )
}