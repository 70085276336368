import React from 'react';

const NotFound = () => {
    return (
        <>
            <div className="err_finale  animate__animated animate__slideInUp">
                <div className="center_checks">
                    <h5>Verifica tu URL</h5>
                    <p>Lo sentimos la dirección que buscas no se encuentra o es incorrecta, por favor verifica la información e intenta nuevamente.
                    </p>
                    {/* <br />
                            <div className="action_buttons noscroll_screen">
                                <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 button_punto_trader">Reintentar</button>
                            </div> */}
                </div>
            </div>
        </>
    );
}

export default NotFound;
