/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
// Libraries and dependencies
import { useHistory, useLocation } from 'react-router-dom'
import { isMobile, withOrientationChange } from 'react-device-detect'
// Custom functions
import { sendFile } from '../services/api.js'
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import {
    hayExcepcion, statusError, statusData, status, evento, sendEventClick,
    mapErrorGetUserMedia, mapearRespuesta, mapearError
} from '../services/data'
// Custom components
import Loader from "../components/loader"
import ComponenteRecorte from './captura_identificacion/componente_recorte'
import AlertaValidacionDimensiones from './captura_identificacion/alerta_validacion_dimensiones.js'
import ErrorPermissions from "./error_permisos"

let stream = null

let CameraPhotoComprobante = ({ isLandscape }) => {
    // Hooks variables
    const [apikey, setApikey] = useState('')
    const [errorF, setErrorF] = useState('')
    const [intentsData, setIntentsData] = useState(0)
    const [uuid, setUuid] = useState(null)
    const [uuidTrx, setUuidTrx] = useState(null)
    const [uuidOtorgante, setUuidOtorgante] = useState(null)
    const [objectConfig, setObjectConfig] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [proccessing, setProccessing] = useState(false)
    const [showHoloAnimation, setShowHoloAnimation] = useState(true)
    const [errorMsg, setErrorMsg] = useState(false)
    const [errorVideo, setErrorVideo] = useState(false)
    const [preview, setPreview] = useState(false)
    const [capturing, setCapturing] = useState(false)
    const [showAlertDimensions, setShowAlertDimensions] = useState(false)
    const [errorPermissions, setErrorPermissions] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])

    //Referencias
    const refCanvas = useRef(null)
    const refVideo = useRef(null)

    // Navigation
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApikey(apikeyOtorgante)

        const uuidOtor = localStorage.getItem("uuidOtorgante")
        if (uuidOtor) setUuidOtorgante(uuidOtor)

        const uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser) setUuid(uuidUser)

        const uuidTrans = localStorage.getItem("uuidTrx")
        if (uuidTrans) setUuidTrx(uuidTrans)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            configStyle(dataOtorganteJSON)
        }

        if (!isMobile) {
            setShowHoloAnimation(false)
            refVideo.current.classList.add('myVideo_rotate')
        } else {
            if (hayExcepcion()) {
                setShowHoloAnimation(false)
            }
        }

        const responseConfig = setConfig()
        setObjectConfig(responseConfig)
        status(responseConfig.step, responseConfig.description)

        permisoCamara()

        return () => {
            stopVideoLocal()
        }
    }, [])

    const permisoCamara = async () => {
        const deviceId = localStorage.getItem('deviceId')
        if (stream) {
            stream.getTracks().forEach(track => {
                track.stop()
            })
        }

        const video_constraints = {
            width: { ideal: 640 },
            height: { ideal: 480 },
            advanced: [
                { width: 1280, height: 720 },
                { aspectRatio: 4 / 3 }
            ]
        }

        if (deviceId === 'user' || deviceId === 'environment') {
            video_constraints.facingMode = deviceId
        } else {
            video_constraints.deviceId = { exact: deviceId }
        }

        const constraints = {
            audio: false,
            video: video_constraints,
        }

        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
            stream = mediaStream
            refVideo.current.srcObject = mediaStream
            try {
                refVideo.current.onloadedmetadata = function (e) {
                    refVideo.current.play()
                    if (mediaStream.getVideoTracks().length > 0) {
                        //console.log("disponibles");
                        setErrorVideo(false)
                    } else {
                        //console.log("No disponibles");    
                        setErrorVideo(true)
                    }
                }
                refVideo.current.addEventListener('canplay', function () {
                    refCanvas.current.setAttribute('width', refVideo.current.videoWidth * 1.5)
                    refCanvas.current.setAttribute('height', refVideo.current.videoHeight * 1.5)
                }, false)
            } catch (error) {
                setErrorVideo(true)
            }

        } catch (error) {
            handleErrorMediaUser(error)
            setErrorVideo(true)
        }

    }

    const capturarImagen = () => {
        //console.log("en foto");
        var urlCreator = window.URL || window.webkitURL
        setTimeout(() => {
            const contextCanvas = refCanvas.current.getContext('2d')
            contextCanvas.drawImage(refVideo.current, 0, 0, refVideo.current.videoWidth * 1.5, refVideo.current.videoHeight * 1.5)
            refCanvas.current.toBlob((blob) => {
                blob.lastModifiedDate = new Date()
                blob.name = objectConfig.imageName + '.jpeg'
                setIntentsData(0)
                setPreview(true)
                setImageUrl(urlCreator.createObjectURL(blob))
                setLoading(false)
                setProccessing(false)
            }, 'image/jpeg', 0.9);
        }, 1050);
    }

    const initRecord = () => {
        sendEventClick(setConfig().description, 'CAPTURAR COMPROBANTE')
        setLoading(true)
        setCapturing(true)
        capturarImagen()
    }

    const statusF = () => {
        statusError(setConfig().step, errorF, "error")
    }

    const cancelarCaptura = () => {
        stopVideoLocal()
        sendEventClick(setConfig().description, 'CANCELAR')
        setLoading(false)
        setProccessing(false)
        setIntentsData(0)
        history.replace(objectConfig.cancelStep)
    }

    const stopVideoLocal = () => {
        // Esta función solo detiene el streaming del tag de video, no detiene el proceso
        if (stream) stream.getTracks().forEach(track => track.stop())
    }

    const enviarImagen = async (blobImage) => {
        setProccessing(true)
        try {
            const response = await sendFile(blobImage, objectConfig.folder, objectConfig.fileName)
            const { status, data } = response
            if (status === 200) {
                // Detener cámara
                stopVideoLocal()
                evento(setConfig().description, 'Success', mapearRespuesta(status, data), true)
                statusData(objectConfig.stepCapture, { capturado: true })
                setTimeout(() => {
                    history.replace({
                        pathname: objectConfig.nextStep
                    })
                }, 200)
            }

        } catch (error) {
            const errorMapeado = mapearError(error)
            evento('Obtener parametros otrogante', errorMapeado.tipoError, errorMapeado.objetoError, false)
            setErrorMsg("Por favor captura nuevamente tu comprobante.")
            setErrorF("Error en la captura de comprobante")
            setLoading(false)
            statusF()
        } finally {
            setLoading(false)
            setProccessing(false)
        }
    }

    const setConfig = () => {
        const caseComprobante = location.state.tipoComprobante;
        const config = {};
        switch (caseComprobante) {
            case 'domicilio':
                config.step = 'captura_comprobante_domicilio_page'
                config.stepCapture = 'captura_comprobante_domicilio'
                config.description = 'Captura Comprobante Domicilio'
                config.fileName = '_comprobante_domicilio.jpeg'
                config.imageName = 'comprobante_domicilio'
                config.folder = '/domicilio'
                config.nextStep = '/preparacion_comprobante_bancario'
                config.cancelStep = '/preparacion_comprobante_domicilio'
                break;
            case 'estadoCuenta':
                config.step = 'captura_comprobante_bancario_page'
                config.stepCapture = 'captura_comprobante_bancario'
                config.description = 'Captura Comprobante Bancario'
                config.fileName = '_comprobante_bancario.jpeg';
                config.imageName = 'comprobante_bancario';
                config.folder = '/comprobante_bancario';
                config.cancelStep = '/preparacion_comprobante_bancario'
                if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                    config.nextStep = '/clausula'
                } else if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                    config.nextStep = '/autorizacion_solicitud'
                } else {
                    config.nextStep = '/preparacion_video_grabacion'
                }
                break
            default:
                break
        }
        return config
    }

    const handleErrorMediaUser = (error) => {
        const errorObject = mapErrorGetUserMedia(error)
        evento(setConfig().description, 'User Media', errorObject, true)
        if (errorObject.status === 'PERMISOS DENEGADOS')
            setErrorPermissions(true);
        else
            setErrorVideo(true);
    }

    return (
        <div className="container">
            <div className="module_container overflow_hddn" style={{ position: 'fixed' }}>
                <div className="camera_capture_frame">
                    <video ref={refVideo} id="video_wrt" playsInline></video>
                    <canvas ref={refCanvas} id="canvas_wrt_environment" hidden></canvas>
                </div>
                <div className="module_gradient_overlay"></div>
                <div className="module_title animated slideInDown">
                    <p className="calibri_punto_trader text_size_punto_trader">Captura la <b className="calibri_bold_punto_trader">portada</b> de tu documento</p>
                </div>
                <div className="alert_instruction">
                    <img height="24" src="images/evita_reflejos_warn.png" alt="" />
                </div>
                <div>
                    <img className={isMobile ? "document_msk" : "document_msk_dsk"} src={isMobile ? "images/doc_check_msk_a.svg" : "images/doc_check_msk_a_dsk.svg"} alt="" />
                </div>

                <div className="module_buttons animated fadeIn delay-3s">

                    <button type="button" onClick={() => initRecord()} className={["btn btn-raised btn-primary forcewidth100", (capturing ? "button_gray_punto_trader" : "button_punto_trader"), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={proccessing || loading}>{(proccessing || loading ? "Procesando..." : "Capturar comprobante")}</button>
                    <button type="button" onClick={() => cancelarCaptura()} className={["btn btn-raised btn-primary forcewidth100 button_outline_punto_trader", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={proccessing || loading}>Cancelar</button>
                </div>

            </div>

            {(proccessing) && <Loader />}

            {((loading && !showHoloAnimation) && <div className="sprite_stay_a animado"></div>)}
            {
                (isLandscape) && (
                    <div className="rotate_device">
                        <div className="center_info_bx">
                            <img src="images/rotate_device.svg" height="100" alt="" />
                            <p className="calibri_punto_trader">Por favor usa tu dispositivo en vertical<br />
                                <small>Gira tu dispositivo para continuar</small>
                            </p>
                        </div>
                    </div>
                )
            }
            {(intentsData === 1) &&
                <div className="overlay_box">
                    <div className="alert_box">
                        <p className="animated slideInDown">No se pudo leer correctamente tus datos, por favor vuelve a intentar</p>
                        <button type="button" onClick={() => {
                            setIntentsData(2)
                        }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader alert_btn  animated fadeIn delay-2s">Volver a intentar</button>
                    </div>
                </div>
            }
            {(errorMsg) &&
                <div className="overlay_box">
                    <div className="alert_box">
                        <p className="calibri_punto_trader animated slideInDown">Se ha detectado un bloqueo de red, verifica tu conexión de internet</p>
                        <button type="button" onClick={() => {
                            setErrorMsg(false);
                        }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader alert_btn  animated fadeIn delay-2s">Volver a intentar</button>
                    </div>
                </div>
            }
            {(errorVideo) &&
                <div className="overlay_box">
                    <div className="alert_box">
                        <p className="calibri_punto_trader animated slideInDown">Hemos detectado que la <b className="calibri_bold_punto_trader">cámara de tu dispositivo</b> está siendo usada por otra web en <b className="calibri_bold_punto_trader">alguna pestaña</b>, por favor asegúrate de <b className="calibri_bold_punto_trader">cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                        <button type="button" onClick={() => {
                            setErrorVideo(false)
                            window.location.reload()
                            //this.props.history.push("/identificacion")
                        }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader alert_btn  animated fadeIn delay-2s">Volver a intentar</button>
                    </div>
                </div>
            }
            {preview && <ComponenteRecorte
                tipo='comprobante'
                imageUrl={imageUrl}
                enviarImagen={blobImage => enviarImagen(blobImage)}
                reload={() => {
                    setPreview(false)
                    setCapturing(false)  
                    sendEventClick(setConfig().description, 'CAPTURAR DE NUEVO', {})
                }}
                side={'frente'}
                configuration={{
                    apikey: apikey,
                    dataOtorgante: dataOtorgante,
                    uuidUser: uuid,
                    uuidTransaccion: uuidTrx,
                    uuidOtorgante: uuidOtorgante
                }}
                setShowAlertDimensions={setShowAlertDimensions}
            />}
            {showAlertDimensions && <AlertaValidacionDimensiones setShowAlertDimensions={setShowAlertDimensions} />}
            {errorPermissions && <ErrorPermissions permisosDenegados={errorPermissions} setErrorPermisos={setErrorPermissions} permisoCamara={permisoCamara} />}
        </div>
    )


}

CameraPhotoComprobante = withOrientationChange(CameraPhotoComprobante)
export default CameraPhotoComprobante