import React from "react";

const ValidacionDispositivo = (props) => {
    const { setValidateStatus } = props;

    return (
        <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title ubuntu_bold_punto_trader">Queremos que tengas la mejor experiencia </h5>
                    </div>
                    <div className="modal-body">
                        <p className="calibri_punto_trader">Tu dispositivo <b>no cumple</b> con los <b>requisitos mínimos</b> para garantizar una experiencia <b>óptima</b> en el proceso.</p>
                        <p className="calibri_punto_trader">Un dispositivo de gama <b>media</b> o <b>alta</b> es suficiente</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => setValidateStatus(false)} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader" >Probaré en éste dispositivo</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ValidacionDispositivo;