/* Librerías */
import { isMobile, isAndroid } from 'react-device-detect'
/* Funciones */
import { evento } from '../../services/data'
import { mapErrorGetUserMedia } from '../../services/data'

//Función que obtiene los permisos para acceder a la cámara y micrófono
export const getPermissions = (setPermissionsDenied, setVideoDevices, setErrorPermissions, setLoading) => {
    return navigator.mediaDevices.getUserMedia(
        { video: true, audio: true }
    ).then(async (mediaStream) => {
        setPermissionsDenied(false)
        const videoTag = document.createElement('video');
        if (videoTag) {
            videoTag.srcObject = mediaStream;
        }
        stopTrack(mediaStream);
        await getVideoDevices(setVideoDevices);
        setLoading(false);
    }).catch(error => {
        handleErrorGetUserMedia(error, setErrorPermissions, setLoading, setPermissionsDenied);
    })
}

/* Obtener dipositivos de video */
const getVideoDevices = (setVideoDevices) => {
    const newArrayVideoDevices = []
    return navigator.mediaDevices.enumerateDevices().then((devices) => {
        //console.log('DEVICES => ', devices);
        devices.forEach(device => {
            if (device.kind === 'videoinput') {
                newArrayVideoDevices.push(device);
            }
        });
        evento('Obtener dispositivos de video', 'Medios dispositivo', { dispositivos: newArrayVideoDevices }, true);
        setVideoDevices(newArrayVideoDevices);
    }).catch(error => {
        evento('Obtener dispositivos de video', 'Medios dispositivo', { error: error.toString() }, true);
    })
}

/* Obtener deviceId */
export const getDeviceId = async (devices, setId, setErrorPermissions, setLoading, setPermissionsDenied) => {
    console.log(`🚀 --------------------------------------------------------🚀`)
    console.log(`🚀 ~ file: get_id.js:40 ~ getDeviceId ~ devices:`, devices)
    console.log(`🚀 --------------------------------------------------------🚀`)
    const url = new URL(window.location);
    const search_camera = url.searchParams.get('camera');
    if (devices.length > 2) {
        for (const device of devices) {
            try {
                const mediaStream = await loadStream(device);
                const mediaStreamTrack = mediaStream.getVideoTracks()[0];
                const { label } = mediaStreamTrack;
                if (label && validarEtiqueta(label, search_camera)) {
                    console.log(`🚀 ----------------------------------------------------🚀`)
                    console.log(`🚀 ~ file: get_id.js:52 ~ getDeviceId ~ label:`, label)
                    console.log(`🚀 ----------------------------------------------------🚀`)
                    evento('Obtener etiqueta del dispositivo de video', 'Medios dispositivo', { label: label }, true);
                    const { deviceId } = mediaStreamTrack.getCapabilities();
                    console.log(`🚀 ----------------------------------------------------------🚀`)
                    console.log(`🚀 ~ file: get_id.js:57 ~ getDeviceId ~ deviceId:`, deviceId)
                    console.log(`🚀 ----------------------------------------------------------🚀`)
                    evento('Obtener id del dispositivo de video (principal)', 'Medios dispositivo', { deviceId: deviceId }, true);
                    setDeviceId(deviceId, setId);
                    stopTrack(mediaStream);
                    break;
                }
                stopTrack(mediaStream);
            } catch (error) {
                evento('Error al obtener id del dispositivo de video', 'Medios dispositivo', { error: error.toString() }, true);
                handleErrorGetUserMedia(error, setErrorPermissions, setLoading, setPermissionsDenied);
            }
        }
    } else {
        (isMobile && search_camera === 'front') ? setDeviceId('user', setId) : setDeviceId('environment', setId);
    }
}

/* Detener track stream */
const stopTrack = (mediaStream) => {
    mediaStream.getTracks().forEach(track => {
        track.stop();
    });
}

/* Asignar deviceId */
export const setDeviceId = (id, setId) => {
    setId(id);
    localStorage.setItem('deviceId', id);
}

/* Obtener stream */
const loadStream = (device) => {
    return navigator.mediaDevices.getUserMedia({
        video: {
            deviceId: { exact: device.deviceId },
        }
    });
}

/* Manejo de errores */
const handleErrorGetUserMedia = (error, setErrorPermissions, setLoading, setPermissionsDenied) => {
    console.log(`🚀 ----------------------------------------------------------------🚀`)
    console.error(`🚀 ~ file: get_id.js:100 ~ handleErrorGeUserMedia ~ error:`, error)
    console.log(`🚀 ----------------------------------------------------------------🚀`)
    const errorObject = mapErrorGetUserMedia(error);
    if (errorObject.status === 'PERMISOS DENEGADOS') setPermissionsDenied(true);
    evento('Inicio', 'User Media', errorObject, true);
    setErrorPermissions(true);
    setLoading(false);
}

/* Validar etiqueta cuando el navegador detecta más de 2 dispositivos */
const validarEtiqueta = (label, search_camera) => {
    console.log(`🚀 ---------------------------------------------------------🚀`)
    console.log(`🚀 ~ file: get_id.js:129 ~ validarEtiqueta ~ label:`, label)
    console.log(`🚀 ---------------------------------------------------------🚀`)
    let validacion = false;
    const etiqueta = label.toLowerCase();
    if (etiqueta.includes((search_camera === 'front') ? search_camera : 'back')) {
        const palabra = 'front';
        validacion = validacionAndroid(etiqueta, search_camera, palabra);
    } else if (etiqueta.includes((search_camera === 'frontal') ? search_camera : 'trasera')) {
        const palabra = 'frontal';
        validacion = validacionAndroid(etiqueta, search_camera, palabra);
    } else if (etiqueta.includes((search_camera === 'frontal') ? search_camera : 'traseira')) {
        const palabra = 'frontal';
        validacion = validacionAndroid(etiqueta, search_camera, palabra);
    } else if (etiqueta.includes((search_camera === 'photo avant') ? search_camera : 'arrière')) {
        const palabra = 'photo avant';
        validacion = validacionAndroid(etiqueta, search_camera, palabra);
    }
    return validacion;
}

/* Validación */
const validacionAndroid = (etiqueta, search_camera, palabra) => {
    let validacion = false;
    if (isAndroid) {
        if (etiqueta.includes((search_camera === palabra) ? '1' : '0')) validacion = true;
    } else {
        validacion = true;
    }
    return validacion;
}