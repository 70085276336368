/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { useParams, useHistory } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid'
import { isChrome, isMobile, isIOS, isSafari, deviceDetect } from 'react-device-detect'
/* Componentes */
import Header from "../components/header"
import Ayuda from './ayuda'
import Loader from "../components/loader"
import ErrorPermissions from "../components/error_permisos"
import ValidacionDispositivo from "./validacion_dispositivo"
import IdTransaccion from "../components/id_transaccion";
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { initStatus, statusError, evento, validarDispositivo, mapearRespuesta, mapearError, setCancelarINE } from '../services/data'
import { getPermissions, getDeviceId } from "../utilities/permissions/get_id"
/* Servicios */
import { obtenerParametrosOtorgante } from '../services/api.js'
/* Custom hooks */
import { useScrollToTop } from '../utilities/hooks/custom'

const servicio = process.env.REACT_APP_SERVICE

export default function Inicio() {

    useScrollToTop()

    /* Params */
    const { apikey } = useParams()
    /* Hooks */
    const history = useHistory()
    const [nombreOtorgante, setNombreOtorgante] = useState(null)
    const [id, setId] = useState(null)
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [loading, setLoading] = useState(true)
    const [notSupported, setNotSupported] = useState(false)
    const [notSupportediOS, setNotSupportediOS] = useState(false)
    const [isCorrectApikey, setCorrectApikey] = useState(true)
    const [hayCookies] = useState(navigator.cookieEnabled)
    const [showHelp, setShowHelp] = useState(false)
    const [start, setStart] = useState(false)
    const [isOpenByPivot, setIsOpenByPivot] = useState(false)
    const [permissionsDenied, setPermissionsDenied] = useState(false)
    const [errorPermissions, setErrorPermissions] = useState(false)
    const [validateStatus, setValidateStatus] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [videoDevices, setVideoDevices] = useState([])

    useEffect(() => {
        const itemsDeleteLS = [
            "codes",
            "ocrFront",
            "ocrBack",
            "time",
            "bloqueo",
            "face",
            "fotos",
            "enlace",
            "data_user",
            "movil",
            "dataOtorgante",
            "apikeyOtorgante",
            "uuidTrx",
            "fechaVigencia",
            "uuidOtorgante",
            "fields",
            "links",
            "flag",
            "validacionRENAPO",
            "jobRENAPO",
            "correoElectronico",
            'nombreCompleto',
            'scoreObject',
            'idOtorgante',
            'tipoFlujo'
        ];

        itemsDeleteLS.forEach((element) => {
            localStorage.removeItem(element);
        })

        if (hayCookies) {
            const uuidUser = localStorage.getItem("uuidUser")
            if (uuidUser === null) {
                if (new URL(window.location).searchParams.get("cliente")) {
                    localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
                } else {
                    const newUuid = uuidv4();
                    localStorage.setItem("uuidUser", newUuid);
                }
            } else {
                if (new URL(window.location).searchParams.get("cliente")) {
                    localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
                }
            }

            localStorage.setItem("apikeyOtorgante", apikey)

            if (new URL(window.location).searchParams.get("uuidTrx")) {
                //console.log("hay uuidTrx");
                const trx_id = new URL(window.location).searchParams.get("uuidTrx")
                trx_id && localStorage.setItem("uuidTrx", trx_id)
                trx_id && setUuidTransaccion(trx_id)
            } else {
                const trx_id = uuidv4()
                trx_id && localStorage.setItem("uuidTrx", trx_id)
                trx_id && setUuidTransaccion(trx_id)
            }

            if (new URL(window.location).searchParams.get("scheme")) {
                //console.log("hay uuidTrx");
                localStorage.setItem("package", new URL(window.location).searchParams.get("scheme"))
            }
        }
        if (window.opener) {
            setIsOpenByPivot(true);
        }
    }, [apikey])

    //UseEffect que se ejecuta para cargar los datos del otorgante y para validar las características del
    //dispositivo, se obtienen permisos de cámara y se selecciona la que se usará
    useEffect(() => {
        console.log(`🚀 --------------------------------------------------------🚀`)
        console.log(`🚀 ~ file: inicio.jsx:122 ~ useEffect ~ version:`, 'Version 3.1.3.8')
        console.log(`🚀 --------------------------------------------------------🚀`)
        localStorage.setItem("completado", "false");
        if (!isMobile && !isChrome) {
            //console.log("desktop not supported browsers");
            setNotSupported(true)
            setLoading(false)
        } else if (isMobile && isIOS && !isSafari) {
            //console.log("mobile not supported browsers in iOS");
            setNotSupportediOS(true)
            setLoading(false)
        } else if (isMobile && !isIOS && !isChrome) {
            //console.log("Android, not supported browser");
            setNotSupported(true)
            setLoading(false)
        } else {
            obtenerParametros()
            obtenerPermisos()
            validarDispositivoFunction()
        }
        evento('Dispositvo', 'Step', deviceDetect(), true)
        evento('Dimensiones viewport', 'Información', { innerWidth: window.innerWidth, innerHeight: window.innerHeight }, true)
    }, [])

    /*Validamos los dispositivos de video que encontramos*/
    useEffect(() => {
        if (videoDevices.length > 0) getDeviceId(videoDevices, setId, setErrorPermissions, setLoading, setPermissionsDenied)
    }, [videoDevices])

    useEffect(() => {
        //console.log("actualizando dataOtorgante");
        setDataOtorgante(dataOtorgante)
        configStyle(dataOtorgante)
        if (hayCookies) {
            localStorage.setItem("dataOtorgante", JSON.stringify(dataOtorgante))
        }
        const logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""));
        ////console.log("logo", logo);
        document.getElementById("imagenLogo").src = logo;
        const ID_OTORGANTE = (obtenerValorConfig(dataOtorgante, 'ID_OTORGANTE', ""));
        //console.log("ID_OTORGANTE",ID_OTORGANTE);
        if (ID_OTORGANTE) {
            localStorage.setItem("uuidOtorgante", ID_OTORGANTE)
        } else {
            //console.log("no hay uuid otrogante");
        }
    }, [dataOtorgante])

    const obtenerParametros = async () => {
        //setLoading(true)
        try {
            const response = await obtenerParametrosOtorgante(apikey)
            console.log(`🚀 ------------------------------------------------------------------🚀`)
            console.log(`🚀 ~ file: inicio.jsx:174 ~ obtenerParametros ~ response:`, response)
            console.log(`🚀 ------------------------------------------------------------------🚀`)
            const { status, data, data: { payload } } = response
            if (status === 200) {
                setDataOtorgante(payload)
                setNombreOtorgante(obtenerValorConfig(payload, 'NOMBRE_CORTO', ""))
                evento('Obtener parametros otrogante', 'Success', mapearRespuesta(status, data), true)
                setStart(true)
            }
        } catch (error) {
            console.log(`🚀 ------------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: inicio.jsx:182 ~ obtenerParametros ~ error:`, error)
            console.log(`🚀 ------------------------------------------------------------🚀`)
            setCorrectApikey(false)
            statusError('inicio_page', 'Error al obtener los parámetros del otorgante', 'error')
            const errorMapeado = mapearError(error)
            evento('Obtener parametros otrogante', errorMapeado.tipoError, errorMapeado.objetoError, false)
        }
    }

    //Función que obtiene los permisos para acceder a la cámara y micrófono
    const obtenerPermisos = async () => {
        await getPermissions(setPermissionsDenied, setVideoDevices, setErrorPermissions, setLoading)
    }

    const validarDispositivoFunction = async () => {
        const response = await validarDispositivo()
        !response && setValidateStatus(true)
    }

    const reload = () => {
        window.location.reload()
    }

    const startFlow = (redirect) => {
        setCancelarINE()
        const description = (redirect === 'cliente') ? 'YA SOY CLIENTE' : 'SOY CLIENTE NUEVO'
        localStorage.setItem('tipoFlujo', description)
        evento('inicio', 'Click', { description: description }, true)
        if (!window.opener) {
            initStatus(nombreOtorgante)
        }
        const paso = (redirect === 'cliente') ? '/cliente' : '/inicio'
        setTimeout(() => {
            history.push(paso)
        }, 200);
    }

    useEffect(() => {
        if (isOpenByPivot) initStatus(nombreOtorgante)
    }, [isOpenByPivot])


    return (
        <>
            <div className="main_gradient">
                <div className="flex_container">
                    <Header show={() => setShowHelp(true)} />
                    <div className="main_text_container custom_padding flex_body">
                        <h1 className="title_punto_trader" style={{ marginBottom: '0' }}>¿Ya eres cliente?</h1>
                        <div className="animate__animated animate__fadeIn animate__delay-2s">
                            <p className="text_punto_trader">Si ya eres cliente, asegúrate de tener tu número de cliente y CURP a la mano. De lo contrario, inicia tu proceso.</p>
                        </div>
                        {/* {servicio !== 'prod_' &&
                            <div className='main_text_container animate__animated animate__fadeIn animate__delay-2s'>
                                <p>
                                    Folio: <br /> <b>{localStorage.getItem('uuidTrx') ? localStorage.getItem('uuidTrx').split('-')[0] : ''}</b>
                                </p>
                            </div>

                        } */}
                    </div>
                    <div className="action_buttons flex_buttons">
                        <button onClick={() => startFlow('nuevo')} className="btn btn-raised btn-primary forcewidth100 button_punto_trader animate__animated animate__fadeIn animate__delay-3s" disabled={(start && !permissionsDenied && id) ? false : true}>Soy cliente nuevo</button>
                        <button onClick={() => startFlow('cliente')} className="btn btn-raised btn-primary forcewidth100 button_punto_trader animate__animated animate__fadeIn animate__delay-3s" disabled={(start && !permissionsDenied) ? false : true}>Ya soy cliente</button>
                    </div>
                </div>
                {((notSupported) && <div className="browser_not_comp show_me_bx">
                    <div className="top_bx">
                        <img src="images/browser_alert_icon.svg" alt="" />
                        <h4>Lo sentimos tu navegador actual no es <br />
                            compatible con nuestra plataforma.</h4>
                        <div className="divider"></div>
                    </div>
                    <div className="bottom_bx">
                        <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>
                        <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                            <img src="images/browser_chrome.png" width="96" alt="" />
                            <a href={isMobile ? 'https://play.google.com/store/apps/details?id=com.android.chrome' : 'https://www.google.com/chrome/'}>Chrome</a>
                            <small>Google</small>
                        </div>
                    </div>

                </div>)}
                {(notSupportediOS) && <div className="browser_not_comp show_me_bx">
                    <div className="top_bx">
                        <img src="images/browser_alert_icon.svg" alt="" />
                        <h4>Apple limita el uso de aplicaciones avanzadas en este navegador.</h4>
                        <div className="divider"></div>
                    </div>
                    <div className="bottom_bx">
                        <p><small>Para continuar con tu proceso utiliza o descarga Safari para iOS.</small></p>
                        <div className="browser_bx ios_browser">
                            <img src="images/browser_ios.png" width="96" alt="" />
                            <a href="https://apps.apple.com/mx/app/safari/id1146562112">Safari</a>
                            <small>Apple</small>
                        </div>
                    </div>
                </div>}
                {
                    (!hayCookies) && (<div className="err_finale animate__animated animate__slideInUp">
                        <div className="center_checks">
                            <h5>Verifica tu navegador</h5>
                            <p>Para acceder a este sitio es necesario habilitar el uso de Cookies
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 button_punto_trader">Reintentar</button>
                            </div>
                        </div>
                    </div>)}
                {
                    (!isCorrectApikey) && (<div className="err_finale  animate__animated animate__slideInUp">
                        <div className="center_checks">
                            <h5>Verifica tu URL</h5>
                            <p>Lo sentimos la dirección que buscas no se encuentra o es incorrecta, por favor verifica la información e intenta nuevamente.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 button_punto_trader">Reintentar</button>
                            </div>
                        </div>
                    </div>)
                }
                <IdTransaccion />
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {loading && <Loader />}
            {errorPermissions && <ErrorPermissions permisosDenegados={permissionsDenied} setErrorPermisos={setErrorPermissions} permisoCamara={obtenerPermisos} />}
            {validateStatus && <ValidacionDispositivo setValidateStatus={setValidateStatus} />}
        </>
    )
}