/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { Link, useHistory, useLocation } from "react-router-dom"
import { isIOS, isSafari } from 'react-device-detect'
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Ayuda from './ayuda'
import Loader from '../components/loader'
import CancelarFlujo from "./flujo/cancelar_flujo"
import IdTransaccion from "../components/id_transaccion"
/* Funciones */
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { status, statusError, validarRenapo, statusData, sendEventClick, agregarInfo, generateZip, cancelarINE, validarParametroOtorgante } from '../services/data'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"

const Clausula = () => {

    useScrollToTop()

    /* Location */
    const location = useLocation()
    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [showCancel, setShowCancel] = useState(false)
    const [showHelp, setShowHelp] = useState(false);
    const [fatcaAceptado, setFatcaAceptado] = useState(false)
    const [crsAceptado, setCrsAceptado] = useState(false)
    const [formularioFatcaAceptado, setFormularioFatcaAceptado] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [disabledCheck, setDisabledCheck] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {
        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) setUuidTransaccion(uuidTransaccionLS)

        if (location.state) {
            const { fatcaAceptado, crsAceptado, formularioFatcaAceptado } = location.state

            if (fatcaAceptado) {
                setFatcaAceptado(fatcaAceptado)
                document.querySelector('#span_fatca').click()
            }
            if (crsAceptado) {
                setCrsAceptado(crsAceptado)
                document.querySelector('#span_crs').click()
            }
            if (formularioFatcaAceptado) {
                setFormularioFatcaAceptado(formularioFatcaAceptado)
                document.querySelector('#span_formulario').click()
            }
        }

        status("clausulas_page", "Clausula")
        validarRenapo()
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = async () => {
        setLoading(true)
        cancelarINE()
        sendEventClick('Cláusula', 'SI', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("clausulas", 'Cancelado', "cancelado")
        localStorage.setItem('completado', 'true')
        if (!window.opener) {
            if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
                setTimeout(() => {
                    history.replace(`/${apiKey}`)
                    setLoading(false)
                }, 300)
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    useEffect(() => {
        if (fatcaAceptado && crsAceptado && formularioFatcaAceptado) {
            setDisabledCheck(true)
            setTimeout(() => {
                history.replace('/preparacion_video_grabacion')
            }, 1000)
        }
    }, [fatcaAceptado, crsAceptado, formularioFatcaAceptado])


    const aceptarDocumentos = (description, paso, check) => {
        agregarInfo(description, check)
        if (check) {
            let data = { [description]: true }
            statusData(paso, data)
        }
    }

    return (
        <>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ubuntu_bold_punto_trader">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="text_punto_trader">En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setShowCancel(false); sendEventClick('Cláusula', 'NO') }} className="btn btn-secondary modal_btn_w text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">No</button>
                                <button type="button" onClick={() => statusE()} className="btn btn-raised btn-secondary modal_btn_w button_punto_trader">Sí</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient flex_container">
                <Header show={() => setShowHelp(true)} />
                <div className="flex_body">
                    <div className="main_text_container">
                        <h1 className="title_punto_trader animated">{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2")}</h1>
                        <p className="text_punto_trader animated">Lee <b className="calibri_bold_punto_trader">atentamente</b> los documentos que te presentaremos a <b className="calibri_bold_punto_trader">continuación</b> para proceder a su <b className="calibri_bold_punto_trader">aceptación</b>.</p>
                    </div>
                    <div className="main_icon_container animated">
                        <img src="../images/wl/doc_check.png" alt="" />
                    </div>
                    <div className="check_container calibri_punto_trader">
                        <form>
                            {(isIOS || isSafari) ?
                                <div>
                                    <label htmlFor="check_fatca" className="custom-checkbox">
                                        <input id="check_fatca" type="checkbox" onChange={e => { setFatcaAceptado(!fatcaAceptado); aceptarDocumentos('facta_crs_aceptados', 'documento_fatca', e.target.checked); sendEventClick('He leído y acepto "FATCA"', { check: !fatcaAceptado }); }} defaultChecked={fatcaAceptado} disabled={disabledCheck} />
                                        <span id="span_fatca" className="checkbox-decorator"><span className="check"></span></span>
                                        He leído y acepto <Link to={{ pathname: "/validacion_documento", state: { fatcaAceptado: fatcaAceptado, crsAceptado: crsAceptado, formularioFatcaAceptado: formularioFatcaAceptado } }} replace>"FATCA"</Link>.
                                    </label>
                                </div>
                                :
                                <div className="checkbox">
                                    <label htmlFor="check_fatca">
                                        <input id="check_fatca" type="checkbox" onChange={e => { setFatcaAceptado(!fatcaAceptado); aceptarDocumentos('facta_crs_aceptados', 'documento_fatca', e.target.checked); sendEventClick('He leído y acepto "FATCA"', { check: !fatcaAceptado }); }} defaultChecked={fatcaAceptado} disabled={disabledCheck} />
                                        <span id="span_fatca" className="checkbox-decorator"><span className="check"></span></span>
                                        He leído y acepto <Link to={{ pathname: "/validacion_documento", state: { fatcaAceptado: fatcaAceptado, crsAceptado: crsAceptado, formularioFatcaAceptado: formularioFatcaAceptado } }} replace>"FATCA"</Link>.
                                    </label>
                                </div>
                            }
                        </form>
                    </div>
                    <div className="check_container padding_top_0 calibri_punto_trader">
                        <form>
                            {(isIOS || isSafari) ?
                                <div>
                                    <label htmlFor="check_crs" className="custom-checkbox">
                                        <input id="check_crs" type="checkbox" onChange={e => { setCrsAceptado(!crsAceptado); aceptarDocumentos('facta_crs_aceptados', 'documento_crs', e.target.checked); sendEventClick('He leído y acepto "CRS"', { check: !crsAceptado }) }} defaultChecked={crsAceptado} disabled={disabledCheck} />
                                        <span id="span_crs" className="checkbox-decorator"><span className="check"></span></span>
                                        He leído y acepto <Link to={{ pathname: "/validacion_documento", state: { fatcaAceptado: fatcaAceptado, crsAceptado: crsAceptado, formularioFatcaAceptado: formularioFatcaAceptado } }} replace>"CRS"</Link>.
                                    </label>
                                </div>
                                :
                                <div className="checkbox">
                                    <label htmlFor="check_crs">
                                        <input id="check_crs" type="checkbox" onChange={e => { setCrsAceptado(!crsAceptado); aceptarDocumentos('facta_crs_aceptados', 'documento_crs', e.target.checked); sendEventClick('He leído y acepto "CRS"', { check: !crsAceptado }) }} defaultChecked={crsAceptado} disabled={disabledCheck} />
                                        <span id="span_crs" className="checkbox-decorator"><span className="check"></span></span>
                                        He leído y acepto <Link to={{ pathname: "/validacion_documento", state: { fatcaAceptado: fatcaAceptado, crsAceptado: crsAceptado, formularioFatcaAceptado: formularioFatcaAceptado } }} replace>"CRS"</Link>.
                                    </label>
                                </div>
                            }
                        </form>
                    </div>
                    <div className="check_container padding_top_0 calibri_punto_trader">
                        <form>
                            {(isIOS || isSafari) ?
                                <div>
                                    <label htmlFor="check_formulario" className="custom-checkbox">
                                        <input id="check_formulario" type="checkbox" onChange={e => { setFormularioFatcaAceptado(!formularioFatcaAceptado); aceptarDocumentos('formulario_fatca_aceptado', 'documento_formulario_fatca', e.target.checked); sendEventClick('He leído y acepto "Formulario FATCA"', { check: !formularioFatcaAceptado }) }} defaultChecked={formularioFatcaAceptado}  disabled={disabledCheck} />
                                        <span id="span_formulario" className="checkbox-decorator"><span className="check"></span></span>
                                        He leído y aceptado <Link to={{ pathname: "/autorizacion_documento", state: { fatcaAceptado: fatcaAceptado, crsAceptado: crsAceptado, formularioFatcaAceptado: formularioFatcaAceptado } }} replace>"Formulario FATCA"</Link>.
                                    </label>
                                </div>
                                :
                                <div className="checkbox">
                                    <label htmlFor="check_formulario">
                                        <input id="check_formulario" type="checkbox" onChange={e => { setFormularioFatcaAceptado(!formularioFatcaAceptado); aceptarDocumentos('formulario_fatca_aceptado', 'documento_formulario_fatca', e.target.checked); sendEventClick('He leído y acepto "Formulario FATCA"', { check: !formularioFatcaAceptado }) }} defaultChecked={formularioFatcaAceptado}  disabled={disabledCheck} />
                                        <span id="span_formulario" className="checkbox-decorator"><span className="check"></span></span>
                                        He leído y aceptado  <Link to={{ pathname: "/autorizacion_documento", state: { fatcaAceptado: fatcaAceptado, crsAceptado: crsAceptado, formularioFatcaAceptado: formularioFatcaAceptado } }} replace>"Formulario FATCA"</Link>.
                                    </label>
                                </div>
                            }
                        </form>
                    </div>
                </div>
                <div className="action_buttons flex_buttons">
                    {/* <Link to="/validacion_documento" className="btn btn-raised btn-primary forcewidth100 main_bg_color">LEER DOCUMENTO</Link> */}
                    <button onClick={e => { setShowCancel(true); sendEventClick('Cláusula', 'CANCELAR') }} className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader">
                        Cancelar</button>
                </div>
                <IdTransaccion />
                <Footer />
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {loading && <Loader />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} apikeyOtorgante={apiKey} />}
        </>
    )

}

export default Clausula