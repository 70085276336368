/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react"
/* Librerías */
import { useLocation } from "react-router-dom/cjs/react-router-dom"
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Ayuda from './ayuda'
import FormularioIneConsulta from './forms/formulario_ine_consulta'
// import FormularioIne from './forms/formulario_ine'
// import FormularioPasaporte from './forms/formulario_pasaporte'
// import FormularioIdArgentino from './forms/formulario_id_argetino'
// import FormularioDni from './forms/formulario_dni'
import IdTransaccion from "../components/id_transaccion"
/* Funciones */
// import { configStyle, obtenerValorConfig } from '../services/configStyle'
// import { hayExcepcion, status } from '../services/data'
import { configStyle } from '../services/configStyle'
import { status } from '../services/data'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"

let modelo = 'A'

let ResultadosIdentificacion = () => {

    useScrollToTop()

    /* Location */
    const location = useLocation()
    if (location.hasOwnProperty('state')) {
        if (location.state.hasOwnProperty('modeloIdentificacion')) {
            const { modeloIdentificacion } = location.state
            modelo = modeloIdentificacion
        }
    }
    /* Hooks */
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataUser, setDataUser] = useState([])
    // const [esPassport, setEsPassport] = useState(false)
    // const [esMigratoria, setEsMigratoria] = useState(false)
    const [showHelp, setShowHelp] = useState(false);
    const [esIne, setEsIne] = useState(false);
    // const [esIdArgentino, setIdArgentino] = useState(false);

    useEffect(() => {

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS) setDataUser(JSON.parse(dataUserLS))

        const dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (ocrFront && ocrFront.identificacionElectoral)
            setEsIne(true)
        // if (dataOCRFrontLS) {
        //     if (ocrFront.identificacionElectoral)
        //         setEsIne(true);
        //     if (ocrFront.identificacionPasaporte)
        //         setEsPassport(true)
        //     if (ocrFront.identificacionMigratoria)
        //         setEsMigratoria(true)
        // } else {
        //     setIdArgentino(true);
        // }
        status("datos_personales_ocr_page", "Datos personales OCR")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        if (dataUser.length > 0) localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    return (
        <Fragment>
            <div className="main_gradient flex_container">
                <Header ruta="resultados_identificacion" show={() => setShowHelp(true)} />
                <div className="flex_body">
                    <div className="main_text_container">
                        <h1 className="title_punto_trader animate__animated">Verifica tu información</h1>
                        <p className="calibri_punto_trader animate__animated">Revisa que tus datos coincidan con los de tu identificación.</p>
                    </div>
                    <FormularioIneConsulta
                        modeloIdentificacion={modelo}
                        identificacionElectoral={esIne}
                    />
                    {/* {(obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "true") === "true" && esIne) ?
                        <FormularioIneConsulta />
                        : (esIne) ?
                            <FormularioIne />
                            : hayExcepcion() ?
                                <FormularioDni />
                                : (esPassport || esMigratoria) ?
                                    <FormularioPasaporte />
                                    : (esIdArgentino) ?
                                        <FormularioIdArgentino />
                                        : ("")} */}
                </div>
                <IdTransaccion />
                <div className="margin_top_10">
                    <Footer />
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </Fragment>
    )
}

export default ResultadosIdentificacion