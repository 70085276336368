import React, { useState, useEffect, Fragment } from "react"
/* Librerías */
import { useHistory, Redirect } from "react-router-dom"
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Ayuda from './ayuda'
/* Funciones */
import { configStyle, obtenerValorConfig, replaceValue } from '../services/configStyle'
import { status, statusData, agregarInfo, evento } from '../services/data'
import IdTransaccion from "../components/id_transaccion";
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"

const Terminos = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [showHelp, setShowHelp] = useState(false)
    const [disabledCheck, setDisabledCheck] = useState(false)
    const [dataUser, setDataUser] = React.useState({})
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {
        const dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS) {
            setDataUser(JSON.parse(dataUserLS));
        }
        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            setDataOtorgante(JSON.parse(dataOtorganteLS));
        }
        status("aviso_privacidad_page", "Aviso Privacidad")
    }, [])
    useEffect(() => {
        if (Object.keys(dataUser).length > 0) localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const aceptarTerminos = check => {
        setDisabledCheck(true)
        const tituloDocumento = (obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_1", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_1")
        const description = `He leído y acepto "${tituloDocumento}"`;
        evento('Aviso Privacidad', 'Click', { description: description, check: check }, true);
        agregarInfo('aviso_privacidad_aceptado', true);
        const data = { aviso_privacidad_aceptado: true }
        if (check) {
            statusData("aviso_privacidad", data)
            setTimeout(() => {
                history.replace("/preparacion_identificacion");
            }, 1000);
        }
    };

    const printText = () => {
        let textToPrint = ""
        let textStorage = obtenerValorConfig(dataOtorgante, "TEXTO_DE_CONSENTIMIENTO_1")
        textToPrint = replaceValue(textStorage, "{OTORGANTE}", obtenerValorConfig(dataOtorgante, "RAZON_SOCIAL"))
        if (textToPrint) {
            return textToPrint.split("{enter}").map((line, index) => {
                return (<Fragment key={index}>{line} <br /></Fragment>)
            })
        } else {
            return textToPrint
        }
    }

    return (
        <>
            <div className="main_gradient flex_container">
                {(obtenerValorConfig(dataOtorgante, "REQ_ACEPTACION_DE_DATOS", "VERDADERO") === "FALSO") ? <Redirect to="/preparacion_identificacion" /> : ""}
                <Header show={() => setShowHelp(true)} />
                <div className="flex_body">
                    <h5 className="left-align margin_4 text-color">
                        <b className="ubuntu_bold_punto_trader">{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_1", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_1")}.</b>
                    </h5>
                    <div className="terms_container">
                        <div className="terms_bg_box">
                            <span align="left" className="left-align calibri_punto_trader">
                                <h6 className="text-right text-small text-strong">
                                    <small>
                                        <strong>
                                            {(obtenerValorConfig(dataOtorgante, "LUGAR_Y_FECHA_CONSENTIMIENTO_1", "FALSO") === "FALSO") ? ("") : (<Fragment>{obtenerValorConfig(dataOtorgante, "CIUDAD_LEGAL_DEL_OTORGANTE")} a {new Intl.DateTimeFormat("es-MX", {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                            }).format(new Date())}</Fragment>)}
                                        </strong>
                                    </small>
                                </h6>
                                {printText()}
                            </span>
                            <div className="terms_bg_box_gradient"></div>
                        </div>
                    </div>
                </div>
                <div className="check_container flex_buttons">
                    <form>
                        <div className="checkbox">
                            <label htmlFor="check_terms" className="calibri_punto_trader">
                                <input id="check_terms" type="checkbox" onChange={e => aceptarTerminos(e.target.checked)} disabled={disabledCheck}/>
                                <span className="checkbox-decorator"><span className="check"></span></span>
                                He leído y acepto "{obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_1")}".
                            </label>
                        </div>
                    </form>
                </div>
                <IdTransaccion />
                <Footer />
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>
    )
}

export default Terminos