import React, { useEffect, useState } from 'react'
/* Funciones */
import { onCut, onPaste } from '../../../services/data'

const Index = ({
    modeloIdentificacion,
    inputsValue,
    inputsErrors,
    setShowInfo,
    handleChange,
}) => {
    /* Hooks */
    const [focusCIC, setfocusCIC] = useState(false)
    const [focusIdentificadorCiudadano, setFocusIdentificadorCiudadano] = useState(false)
    const [focusOCR, setFocusOCR] = useState(false)
    useEffect(() => {
        // mostrarCampos(modeloIdentificacion)
    }, [modeloIdentificacion])

    const mostrarCampos = (modelo) => {
        switch (modelo) {
            case 'D':
                return (<>
                    <div className={["form-group", "bmd-form-group", ((focusCIC || inputsValue.cic !== "") ? "is-focused" : ""), inputsErrors.cic && 'error'].join(" ")}>
                        <label htmlFor="cic" className="bmd-label-floating">CIC:<small> *Dato reverso</small></label>
                        <div className="display_flex">
                            <input type="text" className="form-control none_border" id="cic" name="cic" maxLength="13" defaultValue={inputsValue.cic} onChange={handleChange} onFocus={e => {
                                setfocusCIC(true)
                            }} onBlur={event => {
                                if (inputsValue.cic !== event.target.value) {
                                    handleChange(event)
                                }
                                if (inputsValue.cic === "" || event.target.value === '') {
                                    setfocusCIC(false)
                                }
                            }} 
                            onPaste={onPaste}
                            onCut={onCut}
                            />
                            <button type="button" className={['button_info', inputsErrors.cic && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                        </div>
                        {inputsErrors.cic && (
                            <span id="ht-CIC" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.cic}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusOCR || inputsValue.ocr !== "") ? "is-focused" : ""), inputsErrors.ocr && 'error'].join(" ")}>
                        <label htmlFor="ocr" className="bmd-label-floating">OCR:<small> *Dato reverso</small></label>
                        <div className="display_flex">
                            <input type="text" className="form-control none_border" id="ocr" name="ocr" maxLength="13" defaultValue={inputsValue.ocr} onChange={handleChange} onFocus={e => {
                                setFocusOCR(true)
                            }} onBlur={event => {
                                if (inputsValue.ocr !== event.target.value) {
                                    handleChange(event)
                                }
                                if (inputsValue.ocr === "" || event.target.value === '') {
                                    setFocusOCR(false)
                                }
                            }} 
                            onPaste={onPaste}
                            onCut={onCut}
                            />
                            <button type="button" className={['button_info', inputsErrors.ocr && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                        </div>
                        {inputsErrors.ocr && (
                            <span id="ht-ocr" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.ocr}</span>
                        )}
                    </div>
                </>)
            case 'C':
                return (<>
                    <div className={["form-group", "bmd-form-group", ((focusOCR || inputsValue.ocr !== "") ? "is-focused" : ""), inputsErrors.ocr && 'error'].join(" ")}>
                        <label htmlFor="ocr" className="bmd-label-floating">OCR:<small> *Dato reverso</small></label>
                        <div className="display_flex">
                            <input type="text" className="form-control none_border" id="ocr" name="ocr" maxLength="13" defaultValue={inputsValue.ocr} onChange={handleChange} onFocus={e => {
                                setFocusOCR(true)
                            }} onBlur={event => {
                                if (inputsValue.ocr !== event.target.value) {
                                    handleChange(event)
                                }
                                if (inputsValue.ocr === "" || event.target.value === '') {
                                    setFocusOCR(false)
                                }
                            }} 
                            onPaste={onPaste}
                            onCut={onCut}
                            />
                            <button type="button" className={['button_info', inputsErrors.ocr && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                        </div>
                        {inputsErrors.ocr && (
                            <span id="ht-CIC" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.ocr}</span>
                        )}
                    </div>
                </>)
            case 'A':
                return (<>
                    <div className={["form-group", "bmd-form-group", ((focusCIC || inputsValue.ocr !== "") ? "is-focused" : ""), inputsErrors.ocr && 'error'].join(" ")}>
                        <label htmlFor="ocr" className="bmd-label-floating">OCR:<small> *Dato reverso</small></label>
                        <div className="display_flex">
                            <input type="text" className="form-control none_border" id="ocr" name="ocr" maxLength="13" defaultValue={inputsValue.ocr} onChange={handleChange} onFocus={e => {
                                setFocusOCR(true)
                            }} onBlur={event => {
                                if (inputsValue.ocr !== event.target.value) {
                                    handleChange(event)
                                }
                                if (inputsValue.ocr === "" || event.target.value === '') {
                                    setFocusOCR(false)
                                }
                            }} 
                            onPaste={onPaste}
                            onCut={onCut}
                            />
                            <button type="button" className={['button_info', inputsErrors.ocr && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                        </div>
                        {inputsErrors.ocr && (
                            <span id="ht-CIC" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.ocr}</span>
                        )}
                    </div>
                </>)
            default:
                return (
                    <>
                        <div className={["form-group", "bmd-form-group", ((focusCIC || inputsValue.cic !== "") ? "is-focused" : ""), inputsErrors.cic && 'error'].join(" ")}>
                            <label htmlFor="cic" className="bmd-label-floating">CIC:<small> *Dato reverso</small></label>
                            <div className="display_flex">
                                <input type="text" className="form-control none_border" id="cic" name="cic" maxLength="13" defaultValue={inputsValue.cic} onChange={handleChange} onFocus={e => {
                                    setfocusCIC(true)
                                }} onBlur={event => {
                                    if (inputsValue.cic !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.cic === "" || event.target.value === '') {
                                        setfocusCIC(false)
                                    }
                                }} 
                                onPaste={onPaste}
                                onCut={onCut}
                                />
                                <button type="button" className={['button_info', inputsErrors.cic && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                            </div>
                            {inputsErrors.cic && (
                                <span id="ht-CIC" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.cic}</span>
                            )}
                        </div>
                        <div className={["form-group", "bmd-form-group", ((focusIdentificadorCiudadano || inputsValue.identificadorCiudadano !== "") ? "is-focused" : ""), inputsErrors.identificadorCiudadano && 'error'].join(" ")}>
                            <label htmlFor="identificadorCiudadano" className="bmd-label-floating">Identificador del ciudadano:<small> *Dato reverso</small></label>
                            <div className="display_flex">
                                <input type="text" className="form-control none_border" id="identificadorCiudadano" name="identificadorCiudadano" maxLength="9" defaultValue={inputsValue.identificadorCiudadano} onChange={handleChange} onFocus={e => {
                                    setFocusIdentificadorCiudadano(true)
                                }} onBlur={event => {
                                    if (inputsValue.identificadorCiudadano !== event.target.value) {
                                        handleChange(event)
                                    }
                                    if (inputsValue.identificadorCiudadano === "" || event.target.value === '') {
                                        setFocusIdentificadorCiudadano(false)
                                    }
                                }} 
                                onPaste={onPaste}
                                onCut={onCut}
                                />
                                <button type="button" className={['button_info', inputsErrors.identificadorCiudadano && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                            </div>
                            {inputsErrors.identificadorCiudadano && (
                                <span id="ht-identificadorCiudadano" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.identificadorCiudadano}</span>
                            )}
                        </div>
                    </>
                )
        }
    }
    return (
        <>
            {mostrarCampos(modeloIdentificacion)}
        </>
    )
}

export default Index;
