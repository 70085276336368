import React from 'react';
/* Librerías */
import { isDesktop } from 'react-device-detect';
/* Imágenes */
import BrowserAlert from '../../../assets/videotoken/advertencias/browser_alert_icon.svg'

const MediaRecorderNoSoportado = () => {
    return (
        <>
            <div className='browser_not_comp show_me_bx'>
                <div className={`top_bx ${isDesktop ? 'padding_desktop_mediarecorder': ''}`}>
                    <img src={BrowserAlert} alt='' />
                    <h4>Lo sentimos tu navegador actual no es <br />
                        compatible con nuestra plataforma.</h4>
                    <div className='divider'></div>
                </div>
                <div className='bottom_bx'>
                    <p><small>Te sugerimos contactar con el equipo de desarrollo.</small></p>
                </div>

            </div>
        </>
    );
}

export default MediaRecorderNoSoportado;
