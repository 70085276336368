/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Librerías */
import { useHistory } from "react-router-dom"
import { isMobile, isIOS } from 'react-device-detect'
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Loader from "../components/loader"
import Ayuda from './ayuda'
import { configStyle, obtenerValorConfig, obtenerValorUser } from '../services/configStyle'
import { status, sendData, statusError, statusData, evento, agregarInfo, sendEventClick, validarParametroOtorgante } from '../services/data'
/* Funciones */
/* Servicios */
import { enviarCorreoAceptacion, obtenerScore, getDocuments, finalUpdateScore } from '../services/api.js'
/* Custom hooks */
import { useScrollToTop } from "../utilities/hooks/custom"
// import IdTransaccion from "../components/id_transaccion";

const Finalizado = () => {

    useScrollToTop()

    /* Hooks */
    const history = useHistory()
    const [apiKey, setApiKey] = useState('')
    const [uuidTrx, setUuidTrx] = useState('')
    const [correo, setCorreo] = useState('')
    const [contador, setContador] = useState(0)
    const [flag, setFlag] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [disableButton, setDisableButton] = useState(true)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataUser, setDataUser] = useState({})

    useEffect(() => {
        const apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante) setApiKey(apikeyOtorgante)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) setUuidTrx(uuidTrx)

        const dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS) setDataUser(JSON.parse(dataUserLS))

        setCorreo(obtenerValorUser(JSON.parse(dataUserLS), "email"))
        status("flujo_finalizado_page", "Flujo Finalizado")
        setTimeout(() => {
            actualizarScore()
        }, 200)
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        if (Object.keys(dataUser).length > 0) localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    useEffect(() => {
        //console.log("contador", contador);
        if (!flag && parseInt(contador) < 10 && parseInt(contador) > 0) {
            obtenerScoreUser()
        } else {
            if (parseInt(contador) > 10) {
                console.log("sin dato en bandera")
                enviarErrorBandera()
            }
        }
    }, [contador])


    const enviarCorreo = () => {
        let tipo = ''
        let uuid = ''
        let uuidTrx = ''
        let bandera = ''
        let correo = ''
        let dataFace = null
        let dataOtorgante = null
        let dataUser = {}
        const objeto = {}
        const informacion = {}
        const dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS) dataUser = JSON.parse(dataUserLS)

        const dataFaceLS = localStorage.getItem("face")
        if (dataFaceLS) dataFace = JSON.parse(dataFaceLS)

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) dataOtorgante = JSON.parse(dataOtorganteLS)

        const uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser) uuid = uuidUser

        const uuidTrxLS = localStorage.getItem("uuidTrx")
        if (uuidTrxLS) uuidTrx = uuidTrxLS

        const banderaLS = localStorage.getItem("flag")
        if (banderaLS) bandera = banderaLS

        if (bandera === "green") {
            objeto.tipo_envio = 'COMPLETO'
        } else {
            objeto.tipo_envio = 'SEMAFORO'
        }

        objeto.semaforo = bandera;
        objeto.uuidUser = uuid;
        objeto.name = localStorage.getItem('nombreCompleto')
        correo = obtenerValorUser(dataUser, "email")
        objeto.email = correo;
        localStorage.setItem("correoElectronico", correo)
        objeto.fNac = obtenerValorUser(dataUser, "nacimiento")
        objeto.curp = obtenerValorUser(dataUser, "curp")
        objeto.vigencia = obtenerValorUser(dataUser, "vigencia")
        objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {}
        objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {}
        let fecha = ""
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
        partner = partner.replace(" ", "_")
        if (partner !== "") {
            partner = partner + "_"
        }
        let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
        name_otorgante = name_otorgante.replace(" ", "_")
        const custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0]
        objeto.custom_uuid = custom_uuid
        agregarInfo('folio', custom_uuid)
        objeto.originOS = isIOS ? 'ios' : 'android'
        objeto.coincidencia = (dataFace.match ? "Coincide" : "Requiere validación")
        objeto.data_user = dataUser
        enviarCorreoAceptacion(objeto).then(({ status, data }) => {
            informacion.status = status;
            informacion.payload = data.payload;
            evento('Envío correo', 'Success', informacion, true)
            if (status === 201) {
                statusData("email_check", { email: correo })
                setTimeout(() => {
                    finalUpdateStatus()
                }, 300);
            }
        }).catch((error) => {
            console.log(`🚀 ---------------------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: finalizado.jsx:155 ~ enviarCorreoAceptacion ~ error:`, { error })
            console.log(`🚀 ---------------------------------------------------------------------🚀`)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message
                informacion.status = status
                tipo = 'Error'
            } else {
                informacion.error = error.toString()
                tipo = 'Exception'
            }
            evento('Envío Correo', tipo, informacion, false)
            setTimeout(() => {
                finalUpdateStatus()
            }, 300)
        })
    }

    const obtenerScoreUser = async () => {
        let tipo = '';
        const informacion = {};
        const uuidUser = localStorage.getItem("uuidUser")
        obtenerScore(uuidUser, !isMobile).then(({ status, data }) => {
            informacion.status = status
            informacion.payload = data.payload
            evento('Obtener score', 'Success', informacion, true);
            if (status === 200) {
                if (data.payload.flag) {
                    const flag = { flag: data.payload.flag }
                    statusData("consulta_score", flag)
                    setFlag(true);
                    const campos = JSON.stringify(data.payload.Fields)
                    localStorage.setItem("fields", campos)
                    localStorage.setItem("flag", data.payload.flag);
                    setTimeout(() => {
                        dataLinks()
                    }, 300)
                } else {
                    console.log("sin bandera")
                    setContador(parseInt(contador) + 1)
                }
            }
        }).catch((error) => {
            console.log(`🚀 -----------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: finalizado.jsx:205 ~ obtenerScore ~ error:`, { error })
            console.log(`🚀 -----------------------------------------------------------🚀`)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message
                informacion.status = status
                tipo = 'Error'
            } else {
                informacion.error = error.toString()
                tipo = 'Exception'
            }
            evento('Obtener score', tipo, informacion, false)
            setContador(parseInt(contador) + 1)
        });
    }

    const dataLinks = () => {
        let tipo = ''
        const informacion = {}
        getDocuments().then(({ status, data }) => {
            informacion.status = status
            informacion.payload = data.payload
            evento('Obtener Documentos', 'Success', informacion, true);
            if (status === 200) {
                if (data.payload.links) {
                    const links = JSON.stringify(data.payload.links)
                    localStorage.setItem('links', links)
                }
                setTimeout(() => {
                    enviarCorreo()
                }, 300)
            }
        }).catch((error) => {
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message
                informacion.status = status
                tipo = 'Error';
            } else {
                informacion.error = error.toString()
                tipo = 'Exception';
            }
            evento('Obtener documentos', tipo, informacion, false);
            setTimeout(() => {
                enviarCorreo()
            }, 300)
        })
    }

    const sendStatus = () => {
        setLoading(false)
        setDisableButton(false)
        if (window.opener) {
            const uuidTrx = localStorage.getItem("uuidTrx")
            const event_t = "OK";
            window.opener.postMessage(JSON.stringify({
                step: "completed",
                result: event_t,
                transaccion: uuidTrx,
            }), localStorage.getItem("externalReference"))
        } else
            return
    }

    const finalUpdateStatus = async () => {
        const res = convertdataJSONStatus();
        const event_t = JSON.stringify(res)
        let statu = "OK"
        if (flag === 'yellow' || flag === 'red') {
            statu = "FAIL"
        }
        const json_final = {
            "status": statu,
            "step": "finished",
            "description": event_t,
            "finished": true
        }
        const event = JSON.stringify(json_final)
        await sendData({ 'step': 'finalizado', 'event': event })
        localStorage.setItem("completado", "true")
        sendStatus()
    }

    const convertdataJSONStatus = () => {
        const jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem("uuidUser") || ""
        jsonObj.uuidTransaccion = localStorage.getItem("uuidTrx") || ''
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || ""
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || ""
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || ""
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || "";
        jsonObj.detailsScore = JSON.parse(localStorage.getItem('fields')) || ""
        return jsonObj
    }

    const actualizarScore = () => {
        setLoading(true)
        let tipo = ''
        const informacion = {}
        let objeto = { createScore: true, }
        let scoreObject = JSON.parse(localStorage.getItem("scoreObject"))
        if (scoreObject) {
            objeto = { ...objeto, ...scoreObject }
        }
        finalUpdateScore(objeto).then(({ status, data }) => {
            if (status === 200) {
                informacion.status = status
                informacion.payload = data.payload
                evento('Actualizar Score', 'Success', informacion, true)
                setTimeout(() => {
                    obtenerScoreUser()
                }, 300);
            }
        }).catch((error) => {
            console.log(`🚀 ---------------------------------------------------------------🚀`)
            console.error(`🚀 ~ file: finalizado.jsx:345 ~ finalUpdateScore ~ error:`, { error })
            console.log(`🚀 ---------------------------------------------------------------🚀`)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message
                informacion.status = status
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Actualizar Score', tipo, informacion, false);
        })
    }

    const enviarErrorBandera = async () => {
        await statusError("score_check", "Sin valores en el score", "error")
    }

    const finalizar = async (event) => {
        if(event) event.preventDefault()
        await sendEventClick('Flujo Finalizado', 'FINALIZAR')
        if (validarParametroOtorgante(dataOtorgante, 'ENABLE_REDIRECT')) {
            setDisableButton(true)
            setTimeout(() => {
                history.replace(`/${apiKey}`)
            }, 300);
        } else {
            // setLoading(true)
            window.location.href = 'https://www.puntocasadebolsa.mx/'
        }
    }

    return (
        <>
            <div className="main_gradient flex_container">
                <Header show={() => setShowHelp(true)} />
                <div className="flex_body">
                    <div className="main_text_container">
                        <h1 className="title_punto_trader">¡Muchas gracias!</h1>
                        <p className="text_punto_trader">Has completado tu solicitud exitosamente. Hemos enviado el resultado del proceso y tu promotor se comunicará contigo.</p>
                        <div className="form calibri_punto_trader">
                            <div className={["form-group", "is-focused"].join(" ")}>
                                <label htmlFor="emailInput" className="bmd-label-floating">Correo electrónico:</label>
                                <p className="calibri_punto_trader">{correo}</p>
                            </div>
                        </div>
                    </div>
                    <div className="main_icon_container">
                        <p className="calibri_punto_trader text-center" >Folio: <br />
                            <b className="calibri_bold_punto_trader">{uuidTrx.split("-")[0]}</b>
                        </p>
                        <br />
                    </div>
                </div>
                {!window.opener &&
                    <div className="action_buttons flex_buttons padding_bottom_10_percent animate__animated animate__fadeIn animate__delay-1s">
                        <button type='button' className="btn btn-raised btn-primary forcewidth100 button_punto_trader" onClick={finalizar}
                            disabled={disableButton}>Finalizar</button>
                    </div>}
                {/* <IdTransaccion /> */}
                <Footer />
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {(loading) && <Loader />}
        </>
    )

}

export default Finalizado