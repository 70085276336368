/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
/* Componentes */
import Header from "../components/headerNA"
import Ayuda from './ayuda'
import Footer from "../components/footer"
import IdTransaccion from "../components/id_transaccion"
/* Funciones */
import { sendData } from '../services/data'


const GraciasPorTuInteres = () => {
    const [showHelp, setShowHelp] = useState(false)

    useEffect(() => {
        finalUpdateStatus()
    }, [])

    const finalUpdateStatus = async () => {
        const res = convertdataJSONStatus()
        const event_t = JSON.stringify(res)
        const json_final = {
            "status": 'OK',
            "step": "client-finished",
            "description": event_t,
            "finished": true
        }
        let event = JSON.stringify(json_final)
        await sendData({ 'step': 'client-finished', 'event': event })
        localStorage.setItem("completado", "true")
        sendStatus()
    }

    const convertdataJSONStatus = () => {
        const jsonObj = {}
        jsonObj.uuidUser = localStorage.getItem("uuidUser") || ''
        jsonObj.uuidTransaccion = localStorage.getItem("uuidTrx") || ''
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {}
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {}
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || []
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {}
        jsonObj.detailsScore = JSON.parse(localStorage.getItem('fields')) || []
        //var res = JSON.stringify(jsonObj)
        //console.log(res);
        return jsonObj
    }

    const sendStatus = () => {
        if (window.opener) {
            const uuidTrx = localStorage.getItem("uuidTrx")
            window.opener.postMessage(JSON.stringify({
                step: "completed",
                result: 'OK',
                transaccion: uuidTrx,
            }), localStorage.getItem("externalReference"));
        } else {
            return
        }
    }

    return (
        <>
            <div className="main_gradient flex_container">
                <Header show={() => setShowHelp(true)} />
                <div className="main_text_container flex_body">
                    <h1 className="animate__animated">Gracias por tu interés</h1>
                    <p className="animate__animated">Un promotor se <b>comunicará</b> contigo a la brevedad para <b>continuar</b> con el proceso.</p>
                </div>
                <IdTransaccion />
                <Footer />
            </div>

            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>
    )
}

export default GraciasPorTuInteres