/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
/* Librerías */
import { useHistory } from 'react-router-dom'
import { isIOS } from 'react-device-detect'
/* Componentes */
import Videotoken from 'dicio-npm-videotoken'
import Loader from '../loader'
import FlujoCancelado from '../../pages/flujo/cancelar_flujo'
import ErrorMessageAlert from '../alerts_modals/error_message_alert'
import LimitIntentsAlert from '../alerts_modals/limit_intents'
import ErrorPermisos from '../error_permisos'
import Instrucciones from './instrucciones'
import Ayuda from '../../pages/ayuda'
import CancelarFlujo from './modals/cancelar_flujo'
import MediaRecorderNoSoportado from './advertencias/media_recorder_no_soportado'
/* Funciones */
import {
    sendEventClick, evento, status, statusData, statusReintento, enviarVideo,
    mapearRespuesta,
    setLocalStorageResultFace,
    mapearError,
    mapearMensajeError,
    flowIncompleted,
    generateZip,
    validarRedireccionamiento,
    mapearRespuestaPruebaVida
} from '../../services/data'
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
/* Servicios */
import { getDocuments, } from '../../services/api';

const Index = ({
    apikey,
    uuidCliente,
    uuidTransaccion,
    dataOtorgante,
    service_call,
    previousStep,
    nextStep,
    // cancelStep,
    type,
    intents,
    disabledButton = false,
}) => {

    /* Variables */
    const urlEnvironment = process.env.REACT_APP_URL
    const environment = (process.env.REACT_APP_SERVICE)
    /* Hooks */
    const history = useHistory()
    const [reintentos, setReintentos] = useState(0)
    const [intentos, setIntentos] = useState(0)
    const [mensajeError, setMensajeError] = useState('')
    const [response, setResponse] = useState('')
    const [errorStatus, setErrorStatus] = useState('')
    const [error, setError] = useState('')
    const [estado, setEstado] = useState('')
    const [bloqueo, setBloqueo] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorPermisos, setErrorPermisos] = useState(false)
    const [permisosDenegados, setPermisosDenegados] = useState(false)
    const [disableFinishButton, setDisableFinishButton] = useState(true)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [insertComponent, setInsertComponent] = useState(true)
    const [isStart, setIstart] = useState(true)
    const [showHelp, setShowHelp] = useState(false)
    const [startProcess, setStartProcess] = useState(false)
    const [sendingVideo, setSendingVideo] = useState(false)
    const [mostraCancelar, setMostrarCancelar] = useState(false)
    const [supportMediaRecorder, setSupportMediaRecorder] = useState(true)
    /* Refs */
    const refLiveness = useRef(null)

    useEffect(() => {
        if (type === 'flujo') {
            status('captura_video_token_page', 'Captura Video Token')
        }

        return () => {
            if (refLiveness.current) {
                detenerVideo()
                setInsertComponent(false)
            }
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
        if (dataOtorgante.length > 0) {
            setIntentos(Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_VIDEO_TOKEN', intents)))
            const bloqueo = localStorage.getItem('bloqueo')
            if (bloqueo && bloqueo === 'true') {
                localStorage.setItem('completado', 'true')
                setBloqueo(true)
                setDisableFinishButton(false)
                setReintentos(Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_VIDEO_TOKEN', intents)))
            } else {
                localStorage.setItem('bloqueo', 'false')
            }
        }
        return () => { }
    }, [dataOtorgante])

    useEffect(() => {
        if (errorStatus && reintentos > 0) {
            if (type === 'flujo') enviarReintento('Intentos Video Token', reintentos)
            if (reintentos === intentos) {
                localStorage.setItem('bloqueo', 'true')
                if (type === 'pdf') {
                    setDisableFinishButton(false)
                    detenerVideo()
                    setTimeout(() => {
                        setInsertComponent(false)
                    }, 500)
                }
                if (type === 'flujo' && response) obtenerDocumentos(response)
            } else {
                if (type === 'flujo') enviarReintento('Captura Video Token', errorStatus)
            }
        }
    }, [reintentos, errorStatus, response])

    useEffect(() => {
        if (!supportMediaRecorder) {
            setIstart(false)
        }
    }, [supportMediaRecorder]);

    const obtenerDocumentos = async (respuesta) => {
        try {
            const response = await getDocuments()
            const { status, data, data: { payload } } = response
            evento('Obtener documentos - ' + type, 'Succes', mapearRespuesta(status, data), true)
            if (status === 200) {
                if (payload.links.length > 0) {
                    let links = JSON.stringify(payload.links)
                    localStorage.setItem('links', links)
                }
            }
            setTimeout(() => {
                finalizarFlujo(respuesta)
            }, 300);
        } catch (error) {
            let errorMapeado = mapearError(error)
            evento('Obtener documentos - ' + type, errorMapeado.tipoError, errorMapeado.objetoError, false)
            setTimeout(() => {
                finalizarFlujo(respuesta)
            }, 300);
        }
    }

    const enviarBlobVideo = async (blob, creacion, expiracion) => {
        setLoading(true)
        if (blob) {
            try {
                const response = await enviarVideo(blob, isIOS, creacion, expiracion, service_call)
                setSendingVideo(false)
                const { status, data, data: { payload } } = response
                if (status === 200) {
                    evento('Videotoken - ' + type, 'Success', mapearRespuesta(status, data), true)
                    if (type === 'flujo') {
                        sendData(data.payload.match)
                        mapearRespuestaPruebaVida(payload)
                    }
                    setLocalStorageResultFace(data.payload)
                    if (type === 'pdf') localStorage.setItem('enlace', data.payload.documentLink)
                    setTimeout(() => {
                        setLoading(false)
                        history.replace(nextStep)
                    }, 300);
                }
            } catch (error) {
                const errorMapeado = mapearError(error)
                setSendingVideo(false)
                evento('Videotoken - ' + type, errorMapeado.tipoError, errorMapeado.objetoError, false)
                if (errorMapeado.objetoError.tipo === 'RESPONSE') {
                    setResponse(errorMapeado)
                    let mensaje = ''
                    if (error.response.data) {
                        mensaje = error.response.data.message_client
                    }
                    let errorMessage = mapearMensajeError(mensaje)
                    if (error.response.status === 500 || error.response.status === 400) {
                        if (mensaje === 'No se encontro rostro' || mensaje === 'Se detecto cubrebocas' || mensaje === 'Condiciones de luz no adecuadas' || mensaje === 'Imagen borrosa' || mensaje === 'Se detectaron multiples caras' || mensaje === 'Falta de luz' || mensaje === 'No se pudo realizar la comparacion de rostro' || mensaje === 'No logramos detectar un rostro') {
                            setMensajeError(errorMessage)
                            setErrorStatus(errorMessage)
                            setError(true)
                            setLoading(false)
                        } else {
                            if (mensaje === 'Lo sentimos no hay coincidencia entre tu selfie registrada y la persona actual') {
                                statusData("captura_video_token", { coincidencia: false })
                            }
                            setMensajeError(errorMessage)
                            setErrorStatus(errorMessage)
                            setError(true)
                            setLoading(false)
                            setReintentos(reintentos => reintentos + 1)
                        }
                    } else if (error.response.status === 404) {
                        setMensajeError(errorMessage)
                        setErrorStatus(errorMessage)
                        setError(true)
                        setLoading(false)
                        setReintentos(reintentos => reintentos + 1)
                    } else {
                        setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        setError(true)
                        setLoading(false)
                    }
                } else {
                    if (error.toString().includes('Network')) {
                        setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        setError(true)
                        setLoading(false)
                    } else if (error.toString().includes('cancel')) {
                        setMensajeError('Se canceló el flujo')
                        setErrorStatus('Se canceló el flujo')
                        setError(true)
                        setLoading(false)
                    } else {
                        setMensajeError('El servicio no se encuentra disponible, lo solucionaremos en breve')
                        setErrorStatus('Error en la captura')
                        setError(true)
                        setLoading(false)
                    }
                }
            }
        }
    }

    const finalizarFlujo = async (response) => {
        detenerVideo()
        setInsertComponent(false)
        setLoading(true)
        await generateZip('SEMAFORO', 'red', isIOS)
        localStorage.setItem('bloqueo', 'true')
        const newDate = new Date().getTime()
        const acceptDta = new Date()
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem('time', acceptDta.getTime())
        const res = convertdataJSON();
        const event_t = await JSON.stringify(res)
        const json_final = {
            'status': 'FAIL',
            'step': 'finished',
            'description': event_t,
            'response': response,
            'finished': true
        }
        const event = JSON.stringify(json_final)
        await flowIncompleted({ 'step': 'captura_video_token', 'event': event }, 'red')
        localStorage.setItem('completado', 'true')
        setLoading(false)
        setDisableFinishButton(false)
    }

    const convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem('uuidUser') || '';
        jsonObj.uuidTrx = localStorage.getItem('uuidTrx') || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem('ocrFront')) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem('ocrBack')) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem('data_user')) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    const redirect = () => {
        if (type === 'flujo') {
            if (validarRedireccionamiento(dataOtorgante)) {
                history.replace(previousStep)
            } else {
                setShowCancelScreen(true)
                setEstado('fallido')
            }
        } else {
            history.replace(previousStep)
        }
    }

    const cancelarFlujoIntento = () => {
        sendEventClick(`Videotoken - ${type}`, 'CANCELAR')
        setError(false)
        setMostrarCancelar(true)
    }

    const enviarReintento = async (step, description) => {
        await statusReintento(step, description, 'RETRY');
        // statusData('captura_video_token', { coincidencia: false })
    }

    const sendData = (match) => {
        //Cuando es exitoso, revisar la bandera  de speech
        statusData('captura_video_token', { coincidencia: match, speech: true })
    }

    const getPermissions = () => {
        setPermisosDenegados(false)
        window.location.reload()
    }

    const reintentar = () => {
        setError(false)
        reiniciarProceso()
    }

    /* Mapear respuestas componente */
    const getResponse = response => {
        // console.log('RESPONSE', response)
        const { mensaje } = response
        switch (mensaje) {
            case 'Obtener parametros otorgante':
                evento(`Videotoken - ${type}`, 'Success', { mensaje: mensaje, respuesta: response.respuesta }, true)
                break
            case 'Cancelar proceso':
                evento(`Videotoken - ${type}`, 'Step', { mensaje: mensaje }, true)
                setMostrarCancelar(true)
                break
            case 'Generando OTP':
                evento(`Videotoken - ${type}`, 'Success', { mensaje: mensaje }, true)
                setSendingVideo(true)
                break
            case 'Generar OTP':
                evento(`Videotoken - ${type}`, 'Success', { mensaje: mensaje, respuesta: response.respuesta }, true)
                setSendingVideo(false)
                break
            case 'Reproduciendo streaming':
                evento(`Videotoken - ${type}`, 'Step', { mensaje: mensaje }, true)
                /* const bloqueo = localStorage.getItem('bloqueo')
                if (bloqueo && bloqueo === 'true') {
                    cancelarServicioOTP()
                    cancelarProceso()
                    setTimeout(() => {
                        setInsertComponent(false)
                    }, 500)
                } */
                break
            case 'Iniciando lectura token':
                evento(`Videotoken - ${type}`, 'Success', { mensaje: mensaje }, true)
                setSendingVideo(true)
                break
            default:
                evento(`Videotoken - ${type}`, 'Step', { mensaje: mensaje }, true)
                break
        }
    }

    const getError = (response) => {
        const { mensaje } = response
        switch (mensaje) {
            case 'Generar OTP':
                const { error: { tipoError, error } } = response
                evento(mensaje, tipoError, error, false)
                switch (error.tipo) {
                    case 'RESPONSE':
                        if (error.data.error_code === 'SVC_ERROR_CONNECT"') {
                            setMensajeError('Tenemos problemas para establecer contacto, asegúrate de tener una buena conexión a internet o inténtalo más tarde')
                        } else {
                            setMensajeError('Por favor asegúrate de retirar tu cubreboca, lentes y evita colocarte de espaldas a una fuente de luz')
                        }
                        enviarReintento('Captura Video Token', 'Error en la captura de rostro')
                        setError(true)
                        setSendingVideo(false)
                        break;
                    case 'REQUEST':
                        if (error.error === 'ERR_CANCELED') {
                            setMensajeError('Tenemos problemas para establecer contacto, asegúrate de tener una buena conexión a internet o inténtalo más tarde')
                        } else  if (error.error === 'ERR_NETWORK') {
                            setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                            setError(true)
                        } else {
                            setMensajeError('Por favor asegúrate de retirar tu cubreboca, lentes y evita colocarte de espaldas a una fuente de luz')
                            enviarReintento('Captura Video Token', 'Error en la captura de rostro')
                            setError(true)
                        }
                        setSendingVideo(false)
                        break;
                    default:
                        setMensajeError('Por favor ubica tu rostro en el área marcada para continuar')
                        enviarReintento('Captura Video Token', 'Error en la captura de rostro')
                        setError(true)
                        setSendingVideo(false)
                        break;
                }
                break
            case 'No soporta MediaRecorder':
                evento(`Videotoken - ${type}`, 'Exception', { error: mensaje }, false)
                setSupportMediaRecorder(false)
                break
            case 'Error video':
                evento(`Videotoken - ${type}`, 'Exception', response.error, false)
                break
            case 'No se pudo obtener el frame del OTP':
                evento(`Videotoken - ${type}`, 'Exception', response.error, false)
                setMensajeError('Tenemos problemas para establecer contacto, asegúrate de tener una buena conexión a internet o inténtalo más tarde')
                enviarReintento('Captura Video Token', 'Error en la captura de rostro')
                setError(true)
                break
            case 'Error al mostrar token':
                evento(`Videotoken - ${type}`, 'Exception', response.error, false)
                setMensajeError('Por favor ubica tu rostro en el área marcada para continuar')
                enviarReintento('Captura Video Token', mensaje)
                setError(true)
                break
            default:
                break
        }
    }

    const getErrorPermissions = error => {
        const { status } = error
        if (status === 'PERMISOS DENEGADOS') {
            setPermisosDenegados(true)
        }
        evento(`Videotoken - ${type}`, 'User Media', status, true)
        setErrorPermisos(true)
    }

    const getVideoBlob = (response) => {
        const { blob, creacion, expiracion } = response
        enviarBlobVideo(blob, creacion, expiracion)
    }

    /* Funciones componente */
    const detenerVideo = () => {
        if (refLiveness.current) refLiveness.current.detenerStreaming()
    }

    const reiniciarProceso = () => {
        if (refLiveness.current) refLiveness.current.reiniciarProceso(true)
    }

    const cancelarProceso = () => {
        if (refLiveness.current) refLiveness.current.cancelarProceso()
    }

    const cancelarServicioOTP = () => {
        if (refLiveness.current) refLiveness.current.cancelarServicioOTP()
    }

    return (
        <>
            {!supportMediaRecorder &&
                <MediaRecorderNoSoportado />
            }
            {(!isStart && supportMediaRecorder && insertComponent) &&
                <div>
                    {insertComponent &&
                        <Videotoken
                            uuidClient={uuidCliente}
                            uuidTransaction={uuidTransaccion}
                            apikey={apikey}
                            environment={environment}
                            urlEnvironment={urlEnvironment}
                            classButtonCancel={'button_outline_punto_trader'}
                            startProcess={startProcess}
                            blocking={bloqueo}
                            paramsOtorgante={dataOtorgante}
                            getResponse={getResponse}
                            getError={getError}
                            getErrorPermissions={getErrorPermissions}
                            getVideoBlob={getVideoBlob}
                            ref={refLiveness}
                        />
                    }
                </div>}

            {showCancelScreen &&
                <FlujoCancelado
                    uuidTransaccion={uuidTransaccion}
                    dataOtorgante={dataOtorgante}
                    estado={estado} />}

            {(error && (reintentos < intentos)) &&
                <ErrorMessageAlert
                    errorMessage={mensajeError}
                    functionOnRetry={reintentar}
                    functionOnCancel={cancelarFlujoIntento}
                />
            }

            {((reintentos >= intentos) || bloqueo) &&
                <LimitIntentsAlert
                    functionOnOut={redirect}
                    uuidTransaccion={uuidTransaccion}
                    disableFinishButton={disableFinishButton}
                />
            }

            {loading && (
                <Loader />
            )}

            {errorPermisos &&
                <ErrorPermisos
                    permisosDenegados={permisosDenegados}
                    setErrorPermisos={setErrorPermisos}
                    permisoCamara={getPermissions}
                />
            }
            {(isStart && !showHelp) &&
                <Instrucciones
                    type={type}
                    previousStep={previousStep}
                    startProccess={startProcess}
                    sendingVideo={sendingVideo}
                    dataOtorgante={dataOtorgante}
                    disabledButton={disabledButton}
                    setEstado={setEstado}
                    setShowHelp={setShowHelp}
                    setStartProcess={setStartProcess}
                    setIstart={setIstart}
                    setLoading={setLoading}
                    setShowCancelScreen={setShowCancelScreen}
                    reiniciarProceso={reiniciarProceso}
                    cancelarProceso={cancelarProceso}
                    cancelarServicioOTP={cancelarServicioOTP}
                />
            }
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {(mostraCancelar) &&
                <CancelarFlujo
                    tipo={'proceso'}
                    type={type}
                    previousStep={previousStep}
                    sendingVideo={sendingVideo}
                    dataOtorgante={dataOtorgante}
                    setEstado={setEstado}
                    setShowCancel={setMostrarCancelar}
                    setLoading={setLoading}
                    setShowCancelScreen={setShowCancelScreen}
                    reiniciarProceso={reiniciarProceso}
                    cancelarProceso={cancelarProceso}
                    cancelarServicioOTP={cancelarServicioOTP}
                />
            }
        </>
    );
}

export default Index;
