/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment } from "react"

const encuentraInfo = ({ modeloIdentificacion }) => {

    const showModal = () => {
        var modal = document.getElementById("myModal")
        var btn = document.getElementById("myBtn")
        var span = document.getElementsByClassName("close")[0]
        var span2 = document.getElementById("closeModal")

        btn.onclick = function () {
            modal.style.display = "block"
        }

        span.onclick = function () {
            modal.style.display = "none"
        }

        span2.onclick = function () {
            modal.style.display = "none"
        }

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.style.display = "none"
            }
        }
    }

    return (
        <Fragment>
            <div className="ocr_data_display">
                <button type="button" id="myBtn"
                    className="btn btn-primary forcewidth100 text_color_punto_trader calibri_punto_trader text_size_punto_trader"
                    data-toggle="modal"
                    data-target="#modal_ine" onMouseOver={showModal}>
                    Encuentra tu información
                </button>
            </div>

            <div className="modal" tabIndex="-1" role="dialog" id="myModal" /*id="modal_ine"*/>
                <div className="modal-dialog" role="document" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title ubuntu_bold_punto_trader">Validación INE</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <p className="calibri_punto_trader">Identifica la versión de tu identificación y válida la información.</p>
                            <p><b className="calibri_bold_punto_trader">Frente</b></p>
                            <div className='centerText'>
                                {(modeloIdentificacion !== 'C') && <img src="./images/guias_identificacion/credencial-modeloEG_f.svg" className="img-fluid" alt="Responsive image" />}
                                {(modeloIdentificacion === 'C') && <img src="./images/guias_identificacion/credencial-modeloC_f.svg" className="img-fluid" alt="Responsive image" />}
                            </div>
                            <p><b className="calibri_bold_punto_trader">Reverso</b></p>
                            <div className='centerText'>
                                {(modeloIdentificacion !== 'C' && modeloIdentificacion !== 'D') && <img src="./images/guias_identificacion/credencial-modeloEG_r.svg" className="img-fluid" alt="Responsive image" />}
                                {(modeloIdentificacion === 'D') && <img src="./images/guias_identificacion/credencial-modeloD__r.svg" className="img-fluid" alt="Responsive image" />}
                                {(modeloIdentificacion === 'C') && <img src="./images/guias_identificacion/credencial-modeloC__r.svg" className="img-fluid" alt="Responsive image" />}
                            </div>
                        </div>
                        <div className="modal-footer" id="closeModal">
                            <button type="button" className="btn btn-secondary text_color_punto_trader calibri_punto_trader text_size_punto_trader" data-dismiss="modal">Entendido</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default encuentraInfo;
