import {
    actualizarEstado, obtenerResultadoRenapo, consultarListasNegras,
    enviarHologramaIdentificacion, enviarEvento, finalUpdateScore, getDocuments, enviarRenapo,
    enviarCorreoAceptacion, enviarImagenEvaluar, nuevoVideotoken, obtenerInfoDispositivo, enviarIneData, /* obtenerResultadoINE, */
    updateScore
} from '../services/api.js';
import {
    v4 as uuidv4
} from 'uuid';
import {
    obtenerValorConfig,
    obtenerValorUser
} from './configStyle';
import moment from 'moment';
import history from '../services/history';
import { isIOS } from 'react-device-detect';

let objetoFinal = {};
let contadorRENAPO = 0, contadorINE = 0, cancelarPeticionINE = false, cancelarPeticionRENAPO = false;

export const agregarInfo = (nombrePropiedad, valorPropiedad) => {
    let dataUserLS = JSON.parse(localStorage.getItem("data_user"));
    dataUserLS[nombrePropiedad] = valorPropiedad;
    localStorage.setItem("data_user", JSON.stringify(dataUserLS))
}


export const hayExcepcion = () => {
    const uuidExcepcion = ["Wco5CTXw7e0JoKR9p7TB9fqOjpUS7iuu", "VCVi90WBhqtRPWboXtfpHBQlLPO00jd4", "MQZq6jzleILkHbQhGULIdwPhn5Jv19HD"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const regresarJson = () => {
    const uuidExcepcion = ["iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const esEditable = () => {
    //lso campos son editables para CDC y otorgantes
    const uuidExcepcion = ["0zmV8ljIWoFfGsaieC4vuEPwpVKYYrAP", "MTMba54LICmL2Boez3KuFdVL1vD1boKk", "7a531a75-e0cf-4158-bd99-86b733ef22ad", "94b502fd-5546-44e1-b12f-2122d410cea0"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    //console.log("respuesta", respuesta);
    return respuesta
}

export const initStatus = (nombreOtorgante) => {
    //console.log("entre a initStatus");
    updateInitStatus(nombreOtorgante);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: "inicio_page",
            result: "OK",
            //data: { transaccion: localStorage.getItem("uuidTrx")}
        }), new URL(window.location).searchParams.get("referer"))
        //console.log("hay referer");
        window.opener.postMessage(JSON.stringify({
            step: "inicio",
            result: "OK",
            data: {
                uuid: localStorage.getItem("uuidUser")
            }
        }), new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalJWT", new URL(window.location).searchParams.get("jwt"))
    }
    if (new URL(window.location).searchParams.get("referer")) {
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
    }
    if (new URL(window.location).searchParams.get("schema")) {
        localStorage.setItem("package", new URL(window.location).searchParams.get("schema"))
    }
}

const updateInitStatus = async (nombreOtorgante) => {
    if (localStorage.getItem("completado") === "false") {
        let trx_id = localStorage.getItem("uuidTrx")
        if (trx_id === null) {
            //console.log("se creo nuevo trx");
            trx_id = uuidv4()
            localStorage.setItem("uuidTrx", trx_id)
        }
        const json = {
            "status": "OK",
            "step": "inicio"
        }
        const company_id = localStorage.getItem("apikeyOtorgante");
        const client_id = localStorage.getItem("uuidUser");
        const tipoFlujo = localStorage.getItem("tipoFlujo") || '';
        const event = JSON.stringify(json);
        const objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'step': "inicio_page",
            'event': event,
            'origin': "celd-pcb",
            'nombreOtorgante': nombreOtorgante,
            'flujo': tipoFlujo
        }

        console.log(`🚀 ----------------------------------------------------------------------🚀`)
        console.log(`🚀 ~ file: data.js:117 ~ updateInitStatus ~ objetoStatus:`, objetoStatus)
        console.log(`🚀 ----------------------------------------------------------------------🚀`)

        try {
            const response = await actualizarEstado(objetoStatus);
            const { status, data } = response;
            if (status === 200) {
                await evento('Inicio', 'Update Cycle', mapearRespuesta(status, data), true);
            }
        } catch (error) {
            console.log(`🚀 ----------------------------------------------------🚀`)
            console.error(`🚀 ~ file: data.js:279 ~ updateStatus ~ error:`, error)
            console.log(`🚀 ----------------------------------------------------🚀`)
            const errorMapeado = mapearError(error)
            evento('Inicio', `${errorMapeado.tipoError} Update Cycle`, errorMapeado.objetoError, false)
        }
    } else {
        return
    }
}

export const status = (paso, updatepaso) => {
    let json = {
        "status": "OK",
        "step": updatepaso
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK"
        }), localStorage.getItem("externalReference"))
    }
}

export const statusData = async (paso, datos) => {
    var json = {};
    json = {
        "status": "OK",
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    await updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK",
            data: datos
        }), localStorage.getItem("externalReference"))
    }
}

export const statusError = async (paso, datos, tipo) => {
    let json = {};
    let status = "INCOMPLETED"
    if (tipo !== "cancelado") {
        status = "FAIL"
    }
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }
    if (tipo === "cancelado") {
        json.finished = true;
    }
    const event_t = JSON.stringify(json);

    const objetoStatus = {
        'step': paso,
        'event': event_t
    }
    await updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            status: 'INCOMPLETED',
            type: "PROCESS",
            error_text: datos,
        }), localStorage.getItem("externalReference"))
    }
}

const updateStatus = async (objetoInformacion) => {
    if (localStorage.getItem("completado") === "false") {
        const trx_id = localStorage.getItem("uuidTrx");
        const company_id = localStorage.getItem("apikeyOtorgante");
        const client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const bandera = localStorage.getItem("flag");

        const telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        const correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        const fullName = localStorage.getItem("nombreCompleto") ? localStorage.getItem("nombreCompleto") : '';
        const flag = bandera ? bandera : '';
        const tipoIdentificacion = localStorage.getItem("typeID");
        const tipoFlujo = localStorage.getItem("tipoFlujo") || '';

        const objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd-pcb",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag,
            'typeID': tipoIdentificacion ? tipoIdentificacion : '',
            'flujo': tipoFlujo
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        console.log(`🚀 ----------------------------------------------------------------🚀`)
        console.log(`🚀 ~ file: data.js:255 ~ updateStatus ~ objetoFinal:`, objetoFinal)
        console.log(`🚀 ----------------------------------------------------------------🚀`)

        const dataEvent = JSON.parse(objetoInformacion.event);

        try {
            const response = await actualizarEstado(objetoFinal);
            const { status, data } = response;
            if (status === 200) {
                const objeto = mapearRespuesta(dataEvent.status, data)
                objeto.dataLifeCycle = objetoFinal;
                await evento(dataEvent.step, 'Update Cycle', objeto, true)
            }
        } catch (error) {
            console.log(`🚀 ----------------------------------------------------🚀`)
            console.error(`🚀 ~ file: data.js:279 ~ updateStatus ~ error:`, error)
            console.log(`🚀 ----------------------------------------------------🚀`)
            const errorMapeado = mapearError(error)
            await evento(dataEvent.step, `${errorMapeado.tipoError} Update Cycle`, errorMapeado.objetoError, false)
        }
    } else {
        return
    }
}

/* Validaciones */

export const hayLetras = (input) => {
    let result = true
    var expreg = /[a-z]/gi;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    return result
}

export const esNumLetras = (input) => {
    let result = true
    var expreg = /^[A-Za-z0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esNumLetras",result);
    return result
}

export const esSoloLetras = (input) => {
    let result = true
    // var expreg = /^[A-Za-z-zÀ-ú\s]+$/g;
    const expreg = /^[A-Za-zÀ-ÖØ-öø-ÿ_ ]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esSoloLetras",result);
    return result
}

export const esNumeros = (input) => {
    let result = true
    var expreg = /^[0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esnum",result);
    return result
}

const validarCurp = (curp) => {
    let result = true
    const expreg = /^[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;
    //var result = input.match(expreg);
    if (!curp.match(expreg))
        result = false
    //console.log("RESULT",result);
    return result
}

export const statusReintento = (paso, datos, tipo) => {
    var json = {};
    let status = tipo
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }

    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }

    return updateStatus(objetoStatus);
}

export const sendData = (objetoStatus) => {
    return updateStatus(objetoStatus);
}

export const formatoFechaOcr = (fecha) => {
    let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
    let nacimientoC = fecha
    if (fecha.includes(' '))
        nacimientoC = fecha.replaceAll(' ', '/')
    if (moment(nacimientoC, formatosP, true).isValid())
        return nacimientoC;
    else {
        return "";
    }
}

export const formatoFechaCuatroDigitos = (fecha) => {
    const añoActualInt = parseInt(new Date().getFullYear().toString().substring(2));
    const añoFechaOcr = fecha.substring(2);
    const añoFechaOcrInt = parseInt(fecha.substring(2));
    const fechaSinAño = fecha.substring(0, 6);
    let fechaOcr = '';
    if (añoFechaOcrInt >= añoActualInt) {
        fechaOcr = fechaSinAño + '19' + añoFechaOcr;
    } else {
        const mayoriaDeEdad = añoActualInt - 18;
        if (añoFechaOcrInt <= mayoriaDeEdad) {
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        } else {
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        }
    }
    return fechaOcr;
}

export const validarValoresFormulario = (valores) => {
    let validacion = {
        errores: {},
        correcto: true
    };

    Object.entries(valores).forEach(([key, value]) => {
        const campoValidado = validarCampo(value, key);
        if (!campoValidado.correcto) {
            validacion.errores[key] = campoValidado.errores[key];
            validacion.correcto = false;
        }
    });

    return validacion;
};

export const validarCampo = (valor, campo) => {
    let validacion = {
        errores: {},
        correcto: false
    };
    let formatosFecha = ['DD/MM/YYYY'];
    switch (campo) {
        case "nombreCompleto":
            if (!valor.trim()) {
                validacion.errores.nombreCompleto = 'Debes ingresar un valor válido';
            } else if (valor.length < 10 || !esSoloLetras(valor)) {
                validacion.errores.nombreCompleto = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fechaNacimiento":
            if (!valor.trim()) {
                validacion.errores.fechaNacimiento = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.fechaNacimiento = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "clave":
            if (!valor.trim()) {
                validacion.errores.clave = 'Debes ingresar un valor válido';
            } else if (!esNumLetras(valor) || valor.length < 17 || valor.length > 20) {
                validacion.errores.clave = 'Clave de elector no válida';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fechaRegistro":
            if (!valor.trim()) {
                validacion.errores.fechaRegistro = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 4) {
                validacion.errores.fechaRegistro = 'Revisa que el año sea 4 dígitos';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroEmision":
            if (!valor.trim()) {
                validacion.errores.numeroEmision = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 2) {
                validacion.errores.numeroEmision = 'Formato de Emisión no válido, 2 dígitos';
            } else {
                validacion.correcto = true;
            }
            break;
        case "cic":
            if (!valor.trim()) {
                validacion.errores.cic = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 9) {
                validacion.errores.cic = 'Formato de CIC no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "ocr":
            if (!valor.trim()) {
                validacion.errores.ocr = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length < 12 || valor.length > 13) {
                validacion.errores.ocr = 'Formato de OCR no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "identificadorCiudadano":
            if (!valor.trim()) {
                validacion.errores.identificadorCiudadano = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 9) {
                validacion.errores.identificadorCiudadano = 'Formato de identificador del ciudadano no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "curp":
            if (!valor.trim()) {
                validacion.errores.curp = 'Debes ingresar un valor válido';
            } else if (valor.length < 18 || !esNumLetras(valor) || valor.length > 18 || !validarCurp(valor)) {
                validacion.errores.curp = 'Formato de CURP no válido';
            } else if (valor.length === 18 && !validarCurp(valor)) {
                validacion.errores.curp = 'Formato de CURP no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "vigencia":
            let today = new Date();
            let year = today.getFullYear();
            if (!valor.trim()) {
                validacion.errores.vigencia = 'Debes ingresar un valor válido';
            } else if ((valor.length !== 4)) {
                validacion.errores.vigencia = 'Revisa que el año sea 4 dígitos';
            } else if (!esNumeros(valor)) {
                validacion.errores.vigencia = 'Formato de vigencia no válido';
            } else if (Number(valor) < year) {
                validacion.errores.vigencia = 'Vigencia de identificación no válida';
            } else {
                validacion.correcto = true;
            }
            break;
        case "vigenciaPasaporte":
            if (!valor.trim()) {
                validacion.errores.vigenciaPasaporte = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.errores.vigenciaPasaporte = 'Revisa el formato dd/mm/aaaa';
            } else if (!esNumeros(valor)) {
                validacion.errores.vigenciaPasaporte = 'Formato de vigencia no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "dni":
            if (!valor.trim()) {
                validacion.errores.dni = 'Debes ingresar un valor válido';
            } else if (valor.length < 3 || !esNumLetras(valor)) {
                validacion.errores.dni = 'Formato de DNI no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "nombres":
            if (!valor.trim()) {
                validacion.errores.nombres = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.nombres = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "apellidoPaterno":
            if (!valor.trim()) {
                validacion.errores.apellidoPaterno = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.apellidoPaterno = 'Apellido no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "apellidoMaterno":
            if (!valor.trim()) {
                validacion.errores.apellidoMaterno = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.apellidoMaterno = 'Apellido no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "genero":
            if (!valor.trim()) {
                validacion.errores.genero = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.genero = 'Género no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "telefono":
            if (!valor.trim()) {
                validacion.errores.telefono = 'Debes ingresar un valor válido';
            } else if (valor.length < 10 || !esNumeros(valor)) {
                validacion.errores.telefono = 'Número celular no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "email":
            if (!valor.trim() || !validar_correo(valor.trim())) {
                validacion.errores.email = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "entidadFederativa":
            if (!valor.trim()) {
                validacion.errores.entidadFederativa = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "pais":
            if (!valor.trim()) {
                validacion.errores.pais = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "nacionalidad":
            if (!valor.trim()) {
                validacion.errores.nacionalidad = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "empresa":
            if (!valor.trim()) {
                validacion.errores.empresa = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "giro":
            if (!valor.trim()) {
                validacion.errores.giro = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "actividadEconomica":
            if (!valor.trim()) {
                validacion.errores.actividadEconomica = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "rfc":
            if (!valor.trim()) {
                validacion.errores.rfc = 'Debes ingresar un valor válido';
            } else if (!esNumLetras(valor) || valor.length < 13) {
                validacion.errores.rfc = 'RFC no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numSerieEFirma":
            if (!valor.trim()) {
                validacion.correcto = true;
            } else if (!esNumeros(valor)) {
                validacion.errores.numSerieEFirma = 'Número de serie no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "residenciaFiscal":
            if (!valor.trim()) {
                validacion.errores.residenciaFiscal = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroCuenta":
            if (!valor.trim()) {
                validacion.errores.numeroCuenta = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor)) {
                validacion.errores.numeroCuenta = 'Número de cuenta no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "clabe":
            if (!valor.trim()) {
                validacion.errores.clabe = 'Debes ingresar un valor válido';
            } else if (valor.length < 18 || !esNumeros(valor)) {
                validacion.errores.clabe = 'Número CLABE no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroDepositos":
            if (!valor.trim()) {
                validacion.errores.numeroDepositos = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor)) {
                validacion.errores.numeroDepositos = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroRetiros":
            if (!valor.trim()) {
                validacion.errores.numeroRetiros = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor)) {
                validacion.errores.numeroRetiros = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fuenteIngresos":
            if (!valor.trim()) {
                validacion.errores.fuenteIngresos = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "transaccionalidadMensualDepositos":
            if (!valor.trim()) {
                validacion.errores.transaccionalidadMensualDepositos = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "transaccionalidadMensualRetiros":
            if (!valor.trim()) {
                validacion.errores.transaccionalidadMensualRetiros = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "cargoPolitico":
            if (!valor.trim()) {
                validacion.errores.cargoPolitico = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "tipoServicio":
            if (!valor.trim()) {
                validacion.errores.tipoServicio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "nombreCargoPolitico":
            if (!valor.trim()) {
                validacion.errores.nombreCargoPolitico = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "institucion":
            if (!valor.trim()) {
                validacion.errores.institucion = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "otroCargoPolitico":
            if (!valor.trim()) {
                validacion.errores.otroCargoPolitico = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroOperacionesMensuales":
            if (!valor.trim()) {
                validacion.errores.numeroOperacionesMensuales = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor)) {
                validacion.errores.numeroOperacionesMensuales = 'Debes ingresar un valor válid';
            } else {
                validacion.correcto = true;
            }
            break;
        case "rangoEdad":
            if (!valor.trim()) {
                validacion.errores.rangoEdad = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "nivelEstudios":
            if (!valor.trim()) {
                validacion.errores.nivelEstudios = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "estudiosEconomia":
            if (!valor.trim()) {
                validacion.errores.estudiosEconomia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "estrategiaInversionMayorExperiencia":
            if (!valor.trim()) {
                validacion.errores.estrategiaInversionMayorExperiencia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "instrumentosMercadoFinaciero":
            if (valor.length === 0) {
                validacion.errores.instrumentosMercadoFinaciero = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "rangoInversiones":
            if (!valor.trim()) {
                validacion.errores.rangoInversiones = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "origenRecursos":
            if (!valor.trim()) {
                validacion.errores.origenRecursos = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "porcentajePatrimonio":
            if (!valor.trim()) {
                validacion.errores.porcentajePatrimonio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "objetivoInversion":
            if (!valor.trim()) {
                validacion.errores.objetivoInversion = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "estrategiaInversionPasado":
            if (!valor.trim()) {
                validacion.errores.estrategiaInversionPasado = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "asumirPerdida":
            if (!valor.trim()) {
                validacion.errores.asumirPerdida = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "objetivosInversion":
            if (!valor.trim()) {
                validacion.errores.objetivosInversion = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "plazoEstimado":
            if (!valor.trim()) {
                validacion.errores.plazoEstimado = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "codigoPostalDomicilio":
            if (!valor.trim()) {
                validacion.errores.codigoPostalDomicilio = 'Debes ingresar un valor válido';
            } else if (valor.length < 5) {
                validacion.errores.codigoPostalDomicilio = 'Código postal no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "paisDomicilio":
            if (!valor.trim()) {
                validacion.errores.paisDomicilio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "entidadFederativaDomicilio":
            if (!valor.trim()) {
                validacion.errores.entidadFederativaDomicilio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "municipioDomicilio":
            if (!valor.trim()) {
                validacion.errores.municipioDomicilio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "coloniaDomicilio":
            if (!valor.trim()) {
                validacion.errores.coloniaDomicilio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "calleDomicilio":
            if (!valor.trim()) {
                validacion.errores.calleDomicilio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroCalleExteriorDomicilio":
            if (!valor.trim()) {
                validacion.errores.numeroCalleExteriorDomicilio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroCalleInteriorDomicilio":
            validacion.correcto = true;
            break;
        case "otraColoniaDomicilio":
            if (!valor.trim()) {
                validacion.errores.otraColoniaDomicilio = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "codigoPostalDomicilioCorrespondencia":
            if (!valor.trim()) {
                validacion.errores.codigoPostalDomicilioCorrespondencia = 'Debes ingresar un valor válido';
            } else if (valor.length < 5) {
                validacion.errores.codigoPostalDomicilioCorrespondencia = 'Código postal no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "paisDomicilioCorrespondencia":
            if (!valor.trim()) {
                validacion.errores.paisDomicilioCorrespondencia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "entidadFederativaDomicilioCorrespondencia":
            if (!valor.trim()) {
                validacion.errores.entidadFederativaDomicilioCorrespondencia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "municipioDomicilioCorrespondencia":
            if (!valor.trim()) {
                validacion.errores.municipioDomicilioCorrespondencia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "coloniaDomicilioCorrespondencia":
            if (!valor.trim()) {
                validacion.errores.coloniaDomicilioCorrespondencia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "calleDomicilioCorrespondencia":
            if (!valor.trim()) {
                validacion.errores.calleDomicilioCorrespondencia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroCalleExteriorDomicilioCorrespondencia":
            if (!valor.trim()) {
                validacion.errores.numeroCalleExteriorDomicilioCorrespondencia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroCalleInteriorDomicilioCorrespondencia":
            validacion.correcto = true;
            break;
        case "otraColoniaDomicilioCorrespondencia":
            if (!valor.trim()) {
                validacion.errores.otraColoniaDomicilioCorrespondencia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "codigoPostalDomicilioFiscal":
            if (!valor.trim()) {
                validacion.errores.codigoPostalDomicilioFiscal = 'Debes ingresar un valor válido';
            } else if (valor.length < 5) {
                validacion.errores.codigoPostalDomicilioFiscal = 'Código postal no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "paisDomicilioFiscal":
            if (!valor.trim()) {
                validacion.errores.paisDomicilioFiscal = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "entidadFederativaDomicilioFiscal":
            if (!valor.trim()) {
                validacion.errores.entidadFederativaDomicilioFiscal = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "municipioDomicilioFiscal":
            if (!valor.trim()) {
                validacion.errores.municipioDomicilioFiscal = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "coloniaDomicilioFiscal":
            if (!valor.trim()) {
                validacion.errores.coloniaDomicilioFiscal = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "calleDomicilioFiscal":
            if (!valor.trim()) {
                validacion.errores.calleDomicilioFiscal = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroCalleExteriorDomicilioFiscal":
            if (!valor.trim()) {
                validacion.errores.numeroCalleExteriorDomicilioFiscal = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroCalleInteriorDomicilioFiscal":
            validacion.correcto = true;
            break;
        case "otraColoniaDomicilioFiscal":
            if (!valor.trim()) {
                validacion.errores.otraColoniaDomicilioFiscal = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroCliente":
            if (!valor.trim()) {
                validacion.errores.numeroCliente = 'Debes ingresar un valor válido';
            } else if (valor.length < 3 || !esNumeros(valor) || valor.length > 30) {
                validacion.errores.numeroCliente = 'Numero de cliente no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "otraFuenteIngresos":
            if (!valor.trim()) {
                validacion.errores.otraFuenteIngresos = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "estadoCivil":
            if (!valor.trim()) {
                validacion.errores.estadoCivil = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "paisOCR":
            validacion.correcto = true;
            break;
        case "entidadFederativaOCR":
            validacion.correcto = true;
            break;
        default:
            validacion.correcto = true;
            break;
    }
    return validacion;
};

export const findInArray = (array, value, prop) => {
    let foundObject = null;
    if (prop) {
        foundObject = array.find(objeto => objeto[prop] === value);
    } else {
        foundObject = array.find(objeto => objeto.toLowerCase() === value.toLowerCase());
    }
    return foundObject;
}

export const validateInfoOCR = (nuevoObjeto, pais, entidadFederativa) => {
    let validacion = false;
    let validacionColonia = false;
    let validacionMunicipio = false;
    const ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
    if (ocrFront) {
        if (nuevoObjeto) {
            if (nuevoObjeto.coloniaDomicilio) {
                validacionColonia = ocrFront.colonia.includes(nuevoObjeto.coloniaDomicilio.toUpperCase());
            }
            if (nuevoObjeto.municipioDomicilio) {
                validacionMunicipio = ocrFront.municipio.includes(nuevoObjeto.municipioDomicilio.toUpperCase());
            }
            if ((ocrFront.cp.trim() === nuevoObjeto.codigoPostalDomicilio) && (ocrFront.calle.trim() === nuevoObjeto.calleDomicilio.toUpperCase().trim()) && (ocrFront.numero.trim() === nuevoObjeto.numeroCalleExteriorDomicilio) && validacionMunicipio && validacionColonia && nuevoObjeto.paisDomicilio === pais && nuevoObjeto.entidadFederativaDomicilio === entidadFederativa) {
                validacion = true;
            }
        } else {
            if (ocrFront.cp && ocrFront.cp.trim() && ocrFront.calle && ocrFront.calle.trim() && ocrFront.numero && ocrFront.numero.trim() && ocrFront.estado && ocrFront.estado.trim() && ocrFront.municipio && ocrFront.municipio.trim() && ocrFront.colonia && ocrFront.colonia.trim()) {
                validacion = true;
            }
        }
    }
    return validacion;
}

export const calculateX = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateY = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const calculateWidth = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);
    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateHeight = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const validarRenapo = async paso => {
    let jobLS = localStorage.getItem("jobRENAPO");
    let validacionRENAPO = localStorage.getItem("validacionRENAPO");
    let tipo = '';
    let informacion = {};
    //console.log(jobLS);
    if (jobLS && (validacionRENAPO === 'false')) {
        //console.log('VALIDACION_RENAPO', validacionRENAPO);
        return obtenerResultadoRenapo(jobLS).then(async ({ data: { payload: { item } }, status, data: { payload } }) => {
            if (status === 200) {
                if (item && item.length > 0) {
                    informacion.status = status;
                    informacion.payload = payload;
                    //localStorage.setItem("validacionRENAPO", true);
                    let responseItem = item[0];
                    if (responseItem.status.trim() === 'VALIDADO') {
                        statusData('validacion_renapo', { validacion: true, status: responseItem.status.trim() });
                        localStorage.setItem("validacionRENAPO", true);
                        localStorage.removeItem('jobRENAPO');
                    } else if (responseItem.status.trim() === 'INVALIDO') {
                        await generateZip('SEMAFORO', 'red', isIOS);
                        await statusError('validacion_renapo', { validacion: false, status: responseItem.status.trim() }, 'cancelado');
                        await obtenerDocumentos('validacion_renapo', informacion, 'red');
                        localStorage.setItem('completado', 'true');
                        history.replace({
                            pathname: '/finalizado',
                            state: { tipo: 'invalido' },
                        });
                    } else if (paso === 'videotoken') {
                        await generateZip('TRUNCOS', 'trunco', isIOS);
                        await statusError('validacion_renapo', { validacion: false, status: 'ERROR RENAPO' }, 'cancelado');
                        await obtenerDocumentos('validacion_renapo', informacion, 'cancelado');
                        localStorage.setItem('completado', 'true');
                        history.replace({
                            pathname: '/finalizado',
                            state: { tipo: 'cancelado' },
                        });
                    }
                } else {
                    if (paso === 'videotoken') {
                        await generateZip('TRUNCOS', 'trunco', isIOS);
                        await statusError('validacion_renapo', { validacion: false, status: 'ERROR RENAPO' }, 'cancelado');
                        await obtenerDocumentos('validacion_renapo', informacion, 'cancelado');
                        localStorage.setItem('completado', 'true');
                        history.replace({
                            pathname: '/finalizado',
                            state: { tipo: 'cancelado' },
                        });
                    }
                }
                evento('Validación RENAPO', 'Success', informacion, true);
            }
        }).catch(async error => {
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Validación RENAPO', tipo, informacion, false);
            if (paso === 'videotoken') {
                await generateZip('TRUNCOS', 'trunco', isIOS);
                await statusError('validacion_renapo', { validacion: false, status: 'ERROR RENAPO' }, 'cancelado');
                await obtenerDocumentos('validacion_renapo', informacion, 'cancelado');
                localStorage.setItem('completado', 'true');
                // history.replace('/cancelado');
                history.replace({
                    pathname: '/finalizado',
                    state: { tipo: 'cancelado' },
                });
            }
        });
    } else {
        if (paso === 'videotoken' && (!validacionRENAPO || (validacionRENAPO !== 'true'))) {
            informacion.mensaje = 'Error al encolar petición RENAPO';
            evento('Validación RENAPO', 'Error', informacion, true);
            await generateZip('TRUNCOS', 'trunco', isIOS);
            await statusError('validacion_renapo', { validacion: false, status: 'ERROR ENCOLAMIENTO' }, 'cancelado');
            await obtenerDocumentos('validacion_renapo', informacion, 'cancelado');
            localStorage.setItem('completado', 'true');
            // history.replace('/cancelado');
            history.replace({
                pathname: '/finalizado',
                state: { tipo: 'encolamiento' },
            });
        } else {
            if (!validacionRENAPO) encolarPeticionRenapo();
        }

    }
}

export const validarListasNegras = (uuid, nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento) => {
    let tipo = '';
    let informacion = {};
    consultarListasNegras(uuid, nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento).then(async ({ status, data: { payload: { respuesta: { existen_coincidencias } } }, data: { payload } }) => {
        //if (staus === 200) {
        informacion.status = status;
        informacion.payload = payload;
        if (!existen_coincidencias) {
            statusData('validacion_listas_negras', { validacion: existen_coincidencias });
        } else {
            await generateZip('SEMAFORO', 'red', isIOS);
            await statusError('validacion_listas_negras', 'Validacion listas negras no exitosa', 'cancelado');
            await obtenerDocumentos('validacion_listas_negras', informacion, 'red');
            localStorage.setItem('completado', 'true');
            setTimeout(() => {
                history.replace('/cancelado');
            }, 300);
        }
        evento('Validación listas negras', 'Success', informacion, true);
        //}
    }).catch(async error => {
        if (error.response) {
            let { data, status } = error.response
            informacion.mensaje = data;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Validación listas negras', tipo, informacion, false);
        await generateZip('SEMAFORO', 'red', isIOS);
        await statusError('validacion_listas_negras', 'Error al obtener validacion listas negras', 'cancelado');
        await obtenerDocumentos('validacion_listas_negras', informacion, 'red');
        localStorage.setItem('completado', 'true');
        setTimeout(() => {
            history.replace('/cancelado');
        }, 300);
    });
}

export const evento = async (paso, tipo, data, finished) => {
    const dato = JSON.stringify(data);
    let fecha = "";
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let seconds = date.getSeconds();
    fecha = `${year}-${(month > 9) ? month : `0${month}`}-${(day > 9) ? day : `0${day}`}_${(hour > 9) ? hour : `0${hour}`}:${(min > 9) ? min : `0${min}`}:${(seconds > 9) ? seconds : `0${seconds}`}`;
    const uuidUser = localStorage.getItem("uuidUser");
    const uuidTrx = localStorage.getItem("uuidTrx");
    const apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    const uuidOtorgante = localStorage.getItem("uuidOtorgante");
    const objeto = {
        uuid_clt: uuidUser,
        uuid_trx: uuidTrx,
        uuid_oto: uuidOtorgante,
        apikey: apikeyOtorgante,
        paso: paso,
        tipo: tipo,
        dato: dato,
        finished: finished,
        time_stamp: fecha
    }

    try {
        await enviarEvento(objeto)
        return
    } catch (error) {
        console.log(`🚀 -----------------------------------------------------🚀`)
        console.error(`🚀 ~ file: data.js:1259 ~ enviarEvento ~ error:`, error)
        console.log(`🚀 -----------------------------------------------------🚀`)
    }
}

export const agregarInfoAdicional = (elemento) => {
    let dataUserLS = JSON.parse(localStorage.getItem("data_user"))
    const informacionAdicional = dataUserLS.find((item) => {
        return item.description === 'informacion_adicional'
    })
    //console.log('INFO',informacionAdicional);
    informacionAdicional.value.push(elemento);
    localStorage.setItem("data_user", JSON.stringify(dataUserLS));
}

export const endpointHolograma = (file, isIOS) => {
    let tipo = '';
    let informacion = {};
    enviarHologramaIdentificacion(file, isIOS).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            tipo = 'Success';
            evento('Captura Holograma', tipo, informacion, true);
        }
    }).catch(error => {
        console.log('ERROR', error);
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Captura Holograma', tipo, informacion, false);
    })
}

export const flowIncompleted = async (objetoInformacion, flag) => {
    let tipo = '';
    let informacion = {};
    let objeto = { createScore: true }
    let scoreObject = JSON.parse(localStorage.getItem('scoreObject'))
    if (scoreObject) {
        objeto = { ...objeto, ...scoreObject }
    }
    return finalUpdateScore(objeto).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            evento('Actualizar Score', 'Success', informacion, true);
        }
        setFlagStatus(objetoInformacion, flag);
    }).catch(error => {
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Actualizar Score', tipo, informacion, false);
        setFlagStatus(objetoInformacion, flag);
    });
}

export const setFlagStatus = async (objetoInformacion, flag) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));

        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let fullName = localStorage.getItem("nombreCompleto") ? localStorage.getItem("nombreCompleto") : '';
        let tipoIdentificacion = localStorage.getItem("typeID");
        let tipoFlujo = localStorage.getItem("tipoFlujo") || '';

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd-pcb",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag,
            'typeID': tipoIdentificacion ? tipoIdentificacion : '',
            'flujo': tipoFlujo
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event)

        actualizarEstado(objetoFinal).then(async ({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                await evento(dataEvent.step, tipo, informacion, true);
                localStorage.setItem("completado", "true");
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento(dataEvent.step, tipo, informacion, false);
            localStorage.setItem("completado", "true");
        });
        if (window.opener) {
            window.opener.postMessage(JSON.stringify({
                step: objetoInformacion.step,
                status: 'INCOMPLETED',
                type: "PROCESS",
                error_text: 'Finalizado por reintentos',
            }), localStorage.getItem("externalReference"))
        }
    } else {
        return
    }
}

const obtenerDocumentos = async (paso, respuesta, flag) => {
    let tipo = '';
    let informacion = {};
    let res = await convertdataJSON();
    let event_t = await JSON.stringify(res);
    let json_final = {};
    let status = "FAIL"
    json_final = {
        "status": status,
        "step": "finished",
        "description": event_t,
        'response': respuesta,
        "finished": true
    }
    const event = JSON.stringify(json_final);
    return getDocuments().then(async (response) => {
        informacion.payload = response.data.payload;
        evento('Obtener Documentos', 'Success', informacion, true);
        if (response.status === 200) {
            if (response.data.payload.links.length > 0) {
                let links = JSON.stringify(response.data.payload.links);
                localStorage.setItem('links', links);
            }
        }
        await flowIncompleted({ 'step': paso, 'event': event }, flag);
    }).catch(async (error) => {
        if (error.response) {
            tipo = 'Exception';
        }
        evento('Obtener Documentos', tipo, informacion, false);
        await flowIncompleted({ 'step': paso, 'event': event }, 'red');
        //console.log("algun error documents");
    })
}

const convertdataJSON = () => {
    const jsonObj = {};
    jsonObj.uuidUser = localStorage.getItem("uuidUser") || '';
    jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
    jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
    return jsonObj;
}

export const encolarPeticionRenapo = () => {
    if (!cancelarPeticionRENAPO) {
        if (contadorRENAPO < 10) {
            let tipo = '';
            let informacion = {};
            const dataUser = (JSON.parse(localStorage.getItem("data_user")));
            let curp = obtenerValorUser(dataUser, "curp", '');
            return enviarRenapo(curp).then(({ status, data: { payload }, data: { payload: { job_id } } }) => {
                localStorage.setItem("jobRENAPO", job_id);
                localStorage.setItem("validacionRENAPO", false);
                informacion.status = status;
                informacion.payload = payload;
                evento('Encolar petición RENAPO', 'Success', informacion, true);
                statusData('validacion_renapo', { validacion: false, status: 'EN PROCESO' });
                contadorRENAPO = 0;
                validarRenapo(job_id);
            }).catch(error => {
                if (error.response) {
                    let { data, status } = error.response
                    informacion.data = data;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Encolar petición RENAPO', tipo, informacion, false);
                if (error.toString() === 'Cancel') {
                    statusData('validacion_renapo', { validacion: false, status: 'CANCELADO' });
                    contadorRENAPO = 0;
                } else {
                    statusData('validacion_renapo', { validacion: false, status: 'ERROR ENCOLAMIENTO' });
                    encolarPeticionRenapo(curp);
                    contadorRENAPO++;
                }
            })
        } else {
            statusData('validacion_renapo', { validacion: false, status: 'ERROR RENAPO' });
            contadorRENAPO = 0;
            return;
        }
    } else {
        statusData('validacion_renapo', { validacion: false, status: 'CANCELADO' });
        cancelarPeticionRENAPO = false;
        contadorRENAPO = 0;
    }
}

export const sendZipFlag = async (tipoEnvio, bandera, isIOS) => {
    let objeto = {};
    let tipo = '';
    let informacion = {};
    let uuid = '';
    let uuidTrx = '';
    let dataUser = {};
    let dataOtorgante = [];
    let uuidUser = localStorage.getItem("uuidUser")
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem("uuidTrx")
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataUserLS = localStorage.getItem("data_user")
    if (dataUserLS) {
        dataUser = JSON.parse(dataUserLS);
    }
    let dataOtorganteLS = localStorage.getItem("dataOtorgante")
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    objeto.tipo_envio = tipoEnvio;
    objeto.semaforo = bandera;
    objeto.uuidUser = uuid;
    objeto.name = obtenerValorUser(dataUser, "nombreCompleto", '');
    objeto.email = '';
    objeto.fNac = obtenerValorUser(dataUser, "nacimiento", '');
    objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
    objeto.vigencia = obtenerValorUser(dataUser, "vigencia", '');
    objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
    let fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");
    objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
    objeto.originOS = isIOS ? 'ios' : 'android';
    objeto.coincidencia = "Requiere validación";
    enviarCorreoAceptacion(objeto).then((response) => {
        if (response.status === 200) {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Envío Zip Bandera', 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Envío Zip Bandera', tipo, informacion, false);
    })
}

export const sendImage = async (imagen, modelo, nombre, tipoImagen, lado) => {
    let tipo = '';
    let informacion = {};
    enviarImagenEvaluar(imagen, modelo, nombre, lado).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            evento(tipoImagen, 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento(tipoImagen, tipo, informacion, false);
    })
}


/*--- Generación de archivop ZIP de NOM 151 ---*/
export const generateZip = async (tipoEnvio, bandera, isIOS) => {
    let objeto = {}
    let dataOtorgante = [];
    let dataUser = [];
    let uuid = '';
    let uuidTrx = '';
    let tipo = '';
    let nombreEvento = '';
    let informacion = {};

    let dataOtorganteLS = localStorage.getItem("dataOtorgante");
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    let uuidUser = localStorage.getItem("uuidUser");
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem("uuidTrx");
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataUserLS = localStorage.getItem("data_user")
    if (dataUserLS) {
        dataUser = JSON.parse(dataUserLS);
    }
    const sendZipTruncos = obtenerValorConfig(dataOtorgante, "SEND_ZIP_TRUNCOS", 'false');
    const sendZipSemaforo = obtenerValorConfig(dataOtorgante, "SEND_ZIP_SEMAFORO", 'false');
    let fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");

    objeto.tipo_envio = tipoEnvio;
    objeto.semaforo = bandera;
    objeto.uuidUser = uuid;
    objeto.originOS = isIOS ? 'ios' : 'android';
    objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];

    switch (bandera) {
        case 'trunco':

            nombreEvento = 'Generacíon de Nom 151 trunco';
            objeto.ocrFront = null;
            objeto.ocrBack = null;
            objeto.data_user = null;
            break;

        default:

            nombreEvento = 'Generacíon de Nom 151 con Bandera';
            objeto.name = obtenerValorUser(dataUser, "nombreCompleto");
            objeto.email = '';
            objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
            objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
            objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
            objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
            objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
            objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
            objeto.coincidencia = "Requiere validación";

            break;
    }
    if (((tipoEnvio === 'TRUNCOS') && (sendZipTruncos === 'true')) || ((tipoEnvio === 'SEMAFORO') && (sendZipSemaforo === 'true'))) {
        return enviarCorreoAceptacion(objeto).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento(nombreEvento, 'Success', informacion, true);
            }
        }).catch((error) => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento(nombreEvento, tipo, informacion, false);
        })
    } else
        return
}

//LIVENESS

const msjError = "No hemos logrado validar tu captura, por favor sigue las instrucciones e intenta nuevamente"
const msjLuz = "Por favor busca una posición con menos luz directa en tu rostro al momento de la captura."
const msjObscuridad = "Por favor busca un lugar con más luz al momento de la captura."
const msjCubrebocas = "Parece que algún objeto obstruye tu rostro, por favor retíralo y captura nuevamente."
const msjRostro = "Por favor busca una posición con menos luz directa y centra tu rostro en el área marcada al momento de la captura."
const msjMultiple = "Parece que hay más de un rostro en la toma, asegúrate que no haya nadie mas al momento de la captura."
const msjBorrosa = "Por favor asegúrate que tu cámara esta limpia y mantén firme tu dispositivo para evitar distorsiones en la imagen."
const msjFrase = "No logramos entender la frase, por favor lee FUERTE y CLARO en un lugar silencioso e intenta nuevamente."
const msjFrase2 = "No logramos entender la frase, por favor lee FUERTE y CLARO en un lugar silencioso e intenta nuevamente."
const msjErrorEmbedings = "Lo sentimos detectamos inconsistencias en tu captura, por favor reinicia el proceso."

export const cancelarINE = () => cancelarPeticionINE = true;

export const cancelarRENAPO = () => cancelarPeticionRENAPO = true;

export const setCancelarINE = () => cancelarPeticionINE = false;

export const setCancelarRENAPO = () => cancelarPeticionRENAPO = false;

export const convertBase64ToBlob = (base64) => {
    return fetch(base64).then(res => res.blob())
}

export const enviarImagenVideotoken = (image, isIOS, service_call) => {
    let originOS = isIOS ? 'ios' : 'android';
    let data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", null);
    data.append("expire", null);
    data.append("originOS", originOS);
    data.append("service_call", service_call);
    data.append("disable_video_token", true);
    const ocrFront = JSON.parse(localStorage.getItem("ocrFront"))
    let passFlag = "false"
    if (ocrFront) {
        if (ocrFront.identificacionPasaporte) {
            passFlag = "true"
        }
    }
    data.append("identificacion_pasaporte", passFlag);
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.png';
    data.append("videotoken", image, VIDEO_OUTPUT_FILE);

    return nuevoVideotoken(data)

}

export const mapearError = (error) => {
    let objetoError = {};
    let tipoError = '';
    if (error.response) {
        const { data, status } = error.response;
        objetoError.status = status;
        objetoError.data = data;
        objetoError.tipo = 'RESPONSE';
        tipoError = 'Error';
    } else if (error.request) {
        objetoError.error = error.message ? error.message : error.toString();
        objetoError.tipo = 'REQUEST';
        tipoError = 'Error';
    } else {
        objetoError.error = error.message;
        objetoError.tipo = 'EXCEPTION';
        tipoError = 'Exception';
    }
    return { objetoError, tipoError };
}

export const mapearMensajeError = (mensaje) => {
    let eMessage = null
    switch (mensaje) {
        case 'No se encontro rostro':
            eMessage = msjRostro
            break;
        case 'Se detecto cubrebocas':
            eMessage = msjCubrebocas
            break;
        case 'Condiciones de luz no adecuadas':
            eMessage = msjLuz
            break;
        case 'Imagen borrosa':
            eMessage = msjBorrosa
            break;
        case 'Se detectaron multiples caras':
            eMessage = msjMultiple
            break;
        case 'No logramos entender la frase, intente nuevamente':
            eMessage = msjFrase
            break;
        case 'Falta de luz':
            eMessage = msjObscuridad
            break;
        case 'Error frase':
            eMessage = msjFrase2
            break;
        case 'No hay coincidencia intente nuevamente':
            eMessage = msjErrorEmbedings;
            break;
        case 'Lo sentimos no hay coincidencia entre tu selfie registrada y la persona actual':
            eMessage = mensaje
            break;
        case 'Spoof detected':
            eMessage = msjError
            break;
        default:
            eMessage = msjRostro
            break;
    }
    return eMessage
}

export const mapearRespuesta = (status, data) => {
    let objetoRespuesta = {};
    objetoRespuesta.status = status;
    objetoRespuesta.data = data;
    return objetoRespuesta;
}

export const sendEventClick = (paso, description, objectInformation = {}) => {
    const objeto = { description: description };
    const objetoEvento = {
        ...objeto,
        ...objectInformation
    }
    return evento(paso, 'Click', objetoEvento, true);
}

export const setLocalStorageResultFace = (result) => {
    let face = {}
    //console.log("responseWRT: ", result);
    if (result.match) {
        face = {
            match: result.match,
            /* gender: (result.faceComparison.payload.Query[0].gender) === "male" ? "Masculino" : "Femenino",
            age: result.faceComparison.payload.Query[0].age.toFixed(0),
            expressions: result.faceComparison.payload.Query[0].expressions, */
            liveness: result.liveness
        }
    } else {
        face = {
            match: false,
            gender: "Indeterminado",
            age: 0,
            expressions: {
                happy: 0.0
            },
            liveness: "No superada"
        };
    }//liveness
    localStorage.setItem("face", JSON.stringify(face));
};

// export const validarOtorganteCDC = () => {
//     let otorganteCDC = false;
//     const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
//     const uuidOtorgante = localStorage.getItem("uuidOtorgante");
//     const enable_send_mail = obtenerValorConfig(dataOtorgante, 'ENABLE_SEND_MAIL', "");
//     const validacionCorreoCDC = (enable_send_mail && (enable_send_mail.toLowerCase() === 'false')) ? true : false;

//     if (uuidOtorgante === 'e89ba843-66e4-4f8f-92ae-a148fb751dba' || validacionCorreoCDC) {
//         otorganteCDC = true;
//     }
//     return otorganteCDC;
// }

export const validarRedireccionamiento = (dataOtorgante) => {
    let validacion = false;
    const enable_redirect = obtenerValorConfig(dataOtorgante, 'ENABLE_REDIRECT', '');
    const validacionRedirect = (enable_redirect && (enable_redirect.toLowerCase() === 'true')) ? true : false;

    if (validacionRedirect) {
        validacion = true;
    }
    return validacion;
}

export const validarParametroOtorgante = (dataOtorgante, parametro) => {
    let validacion = false;
    const parametroOtorgante = obtenerValorConfig(dataOtorgante, parametro, '')
    const validacionParametro = (parametroOtorgante && (parametroOtorgante.toLowerCase() === 'true')) ? true : false;
    if (validacionParametro) {
        validacion = true;
    }
    return validacion;
}

export const validarDispositivo = async () => {
    let responseDevice = false;
    try {
        const response = await obtenerInfoDispositivo()
        console.log(`🚀 -----------------------------------------------------------------🚀`)
        console.log(`🚀 ~ file: data.js:1943 ~ validarDispositivo ~ response:`, response)
        console.log(`🚀 -----------------------------------------------------------------🚀`)
        const { status, data, data: { payload } } = response
        if (status === 200) {
            evento('Validar dispositivo', 'Success', mapearRespuesta(status, data), true);
            if (payload.status === 'DISPOSITIVO_PERMITIDO' || payload.status === 'DISPOSITIVO_NO_ENCONTRADO')
                responseDevice = true;
            else
                responseDevice = false;
            //setGeToResponseInfoDevice(true)
        }
    } catch (error) {
        console.log(`🚀 -----------------------------------------------------------🚀`)
        console.error(`🚀 ~ file: data.js:1971 ~ validarDispositivo ~ error:`, error)
        console.log(`🚀 -----------------------------------------------------------🚀`)
        const errorMapeado = mapearError(error)
        evento('Validar dispositivo', errorMapeado.tipoError, errorMapeado.objetoError, false)
    }
    return responseDevice;
}


export const getNextStepIdentification = (dataOtorgante, tipo) => {
    let step;
    switch (tipo) {
        case 'flujo':
            step = '/video_token';
            if (validarParametroOtorgante(dataOtorgante, 'DISABLE_LIVENESS')) {
                step = {
                    pathname: '/preparacion_liveness',
                    state: { type: 'flujo' }
                }
            } else {
                if (validarParametroOtorgante(dataOtorgante, 'DISABLE_VIDEO_TOKEN')) {
                    step = '/selfie';
                }
            }
            break;
        case 'pdf':
            step = '/pdf_video_token';
            if (validarParametroOtorgante(dataOtorgante, 'DISABLE_LIVENESS')) {
                step = {
                    pathname: '/preparacion_liveness',
                    state: { type: 'pdf' }
                }
            } else {
                if (validarParametroOtorgante(dataOtorgante, 'DISABLE_VIDEO_TOKEN')) {
                    step = '/selfie_pdf';
                }
            }
            break;
        default:
            step = '/video_token';
            break;
    }

    return step;
}

/* export const validarINE = async jobID => {
    if (!cancelarPeticionINE) {
        if (contadorINE < 15) {
            let tipo = '';
            let informacion = {};
            obtenerResultadoINE(jobID).then(async ({ data: { payload: { message } }, status, data: { payload } }) => {
                if (status === 200) {
                    if (message && message.length > 0) {
                        informacion.status = status;
                        informacion.payload = payload;
                        let responseItem = message[0];
                        if (responseItem.estatus.trim() === 'VALIDADO') {
                            statusData('validacion_ine', { validacion: true, status: responseItem.estatus.trim(), mensaje: responseItem.mensaje });
                            contadorINE = 0;
                        } else if (responseItem.estatus.trim() === 'INVALIDO') {
                            statusData('validacion_ine', { validacion: false, status: responseItem.estatus.trim(), mensaje: responseItem.mensaje });
                        } else {
                            statusData('validacion_ine', { validacion: false, status: responseItem.estatus.trim() });
                            setTimeout(() => {
                                validarINE(jobID);
                                contadorINE++;
                            }, 1000);
                        }
                    } else {
                        statusData('validacion_ine', { validacion: false, status: 'PROCESANDO' });
                        setTimeout(() => {
                            validarINE(jobID);
                            contadorINE++;
                        }, 1000);
                    }
                    evento('Validación INE', 'Success', informacion, true);
                }
            }).catch(error => {
                if (error.response) {
                    let { data, status } = error.response
                    informacion.mensaje = data;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Validación INE', tipo, informacion, false);
                setTimeout(() => {
                    if (error.toString() === 'Cancel') {
                        statusData('validacion_ine', { validacion: false, status: 'CANCELADO' });
                        contadorINE = 0;
                    } else {
                        statusData('validacion_ine', { validacion: false, status: 'ERROR VALIDACION' });
                        validarINE(jobID);
                        contadorINE++;
                    }
                }, 1000);
            });
        } else {
            statusData('validacion_ine', { validacion: false, status: 'ERROR INE' });
            contadorINE = 0;
        }
    } else {
        statusData('validacion_ine', { validacion: false, status: 'CANCELADO' });
        cancelarPeticionINE = false;
        contadorINE = 0;
    }
} */

export const encolarPeticionINE = (modeloIdentificacion, objeto, identificacionElectoral, idCiudadano) => {
    if (!cancelarPeticionINE) {
        if (contadorINE < 10) {
            let tipo = '';
            let informacion = {};
            if (identificacionElectoral) {
                const data = generarDataINE(modeloIdentificacion, objeto, idCiudadano)
                return enviarIneData(data).then(
                    ({ status, data: { payload } }) => {
                        informacion.status = status;
                        informacion.payload = payload;
                        evento('Encolar petición INE', tipo, informacion, true);
                        // statusData('validacion_ine', { validacion: false, status: 'EN PROCESO' });
                        contadorINE = 0;
                        // validarINE(message.data);
                    }).catch(error => {
                        if (error.response) {
                            if (error.response.status === 400) {
                                let errorMsg = error.response.data?.message_client?.mensaje || ''
                                if (errorMsg.trim() === "La transacción ya se encuentra registrada en la base de datos.") {
                                    contadorINE = 10;
                                }
                            }
                            let { data, status } = error.response
                            informacion.data = data;
                            informacion.status = status;
                            tipo = 'Error';
                        } else {
                            informacion.error = error.toString();
                            tipo = 'Exception';
                        }
                        evento('Encolar petición INE', tipo, informacion, false);
                        if (error.toString() === 'Cancel') {
                            statusData('validacion_ine', { validacion: false, status: 'CANCELADO' });
                            contadorINE = 0;
                        } else {
                            statusData('validacion_ine', { validacion: false, status: 'ERROR ENCOLAMIENTO' });
                            encolarPeticionINE(modeloIdentificacion, objeto, identificacionElectoral);
                            contadorINE++;
                        }
                    })
            } else {
                return
            }
        } else {
            statusData('validacion_ine', { validacion: false, status: 'ERROR INE' });
            contadorINE = 0;
        }
    } else {
        statusData('validacion_ine', { validacion: false, status: 'CANCELADO' });
        cancelarPeticionINE = false;
        contadorINE = 0;
    }

}

export const validar_correo = (correo) => {
    // eslint-disable-next-line no-useless-escape
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(correo)) {
        return true;
    }
    return false;
}

export const mapearRespuestaPruebaVida = (payload) => {
    const { faceComparison: { data: { confidenceMatch }, payload: { Match, distance } } } = payload;
    agregarInfo('confidence_match', confidenceMatch);
    agregarInfo('distancia_euclidiana', distance);
    agregarInfo('resultado_comparacion', Match);
}

const generarDataINE = (modeloIdentificacion, objeto, idCiudadano) => {
    const data = {
        "uuid": localStorage.getItem("uuidUser"),
        "turn": -1,
        "modeloIdentificacion": modeloIdentificacion
    }

    switch (modeloIdentificacion) {
        case 'D':
            data.data = {
                cic: objeto.cic,
                ocr: objeto.ocr
            }
            return data
        case 'C':
            data.data = {
                ocr: objeto.ocr,
                clave_elector: objeto.clave,
                numero_emision: objeto.numeroEmision
            }
            return data
        case 'A':
            data.data = {
                ocr: objeto.ocr,
                clave_elector: objeto.clave,
                numero_emision: objeto.numeroEmision,
                id_ciudadano: idCiudadano,
            }

            return data
        default:
            data.data = {
                cic: objeto.cic,
                id_ciudadano: objeto.identificadorCiudadano,
            }
            return data
    }
}

export const mapearInformacionFormulario = (objeto, agregarInfo, fechaNacimiento) => {
    const infoFormulario = [];

    for (const [key, value] of Object.entries(objeto)) {
        switch (key) {
            case 'clave':
                infoFormulario.push({ description: 'claveElector', value: value });
                agregarInfo('claveElector', value);
                break;
            case 'curp':
                agregarInfo("curp", value);
                break;
            case 'vigencia':
                infoFormulario.push({ description: "vigencia", value: "31/12/20" + value.substring(2) });
                break;
            case 'nombreCompleto':
                infoFormulario.push({ description: key, value: value });
                localStorage.setItem('nombreCompleto', value);
                break;
            default:
                infoFormulario.push({ description: key, value: value });
                break;
        }
    }
    infoFormulario.push({ description: "nacimiento", value: fechaNacimiento });
    // agregarInfo('nacimiento', fechaNacimiento);
    return infoFormulario;
}

export const mapErrorGetUserMedia = (error) => {
    let errorObject = {};
    if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        //required track is missing 
        errorObject = { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' };
    } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
        //webcam or mic are already in use 
        errorObject = { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' };
    } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
        //constraints can not be satisfied by avb. devices 
        errorObject = { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' };
    } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        //permission denied in browser 
        errorObject = { error: error.name, status: 'PERMISOS DENEGADOS' };
    } else if (error.name === 'TypeError' || error.name === 'TypeError') {
        //empty constraints object 
        errorObject = { error: error.name, status: 'CONSTRAINTS VACÍOS' };
    } else {
        //other errors 
        errorObject = { error: error.toString(), status: 'OTRO TIPO DE ERROR' };
    }
    return errorObject;
}

export const enviarVideo = (video, isIOS, creacion, expiracion, service) => {
    const data = new FormData();
    let originOS = isIOS ? 'ios' : 'android';
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append('created', creacion);
    data.append('expire', expiracion);
    data.append('originOS', originOS);
    data.append('service_call', service);
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("videotoken", video, VIDEO_OUTPUT_FILE);

    return nuevoVideotoken(data)
}

export const actualizarScoreModeloC = () => {
    const objeto = {};
    const headers = {
        'Content-Type': 'application/json',
        'transaccion': localStorage.getItem("uuidTrx"),
        'cliente': localStorage.getItem("uuidUser"),
        'apikey': localStorage.getItem("apikeyOtorgante")
    };
    objeto.uuid = localStorage.getItem("uuidUser");
    objeto.transaccion = localStorage.getItem("uuidTrx");
    objeto.update = 'true';
    objeto.ocrBackIsNotBlank = true;
    const data = JSON.stringify(objeto);
    return updateScore(data, headers)
}

/*
 * ==============================================
 * Nuevas funciones
 * ==============================================
 */

export const onCut = (event) => {
    event.preventDefault()
    return false;
}

export const onPaste = (event) => {
    event.preventDefault()
    return false;
}