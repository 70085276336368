import React, { useState, useEffect } from 'react'
/* Componentes */
import Liveness from '../../../components/liveness_pasivo_interactivo'

const Index = () => {

    /* Variable */
    const nextStep = '/envio_correo'
    const apikey = localStorage.getItem("apikeyOtorgante") || ''
    const uuidCliente = localStorage.getItem("uuidUser") || ''
    const uuidTransaccion = localStorage.getItem("uuidTrx") || ''
    /* Hooks */
    const [dataOtorgante, setDataOtorgante] = useState([])

    useEffect(() => {

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            const dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            setDataOtorgante(dataOtorganteJSON)
        }

    }, [])

    return (
        <>
            <Liveness
                apikey={apikey}
                uuidCliente={uuidCliente}
                uuidTransaccion={uuidTransaccion}
                dataOtorgante={dataOtorgante}
                service_call={'videotoken-embedding'}
                previousStep={{ pathname: '/preparacion_liveness', state: { type: 'flujo' } }}
                nextStep={nextStep}
                cancelStep={`/${apikey}`}
                type={'flujo'}
                intents={'5'}
            />
        </>
    );
}

export default Index;
