/* eslint-disable react-hooks/exhaustive-deps */
/* React hooks/eshaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
/* Librerías */
import { useHistory } from 'react-router-dom';
import { isIOS, isMobile, withOrientationChange } from 'react-device-detect';
/* Functions/utils */
import { configStyle, obtenerValorConfig, positionDiv } from './utilities/styles/configStyle';
import { evento, sendEventClick } from './utilities/events/eventos';
import {
    generarObjetoLifeCycle, enviarImagenIdentificacion, extraerOCR, extraerOCRCompleto, mapearOCR,
    generateZip, flowIncompleted, validarRedireccionamiento, mapErrorGetUserMedia
} from './services/data';
import { mapearRespuesta, mapearError } from './utilities/response/map_response';
/* Custom components */
import Ayuda from '../../pages/ayuda';
import ComponenteRecorte from './componente_recorte';
import LoaderOCR from './loaderOCR';
import OverlayCargandoCamara from './overlay_cargando_camara';
import AlertaValidacionDimensiones from './alerta_validacion_dimensiones';
import PantallaCancelarFlujo from "../../pages/flujo/cancelar_flujo"
import Loader from '../loader';
import ErrorPermissions from "../error_permisos"

const urlCreator = window.URL || window.webkitURL;
let stream = null

const ComponenteCapturaIdentificacion = (props) => {
    /* Props */
    const { uuidTransaccion, apikey, isLandscape, dataOtorgante, fullOcr, ocr, classify, nextStep } = props;

    /* Hooks/variables */
    const [imageUrl, setImageUrl] = useState('');
    const [errorF, setErrorF] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [side, setSide] = useState('front');
    const [intentsFront, setIntentsFront] = useState(0);
    const [intentsBack, setIntentsBack] = useState(0);
    const [intentosFrente, setIntentosFrente] = useState(0);
    const [intentosReverso, setIntentosReverso] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [isStart, setIsStart] = useState(true);
    const [proccessing, setProccessing] = useState(false);
    const [desktop, setDesktop] = useState(false);
    const [showIdOverlay, setShowIdOverlay] = useState(false);
    const [intentsData, setIntentsData] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [errorVideo, setErrorVideo] = useState(false);
    const [previewId, setPreviewId] = useState(false);
    const [capturing, setCapturing] = useState(false);
    const [errorCapture, setErrorCapture] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [dataResponse, setDataResponse] = useState(null);
    const [showCancelScreen, setShowCancelScreen] = useState(false);
    const [onLoadViewCamera, setOnLoadViewCamera] = useState(true);
    const [errorPermissions, setErrorPermissions] = useState(false);
    const [showAlertDimensions, setShowAlertDimensions] = useState(false);
    const [disableFinishButton, setDisableFinishButton] = useState(true);
    const [mostrarLoader, setMostrarLoader] = useState(false);
    const [modeloIdentificacion, setModeloIdentificacion] = useState(false);

    /* Refs */
    const refCanvas = useRef(null);
    const refVideo = useRef(null);
    const refDivFooter = useRef(null);
    const refButtonCapture = useRef(null);
    const refCanvasMask = useRef(null);
    const refContainerCanvasMask = useRef(null);

    /* Navigation hooks */
    const history = useHistory();


    useEffect(() => {
        if (!isMobile) {
            setDesktop(true);
            refVideo.current.classList.add('myVideo_rotate');
        }

        // iniciarCaptura();
        // const bloqueo = localStorage.getItem('bloqueo');
        // if (!bloqueo || bloqueo === 'false') {
        //     permisoCamara();
        // }
        if (isIOS) {
            document.addEventListener('visibilitychange', () => {
                console.log(`🚀 -----------------------------------------------------------------------------------------------------------------------------------🚀`)
                console.log(`🚀 ~ file: componente_captura_identificacion.js:88 ~ document.addEventListener ~ document.visibilityState:`, document.visibilityState)
                console.log(`🚀 -----------------------------------------------------------------------------------------------------------------------------------🚀`)
                if (document.visibilityState === "visible") {
                    const bloqueo = localStorage.getItem('bloqueo');
                    if ((!bloqueo || bloqueo === 'false') && refVideo.current) {
                        permisoCamara();
                    }
                } else {
                    if (stream) stream.getTracks().forEach(track => track.stop());
                }
            }, true)
        }

        return () => {
            setProccessing(false)
            if (stream) stream.getTracks().forEach(track => track.stop());
            document.removeEventListener('visibilitychange', () => { }, false)
        }
    }, []);

    useEffect(() => {
        if (props.dataOtorgante.length > 0) {
            configStyle(props.dataOtorgante);

            const bloqueo = localStorage.getItem('bloqueo');
            if (!bloqueo) {
                localStorage.setItem('bloqueo', 'false');
            } else {
                if (bloqueo === 'true') {
                    setDisableFinishButton(false);
                    if (stream) stream.getTracks().forEach(track => track.stop());
                    setIntentsFront(Number(obtenerValorConfig(props.dataOtorgante, 'INTENTOS_ID_FRONTAL', '3')));
                }
            }

            setIntentosFrente(Number(obtenerValorConfig(props.dataOtorgante, 'INTENTOS_ID_FRONTAL', '3')));
            setIntentosReverso(Number(obtenerValorConfig(props.dataOtorgante, 'INTENTOS_ID_REVERSO', '3')));
        }

        if (props.apikey && props.uuidTransaccion && props.uuidUser && props.nombreOtorgante) {
            generarObjetoLifeCycle('paso', { status: 'OK', step: 'captura_id_page', stepEvent: 'Captura ID' }, props);
            iniciarCaptura();
            const bloqueo = localStorage.getItem('bloqueo');
            if (!bloqueo || bloqueo === 'false') {
                permisoCamara();
            }
        }

        return () => { };
    }, [props]);

    useEffect(() => {
        if (refDivFooter.current) refDivFooter.current.style.position = positionDiv(window.innerWidth, window.innerHeight);
        return () => { };
    }, [refDivFooter]);

    useEffect(() => {
        if (intentsData > 0 && errorF) {
            enviarReintento('Reintento Captura ID', errorF);
        }
        return () => { };
    }, [intentsData, errorF]);

    useEffect(() => {
        if (intentsFront > 0 && errorF) {
            enviarReintento('Intentos Captura ID Frente', intentsFront);
            if (intentsFront === intentosFrente) {
                finalizarFlujo();
            } else {
                enviarReintento('Reintento Captura ID', errorF);
            }
        }
        return () => { };
    }, [intentsFront, errorF]);

    useEffect(() => {
        if (intentsBack > 0 && errorF) {
            enviarReintento('Intentos Captura ID Reverso', intentsBack);
            if (intentsBack === intentosReverso) {
                sendData();
                setTimeout(() => {
                    history.replace({
                        pathname: nextStep,
                        state: { passport: false, modeloIdentificacion: modeloIdentificacion }
                    })
                }, 300);
            } else {
                enviarReintento('Reintento Captura ID', errorF);
            }
        }
        return () => { };
    }, [intentsBack, errorF]);

    useEffect(() => {
        if (!previewId && !isLandscape) {
            /* Dibujar bordes máscara identificación */
            if (isMobile) {
                resizeCanvas();
                let contextCanvas = refCanvasMask.current.getContext('2d');
                /* Inserción de imágenes para guía */
                if (side === 'front') {
                    const icon_man = new Image();
                    const icon_stripes = new Image();
                    icon_man.addEventListener(
                        'load',
                        () => {
                            if (window.innerWidth <= 424 && window.innerWidth > 412) {
                                if (window.innerHeight >= 839) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 80) / 100), ((refCanvasMask.current.height * 50) / 100));
                                } else if (window.innerHeight < 669) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 75) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 75) / 100), ((refCanvasMask.current.height * 52) / 100));
                                }
                            } else if (window.innerWidth <= 412 && window.innerWidth > 393) {
                                if (window.innerHeight >= 800) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 7) / 100), 0, ((refCanvasMask.current.width * 90) / 100), ((refCanvasMask.current.height * 50) / 100));
                                } else if (window.innerHeight === 718) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 82) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 82) / 100), ((refCanvasMask.current.height * 55) / 100));
                                }
                            } else if (window.innerWidth <= 393 && window.innerWidth > 375) {
                                if (window.innerHeight > 560) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 13) / 100), 5, ((refCanvasMask.current.width * 80) / 100), ((refCanvasMask.current.height * 50) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 13) / 100), 5, ((refCanvasMask.current.width * 80) / 100), ((refCanvasMask.current.height * 55) / 100));
                                }
                            } else if (window.innerWidth === 375) {
                                if (window.innerHeight < 667) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 75) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 75) / 100), ((refCanvasMask.current.height * 50) / 100));
                                }
                            } else if (window.innerWidth === 360) {
                                if (window.innerHeight >= 764) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 10, ((refCanvasMask.current.width * 80) / 100), ((refCanvasMask.current.height * 48) / 100));
                                } else if (window.innerHeight === 740) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 5, ((refCanvasMask.current.width * 85) / 100), ((refCanvasMask.current.height * 53) / 100));
                                } else if (window.innerHeight <= 708 && window.innerHeight > 664) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 80) / 100), ((refCanvasMask.current.height * 53) / 100));
                                } else if (window.innerHeight <= 664 && window.innerHeight > 588) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 80) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else if (window.innerHeight === 588) {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 3, ((refCanvasMask.current.width * 77) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 80) / 100), ((refCanvasMask.current.height * 55) / 100));
                                }
                            } else {
                                contextCanvas.drawImage(icon_man, ((refCanvasMask.current.width * 10) / 100), 0, ((refCanvasMask.current.width * 80) / 100), ((refCanvasMask.current.height * 50) / 100));
                            }
                        },
                        false
                    );
                    icon_stripes.addEventListener(
                        'load',
                        () => {
                            if (window.innerWidth <= 424 && window.innerWidth > 412) {
                                if (window.innerHeight < 669) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 38) / 100), ((refCanvasMask.current.height * 40) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 58) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 38) / 100), ((refCanvasMask.current.height * 40) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 57) / 100));
                                }
                            } else if (window.innerWidth <= 412 && window.innerWidth > 393) {
                                if (window.innerHeight >= 800) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 35) / 100), ((refCanvasMask.current.height * 40) / 100), ((refCanvasMask.current.width * 50) / 100), ((refCanvasMask.current.height * 60) / 100));
                                } else if (window.innerHeight >= 718) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 43) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 45) / 100), ((refCanvasMask.current.height * 38) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 60) / 100));
                                }
                            } else if (window.innerWidth <= 393 && window.innerWidth > 375) {
                                if (window.innerHeight > 560) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 43) / 100), ((refCanvasMask.current.height * 40) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 57) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 43) / 100), ((refCanvasMask.current.height * 43) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 54) / 100));
                                }
                            } else if (window.innerWidth === 375) {
                                if (window.innerHeight < 667) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 38) / 100), ((refCanvasMask.current.height * 42) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 38) / 100), ((refCanvasMask.current.height * 40) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 58) / 100));
                                }
                            } else if (window.innerWidth <= 360) {
                                if (window.innerHeight >= 764) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 38) / 100), ((refCanvasMask.current.height * 41) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else if (window.innerHeight <= 708 && window.innerHeight > 664) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 42) / 100), ((refCanvasMask.current.height * 41) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else if (window.innerHeight >= 664 && window.innerHeight < 708) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 45) / 100), ((refCanvasMask.current.height * 42) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 55) / 100));
                                } else if (window.innerHeight === 588) {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 42) / 100), ((refCanvasMask.current.height * 42) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 56) / 100));
                                } else {
                                    contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 42) / 100), ((refCanvasMask.current.height * 42) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 54) / 100));
                                }
                            } else {
                                contextCanvas.drawImage(icon_stripes, ((refCanvasMask.current.width * 45) / 100), ((refCanvasMask.current.height * 38) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 60) / 100));
                            }
                        },
                        false
                    );
                    icon_man.src = '/images/componente_captura_identificacion/man_ico_f@2x.png';
                    icon_stripes.src = '/images/componente_captura_identificacion/lines_ico_f@2x.png';
                } else {
                    const icon_cbar = new Image();
                    const icon_stripes = new Image();
                    const icon_qr = new Image();
                    icon_cbar.addEventListener(
                        'load',
                        () => {
                            contextCanvas.drawImage(icon_cbar, ((refCanvasMask.current.width * 75) / 100), 0, ((refCanvasMask.current.width * 28) / 100), ((refCanvasMask.current.height * 30) / 100));
                        },
                        false
                    );
                    icon_stripes.addEventListener(
                        'load',
                        () => {
                            contextCanvas.drawImage(icon_stripes, 0, 0, ((refCanvasMask.current.width * 45) / 100), ((refCanvasMask.current.height * 100) / 100));
                        },
                        false
                    );
                    icon_qr.addEventListener(
                        'load',
                        () => {
                            contextCanvas.drawImage(icon_qr, ((refCanvasMask.current.width * 50) / 100), ((refCanvasMask.current.height * 66) / 100), ((refCanvasMask.current.width * 40) / 100), ((refCanvasMask.current.height * 28) / 100));
                        },
                        false
                    );
                    icon_cbar.src = '/images/componente_captura_identificacion/cbar_ico_b@2x.png';
                    icon_stripes.src = '/images/componente_captura_identificacion/lines_ico_b@2x.png';
                    icon_qr.src = '/images/componente_captura_identificacion/qrr_ico_b@2x.png';
                }

                contextCanvas.beginPath();
                /* Lado superior */
                /* contextCanvas.roundRect(0, 20, (refCanvasMask.current.width - 4), (refCanvasMask.current.height - 52), [20]);
                contextCanvas.lineWidth = 35;
                contextCanvas.strokeStyle = '#ff0000'; 
                contextCanvas.stroke();*/
                contextCanvas.moveTo((refCanvasMask.current.width * 2.3 / 100), 0);
                //contextCanvas.lineTo(0, 100);
                contextCanvas.lineTo((refCanvasMask.current.width * 2.3 / 100), refCanvasMask.current.height);
                contextCanvas.moveTo((refCanvasMask.current.width * 2.3 / 100), 0);
                // contextCanvas.moveTo(0, -8);
                // contextCanvas.lineTo(150, -8);
                //contextCanvas.lineTo(refCanvasMask.current.width, -8);
                contextCanvas.lineTo(refCanvasMask.current.width, 0);
                contextCanvas.moveTo((refCanvasMask.current.width * 98 / 100), 0);
                // contextCanvas.lineTo((refCanvasMask.current.width), 100);
                contextCanvas.lineTo((refCanvasMask.current.width * 98 / 100), refCanvasMask.current.height);
                // contextCanvas.moveTo(refCanvasMask.current.width, -8);
                // contextCanvas.lineTo((refCanvasMask.current.width - 150), -8);
                /* Lado inferior */
                contextCanvas.moveTo(0, refCanvasMask.current.height);
                //contextCanvas.lineTo(0, (refCanvasMask.current.height - 100));
                contextCanvas.lineTo(refCanvasMask.current.height, (refCanvasMask.current.width));
                // contextCanvas.moveTo(0, (refCanvasMask.current.height + 8));
                // contextCanvas.lineTo(150, (refCanvasMask.current.height + 8));
                // contextCanvas.moveTo((refCanvasMask.current.width), refCanvasMask.current.height);
                // contextCanvas.lineTo((refCanvasMask.current.width), (refCanvasMask.current.height - 100));
                // contextCanvas.moveTo(refCanvasMask.current.width, (refCanvasMask.current.height + 8));
                // contextCanvas.lineTo((refCanvasMask.current.width - 150), (refCanvasMask.current.height + 8));
                contextCanvas.lineWidth = 80;
                contextCanvas.strokeStyle = 'rgba(255, 255, 255, 0.5)';
                contextCanvas.stroke();
                contextCanvas.closePath();
            }

        }
        return () => { };
    }, [previewId, isLandscape, side]);

    // useEffect(() => {
    //     if (imageURL) {
    //         let a = document.createElement('a');
    //         document.body.appendChild(a);
    //         a.style = 'display: none';
    //         a.href = imageURL;
    //         a.download = 'captura_ine.png';
    //         a.click();
    //     }
    // }, [imageURL]);

    useEffect(() => {
        setShowIdOverlay(desktop ? true : false);
    }, [desktop]);

    const iniciarCaptura = () => {
        //sendEventClick('Captura ID', 'CONTINUAR', {}, props);
        //setIsStart(false);
        //setShowIdOverlay(desktop ? true : false);
        refVideo.current.classList.remove('blur_video');
    }


    const permisoCamara = () => {
        const deviceId = localStorage.getItem('deviceId');
        configure(deviceId);

        //getPermissions();

        /* let deviceFuncional = '';
        navigator.mediaDevices.getUserMedia({video:true}).then(mediaStream =>{
            navigator.mediaDevices.enumerateDevices().then(function (devices) {
                evento('Captura ID', 'User Media Component', { status: devices }, true, props)
                for (let i = 0; i < devices.length; i++) {
                    let device = devices[i];
                    if (device.kind === 'videoinput') {
                        if ((device.label).includes('back')) {
                            if ((device.label).includes('0')) {
                                deviceFuncional = device.deviceId;
                            }
                        }
                    }
                }
                evento('Captura ID', 'User Media Component', { deviceFuncional: deviceFuncional }, true, props)
                start(deviceFuncional);
                if (mediaStream) mediaStream.getTracks().forEach(track => track.stop());
            });
        }).catch(handleErrorMediaUser) */
    }

    /*const start = (deviceFuncional) => {

        if (stream) {
            stream.getTracks().forEach(track => {
                track.stop();
            })
        }

        let lista = localStorage.getItem('camarasList')

        if (isMobile && (!deviceFuncional || !isIOS) && !lista) {
            let arrayLista = [];
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                evento('Captura ID', 'User Media Component', { status: devices }, true, props)
                for (var i = 0; i < devices.length; i++) {
                    var device = devices[i];
                    if (device.kind === 'videoinput') {
                        arrayLista.push(device.deviceId)
                        if ((device.label).includes("back")) {
                            if ((device.label).includes("0")) {
                                deviceFuncional = device.deviceId;
                            }
                        }
                    }
                }
                if (arrayLista.length > 2 && !deviceFuncional) {
                    localStorage.setItem("camarasList", JSON.stringify(arrayLista))
                    setShowSelect(true)
                    setDispositivosVideo(arrayLista)
                    setMsjMultiCamaras(true)
                    deviceFuncional = arrayLista[arrayLista.length - 1];
                }
                console.log("deviceFuncional",deviceFuncional);
                configure(deviceFuncional)
            });
        } else {
            configure(deviceFuncional)
        }

    } */

    const configure = (deviceFuncional) => {
        // setDispositivosActual(deviceFuncional)
        if (stream) {
            stream.getTracks().forEach(track => {
                track.stop();
            })
        }

        const video_constraints = {
            width: { ideal: 640 },
            height: { ideal: 480 },
            advanced: [
                { width: 1280, height: 720 },
                { aspectRatio: 4 / 3 }
            ]
        }

        if (deviceFuncional === 'user' || deviceFuncional === 'environment') {
            video_constraints.facingMode = deviceFuncional;
        } else {
            video_constraints.deviceId = { exact: deviceFuncional };
        }

        const constraints = {
            audio: false,
            video: video_constraints,
        }

        const localStream = navigator.mediaDevices.getUserMedia(constraints)
            .catch(handleErrorMediaUser);

        localStream.then(function (mediaStream) {
            stream = mediaStream;
            refVideo.current.srcObject = mediaStream;
            try {
                refVideo.current.onloadedmetadata = function (e) {
                    refVideo.current.play();
                    if (mediaStream.getVideoTracks().length > 0) {
                        // console.log('disponibles');
                        setErrorVideo(false)
                        setOnLoadViewCamera(false)
                    } else {
                        // console.log('No disponibles ');    
                        setErrorVideo(true);
                    }
                }
                refVideo.current.addEventListener('canplay', function (ev) {
                    // height = refVideo.current.videoHeight / (refVideo.current.videoWidth / width);
                    refCanvas.current.setAttribute('width', refVideo.current.videoWidth * 1.5);
                    refCanvas.current.setAttribute('height', refVideo.current.videoHeight * 1.5);
                }, false);
            } catch (error) {
                setErrorVideo(true);
            }
        }).catch(e => {
            setErrorVideo(true);
            console.trace(e);
        })
    }

    const sendData = () => {
        let jsonObj = {};
        jsonObj.ocrFront = JSON.parse(localStorage.getItem('ocrFront'));
        jsonObj.ocrBack = JSON.parse(localStorage.getItem('ocrBack'));
        generarObjetoLifeCycle('pasoInformacion', { status: 'OK', step: 'captura_id_ocr', description: jsonObj }, props);
    }

    const initRecord = (side) => {
        setSide(side);
        setLoading(true);
        setCapturing(true);
        refButtonCapture.current.classList.remove('main_bg_color');
        refButtonCapture.current.classList.remove('gray_bg_color');
        let description = (side === 'front') ? 'CAPTURAR FRENTE' : 'CAPTURAR REVERSO';
        sendEventClick('Captura ID', description, {}, props);
        if (side === 'front') {
            takeFotoFront()
        } else {
            takeFotoBack()
        }
    }

    const takeFotoFront = () => {
        setTimeout(() => {
            let contextCanvas = refCanvas.current.getContext('2d');
            contextCanvas.drawImage(refVideo.current, 0, 0, refVideo.current.videoWidth * 1.5, refVideo.current.videoHeight * 1.5);
            refCanvas.current.toBlob((blob) => {
                //descargarImagen(blob);
                setLoading(false);
                setPreviewId(true);
                setImageUrl(urlCreator.createObjectURL(blob));
                setCapturing(false);
            }, 'image/png', 0.9);
        }, 1350);
    }

    const takeFotoBack = () => {
        setTimeout(() => {
            let contextCanvas = refCanvas.current.getContext('2d');
            contextCanvas.drawImage(refVideo.current, 0, 0, refVideo.current.videoWidth * 1.5, refVideo.current.videoHeight * 1.5);
            refCanvas.current.toBlob((blob) => {
                //descargarImagen(blob);
                setLoading(false);
                setPreviewId(true);
                setImageUrl(urlCreator.createObjectURL(blob));
                setCapturing(false);
            }, 'image/png', 0.9);
        }, 1350);
    }

    const enviarImagen = async (blobImage) => {
        // descargarImagen(blobImage);
        let tipoIdentificacion = '';
        let nombre = '';
        setPreviewId(false);
        setProccessing(true);
        setCapturing(true);
        if (side === 'front') {
            if (ocr) {
                const response = extraerOCR(props, blobImage, side);
                const { status, data } = response
                if (status && status === 200) {
                    if (data.payload.ocrData.ocrFrontIsNotBlank) {
                        data.side = side;
                        evento('Extraer OCR', 'Successs', mapearRespuesta(status, data), true, props);
                        if (data.identificacionElectoral) {
                            tipoIdentificacion = 'INE';
                        }
                        else if (data.identificacionPasaporte) {
                            tipoIdentificacion = 'PASAPORTE';
                        }
                        else if (data.documentoNacionalPeru) {
                            tipoIdentificacion = 'DNI_PERU';
                        }
                        else if (data.identificacionMigratoria) {
                            tipoIdentificacion = 'FMM';
                        }
                        else {
                            tipoIdentificacion = 'INE';
                        }
                        let jsonObj = { 'type_id': tipoIdentificacion };
                        generarObjetoLifeCycle('pasoInformacion', { status: 'OK', step: 'captura_id', description: jsonObj }, props);
                    } else {
                        //that.setState({ errorMessage: 'No se pudo leer correctamente tus datos, por favor vuelve a intentar.', intentsFront: that.state.intentsFront + 1, errorCapture: true, errorF: 'Error en la captura de identificación', token: null, loading: false, proccessing: false, hasResponse: true, capturing: false });
                        //that.statusR();
                        let errorMapeado = mapearError(response);
                        errorMapeado.objetoError.error = `ocrFrontIsNotBlank: ${data.payload.ocrData.ocrFrontIsNotBlank}`;
                        evento('Extraer Ocr', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                    }
                } else {
                    let errorMapeado = mapearError(response);
                    evento('Extraer Ocr', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                }
            }
            if (fullOcr) {
                try {
                    const response = await extraerOCRCompleto(props, blobImage, side, isIOS, isMobile);
                    console.log(`🚀 ---------------------------------------------------------------------------------------🚀`)
                    console.log(`🚀 ~ file: componente_captura_identificacion.js:597 ~ enviarImagen ~ response:`, response)
                    console.log(`🚀 ---------------------------------------------------------------------------------------🚀`)
                    const { status, data } = response;
                    setDataResponse(data);
                    if (status && status === 200) {
                        if (data.payload.ocrData.ocrFrontIsNotBlank) {
                            data.side = side;
                            evento('Captura ID', 'Success', mapearRespuesta(status, data), true, props);
                            const ocrMapeado = mapearOCR(data.payload.ocrData, 'front');
                            let tipo = '';
                            if (ocrMapeado.identificacionElectoral) {
                                tipo = 'INE';
                                if (data.hasOwnProperty('payload')) {
                                    const { payload: { ocrData: { modeloIdentificacion } } } = data;
                                    console.log(`🚀 ---------------------------------------------------------------------------------------------------------------🚀`)
                                    console.log(`🚀 ~ file: componente_captura_identificacion.js:615 ~ enviarImagen ~ modeloIdentificacion:`, modeloIdentificacion)
                                    console.log(`🚀 ---------------------------------------------------------------------------------------------------------------🚀`)
                                    setModeloIdentificacion(modeloIdentificacion)
                                }
                                let jsonObj = { 'type_id': tipo };
                                localStorage.setItem('typeID', tipo.toLowerCase());
                                generarObjetoLifeCycle('pasoInformacion', { status: 'OK', step: 'captura_id', description: jsonObj }, props);
                                if (intentsData < intentosFrente) {
                                    localStorage.setItem('intentsData', 'OCR');
                                    setLoading(false);
                                    setProccessing(false);
                                    setSide('back');
                                    setIntentsFront(0);
                                    setIntentsData(0);
                                    setCapturing(false);
                                } else {
                                    setLoading(false);
                                    setProccessing(false);
                                    setIntentsData(intentsData + 1);
                                    setErrorF('Datos incompletos');
                                    // setHasResponse(true);
                                    setCapturing(false);
                                }
                                if (intentsFront === Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_ID_FRONTAL', '3'))) {
                                    setCapturing(false);
                                    setProccessing(false);
                                    setSide('back');
                                    setCapturing(false);
                                    /* if (tipo === 'PASAPORTE') {
                                        setLoading(false);
                                        setCapturing(false);
                                        sendData()
                                        setTimeout(() => {
                                            setProccessing(false);
                                            history.push({
                                                pathname: nextStep,
                                                state: { passport: false }
                                            })
                                        }, 300);
                                    } else {
                                        setCapturing(false);
                                        setProccessing(false);
                                        setSide('back');
                                        setCapturing(false);
                                    } */
                                }
                                setLoading(false);
                                setProccessing(false);
                                // setHasResponse(true);
                                setCapturing(false);
                            }
                            /*  else if (ocrMapeado.identificacionPasaporte) {
                                 tipo = 'PASAPORTE';
                                 // setIsPassport(true);
                             }
                             else if (ocrMapeado.documentoNacionalPeru) {
                                 tipo = 'DNI_PERU';
                             }
                             else if (ocrMapeado.identificacionMigratoria) {
                                 tipo = 'FMM';
                             } */
                            else {
                                evento('Captura ID', 'Error', { error: 'Identificación no válida' }, false, props)
                                setErrorMessage('No hemos logrado validar tu identificación correctamente; recuerda que únicamente se admiten IFE/INE.');
                                setErrorCapture(true);
                                setErrorF('Error en la captura de identificación');
                                setLoading(false);
                                setProccessing(false);
                                // setHasResponse(true);
                                setCapturing(false);
                            }
                            /* if (tipo === 'PASAPORTE') {
                                setLoading(false);
                                setCapturing(false);
                                sendData();
                                setTimeout(() => {
                                    setProccessing(false);
                                    history.push({
                                        pathname: nextStep,
                                        state: { passport: false }
                                    })
                                }, 300);
                            } else {
                                if (ocrMapeado.nombreCompleto && (ocrMapeado.curp || ocrMapeado.dni)) {
                                    if (ocrMapeado.dni) {
                                        if (ocrMapeado.dni.length < 3 || ocrMapeado.nombreCompleto.length < 10) {
                                            if (intentsData < intentosFrente) {
                                                localStorage.setItem('intentsData', 'OCR');
                                                setLoading(false);
                                                setProccessing(false);
                                                setSide('back');
                                                setIntentsFront(0);
                                                setIntentsData(0);
                                                setCapturing(false);
                                            } else {
                                                setLoading(false);
                                                setProccessing(false);
                                                setIntentsData(intentsData + 1);
                                                setErrorF('Datos no cumplen con formato');
                                                // setHasResponse(true);
                                                setCapturing(false);
                                            }
                                        } else {
                                            setLoading(false);
                                            setProccessing(false);
                                            setSide('back');
                                            setIntentsFront(0);
                                            setIntentsData(0);
                                            setCapturing(false);
                                        }
                                    } else {
                                        if (ocrMapeado.curp.length < 16 || ocrMapeado.nombreCompleto.length < 10) {
                                            if (intentsData < intentosFrente) {
                                                localStorage.setItem('intentsData', 'OCR');
                                                setLoading(false);
                                                setProccessing(false);
                                                setSide('back');
                                                setIntentsFront(0);
                                                setIntentsData(0);
                                                setCapturing(false);
                                            } else {
                                                setLoading(false);
                                                setProccessing(false);
                                                setIntentsData(intentsData + 1);
                                                setErrorF('Datos no cumplen con formato');
                                                // setHasResponse(true);
                                                setCapturing(false);
                                            }
                                        } else {
                                            setLoading(false);
                                            setProccessing(false);
                                            setSide('back');
                                            setIntentsFront(0);
                                            setIntentsData(0);
                                            setCapturing(false);
                                        }
                                    }
                                } else {
                                    if (intentsData < intentosFrente) {
                                        localStorage.setItem('intentsData', 'OCR');
                                        setLoading(false);
                                        setProccessing(false);
                                        setSide('back');
                                        setIntentsFront(0);
                                        setIntentsData(0);
                                        setCapturing(false);
                                    } else {
                                        setLoading(false);
                                        setProccessing(false);
                                        setIntentsData(intentsData + 1);
                                        setErrorF('Datos incompletos');
                                        // setHasResponse(true);
                                        setCapturing(false);
                                    }
                                }
                            } */
                        } else {
                            let errorMapeado = mapearError(data);
                            errorMapeado.objetoError.error = `ocrFrontIsNotBlank: ${data.payload.ocrData.ocrFrontIsNotBlank}`;
                            evento('Captura ID', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                            setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                            setIntentsFront(intentsFront + 1);
                            setErrorCapture(true);
                            setErrorF('Error en la captura de identificación');
                            setLoading(false);
                            setProccessing(false);
                            // setHasResponse(true);
                            setCapturing(false);
                        }
                    } else {
                        let errorMapeado = mapearError(response);
                        evento('Captura ID', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                        if (response.response) {
                            setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                            setIntentsFront(intentsFront + 1);
                            setErrorCapture(true);
                            setErrorF('Error en la captura de identificación');
                            setLoading(false);
                            setProccessing(false);
                            // setHasResponse(true);
                            setCapturing(false);
                        } else {
                            if (response.toString().includes('Network')) {
                                setErrorMessage('Revisa tu conexión a internet e intenta nuevamente.');
                                setErrorCapture(true);
                                setErrorF('Error en la captura de identificación');
                                setLoading(false);
                                setProccessing(false);
                                // setHasResponse(true);
                                setCapturing(false);
                            } else {
                                setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                                setErrorCapture(true);
                                setErrorF('Error en la captura de identificación');
                                setLoading(false);
                                setProccessing(false);
                                // setHasResponse(true);
                                setCapturing(false);
                            }
                        }
                    }
                } catch (error) {
                    let errorMapeado = mapearError(error);
                    evento('Captura ID', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                    if (error.response) {
                        setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                        setIntentsFront(intentsFront + 1);
                        setErrorCapture(true);
                        setErrorF('Error en la captura de identificación');
                        setLoading(false);
                        setProccessing(false);
                        // setHasResponse(true);
                        setCapturing(false);
                    } else {
                        if (error.toString().includes('Network')) {
                            setErrorMessage('Revisa tu conexión a internet e intenta nuevamente.');
                            setErrorCapture(true);
                            setErrorF('Error en la captura de identificación');
                            setLoading(false);
                            setProccessing(false);
                            // setHasResponse(true);
                            setCapturing(false);
                        } else {
                            setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                            setErrorCapture(true);
                            setErrorF('Error en la captura de identificación');
                            setLoading(false);
                            setProccessing(false);
                            // setHasResponse(true);
                            setCapturing(false);
                        }
                    }
                }
            }
            if (classify) {
                if (!fullOcr && !ocr) {
                    extraerOCR(props, blobImage, side).then(async ({ status, data, data: { payload: { ocrData } } }) => {
                        if (status === 200) {
                            if (ocrData.ocrFrontIsNotBlank) {
                                try {
                                    nombre = side + "-" + (new Date().getTime()) + ".png";
                                    const response = await enviarImagenIdentificacion(props, blobImage, 'ine', nombre, side);
                                    const { status, data } = response;
                                    if (status && status === 200) {
                                        data.side = side;
                                        evento('Evaluación Imagen Frente', 'Success', mapearRespuesta(status, data), true, props);
                                    } else {
                                        let errorMapeado = mapearError(response);
                                        evento('Evaluación Imagen Frente', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                                    }
                                } catch (error) {
                                    let errorMapeado = mapearError(error);
                                    evento('Evaluación Imagen Frente', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                                }
                                if (ocrData.identificacionElectoral) {
                                    tipoIdentificacion = 'INE';
                                }
                                else if (ocrData.identificacionPasaporte) {
                                    tipoIdentificacion = 'PASAPORTE';
                                }
                                else if (ocrData.documentoNacionalPeru) {
                                    tipoIdentificacion = 'DNI_PERU';
                                }
                                else if (ocrData.identificacionMigratoria) {
                                    tipoIdentificacion = 'FMM';
                                }
                                else {
                                    tipoIdentificacion = 'INE';
                                }
                                let jsonObj = { 'type_id': tipoIdentificacion };
                                generarObjetoLifeCycle('pasoInformacion', { status: 'OK', step: 'captura_id', description: jsonObj }, props);
                            } else {
                                let errorMapeado = mapearError(data);
                                errorMapeado.objetoError.error = `ocrFrontIsNotBlank: ${ocrData.ocrFrontIsNotBlank}`;
                                evento('Extraer Ocr', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                            }
                        }
                    }).catch(error => {
                        let errorMapeado = mapearError(error);
                        evento('Captura ID', errorMapeado.tipoError, errorMapeado.objetoError, false, props);

                    });
                } else {
                    try {
                        nombre = side + "-" + (new Date().getTime()) + ".png";
                        const response = await enviarImagenIdentificacion(props, blobImage, 'ine', nombre, side);
                        const { status, data } = response;
                        if (status && status === 200) {
                            data.side = side;
                            evento('Evaluación Imagen Frente', 'Success', mapearRespuesta(status, data), true, props);
                        } else {
                            let errorMapeado = mapearError(response);
                            evento('Evaluación Imagen Frente', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                        }

                    } catch (error) {
                        let errorMapeado = mapearError(error);
                        evento('Evaluación Imagen Frente', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                    }
                }
            }
        } else {
            if (ocr) {
                const response = extraerOCR(props, blobImage, side);
                const { status, data } = response
                if (status && status === 200) {
                    if (data.payload.ocrData.ocrFrontIsNotBlank) {
                        data.side = side;
                        evento('Captura ID', 'Success', mapearRespuesta(status, data), true, props);
                        evento('Captura ID', 'Success', mapearRespuesta(status, data), true, props);
                    } else {
                        let errorMapeado = mapearError(response);
                        errorMapeado.objetoError.error = `ocrFrontIsNotBlank: ${data.payload.ocrData.ocrFrontIsNotBlank}`;
                        evento('Extraer Ocr', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                    }
                } else {
                    let errorMapeado = mapearError(response);
                    evento('Extraer Ocr', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                }
            }
            if (classify) {
                try {
                    nombre = side + "-" + (new Date().getTime()) + ".png";
                    const response = await enviarImagenIdentificacion(props, blobImage, 'ine', nombre, side);
                    const { status, data } = response;
                    if (status && status === 200) {
                        data.side = side;
                        evento('Evaluación Imagen Frente', 'Success', mapearRespuesta(status, data), true, props);
                    } else {
                        let errorMapeado = mapearError(response);
                        evento('Evaluación Imagen Frente', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                    }

                } catch (error) {
                    let errorMapeado = mapearError(error);
                    evento('Evaluación Imagen Frente', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                }
            }
            if (fullOcr) {
                try {
                    const response = await extraerOCRCompleto(props, blobImage, side, isIOS, isMobile);
                    const { status, data } = response;
                    setDataResponse(data);
                    if (status && status === 200) {
                        if (data.payload.ocrData.ocrBackIsNotBlank) {
                            data.side = side;
                            evento('Captura ID', 'Success', mapearRespuesta(status, data), true, props);
                            if (data.payload.ocrData.documentoNacionalMexico) {
                                mapearOCR(data.payload.ocrData, 'back');
                                setLoading(false);
                                setCapturing(false);
                                sendData();
                                setTimeout(() => {
                                    // setProccessing(false);
                                    history.replace({
                                        pathname: nextStep,
                                        state: { passport: false, modeloIdentificacion: modeloIdentificacion }
                                    })
                                }, 300);
                            } else {
                                evento('Captura ID', 'Error', { error: 'Identificación no válida' }, false, props)
                                setErrorMessage('No hemos logrado validar tu identificación correctamente; recuerda que únicamente se admiten IFE/INE.');
                                setErrorCapture(true);
                                setErrorF('Error en la captura de identificación');
                                setLoading(false);
                                setProccessing(false);
                                setCapturing(false);
                            }
                        } else {
                            let errorMapeado = mapearError(data);
                            errorMapeado.objetoError.error = `ocrBackIsNotBlank: ${data.payload.ocrData.ocrBackIsNotBlank}`;
                            evento('Captura ID', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                            setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                            setIntentsBack(intentsBack + 1);
                            setErrorCapture(true);
                            setErrorF('Error en la captura de identificación');
                            setLoading(false);
                            setProccessing(false);
                            // setHasResponse(true);
                            setCapturing(false);
                        }
                    } else {
                        let errorMapeado = mapearError(response);
                        evento('Captura ID', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                        if (response.response) {
                            setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                            setErrorCapture(true);
                            setErrorF('Error en la captura de identificación');
                            setLoading(false);
                            setProccessing(false);
                            // setHasResponse(true);
                            setCapturing(false);
                            if (response.response.status === 400) {
                                setIntentsBack(intentsBack + 1);
                            }
                        } else {
                            if (errorMapeado.objetoError.error === "Network Error" || errorMapeado.objetoError.error === "timeout of 40000ms exceeded") {
                                setErrorMessage('Revisa tu conexión a internet e intenta nuevamente.');
                                setErrorCapture(true);
                                setErrorF('Error en la captura de identificación');
                                setLoading(false);
                                setProccessing(false);
                                // setHasResponse(true);
                                setCapturing(false);
                            } else {
                                setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                                setErrorCapture(true);
                                setErrorF('Error en la captura de identificación');
                                setLoading(false);
                                setProccessing(false);
                                // setHasResponse(true);
                                setCapturing(false);
                            }
                        }
                    }
                } catch (error) {
                    const errorMapeado = mapearError(error);
                    evento('Captura ID', errorMapeado.tipoError, errorMapeado.objetoError, false, props);
                    if (error.response) {
                        setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme e intenta nuevamente.');
                        setIntentsBack(intentsBack + 1);
                        setErrorCapture(true);
                        setErrorF('Error en la captura de identificación');
                        setLoading(false);
                        setProccessing(false);
                        // setHasResponse(true);
                        setCapturing(false);
                    } else {
                        //if (error.toString().includes('Network')) {
                        if (errorMapeado.objetoError.error === "Network Error" || errorMapeado.objetoError.error === "timeout of 40000ms exceeded") {
                            setErrorMessage('Revisa tu conexión a internet e intenta nuevamente.');
                            setErrorCapture(true);
                            setErrorF('Error en la captura de identificación');
                            setLoading(false);
                            setProccessing(false);
                            // setHasResponse(true);
                            setCapturing(false);
                        } else {
                            setErrorMessage('Por favor asegúrate que tu cámara este limpia y mantén firme  e intenta nuevamente.');
                            setErrorCapture(true);
                            setErrorF('Error en la captura de identificación');
                            setLoading(false);
                            setProccessing(false);
                            // setHasResponse(true);
                            setCapturing(false);
                        }
                    }
                }
            }
        }
    }

    const cancelarCapturaIdentificacion = () => {
        sendEventClick('Captura ID', 'CANCELAR', {}, props);
        history.replace('/preparacion_identificacion');
    }

    // const descargarImagen = (blob) => {
    //     let url = URL.createObjectURL(blob);
    //     let a = document.createElement('a');
    //     document.body.appendChild(a);
    //     a.style = 'display: none';
    //     a.href = url;
    //     a.download = 'captura_ine.png';
    //     a.click();
    // }

    const enviarReintento = (step, description) => {
        generarObjetoLifeCycle('reintento', { status: 'RETRY', step: step, description: description }, props);
    }

    const finalizarFlujo = async () => {
        setErrorCapture(false);
        setMostrarLoader(true);
        await generateZip(props, 'SEMAFORO', 'red', isIOS);
        localStorage.setItem('bloqueo', 'true');
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem('time', acceptDta.getTime())
        let res = convertdataJSON();
        let event_t = await JSON.stringify(res);
        let json_final = {
            'status': 'FAIL',
            'step': 'captura_id',
            'stepEvent': 'finished',
            'description': event_t,
            'response': dataResponse,
            'bandera': 'red',
        }
        await flowIncompleted(props, json_final);
        localStorage.setItem('completado', 'true');
        setMostrarLoader(false)
        setDisableFinishButton(false)
    }

    const convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem('uuidUser') || '';
        jsonObj.uuidTrx = localStorage.getItem('uuidTrx') || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem('ocrFront')) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem('ocrBack')) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem('data_user')) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    const setCanvasScalingFactor = () => {
        return window.devicePixelRatio || 1;
    }

    const resizeCanvas = () => {
        //Gets the devicePixelRatio
        let width = 0;
        let pixelRatio = setCanvasScalingFactor();

        //The viewport is in portrait mode, so let width should be based off viewport WIDTH
        if (window.innerHeight > window.innerWidth) {
            //Makes the canvas 100% of the viewport width
            width = Math.round(1.0 * window.innerWidth);
        }
        //The viewport is in landscape mode, so let width should be based off viewport HEIGHT
        else {
            //Makes the canvas 100% of the viewport height
            width = Math.round(1.0 * window.innerHeight);
        }

        //This is done in order to maintain the 1:1 aspect ratio, adjust as needed
        let height = width;

        //This will be used to downscale the canvas element when devicePixelRatio > 1
        refContainerCanvasMask.current.style.width = width + "px";
        // refContainerCanvasMask.current.style.height = height + "px";

        refCanvasMask.current.width = width * pixelRatio;
        refCanvasMask.current.height = height * pixelRatio;
    }

    const handleErrorMediaUser = (error) => {
        const errorObject = mapErrorGetUserMedia(error);
        evento('Captura ID', 'User Media', errorObject, true, props);
        if (errorObject.status === 'PERMISOS DENEGADOS')
            setErrorPermissions(true);
        else
            setErrorVideo(true);
    }

    // const handleErrorMediaUser = (error) => {
    //     // console.log('NAME ERROR ', error.name);
    //     if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
    //         //required track is missing 
    //         evento('Captura ID', 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true, props);
    //         setErrorVideo(true);
    //     } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
    //         //webcam or mic are already in use 
    //         evento('Captura ID', 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true, props);
    //         setErrorVideo(true);
    //     } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
    //         //constraints can not be satisfied by avb. devices 
    //         evento('Captura ID', 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true, props);
    //         setErrorVideo(true);
    //     } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
    //         //permission denied in browser 
    //         evento('Captura ID', 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true, props);
    //         setErrorPermissions(true);
    //     } else if (error.name === 'TypeError' || error.name === 'TypeError') {
    //         //empty constraints object 
    //         evento('Captura ID', 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true, props);
    //         setErrorVideo(true);
    //     } else {
    //         //other errors 
    //         evento('Captura ID', 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true, props);
    //         setErrorVideo(true);
    //     }
    // }

    const redirect = () => {
        if (validarRedireccionamiento(dataOtorgante)) {
            setTimeout(() => {
                history.replace(`/${apikey}`);
                setLoading(false);
            }, 300);
        } else {
            setShowCancelScreen(true)
        }
    }

    return (
        <>
            {/* {(isStart && !showHelp) &&
                <>
                    <div className='nu_gradient hollo_bx show_id_hold'>

                        <Header dataOtorgante={dataOtorgante} apikey={apikey} show={() => setShowHelp(true)} />

                        <div className='main_text_container'>
                            <h1 className='fake_over'>Tómale una foto al frente de tu identificación</h1>
                            <br />
                            <p className='izquierdaText'>Colócala sobre una <b>superficie obscura</b> y <b>encuádrala</b> con la guía.
                                <br />
                                <br />
                                ¡Sigue las <b>instrucciones</b>!
                            </p>
                            <br />
                            <div className='row id_type_bx'>
                                <div className='col-12'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <img src={process.env.PUBLIC_URL + '/images/componente_captura_identificacion/id_check.svg'} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={refDivFooter} className='div_bottom' id='div-buttons-calibrate'>
                            <div className='action_buttons'>
                                <button type='button' onClick={(e) => {
                                    iniciarCaptura();
                                    permisoCamara();
                                }} className='btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__fadeIn animate__delay-1s'>CONTINUAR</button>
                                <button onClick={() => cancelarCapturaIdentificacion()} className='btn btn-primary forcewidth100 main_color animate__animated animate__fadeIn animate__delay-1s'>CANCELAR</button>
                            </div>
                        </div>
                    </div>
                </>} */}

            <div className='module_container desk_id_capture' style={{ position: 'fixed' }}>
                <div className='camera_capture_frame'>
                    <video ref={refVideo} id='video_wrt' playsInline={true}></video>
                    <canvas ref={refCanvas} id='canvas_wrt_environment' hidden></canvas>
                </div>
                {/* <div className='module_gradient_overlay'></div>
                <div className='module_title animate__animated animate__slideInDown'>
                    <p>Coloca tu identificación  de <b>{side === 'front' ? 'frente' : 'reverso'}</b></p>
                </div>

                {(previewId) ? ('') : (isMobile) ?
                    <div>
                        <img className='id_mask_mobile animate__animated animate__fadeIn animate__delay-2s' src={'/images/componente_captura_identificacion/id_mask_front_mobile.svg'} alt='' />
                    </div>
                    :
                    <div>
                        <img className='ine_mask id_mask animate__animated animate__fadeIn animate__delay-2s' src={process.env.PUBLIC_URL + '/images/componente_captura_identificacion/id_mask_capture.svg'} alt='' />
                    </div>
                } */}
                <div className="main_container">
                    <div className="instruction_container">
                        {(!previewId && <>
                            <div className='module_title animate__animated animate__slideInDown'>
                                <p className="calibri_punto_trader">Coloca tu identificación  de <b>{side === 'front' ? 'frente' : 'reverso'}</b></p>
                            </div>
                            <div className='alert_instruction animate__animated animate__fadeIn animate__delay-1s'>
                                <img height='24' src={process.env.PUBLIC_URL + 'images/evita_reflejos_warn.png'} alt='' />
                            </div>
                        </>)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: '1 1' }}>
                        <div className='div-side-mask_container'></div>
                        <div className="mask_container" ref={refContainerCanvasMask}>
                            {(previewId) ? ('') : isMobile ?
                                <>
                                    <canvas className='mask_canvas' ref={refCanvasMask}></canvas>
                                    {/* <img className='id_mask_mobile animate__animated animate__fadeIn animate__delay-2s' src={'/images/componente_captura_identificacion/id_mask_front_mobile.svg'} alt='' /> */}
                                </>
                                :
                                <div>
                                    <img className='ine_mask id_mask animate__animated animate__fadeIn animate__delay-2s' src={`/images/componente_captura_identificacion/${(side === 'front') ? 'id_mask_capture_f.svg' : 'id_mask_capture_b.svg'}`} alt='' />
                                </div>
                            }
                            {/*  {side === 'front' ?
                            (!loading) && <img className={isMobile ? 'guide_id_img' : 'guide_id_img_desktop'} src={'images/componente_captura_identificacion/id_guide_front.svg'} alt='' />
                            :
                            (!loading) && <img className={isMobile ? 'guide_id_img' : 'guide_id_img_desktop'} src={'images/componente_captura_identificacion/id_guide_back.svg'} alt='' />
                        } */}
                        </div>
                        <div className='div-side-mask_container'></div>
                    </div>

                    <div className="buttons_container">
                        {intentsFront <= Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_ID_FRONTAL', '3')) && !previewId && !showHelp && (<div className='module_buttons_mask animate__animated animate__fadeIn animate__delay-2s'>
                            {/* {
                                (showSelect && side === 'front' && !proccessing) &&
                                <select className="forcewidth100 selectCamaras" onChange={(event) => configure(event.target.value)}>
                                    <option key="0" disabled={true} >Selecciona la mejor cámara aquí</option>
                                    {dispositivosVideo.map((camara, index) => {
                                        return (
                                            <option key={index} value={camara} selected={(camara[camara.length - 1] === index) ? "true" : "false"}>Cámara {index + 1}</option>
                                        );
                                    })}
                                </select>
                            } */}
                            {side === 'front' ?
                                <button ref={refButtonCapture} type='button' onClick={() => initRecord('front')} className={['btn btn-raised btn-primary forcewidth100', (capturing ? 'button_gray_punto_trader' : 'button_punto_trader'), (isMobile) ? '' : 'desk_id_capture_btn'].join(' ')} disabled={proccessing || loading}>{(proccessing || loading ? 'Procesando...' : 'Capturar frente')}</button>
                                :
                                <button ref={refButtonCapture} type='button' onClick={() => initRecord('back')} className={['btn btn-raised btn-primary forcewidth100', (capturing ? 'button_gray_punto_trader' : 'button_punto_trader'), (isMobile) ? '' : 'desk_id_capture_btn'].join(' ')} disabled={proccessing || loading}>{(proccessing || loading ? 'Procesando...' : 'Capturar reverso')}</button>}
                            {<button type='button' onClick={() => cancelarCapturaIdentificacion()} className={['btn btn-primary forcewidth100 button_outline_punto_trader', (isMobile) ? '' : 'desk_id_capture_btn'].join(' ')} disabled={proccessing || loading}>Cancelar</button>}
                        </div>)}
                    </div>
                </div>
            </div>
            {/* {(!previewId && <div className='alert_instruction animate__animated animate__fadeIn animate__delay-1s alert_light'>
                <img height='24' src={process.env.PUBLIC_URL + 'images/evita_reflejos_warn.png'} alt='' />
            </div>)} */}
            {((desktop && showIdOverlay) && <div className='id_hold show_id_hold'>
                <div className='center_info_bx'>
                    <img src={process.env.PUBLIC_URL + '/images/componente_captura_identificacion/id_hold_icon.png'} height='200' alt='' />
                    <p className="calibri_punto_trader">Toma tu identificación solo con dos dedos <br /> sin obstruir tu foto u otra información<br />
                        <small>Sugerimos tomarla de la esquina superior izquierda</small>
                    </p>
                    <br />
                    <button type='button' className='btn btn-raised btn-primary forcewidth100 main_bg_color button_punto_trader' onClick={() => { setShowIdOverlay(false); sendEventClick('Captura ID', 'ENTENDIDO EMPEZAR', {}, props); }}>Entendido empezar</button>
                </div>
            </div>)}

            {(intentsFront >= Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_ID_FRONTAL', '3'))) && (<div className='err_finale animate__animated animate__slideInUp'>
                <div className='center_checks'>
                    <h5 className="ubuntu_bold_punto_trader">Proceso no exitoso</h5>
                    <p className="calibri_punto_trader text_size_punto_trader">Has superado el número <b className="calibri_bold_punto_trader">máximo</b> de intentos, para este proceso inténtalo nuevamente <b className="calibri_bold_punto_trader">más tarde</b>.</p>
                    <p className="calibri_punto_trader text_size_punto_trader">Si deseas mayor información no dudes en contactarnos con el <b className="calibri_bold_punto_trader">ID</b> de proceso <b className="calibri_bold_punto_trader">{uuidTransaccion.split("-")[0]}</b> </p>
                    <br />
                    {(!window.opener) &&
                        <div className='action_buttons noscroll_screen'>
                            <button className='btn btn-raised btn-primary forcewidth100 button_punto_trader' disabled={disableFinishButton} onClick={redirect}>Entendido</button>
                        </div>
                    }
                </div>
            </div>)}

            {(proccessing) && <LoaderOCR side={side} show={() => setShowHelp(true)} dataOtorgante={dataOtorgante} />}
            {(mostrarLoader) && <Loader />}
            {((loading) && <div className='sprite_stay_a animado'></div>)}
            {/* {(isLandscape) && (
                <div className='rotate_device'>
                    <div className='center_info_bx'>
                        <img src={process.env.PUBLIC_URL + '/images/rotate_device.svg'} height='100' alt='' />
                        <p>Por favor usa tu dispositivo en vertical<br />
                            <small>Gira tu dispositivo para continuar</small>
                        </p>
                    </div>
                </div>
            )} */}
            {((intentsData === 1) || errorCapture) &&
                <div className='overlay_box'>
                    <div className='alert_box'>
                        <p className='calibri_punto_trader animate__animated animate__slideInDown'>{errorMessage}</p>
                        <button type='button' onClick={(e) => {
                            setIntentsData(2);
                            setErrorCapture(false);
                        }} className='btn btn-raised btn-primary forcewidth100 button_punto_trader alert_btn animate__animated animate__fadeIn animate__delay-2s'>Volver a intentar</button>
                    </div>
                </div>}
            {(errorMsg) &&
                <div className='overlay_box'>
                    <div className='alert_box'>
                        <p className='calibri_punto_trader animate__animated animate__slideInDown'>Se ha detectado un bloqueo de red, verifica tu conexión de internet</p>
                        <button type='button' onClick={(e) => {
                            setErrorMsg(false);
                        }} className='btn btn-raised button_punto_trader btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s'>Volver a intentar</button>
                    </div>
                </div>}
            {(errorVideo) &&
                <div className="overlay_box">
                    <div className="alert_box">
                        <p className="calibri_punto_trader animated slideInDown">Hemos detectado que la <b className="calibri_bold_punto_trader">cámara de tu dispositivo</b> está siendo usada por otra web en <b className="calibri_bold_punto_trader">alguna pestaña</b>, por favor asegúrate de <b className="calibri_bold_punto_trader">cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                        <button type="button" onClick={(e) => {
                            setErrorVideo(false)
                            window.location.reload()
                            //this.props.history.push("/identificacion")
                        }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader alert_btn  animated fadeIn delay-2s">Volver a intentar</button>
                    </div>
                </div>
            }
            {previewId && <ComponenteRecorte
                tipo='identificacion'
                imageUrl={imageUrl}
                enviarImagen={blobImage => enviarImagen(blobImage)}
                reload={() => { setPreviewId(false); setCapturing(false); sendEventClick('Captura ID', 'Capturar de nuevo', {}, props); }}
                side={side}
                configuration={props}
                setShowAlertDimensions={setShowAlertDimensions}
            />}
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {/* {msjMultiCamaras && <MultiCamara setMsjMultiCamaras={setMsjMultiCamaras} />} */}
            {errorPermissions && <ErrorPermissions permisosDenegados={errorPermissions} setErrorPermisos={setErrorPermissions} permisoCamara={permisoCamara} />}
            {onLoadViewCamera && <OverlayCargandoCamara />}
            {showAlertDimensions && <AlertaValidacionDimensiones setShowAlertDimensions={setShowAlertDimensions} />}
            {showCancelScreen && <PantallaCancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'fallido'} />}
        </>
    );
}

export default withOrientationChange(ComponenteCapturaIdentificacion);
