/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react"
/* Librerías */
import { Link, useHistory, useLocation } from "react-router-dom"
import { withOrientationChange } from 'react-device-detect'
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Ayuda from './ayuda'
/* Funciones */
import { configStyle, obtenerValorConfig, replaceValue, obtenerValorUser } from '../services/configStyle'
import { status, sendEventClick } from '../services/data';

let AutorizacionDocumento = (props) => {

    /* Props */
    const { isLandscape } = props;
    /* Hooks */
    const history = useHistory()
    const location = useLocation()
    const [nombre, setNombre] = useState('<Nombre de Usuario aqui>')
    const [showHelp, setShowHelp] = useState(false)
    const [fatcaAceptado, setFatcaAceptado] = useState(false)
    const [crsAceptado, setCrsAceptado] = useState(false)
    const [disabledChecbk, setDisabledCheck] = useState(false)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataUser, setDataUser] = React.useState({})

    useEffect(() => {
        const dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS) setDataUser(JSON.parse(dataUserLS))

        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) setDataOtorgante(JSON.parse(dataOtorganteLS))

        const bloqueo = localStorage.getItem("bloqueo")
        const newDate = new Date().getTime()
        if (bloqueo === 'true') {
            if (newDate >= localStorage.getItem("time")) {
                localStorage.setItem("bloqueo", false)
                localStorage.setItem("time", 0)
            }
        }

        const { fatcaAceptado, crsAceptado } = location.state
        if (fatcaAceptado) {
            setFatcaAceptado(fatcaAceptado)
        }
        if (crsAceptado) {
            setCrsAceptado(crsAceptado)
        }
        status("documento_formulario_fatca_page", "Documento de Autorización")
    }, [])


    useEffect(() => {
        if (dataUser.length > 0) localStorage.setItem("data_user", JSON.stringify(dataUser))
        setNombre(obtenerValorUser(dataUser, "nombreCompleto"))
    }, [dataUser])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const aceptarTerminos = check => {
        setDisabledCheck(true)
        const tituloDocumento = (obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2")
        const description = `He leído y acepto "${tituloDocumento}"`
        sendEventClick('Documento de Autorización', description, { check: check })
        if (check) {
            setTimeout(() => {
                history.replace({
                    pathname: '/clausula',
                    state: { formularioFatcaAceptado: check, fatcaAceptado: fatcaAceptado, crsAceptado: crsAceptado }
                })
            }, 1000)
        }
    };

    const printText = () => {
        let textToPrint = ''
        const textStorage = obtenerValorConfig(dataOtorgante, "TEXTO_DE_CONSENTIMIENTO_3")
        textToPrint = replaceValue(textStorage, "{USUARIO}", nombre)
        textToPrint = replaceValue(textToPrint, "{OTORGANTE}", obtenerValorConfig(dataOtorgante, "RAZON_SOCIAL"))
        if (textToPrint) {
            return textToPrint.split("{enter}").map((line, index) => {
                return (<Fragment key={index}>{line} <br /></Fragment>);
            });
        } else {
            return textToPrint;
        }
    }

    return (
        <>
            <div className="main_gradient">
                <Header show={() => setShowHelp(true)} />
                <h5 className="left-align margin_4 text-color ubuntu_bold_punto_trader">
                    <b>{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3")}</b>
                </h5>
                <div className="terms_container">
                    <div className="terms_bg_box">
                        <span align="left" className="left-align calibri_punto_trader">
                            <h6 className="text-right text-small text-strong ubuntu_bold_punto_trader">
                                <small>
                                    <strong>
                                        {(obtenerValorConfig(dataOtorgante, "LUGAR_Y_FECHA_CONSENTIMIENTO_3", "FALSO") === "FALSO") ? ("") : (<Fragment>{obtenerValorConfig(dataOtorgante, "CIUDAD_LEGAL_DEL_OTORGANTE")} a {new Intl.DateTimeFormat("es-MX", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit"
                                        }).format(new Date())}</Fragment>)}
                                    </strong>
                                </small>
                            </h6>

                            {printText()}
                        </span>
                        <div className="terms_bg_box_gradient"></div>
                    </div>
                </div>
                <div className="check_container calibri_punto_trader">
                    <form>
                        <div className="checkbox">
                            <label htmlFor="check_terms">
                                <input id="check_terms" type="checkbox" onChange={e => aceptarTerminos(e.target.checked)} disabled={disabledChecbk} />
                                <span className="checkbox-decorator"><span className="check"></span></span>
                                He leído y acepto "{obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3")}".
                            </label>
                        </div>
                    </form>
                </div>
                <br />
                <br />
                <br />
                <Footer />
            </div>
            {
                (isLandscape) && (
                    <div className="rotate_device">
                        <div className="center_info_bx">
                            <img src="images/rotate_device.svg" height="100" alt="" />
                            <p className="calibri_punto_trader">Por favor usa tu dispositivo en vertical<br />
                                <small>Gira tu dispositivo para continuar</small>
                            </p>
                        </div>
                    </div>
                )
            }
            {(localStorage.getItem("bloqueo") === 'true') && (<div className="err_finale animate__animated animate__slideInUp">
                <div className="center_checks">
                    <h5 className="ubuntu_bold_punto_trader">Proceso no exitoso</h5>
                    <p className="calibri_punto_trader">Has superado el número <b className="calibri_bold_punto_trader">máximo</b> de intentos para este proceso inténtalo nuevamente <b className="calibri_bold_punto_trader">más tarde</b>.</p>
                    <p className="calibri_punto_trader">Si deseas mayor información no dudes en contactarnos con el <b className="calibri_bold_punto_trader">Folio</b> de proceso <b className="calibri_bold_punto_trader">{localStorage.getItem("uuidUser")}</b> </p>
                    <br />
                    <div className="action_buttons noscroll_screen">
                        <Link to={"/" + localStorage.getItem("apikeyOtorgante")} className="btn btn-raised btn-primary forcewidth100 button_punto_trader" replace>Entendido</Link>
                    </div>
                </div>
            </div>)}
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>
    )
}
AutorizacionDocumento = withOrientationChange(AutorizacionDocumento)
export default AutorizacionDocumento

