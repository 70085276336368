import { actualizarEstado, enviarImagenOCR, obtenerOCR, enviarImagenEvaluar, generacionNom151, enviarActualizacionScore } from './api';
import { evento } from '../utilities/events/eventos';
import { mapearRespuesta, mapearError } from '../utilities/response/map_response';
import { obtenerValorConfig, obtenerValorUser } from '../utilities/styles/configStyle';

const bucket = process.env.REACT_APP_BUCKET;

export const hayExcepcion = () => {
    const uuidExcepcion = ['Wco5CTXw7e0JoKR9p7TB9fqOjpUS7iuu', 'VCVi90WBhqtRPWboXtfpHBQlLPO00jd4', 'MQZq6jzleILkHbQhGULIdwPhn5Jv19HD'];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem('apikeyOtorgante')) {
            respuesta = true
        }
    });
    return respuesta
}

/*---- Servicio Life cycle ---*/
export const generarObjetoLifeCycle = async (tipoEvento, objeto, configuration) => {
    if (localStorage.getItem('completado') === 'false') {
        let json = {};
        let event = null;
        const tipoFlujo = localStorage.getItem('tipoFlujo') || '';
        let objetoLifeCycle = {
            'trx_id': configuration.uuidTransaccion,
            'company_id': configuration.apikey,
            'client_id': configuration.uuidUser,
            'origin': 'celd-pcb',
            'nombreOtorgante': configuration.nombreOtorgante,
            'event': null,
            'step': null,
            'flujo': tipoFlujo
        }
        const bandera = objeto.bandera ? objeto.bandera : localStorage.getItem('flag') || '';
        const nombreCompleto = obtenerValorUser(configuration.dataUser, 'nombreCompleto', '') || '';
        const telefono = localStorage.getItem('movil') || '';
        const correo = localStorage.getItem('correoElectronico') || '';
        const tipoIdentificacion = localStorage.getItem('typeID') || '';

        switch (tipoEvento) {
            case 'inicio':
                /* Enviar información a servicio de Life cycle */
                json = {
                    'status': objeto.status,
                    'step': objeto.stepEvent,
                }
                event = JSON.stringify(json);

                objetoLifeCycle.step = objeto.step;
                objetoLifeCycle.event = event;
                await enviarInformacionLifeCycle(objetoLifeCycle, configuration);

                /* Enviar información al pivote */
                if (window.opener && configuration.conexionPivote) {
                    enviarInformacionPivote('inicio', { step: 'inicio_page', result: 'OK', });
                    enviarInformacionPivote('inicio', {
                        step: 'inicio',
                        result: 'OK',
                        data: {
                            uuid: localStorage.getItem('uuidUser')
                        }
                    });

                    /* localStorage.setItem('externalReference', new URL(window.location).searchParams.get('referer'))
                    localStorage.setItem('externalJWT', new URL(window.location).searchParams.get('jwt')) */
                }
                /*  if (new URL(window.location).searchParams.get('referer')) {
                     localStorage.setItem('externalReference', new URL(window.location).searchParams.get('referer'))
                 }
                 if (new URL(window.location).searchParams.get('schema')) {
                     localStorage.setItem('package', new URL(window.location).searchParams.get('schema'))
                 }
                 if (new URL(window.location).searchParams.get('jwt')) {
                     localStorage.setItem('externalJWT', new URL(window.location).searchParams.get('jwt'))
                 } */
                break;
            case 'paso':
                /* Enviar información a servicio de Life cycle */
                json = {
                    'status': objeto.status,
                    'step': objeto.stepEvent,
                }

                event = JSON.stringify(json);

                objetoLifeCycle.step = objeto.step;
                objetoLifeCycle.event = event;
                objetoLifeCycle.nombreCompleto = nombreCompleto;
                objetoLifeCycle.telefono = telefono;
                objetoLifeCycle.correoElectronico = correo;
                objetoLifeCycle.bandera = bandera;
                objetoLifeCycle.typeID = tipoIdentificacion;

                await enviarInformacionLifeCycle(objetoLifeCycle, configuration);

                /* Enviar información al pivote */
                if (window.opener && configuration.conexionPivote) {
                    enviarInformacionPivote(objeto.step, { step: objeto.step, result: 'OK' });
                }

                break;
            case 'pasoInformacion':
                /* Enviar información a servicio de Life cycle */
                json = {
                    'status': objeto.status,
                    'step': objeto.step,
                    'description': objeto.description,
                }
                event = JSON.stringify(json)

                objetoLifeCycle.step = objeto.step;
                objetoLifeCycle.event = event;
                objetoLifeCycle.nombreCompleto = nombreCompleto;
                objetoLifeCycle.telefono = telefono;
                objetoLifeCycle.correoElectronico = correo;
                objetoLifeCycle.bandera = bandera;
                objetoLifeCycle.typeID = tipoIdentificacion;

                await enviarInformacionLifeCycle(objetoLifeCycle, configuration);

                /* Enviar información al pivote */
                if (window.opener && configuration.conexionPivote) {
                    enviarInformacionPivote(objeto.step, {
                        step: objeto.step,
                        result: 'OK',
                        data: objeto.description
                    });
                }
                break;
            case 'error':
                json = {
                    'status': objeto.status,
                    'step': objeto.updateStep,
                    'description': objeto.description,
                }

                if (objeto.tipo === 'cancelado') {
                    json.finished = true;
                }
                event = JSON.stringify(json);

                objetoLifeCycle.step = objeto.step;
                objetoLifeCycle.event = event;
                objetoLifeCycle.nombreCompleto = nombreCompleto;
                objetoLifeCycle.telefono = telefono;
                objetoLifeCycle.correoElectronico = correo;
                objetoLifeCycle.bandera = bandera;
                objetoLifeCycle.typeID = tipoIdentificacion;

                await enviarInformacionLifeCycle(objetoLifeCycle, configuration);

                /* Enviar información al pivote */
                if (window.opener && configuration.conexionPivote) {
                    enviarInformacionPivote(objeto.step, {
                        step: objeto.step,
                        status: 'INCOMPLETED',
                        type: 'PROCESS',
                        error_text: objeto.description
                    });
                    // if (objeto.tipo === 'cancelado') {
                    //     window.close();
                    // }
                }

                break;
            case 'reintento':
                /* Enviar información a servicio de Life cycle */
                json = {
                    'status': objeto.status,
                    'step': objeto.step,
                    'description': objeto.description,
                }
                event = JSON.stringify(json)

                objetoLifeCycle.step = objeto.step;
                objetoLifeCycle.event = event;
                objetoLifeCycle.nombreCompleto = nombreCompleto;
                objetoLifeCycle.telefono = telefono;
                objetoLifeCycle.correoElectronico = correo;
                objetoLifeCycle.bandera = bandera;
                objetoLifeCycle.typeID = tipoIdentificacion;

                await enviarInformacionLifeCycle(objetoLifeCycle, configuration);
                break;
            case 'flujoFinalizado':
                json = {
                    'status': objeto.status,
                    'step': objeto.stepEvent,
                    'description': objeto.description,
                    'response': objeto.response,
                    'finished': true,
                }

                event = JSON.stringify(json);

                objetoLifeCycle.step = objeto.step;
                objetoLifeCycle.event = event;
                objetoLifeCycle.nombreCompleto = nombreCompleto;
                objetoLifeCycle.telefono = telefono;
                objetoLifeCycle.correoElectronico = correo;
                objetoLifeCycle.bandera = bandera;
                objetoLifeCycle.typeID = tipoIdentificacion;

                await enviarInformacionLifeCycle(objetoLifeCycle, configuration);

                /* Enviar información al pivote */
                if (window.opener && configuration.conexionPivote) {
                    enviarInformacionPivote(objeto.step, {
                        step: objeto.step,
                        status: 'INCOMPLETED',
                        type: 'PROCESS',
                        error_text: 'Finalizado por reintentos',
                    });
                }
                break;
            default:
                break;
        }
    } else {
        return
    }
}

const enviarInformacionLifeCycle = async (objetoInformacion, configuration) => {
    if (localStorage.getItem('completado') === 'false') {
        let dataEvent = null;

        dataEvent = JSON.parse(objetoInformacion.event)

        return actualizarEstado(objetoInformacion, configuration).then(async ({ status }) => {
            if (status === 200) {
                await evento(dataEvent.step, 'Update Cycle', mapearRespuesta(dataEvent.status, objetoInformacion), true, configuration);
            }
        }).catch((error) => {
            let errorMapeado = mapearError(error);
            evento(dataEvent.step, errorMapeado.tipo, errorMapeado.objetoError, false, configuration);
        })
    } else {
        return
    }
}

const enviarInformacionPivote = (paso, objetoInformacion) => {
    switch (paso) {
        case 'inicio':
            const referer = localStorage.getItem('externalReference');
            window.opener.postMessage(JSON.stringify(objetoInformacion), referer);
            break;
        default:
            window.opener.postMessage(JSON.stringify(objetoInformacion), localStorage.getItem('externalReference'));
            break;
    }
}
/*-------*/

/*--- Servicio extracción OCR ---*/
export const extraerOCR = (objeto, archivo, lado) => {

    let data = new FormData();
    let newHeaders = [];

    data.append('file', archivo, lado + '-' + (new Date().getTime()) + '.png');
    data.append('event', lado);
    data.append('clientID', objeto.uuidUser);

    newHeaders.push({ key: 'X-Consumer-Custom-ID', value: objeto.uuidOtorgante });
    newHeaders.push({ key: 'X-Consumer-Username', value: objeto.nombreOtorgante });

    return enviarImagenOCR(objeto, data, newHeaders).catch(error => error)
}

export const extraerOCRCompleto = (objeto, archivo, lado, isIOS, isMobile) => {

    let data = new FormData();
    data.append('uuidUser', objeto.uuidUser);
    data.append('ine', archivo, lado + '-' + (new Date().getTime()) + '.png');
    data.append('side', lado);
    data.append('bucket', bucket);
    data.append('originOS', isIOS ? 'ios' : 'android');
    //data.append('SERVICE_ROTATE_IMAGE', isMobile ? true : false);
    data.append('SERVICE_ROTATE_IMAGE', false);

    return obtenerOCR(objeto, data).catch(error => error);
}
/*-------*/

/*--- Servicio clasificación de imágenes ---*/
export const enviarImagenIdentificacion = (objeto, imagen, modelo, nombre, lado) => {

    let data = new FormData();

    data.append('model', modelo);
    data.append('photo', imagen, nombre);
    data.append('useScore', true);
    data.append('event', lado);

    return enviarImagenEvaluar(objeto, data);
}
/*-------*/

/*--- Generar fecha a 4 dígitos ---*/
export const generarFecha = (fechaOCR) => {
    let fecha = ''
    if (fechaOCR.length > 6 && fechaOCR.length < 10) {
        let añoActualInt = new Date().getFullYear().toString().substr(-2);
        let añoFechaOcr = fechaOCR.substr(-2);
        let añoFechaOcrInt = fechaOCR.substr(-2);
        let fechaSinAño = fechaOCR.substr(0, 6);
        if (añoFechaOcrInt >= añoActualInt) {
            fecha = fechaSinAño + '19' + añoFechaOcr;
        } else {
            let mayoriaDeEdad = añoActualInt - 18;
            if (añoFechaOcrInt <= mayoriaDeEdad) {
                fecha = fechaSinAño + '20' + añoFechaOcr;
            } else {
                fecha = fechaSinAño + '20' + añoFechaOcr;
            }
        }
    } else if (fechaOCR.length < 6) {
        fecha = '';
    } else {
        fecha = fechaOCR;
    }

    return fecha;
}
/*-------*/

/*--- Mapear objeto de ocr ---*/
export const mapearOCR = (objeto, tipoOCR) => {
    let objetoMapeado = {};
    switch (tipoOCR) {
        case 'front':
            let fechaOCR = '';
            if (objeto.fechaNacimiento) {
                fechaOCR = generarFecha(objeto.fechaNacimiento);
            }
            objetoMapeado = {
                nombreCompleto: objeto.nombreCompleto || '',
                curp: objeto.curp || '',
                dni: objeto.dni || '',
                claveElector: objeto.claveElector || '',
                estado: objeto.estado || '',
                municipio: objeto.municipio || '',
                seccion: objeto.ineSeccion || '',
                colonia: objeto.colonia || '',
                calle: objeto.calle || '',
                cp: objeto.cp || '',
                numero: objeto.numero || '',
                anioRegistro: objeto.anioRegistro || '',
                numeroRegistro: objeto.numeroRegistro || '',
                ineRegistro: objeto.ineRegistro || '',
                emision: objeto.ineEmision || '',
                vigencia: objeto.ineVigencia || '',
                fechaNacimiento: fechaOCR,
                sexo: objeto.sexo || '',
                apellidoPaterno: objeto.apellidoPaterno || '',
                apellidoMaterno: objeto.apellidoMaterno,
                nombres: objeto.nombres || '',
                identificacionElectoral: objeto.identificacionElectoral,
                documentoNacionalPeru: objeto.documentoNacionalPeru,
                identificacionMigratoria: objeto.identificacionMigratoria,
                identificacionPasaporte: objeto.identificacionPasaporte,
                documentoPermanente: objeto.documentoPermanente,
            };
            localStorage.setItem('ocrFront', JSON.stringify(objetoMapeado));
            return objetoMapeado;
        case 'back':
            objetoMapeado = {
                cadena: objeto.cadena,
                ineVigencia: objeto.ineVigencia,
                cicMRZ: objeto.cicMRZ,
                ciudadanoMRZ: objeto.ciudadanoMRZ,
                nombreCompletoMRZ: objeto.nombreCompletoMRZ,
                seccionMRZ: objeto.seccionMRZ,
            };
            localStorage.setItem('ocrBack', JSON.stringify(objetoMapeado));
            return objetoMapeado;
        default:
            return objetoMapeado;
    }
}
/*-------*/

/*--- Generación de archivop ZIP de NOM 151 ---*/
export const generateZip = async (configuration, tipoEnvio, bandera, isIOS) => {
    let objeto = {}
    let dataOtorgante = [];
    let dataUser = [];
    let uuid = '';
    let uuidTrx = '';
    let nombreEvento = '';

    let dataOtorganteLS = localStorage.getItem('dataOtorgante');
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    let uuidUser = localStorage.getItem('uuidUser');
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem('uuidTrx');
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataUserLS = localStorage.getItem('data_user')
    if (dataUserLS) {
        dataUser = JSON.parse(dataUserLS);
    }
    let sendZipTruncos = obtenerValorConfig(dataOtorgante, 'SEND_ZIP_TRUNCOS', 'false');
    let sendZipSemaforo = obtenerValorConfig(dataOtorgante, 'SEND_ZIP_SEMAFORO', 'false');
    let fecha = ''
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    let partner = obtenerValorConfig(dataOtorgante, 'PARTNER', '')
    partner = partner.replace(' ', '_');
    if (partner !== '') {
        partner = partner + '_'
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', '')
    name_otorgante = name_otorgante.replace(' ', '_');

    objeto.tipo_envio = tipoEnvio;
    objeto.semaforo = bandera;
    objeto.uuidUser = uuid;
    objeto.originOS = isIOS ? 'ios' : 'android';
    objeto.custom_uuid = partner + name_otorgante + '_' + fecha + '_' + uuidTrx.split('-')[0];

    switch (bandera) {
        case 'trunco':
            nombreEvento = 'Generacíon de Nom 151 trunco';
            objeto.ocrFront = null;
            objeto.ocrBack = null;
            objeto.data_user = null;
            break;
        default:
            nombreEvento = 'Generacíon de Nom 151 con Bandera';
            objeto.name = obtenerValorUser(dataUser, 'nombreCompleto');
            objeto.email = '';
            objeto.fNac = obtenerValorUser(dataUser, 'nacimiento');
            objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, 'dni') : obtenerValorUser(dataUser, 'curp');
            objeto.vigencia = obtenerValorUser(dataUser, 'vigencia');
            objeto.ocrFront = JSON.parse(localStorage.getItem('ocrFront')) || {};
            objeto.ocrBack = JSON.parse(localStorage.getItem('ocrBack')) || {};
            objeto.data_user = JSON.parse(localStorage.getItem('data_user')) || [];
            objeto.coincidencia = 'Requiere validación';

            break;
    }
    if (((tipoEnvio === 'TRUNCOS') && (sendZipTruncos.toLowerCase() === 'true')) || ((tipoEnvio === 'SEMAFORO') && (sendZipSemaforo.toLowerCase() === 'true'))) {
        try {
            const response = await generacionNom151(configuration, objeto);
            const { status, data } = response;
            if (status && status === 200) {
                evento(nombreEvento, 'Success', mapearRespuesta(status, data), true, configuration);
            } else {
                let errorMapeado = mapearError(response);
                evento(nombreEvento, errorMapeado.tipoError, errorMapeado.objetoError, false, configuration);
            }
        } catch (error) {
            let errorMapeado = mapearError(error);
            evento(nombreEvento, errorMapeado.tipoError, errorMapeado.objetoError, false, configuration);
        }
    } else
        return
}
/*-------*/

/*--- Flujo terminado por reintentos --- */
export const flowIncompleted = (objeto, objetoInformacion) => {
    let data = {
        createScore: true,
        uuid: objeto.uuidUser,
        transaccion: objeto.uuidTransaccion,
    }
    return enviarActualizacionScore(objeto, data).then(async ({ status, data, data: { payload } }) => {
        if (status === 200) {
            evento('Actualizar Score', 'Success', mapearRespuesta(status, data), true, objeto);
        }
        await generarObjetoLifeCycle('flujoFinalizado', objetoInformacion, objeto);
    }).catch(async error => {
        let errorMapeado = mapearError(error);
        await evento('Actualizar Score', errorMapeado.tipoError, errorMapeado.objetoError, false, objeto);
        await generarObjetoLifeCycle('flujoFinalizado', objetoInformacion, objeto)
    });
}

export const validarRedireccionamiento = (dataOtorgante) => {
    let validacion = false;
    const enable_redirect = obtenerValorConfig(dataOtorgante, 'ENABLE_REDIRECT', '');
    const validacionRedirect = (enable_redirect && (enable_redirect.toLowerCase() === 'true')) ? true : false;

    if (validacionRedirect) {
        validacion = true;
    }
    return validacion;
}
/*-------*/

/* Mapear erro Get User Media */
export const mapErrorGetUserMedia = (error) => {
    let errorObject = {};
    if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        //required track is missing 
        errorObject = { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' };
    } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
        //webcam or mic are already in use 
        errorObject = { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' };
    } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
        //constraints can not be satisfied by avb. devices 
        errorObject = { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' };
    } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        //permission denied in browser 
        errorObject = { error: error.name, status: 'PERMISOS DENEGADOS' };
    } else if (error.name === 'TypeError' || error.name === 'TypeError') {
        //empty constraints object 
        errorObject = { error: error.name, status: 'CONSTRAINTS VACÍOS' };
    } else {
        //other errors 
        errorObject = { error: error.toString(), status: 'OTRO TIPO DE ERROR' };
    }
    return errorObject;
}
/*-------*/