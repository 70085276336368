import React, { useState } from "react"
/* Librerías */
import { useHistory, useLocation } from "react-router-dom"
/* Componentes */
import Header from "../components/headerNA"
import Footer from "../components/footer"
import Ayuda from "./ayuda"
/* Servicios */
import { obtenerEstadoEnlace } from '../services/api'

const PdfTokenResult = () => {

    /* Hooks */
    const location = useLocation()
    const history = useHistory()
    const [enlace] = useState(localStorage.getItem("enlace"))
    const [showHelp, setShowHelp] = useState(false)

    const descargaArchivo = () => {
        obtenerEstadoEnlace(enlace).then((response) => {
            console.info(response);
            if (response.status === 200) {
                //console.log("descarga");
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = enlace;
                a.download = "pdf_autorizacion.pdf"
                document.body.appendChild(a)
                a.click()
            }
        }).catch((error) => {
            console.log(`🚀 -----------------------------------------------------------------------🚀`)
            console.log(`🚀 ~ file: pdf_token_result.jsx:30 ~ obtenerEstadoEnlace ~ error:`, error)
            console.log(`🚀 -----------------------------------------------------------------------🚀`)
            if (location?.state?.type) {
                if (location.state.type === 'interactivo') {
                    history.replace({
                        pathname: '/preparacion_liveness',
                        state: { type: 'pdf' }
                    })
                } else if (location.state.type === 'transitivo') {
                    history.replace("/selfie_pdf")
                }
            } else {
                history.replace("/pdf_video_token")
            }
        })
    }

    return (
        <>
            <div className="main_gradient">
                <div className="flex_container">
                    <Header show={() => setShowHelp(true)} />
                    <div className="main_text_container flex_body">
                        <h1 className="title_punto_trader">Resultado de proceso </h1>
                        <p className='calibri_punto_trader'>Tu documento <b className="calibri_bold_punto_trader">esta listo</b>, puedes <b className="calibri_bold_punto_trader">verlo</b> o <b className="calibri_bold_punto_trader">descargarlo</b> en este momento.</p>
                        <br /><br />
                        <small>*<b>Respalda</b> tu documento, solo se puede <b>consultar una vez</b>.</small>
                    </div>
                    <div className="action_buttons noscroll_screen">
                        <button type="button" onClick={() => { descargaArchivo() }} className="btn btn-raised btn-primary forcewidth100 button_punto_trader">Descargar documento</button>
                        {/*  <a href={localStorage.getItem("enlace")} className="btn btn-raised btn-primary forcewidth100 main_bg_color">DESCARGAR DOCUMENTO</a> */}
                    </div>
                    <Footer />
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>)
}

export default PdfTokenResult