/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
/* Funciones */
import { obtenerValorConfig } from './utilities/styles/configStyle'

const Header = ({ dataOtorgante, show }) => {
    return (
        <>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") !== "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
                <a className="help_btn"><img src={process.env.PUBLIC_URL + '/images/btn_circle_help_c2all.svg'} alt="" onClick={() => show()} /></a>
            </div>
        </>
    )
}

export default Header;